<script setup lang="ts">
import BreadcrumbDefault from '@/components/Breadcrumbs/BreadcrumbDefault.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { ref } from 'vue';
import CandidacyProcess from "@/components/Company/candidacy/CreateProcessCandidacyComponent.vue";



const pageTitle = ref("Processos de Seleção");

</script>

<template>
    <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <CandidacyProcess />
    </DefaultLayout>
  </template>
  
 