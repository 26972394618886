<template>
  <div>
    <div class="flex items-center gap-2 mb-4">
      <button v-for="tab in tabs" :key="tab" @click="activeTable = tab"
        :class="['flex-1 rounded-lg p-4 cursor-pointer font-bold duration-200 bg-gray dark:bg-black dark:border-gray dark:border-[1px] dark:text-white', { 'bg-primary text-white dark:bg-primary': activeTable === tab }]">
        {{ translations[tab] }}
      </button>
    </div>
    <div>
      <div v-if="activeTable === 'dream1'">
        <slot name="dream1"></slot>
      </div>

      <div v-else-if="activeTable === 'dream2'">
        <slot name="dream2"></slot>
      </div>

      <div v-else-if="activeTable === 'dream3'">
        <slot name="dream3"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const activeTable = ref('dream1');
    const tabs = ['dream1', 'dream2', 'dream3'];
    const translations = {
      dream1: 'Perfil 1',
      dream2: 'Perfil 2',
      dream3: 'Perfil 3'
    };

    return {
      activeTable,
      tabs,
      translations
    }
  }
}
</script>
