<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="overflow-x-auto bg-white dark:bg-black shadow rounded-lg">
      <table class="min-w-full bg-white text-[base] md:text-[lg]">
        <thead class="bg-graydark text-white dark:bg-opacity-75">
          <tr class="text-[14px]">
            <th class="px-6 py-3 text-center font-medium text-gray-600">
              Nome Fantasia
            </th>
            <th class="px-6 py-3 text-center font-medium text-gray-600">
              Nome da Empresa
            </th>
            <th class="px-6 py-3 text-center font-medium text-gray-600">
              Contato
            </th>
            <th class="px-6 py-3 text-center font-medium text-gray-600">
              Ações
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray">
          <tr
            v-for="(entity, index) in enterprises"
            :key="entity.id"
            class="text-center odd:bg-white odd:dark:bg-strokedark even:bg-slate-200 even:dark:bg-black dark:border-strokedark"
          >
            <td class="px-6 py-4 flex justify-center items-center">
              {{ entity.tradename ? entity.tradename : "Não informado" }}
            </td>
            <td class="px-5 py-4">
                {{ entity.corporatename ? entity.corporatename : "Não informado" }}
              </td>
              <td class="px-6 py-4">
                {{ entity.cellphone ? entity.cellphone : "Não informado" }}
              </td>
            <td class="px-6 py-4 flex justify-center space-x-3">
              <button
                @click="analyzeEntity(entity)"
                class="font-medium text-primary hover:text-primary_hover"
              >
                <i class="fa-solid fa-eye"></i>
                Visualizar
                <span> </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal de Análise -->
    <div
      v-if="showAnalyzeModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
    >
      <div
        class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto h-[85svh] mt-[8vh] modal-show"
      >
        <div class="mt-6 flex justify-between items-center">
          <h2
            class="text-2xl font-semibold text-center text-gray-900 dark:text-gray-100"
          >
            Detalhes
          </h2>
          <button
            @click="closeAnalyzeModal"
            title="Fechar"
            class="text-primary px-4 py-2 hover:text-primary_hover"
          >
            <i class="fa-regular fa-circle-xmark text-3xl"></i>
          </button>
        </div>
        <div
          class="bg-slate-200 bg-opacity-40 dark:bg-black-2 dark:bg-opacity-5 p-6 rounded-lg"
        >
          <div class="mb-6 text-center">
            <img
              v-if="entityToAnalyze?.imagepath"
              :src="entityToAnalyze?.imagepath"
              alt="Logo"
              class="max-w-[150px] h-auto mx-auto rounded-full shadow-md"
            />
            <img
              v-else
              :src="photoDefault"
              alt="Logo"
              class="max-w-[150px] h-auto mx-auto rounded-full shadow-md"
            />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >CNPJ:</strong
              >
              <span class="block">{{ entityToAnalyze?.cnpj }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Email:</strong
              >
              <span class="block">{{ entityToAnalyze?.email }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Nome Fantasia:</strong
              >
              <span class="block">{{ entityToAnalyze?.tradename }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Nome da Empresa:</strong
              >
              <span class="block">{{ entityToAnalyze?.corporatename }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Cidade:</strong
              >
              <span class="block">{{ entityToAnalyze?.city }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Telefone Fixo:</strong
              >
              <span class="block">{{ entityToAnalyze?.landlinephone }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Código Postal:</strong
              >
              <span class="block">{{ entityToAnalyze?.postalcode }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Endereço:</strong
              >
              <span class="block">{{ entityToAnalyze?.address }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Valores Culturais e Organizacionais:</strong
              >
              <span class="block">{{
                entityToAnalyze?.culturalAndOrganizationalValues
              }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Missão e Visão:</strong
              >
              <span class="block">{{ entityToAnalyze?.missionAndVision }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Missão:</strong
              >
              <span class="block">{{ entityToAnalyze?.mission }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Visão:</strong
              >
              <span class="block">{{ entityToAnalyze?.vision }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Valores:</strong
              >
              <span class="block">{{ entityToAnalyze?.values }}</span>
            </div>
            <!-- Add more fields as needed -->
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Website Institucional:</strong
              >
              <a
                :href="entityToAnalyze?.institutionalWebsite"
                target="_blank"
                class="block text-blue-500 hover:underline"
                >{{ entityToAnalyze?.institutionalWebsite }}</a
              >
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Instagram:</strong
              >
              <a
                :href="`https://instagram.com/${entityToAnalyze?.instagram}`"
                target="_blank"
                class="block text-blue-500 hover:underline"
                >{{ entityToAnalyze?.instagram }}</a
              >
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Cartão CNPJ:</strong
              >
              <button
                @click="openDocument(entityToAnalyze?.cnpjcard)"
                class="block text-blue-500 hover:underline"
              >
                Ver Documento
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<style>
.modal-show::-webkit-scrollbar {
  width: 5px;
}

.modal-show::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-show::-webkit-scrollbar-thumb {
  background-color: #888888a2;
  border-radius: 5px;
}
</style>

<script setup>
import { ref } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import photoDefault from "@/assets/images/user/defaultUser.jpg";

const enterprises = ref([]);
const pageTitle = ref("Empresas Verificadas");
const showAnalyzeModal = ref(false);
const entityToAnalyze = ref(null);

const fetchEnterprises = async () => {
  try {
    const response = await axios.get("/enterprises", {
      params: { isverified: true },
    });
    const enterprisesData = response.data
      .map((ent) => ({ ...ent, type: "enterprise" }))
      .filter((ent) => ent.isverified === true); // Filtra os dados aqui
    return enterprisesData.sort(
      (a, b) => new Date(b.createdat) - new Date(a.createdat)
    );
  } catch (error) {
    console.error("Error fetching enterprises:", error);
    return [];
  }
};

const fetchSecretaries = async () => {
  try {
    const response = await axios.get("/secretaries", {
      params: { isverified: true },
    });
    const secretariesData = response.data
      .map((sec) => ({ ...sec, type: "secretary" }))
      .filter((sec) => sec.isverified === true); // Filtra os dados aqui
    return secretariesData.sort(
      (a, b) => new Date(b.createdat) - new Date(a.createdat)
    );
  } catch (error) {
    console.error("Error fetching secretaries:", error);
    return [];
  }
};

const fetchData = async () => {
  try {
    const enterprisesData = await fetchEnterprises();
    const secretariesData = await fetchSecretaries();

    // Filtra os dados para garantir que isverified seja false
    // Combina os dados de empresas e secretários
    const combinedData = [
      ...enterprisesData.filter((ent) => ent.isverified === true),
      ...secretariesData.filter((sec) => sec.isverified === true)
    ];

    // Ordena os dados pela data de criação
    enterprises.value = combinedData.sort(
      (a, b) => new Date(b.createdat) - new Date(a.createdat)
    );
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const analyzeEntity = async (entity) => {
  try {
    entityToAnalyze.value = entity;
    showAnalyzeModal.value = true;
  } catch (error) {
    console.error("Erro ao analisar empresa:", error);
    Swal.fire({
      icon: "error",
      title: "Error analyzing entity",
      text: error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const closeAnalyzeModal = () => {
  showAnalyzeModal.value = false;
  entityToAnalyze.value = null;
};

const openDocument = (documentUrl) => {
  if (documentUrl) {
    window.open(documentUrl, "_blank");
  } else {
    Swal.fire({
      icon: "error",
      title: "Documento não disponível",
      text: "O link para o documento não está disponível.",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

fetchData();
</script>