<script setup lang="ts">
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import UpdateHiringComponent from "@/components/Company/UpdateHiring.vue";
import { ref } from "vue";

const pageTitle = ref("Atualizar vaga");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="flex justify-center">
      <div
        class="bg-white dark:bg-black rounded-lg shadow-lg py-[30px] px-[30px]"
      >
        <UpdateHiringComponent></UpdateHiringComponent>
      </div>
    </div>
  </DefaultLayout>
</template>