import { createRouter, createWebHistory } from "vue-router";
import SigninView from "@/views/Authentication/SigninView.vue";
import SignupView from "@/views/Authentication/SignupView.vue";
import CalendarView from "@/views/CalendarView.vue";
import BasicChartView from "@/views/Charts/BasicChartView.vue";
import ECommerceView from "@/views/Dashboard/ECommerceView.vue";
import FormElementsView from "@/views/Forms/FormElementsView.vue";
import FormLayoutView from "@/views/Forms/FormLayoutView.vue";
import ProfileView from "@/views/Pages/Profile/ProfileView.vue";
import JobsView from "@/views/Pages/Jobs/JobsView.vue";
import InterView from "@/views/Pages/Interview/InterView.vue";
import TablesView from "@/views/TablesView.vue";
import AlertsView from "@/views/UiElements/AlertsView.vue";
import ButtonsView from "@/views/UiElements/ButtonsView.vue";
import Development from "@/views/Pages/Development/Development.vue";
import Avaliation from "@/views/Pages/Development/Avaliation.vue";
import Home from "@/views/Pages/Home/Home.vue";
import NewHiring from "@/views/Pages/Company/NewHiring/NewHiring.vue";
import CreateGroupCandidacyView from "@/views/Pages/Company/Candidacy/CreateGroupCandidacyView.vue";
import UpdateGroupCandidacyView from "@/views/Pages/Company/Candidacy/UpdateGroupCandidacyView.vue";
import AddNewHiring from "@/views/Pages/Company/AddNewHiring/AddNewHiringView.vue";
import UpdateHiring from "@/views/Pages/Company/UpdateHiring/UpdateHiringView.vue";
import CheckInterview from "@/views/Pages/Company/CheckInterview/CheckInterView.vue";
import DashboardGov from "@/views/Pages/Gov/DashboardGov/Panel.vue";
import UnderConstruction from "@/views/Pages/Helpers/UnderConstruction.vue";
import OpportunitiesView from "@/views/Pages/Opportunities/OpportunitiesView.vue";
import OpportunitiesChatView from "@/views/Pages/Opportunities/OpportunitiesChatView.vue";
import Curriculum from "@/views/Pages/Curriculum/Curriculum.vue";
import KanbanBoard from "@/components/Kanban/KanbanBoard.vue";
import CandidatesTable from "@/views/Pages/Company/Candidates/CandidatesTableView.vue";
import CandidateDetail from "@/views/Pages/Company/Candidates/CandidateDetailView.vue";
import CompaniesList from "@/components/Company/companies/companiesList.vue";
import DreamsList from "@/views/Pages/dreams/DreamsList.vue";
import VacanciesList from "@/views/Pages/Vacancies/VacanciesList.vue";
import Courses from "@/views/Pages/Courses/CoursesView.vue";
import AwaitingAnalysis from "@/views/Pages/Helpers/AwaitingAnalysis.vue";
import SkillsTable from "@/views/Pages/skills/SkillsTable.vue";
import EnterprisesPage from "@/views/Pages/Enterprises/EnterprisesPage.vue";
import EnterprisesVerfiedPage from "@/views/Pages/Enterprises/EnterprisesVerfiedPage.vue";
import ListChatBotComponent from "@/components/ChatBot/ListChatBot.vue";
import ChatBotComponent from "@/components/ChatBot/ChatBot.vue";
import Vacancies from "@/views/Pages/Gov/Vacancies/Vacancies.vue";
import SelectionPage from "@/views/Pages/Gov/Selection/SelectionPage.vue";
import NoticesPage from "@/views/Pages/Gov/Notices/NoticesPage.vue";
import AcceptNoticiesView from "@/views/Pages/Gov/Notices/AcceptNoticies/AcceptNoticiesView.vue";
import WrapperComponent from "@/views/Pages/Helpers/WrapperComponent.vue";
import WrapperChatComponent from "@/views/Pages/Helpers/WrapperChatComponent.vue";
import UserPublicEntenpriseView from "@/views/Pages/UserPublicEnterprise/UserPublicEnterpriseView.vue";
import AreaComponent from "@/components/Areas/AreaComponent.vue";
import ProfileEnterPriseView from "@/views/Pages/Gov/Notices/InfoEnterprise/infoEnterpriseView.vue";
import ListEditais from "@/views/Pages/Secretary/Editais/ListEditais.vue";
import ListCandidates from "@/views/Pages/ListCandidates/ListCandidates.vue";
import SearchCandidate from "@/views/Pages/SearchCandidate/SearchCandidate.vue";
import DashboardAdmin from "@/views/Pages/DashboardAdmin/DashboardAdminView.vue";
import CoursesAdd from "@/views/Pages/CoursesAdd/CoursesAdd.view.vue";
import JobsXlsxView from "@/views/Pages/Jobs/JobsXlxsView.vue";

const routes = [
  {
    mode: "history",
    path: "/avaliacao",
    name: "Avaliação",
    component: Avaliation,
    meta: {
      title: "Avaliação",
      requiresAuth: false,
    },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardGov,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/marcar-entrevista",
    name: "check-interview",
    component: CheckInterview,
    meta: {
      title: "Marcar entrevista",
      requiresAuth: true,
    },
  },
  {
    path: "/adicionar-vaga",
    name: "new-hiring",
    component: AddNewHiring,
    meta: {
      title: "Adicionar vagas",
      requiresAuth: true,
    },
  },
  {
    path: "/atualizar-vaga/:id",
    name: "update-hiring",
    component: UpdateHiring,
    meta: {
      title: "Atualizar vagas",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: SigninView,
    meta: {
      title: "Entrar",
    },
    props: true,
  },
  {
    path: "/cadastro",
    name: "register",
    component: SignupView,
    meta: {
      title: "Cadastro",
    },
  },
  {
    path: "/novas-vagas",
    name: "hiring",
    component: NewHiring,
    meta: {
      title: "Novas vagas",
      requiresAuth: true,
    },
  },
  {
    path: "/processo-seletivo",
    name: "processCandidacy",
    component: CreateGroupCandidacyView,
    meta: {
      title: "Processo Seletivo",
      requiresAuth: true,
    },
  },
  {
    path: "/processo-seletivo/editar/:id",
    name: "processUpdate",
    component: UpdateGroupCandidacyView,
    meta: {
      title: "Atualizar Processo Seletivo",
      requiresAuth: true,
    },
  },
  {
    path: "/desenvolvimento",
    name: "desenvolvimentoProfissional",
    component: Development,
    meta: {
      title: "Desenvolvimento",
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/vagas",
    name: "jobs",
    component: JobsView,
    meta: {
      title: "Vagas",
      requiresAuth: true,
    },
  },
  {
    path: "/entrevistas",
    name: "inteview",
    component: InterView,
    meta: {
      title: "Entrevistas Agendadas",
      requiresAuth: true,
    },
  },
  {
    path: "/perfil",
    name: "profile",
    component: ProfileView,
    meta: {
      title: "Perfil",
      requiresAuth: true,
    },
  },
  {
    path: "/users-public-enterprise",
    name: "Usuários Enterprise",
    component: UserPublicEntenpriseView,
    meta: {
      title: "Usuários Enterprise",
      requiresAuth: true,
    },
  },
  {
    path: "/professionalDevelopment",
    name: "eCommerce",
    component: ECommerceView,
    meta: {
      title: "eCommerce Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: CalendarView,
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
  },
  {
    path: "/forms/form-elements",
    name: "formElements",
    component: FormElementsView,
    meta: {
      title: "Form Elements",
      requiresAuth: true,
    },
  },
  {
    path: "/forms/form-layout",
    name: "formLayout",
    component: FormLayoutView,
    meta: {
      title: "Form Layout",
      requiresAuth: true,
    },
  },
  {
    path: "/tables",
    name: "tables",
    component: TablesView,
    meta: {
      title: "Tables",
      requiresAuth: true,
    },
  },
  {
    path: "/charts/basic-chart",
    name: "basicChart",
    component: BasicChartView,
    meta: {
      title: "Basic Chart",
      requiresAuth: true,
    },
  },
  {
    path: "/ui-elements/alerts",
    name: "alerts",
    component: AlertsView,
    meta: {
      title: "Alerts",
      requiresAuth: true,
    },
  },
  {
    path: "/ui-elements/buttons",
    name: "buttons",
    component: ButtonsView,
    meta: {
      title: "Buttons",
      requiresAuth: true,
    },
  },

  {
    path: "/em-construcao",
    name: "underConstruction",
    component: UnderConstruction,
    meta: {
      title: "Em construção",
      requiresAuth: true,
    },
  },

  {
    path: "/oportunidades",
    name: "oportunidades",
    component: OpportunitiesView,
    meta: {
      title: "Oportunidades",
      requiresAuth: true,
    },
  },

  {
    path: "/oportunidades/chat/:file",
    name: "oportunidadesChat",
    component: OpportunitiesChatView,
    meta: {
      title: "Oportunidades",
      requiresAuth: true,
    },
  },

  {
    path: "/oportunidades-naza",
    name: "OportunidadesNaza",
    component: WrapperComponent, // Atualize para apontar para o componente pai
    meta: {
      title: "Tutor Naza",
      requiresAuth: false,
      styles: {
        backgroundColor: "white",
        padding: "2.5rem",
        marginLeft: "5rem",
        marginTop: "2rem",
        borderRadius: "0.5rem",
      },
    },
  },

  {
    path: "/oportunidades-naza/chat/:file",
    name: "oportunidadesChatNaza",
    component: WrapperChatComponent,
    meta: {
      title: "Tutor Naza Chat",
      requiresAuth: false,
      styles: {
        backgroundColor: "white",
        padding: "2.5rem", // Aqui está o padding sendo definido
        margin: "2rem",
        borderRadius: "0.5rem",
      },
    },
  },

  {
    path: "/trilha",
    name: "trilharDoConhecimento",
    component: KanbanBoard,
    meta: {
      title: "Trilha do Conhecimento",
      requiresAuth: true,
    },
  },

  {
    path: "/curriculo/:id",
    name: "curriculum",
    component: Curriculum,
    meta: {
      title: "Currículo",
      requiresAuth: true,
    },
  },

  {
    path: "/candidates/:id",
    name: "CandidatesTable",
    component: CandidatesTable,
    meta: {
      title: "Lista de Candidatos",
      requiresAuth: true,
    },
  },
  {
    path: "/candidate/:idCandidate/candidacy/:idCandidacy/stage/:stage",
    name: "CandidateId",
    component: CandidateDetail,
    meta: {
      title: "Detalhes do Candidato",
      requiresAuth: true,
    },
  },
  {
    path: "/empresas",
    name: "companiesList",
    component: CompaniesList,
    meta: {
      title: "Empresas Populares",
      requiresAuth: true,
    },
  },
  {
    path: "/professional-dreams",
    name: "dreams",
    component: DreamsList,
    meta: {
      title: "Sonhos Profissionais",
      requiresAuth: true,
    },
  },

  {
    path: "/cursos",
    name: "courses",
    component: Courses,
    meta: {
      title: "Cursos",
      requiresAuth: true,
    },
  },

  {
    path: "/vacanciesList",
    name: "vacancies",
    component: VacanciesList,
    props: (route: { query: { q: any } }) => ({ query: route.query.q }),
    meta: {
      title: "Lista De Vagas",
      requiresAuth: true,
    },
  },
  {
    path: "/aguardando-analise",
    name: "AwaitingAnalysis",
    component: AwaitingAnalysis,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/habilidades",
    name: "Habilidades",
    component: SkillsTable,
    meta: {
      title: "Habilidades",
      requiresAuth: true,
    },
  },
  {
    path: "/empresas-pendentes",
    name: "PendingEnterprises",
    component: EnterprisesPage,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/empresas-verificadas",
    name: "EnterprisesVerfiedPage",
    component: EnterprisesVerfiedPage,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/vagas-page",
    name: "Vacancies",
    component: Vacancies,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/perfil-empresa/:id",
    name: "profile-enterprise",
    component: ProfileEnterPriseView,
    meta: {
      title: "Perfil da empresa",
      requiresAuth: true,
    },
  },
  {
    path: "/processos-selecao",
    name: "SelectionPage",
    component: SelectionPage,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/notices",
    name: "Notices",
    component: NoticesPage,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },
  {
    path: "/adesao-de-editais/:id",
    name: "AcceptNoticies",
    component: AcceptNoticiesView,
    meta: {
      title: "Adesão de Editais",
      requiresAuth: true,
    },
  },
  {
    path: "/area",
    name: "Area",
    component: AreaComponent,
    meta: {
      title: "Aguardando Análise",
      requiresAuth: true,
    },
  },

  // SECRETARY TYPE USER
  {
    path: "/adesao-de-editais",
    name: "AdesaoDeEditais",
    component: ListEditais,
    meta: {
      title: "Adesão de Editais",
      requiresAuth: true,
    },
  },
  {
    path: "/ListCandidates",
    name: "ListCandidates",
    component: ListCandidates,
    meta: {
      title: "Lista De Candidatos",
      requiresAuth: true,
    },
  },
  {
    path: "/SearchCandidate",
    name: "SearchCandidate",
    component: SearchCandidate,
    meta: {
      title: "Lista De Candidatos",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard-admin",
    name: "Dashboard Admin",
    component: DashboardAdmin,
    meta: {
      title: "Dashboard Admin",
      requiresAuth: true,
    },
  },
  {
    path: '/courses-add',
    name: "Courses Add",
    component: CoursesAdd,
    meta: {
      title: "Courses Add",
      requiresAuth: true,
    }
  },
  {
    path: '/jobs-xlsx',
    name: 'JobsXlsx',
    component: JobsXlsxView,
    meta: {
      title: "JobsXlsx",
      requiresAuth: true,
    }
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// Route Guard
router.beforeEach((to, from, next) => {
  document.title = `Oportunidade Jovem ${to.meta.title} | Governo do Piauí`;
  const isAuthenticated = !!localStorage.getItem("token");
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
