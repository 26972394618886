<script setup lang="ts">
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import CandidacyProcess from "@/components/Company/candidacy/CreateProcessCandidacyComponent.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { ref } from "vue";

const pageTitle = ref("Processo de Seleção");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    
    <CandidacyProcess />
  </DefaultLayout>
</template>