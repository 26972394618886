<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import axios from "@/api/axios.js";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default defineComponent({
  setup() {
    const message = ref<string>("");
    const files = ref<Record<string, any[]>>({});
    const router = useRouter();
    const route = useRoute();
    const usertype = ref<string | null>(localStorage.getItem("usertype"));
    const conditionAprroved = ref<boolean>(false);
    const routeStyles = route.meta.styles || {};
    const auth = ref<any>();

    const fetchSecretaryName = async (id: number): Promise<void | string> => {
      try {
        const resp = await axios.get(`/secretaries/${id}`);
        return resp.data.corporatename || "Nome da secretaria não disponível";
      } catch (error) {
        console.error(`Erro ao buscar o nome da secretaria: ${error}`);
        return "Nome da secretaria não disponível";
      }
    };

    const fetchData = async (): Promise<void> => {
      try {
        let resp;
        if (usertype.value == "candidate") {
          resp = await axios.get("/notices/candidate");
        } else if (
          usertype.value == "enterprise" ||
          usertype.value == "userEnterprise"
        ) {
          resp = await axios.get("/notices/enterprise");
        }
        if (resp && resp.data) {
          const notices = resp.data;
          const groupedNotices: Record<string, any[]> = {};

          for (const notice of notices) {
            notice.enterpriseName = await fetchSecretaryName(
              notice.id_secretary
            );
            if (!groupedNotices[notice.enterpriseName]) {
              groupedNotices[notice.enterpriseName] = [];
            }
            groupedNotices[notice.enterpriseName].push(notice);
          }
          files.value = groupedNotices;
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    const sendPropsToNotice = async (id: number): Promise<void> => {
      let date = new Date();
      try {
        let payload = {
          date_solicitation: date,
          id_notice: id,
        };
        await axios.post("props-notice", payload);
      } catch (error) {
        console.error(error);
      } finally {
        fetchData();
        Swal.fire("Sucesso", "Candidatura enviada!", "success");
      }
    };

    const openChat = (file: string): void => {
      const routeName = route.name;
      if (routeName === "OportunidadesNaza") {
        router.push(`/oportunidades-naza/chat/${file}`);
      } else {
        router.push(`/oportunidades/chat/${file}`);
      }
    };

    const openFile = (file: any): void => {
      window.open(file, "_blank");
    };

    const fetchAtualUser = async (): Promise<void> => {
      let user = await axios.post("auth/me");
      auth.value = user.data;
    };

    const verifyCandidacy = (props: any): string => {
      let id = localStorage.getItem("enterpriseId");
      let condition: string = "Habilitar-se";
      for (let i = 0; i < props.length; i++) {
        if (props[i].id_enterprise == id) {
          if (props[i].status == "accept") {
            condition = "Solicitação Aceita";
          } else if (props[i].status == "decline") {
            condition = "Solicitação Recusada";
          } else {
            condition = "Solicitação Enviada";
          }
        }
      }
      return condition;
    };

    const verifyUserType = (): boolean => {
      let resp: boolean = false;
      if (usertype.value == "secretary") {
        resp = true;
      } else if (usertype.value == "enterprise") {
        resp = true;
      } else if (usertype.value == "userSecretary") {
        if (auth.value.userSecretaries[0].permissionnotice == "full") {
          resp = true;
        } else {
          resp = false;
        }
      } else if (usertype.value == "userEnterprise") {
        if (auth.value.userenterprises[0].permissionnotice == "full") {
          resp = true;
        } else {
          resp = false;
        }
      }
      return resp;
    };

    fetchAtualUser();
    fetchData();
    return {
      sendPropsToNotice,
      openChat,
      verifyCandidacy,
      openFile,
      verifyUserType,
      conditionAprroved,
      usertype,
      files,
      routeStyles,
      auth,
    };
  },
});
onMounted(async () => {
  localStorage.setItem("page", "Oportunidades");
});
</script>

<template>
  <div v-if="Object.keys(files).length != 0" :style="routeStyles as any">
    <div v-for="(notices, enterpriseName) in files" :key="enterpriseName">
      <h3 class="ml-6 enterprise-name text-lg font-semibold mb-4">
        {{ enterpriseName }}
      </h3>
      <div
        class="files flex justify-between p-[10px] m-[8px] last:border-b-0 border-b-[1px] border-stroke"
        v-for="(item, index) in notices"
        :key="index"
      >
        <div class="flex flex-col">
          <div class="flex justify-start items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
            <span>{{ item.title }}</span>
          </div>
          <!-- <div class="mt-1">
            <small>Publicado por: {{ item.enterpriseName }}</small>
          </div> -->
        </div>
        <div class="section flex">
          <button
            class="cursor-pointer underline"
            @click="openFile(item.docpath)"
          >
            Visualizar
          </button>
          <button
            v-if="verifyCandidacy(item.props) == 'Solicitação Recusada'"
            class="cursor-pointer bg-red text-white ml-4 rounded-[12px] lg:px-[10px] px-[5px] py-[3px] text-[14px] lg:text-[16px]"
          >
            {{ verifyCandidacy(item.props) }}
          </button>
          <button
            @click="sendPropsToNotice(item.id)"
            :disabled="verifyCandidacy(item.props) != 'Habilitar-se'"
            v-if="
              verifyUserType() &&
              verifyCandidacy(item.props) != 'Solicitação Recusada'
            "
            :class="
              verifyCandidacy(item.props) == 'Habilitar-se'
                ? 'cursor-pointer bg-orange-500 text-white ml-4 hover:opacity-90 rounded-[12px] lg:px-[10px] px-[5px] py-[3px] text-[14px] lg:text-[16px]'
                : verifyCandidacy(item.props) == 'Solicitação Enviada'
                ? 'cursor-pointer bg-yellow-500 text-white ml-4 rounded-[12px] lg:px-[10px] px-[5px] py-[3px] text-[14px] lg:text-[16px]'
                : 'cursor-pointer bg-green-500 text-white ml-4 rounded-[12px] lg:px-[10px] px-[5px] py-[3px] text-[14px] lg:text-[16px]'
            "
          >
            {{ verifyCandidacy(item.props) }}
          </button>

          <button
            class="cursor-pointer text-white bg-primary ml-4 hover:bg-primary_hover rounded-[12px] lg:px-[10px] px-[5px] py-[3px] text-[14px] lg:text-[16px]"
            @click="openChat(item.filename)"
          >
            Chat Naza
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="Object.keys(files).length == 0">
    <div class="text-center">
      <p>Não existem editiais no momento, tente novamente mais tarde!</p>
    </div>
  </div>
</template>


<style scoped>
.enterprise-name {
  padding: 10px 0; /* Ajuste conforme necessário */
  margin-bottom: 15px;
  display: block;
}
</style>
