<template>
    <DefaultLayout>
        <BreadcrumbDefault :pageTitle="pageTitle" />
        <div>
            <div class="overflow-x-auto bg-white dark:bg-black shadow rounded-lg">
                <table class="min-w-full bg-white">
                    <thead class="bg-graydark text-white">
                        <tr>
                            <th class="px-6 py-3 text-center font-medium text-gray-600">
                                Nome Fantasia
                            </th>
                            <th class="px-6 py-3 text-center font-medium text-gray-600">
                                Nome da Empresa
                            </th>
                            <th class="px-6 py-3 text-center font-medium text-gray-600">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray">
                        <tr v-for="(entity, index) in enterprises" :key="entity.id"
                            class="text-center odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark">
                            <td class="px-6 py-4 flex justify-center items-center">
                                {{ entity.tradename }}
                            </td>
                            <td class="px-5 py-4">
                                {{ entity.corporatename }}
                            </td>
                            <td class="px-6 py-4 flex justify-center space-x-3">
                                <button @click="analyzeEntity(entity.id)" class="font-medium hover:underline">
                                    Analisar
                                </button>
                                <button @click="acceptEntity(entity.id)"
                                    class="text-[#11ac3d] font-medium hover:underline">
                                    Aceitar
                                </button>
                                <button @click="deleteEntity(entity.id)" class="text-red font-medium hover:underline">
                                    Excluir
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal de Rejeição -->
        <div v-if="showRejectModal" class="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-4 rounded-lg max-w-md">
                <h2 class="text-lg font-semibold mb-4">Justificativa</h2>
                <textarea v-model="rejectionReason"
                    class="w-full h-40 border border-gray-300 px-3 py-2 rounded-md mb-2"></textarea>
                <div class="flex justify-end">
                    <button @click="closeRejectModal"
                        class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2 hover:bg-gray-400">
                        Cancelar
                    </button>
                    <button @click="confirmReject"
                        class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                        Enviar
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal de Análise -->
        <div v-if="showAnalyzeModal"
            class="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto max-h-screen">
                <h2 class="text-2xl font-semibold mb-6 text-center text-gray-900 dark:text-gray-100">
                    Detalhes
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">CNPJ:</strong>
                        <span class="block">{{ entityToAnalyze?.cnpj }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Email:</strong>
                        <span class="block">{{ entityToAnalyze?.email }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Nome Fantasia:</strong>
                        <span class="block">{{ entityToAnalyze?.tradename }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Nome da Empresa:</strong>
                        <span class="block">{{ entityToAnalyze?.corporatename }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Cidade:</strong>
                        <span class="block">{{ entityToAnalyze?.city }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Telefone Fixo:</strong>
                        <span class="block">{{ entityToAnalyze?.landlinephone }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Código Postal:</strong>
                        <span class="block">{{ entityToAnalyze?.postalcode }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Endereço:</strong>
                        <span class="block">{{ entityToAnalyze?.address }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Valores Culturais e
                            Organizacionais:</strong>
                        <span class="block">{{
                            entityToAnalyze?.culturalAndOrganizationalValues
                        }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Missão e Visão:</strong>
                        <span class="block">{{ entityToAnalyze?.missionAndVision }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Missão:</strong>
                        <span class="block">{{ entityToAnalyze?.mission }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Visão:</strong>
                        <span class="block">{{ entityToAnalyze?.vision }}</span>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Valores:</strong>
                        <span class="block">{{ entityToAnalyze?.values }}</span>
                    </div>
                    <!-- Add more fields as needed -->
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Website Institucional:</strong>
                        <a :href="entityToAnalyze?.institutionalWebsite" target="_blank"
                            class="block text-blue-500 hover:underline">{{ entityToAnalyze?.institutionalWebsite }}</a>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Instagram:</strong>
                        <a :href="`https://instagram.com/${entityToAnalyze?.instagram}`" target="_blank"
                            class="block text-blue-500 hover:underline">{{ entityToAnalyze?.instagram }}</a>
                    </div>
                    <div>
                        <strong class="block text-gray-700 dark:text-gray-300">Cartão CNPJ:</strong>
                        <button @click="openDocument(entityToAnalyze?.cnpjcard)"
                            class="block text-blue-500 hover:underline">
                            Ver Documento
                        </button>
                    </div>
                </div>
                <div class="mt-6 text-center">
                    <img :src="entityToAnalyze?.imagepath" alt="Logo"
                        class="max-w-[150px] h-auto mx-auto rounded-lg shadow-md" />
                </div>
                <div class="mt-6 flex justify-center">
                    <button @click="closeAnalyzeModal"
                        class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script setup>
import { ref } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const enterprises = ref([]);
const pageTitle = ref("Empresas");
const showRejectModal = ref(false);
const entityToReject = ref(null);
const rejectionReason = ref("");
const showAnalyzeModal = ref(false);
const entityToAnalyze = ref(null);

const getAuthToken = () => {
    return localStorage.getItem("authToken");
};

const fetchEnterprises = async () => {
    try {
        const response = await axios.get("/enterprises", {
            params: { isverified: false },
        });
        const enterprisesData = response.data
            .map((ent) => ({ ...ent, type: "enterprise" }))
            .filter((ent) => ent.isverified === false); // Filtra os dados aqui
        return enterprisesData.sort(
            (a, b) => new Date(b.createdat) - new Date(a.createdat)
        );
    } catch (error) {
        console.error("Error fetching enterprises:", error);
        return [];
    }
};

const fetchSecretaries = async () => {
    try {
        const response = await axios.get("/secretaries", {
            params: { isverified: false },
        });
        const secretariesData = response.data
            .map((sec) => ({ ...sec, type: "secretary" }))
            .filter((sec) => sec.isverified === false); // Filtra os dados aqui
        return secretariesData.sort(
            (a, b) => new Date(b.createdat) - new Date(a.createdat)
        );
    } catch (error) {
        console.error("Error fetching secretaries:", error);
        return [];
    }
};

const fetchData = async () => {
    try {
        const enterprisesData = await fetchEnterprises();
        const secretariesData = await fetchSecretaries();

        // Filtra os dados para garantir que isverified seja false
        const filteredEnterprises = enterprisesData.filter(
            (ent) => ent.isverified === false
        );
        const filteredSecretaries = secretariesData.filter(
            (sec) => sec.isverified === false
        );

        enterprises.value = [...filteredEnterprises, ...filteredSecretaries];
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const analyzeEntity = async (entityId) => {
    try {
        const entity = enterprises.value.find((ent) => ent.id === entityId);
        if (!entity) throw new Error("Empresa não encontrada");

        let response;
        if (entity.type === "enterprise") {
            response = await axios.get(`/enterprises/${entityId}`);
        } else if (entity.type === "secretary") {
            response = await axios.get(`/secretaries/complete/${userId}`);
        } else {
            throw new Error("Unknown entity type");
        }

        entityToAnalyze.value = response.data;
        showAnalyzeModal.value = true;
    } catch (error) {
        console.error("Erro ao analisar empresa:", error);
        Swal.fire({
            icon: "error",
            title: "Error analyzing entity",
            text: error.message,
            showConfirmButton: true,
            confirmButtonText: "OK",
        });
    }
};

const acceptEntity = async (entityId) => {
    try {
        const entity = enterprises.value.find((ent) => ent.id === entityId);
        if (!entity) throw new Error("Empresa não encontrada");

        let endpoint;
        if (entity.type === "enterprise") {
            endpoint = `/enterprises/${entityId}`;
        } else if (entity.type === "secretary") {
            endpoint = `/secretaries/update/by-admin/${entityId}`;
        } else {
            throw new Error("Unknown entity type");
        }

        const token = getAuthToken();
        await axios.patch(
            endpoint,
            { isverified: true },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        Swal.fire({ icon: "success", title: "Empresa aceita com sucesso!" });
        enterprises.value = enterprises.value.filter((ent) => ent.id !== entityId);
    } catch (error) {
        console.error("Error accepting entity:", error);
        Swal.fire({
            icon: "error",
            title: "Erro ao aceitar empresa",
            text: error.message,
        });
    }
};

const deleteEntity = async (entityId) => {
    try {
        const entity = enterprises.value.find((ent) => ent.id === entityId);
        if (!entity) throw new Error("Empresa não encontrada");

        let endpoint;
        if (entity.type === "enterprise") {
            endpoint = `/enterprises/${entityId}`;
        } else if (entity.type === "secretary") {
            endpoint = `/secretaries/delete/${entityId}`;
        } else {
            throw new Error("Tipo de empresa desconhecida");
        }

        const token = getAuthToken();
        await axios.delete(endpoint, {
            headers: { Authorization: `Bearer ${token}` },
        });

        Swal.fire({ icon: "success", title: "Empresa deletada com Sucesso" });
        enterprises.value = enterprises.value.filter((ent) => ent.id !== entityId);
    } catch (error) {
        console.error("Error deleting entity:", error);
        Swal.fire({
            icon: "error",
            title: "Erro ao deletar empresa",
            text: error.message,
        });
    }
};

const releaseForTender = async (entityId) => {
    try {
        const entity = enterprises.value.find((ent) => ent.id === entityId);
        if (!entity) throw new Error("Entidade não encontrada");

        let endpoint;
        let updateData = { caneditaljob: true };

        if (entity.type === "enterprise") {
            endpoint = `/enterprises/${entityId}`;
        } else if (entity.type === "secretary") {
            // Use o endpoint para atualizações administrativas para secretárias
            endpoint = `/secretaries/update/by-admin/${entityId}`;
        } else {
            throw new Error("Tipo de entidade desconhecido");
        }

        const token = getAuthToken();
        await axios.patch(endpoint, updateData, {
            headers: { Authorization: `Bearer ${token}` },
        });

        Swal.fire({
            icon: "success",
            title: "Entidade liberada para edital com sucesso",
        });

        // Atualize a entidade na lista local
        const updatedEntity = enterprises.value.find((ent) => ent.id === entityId);
        if (updatedEntity) updatedEntity.caneditaljob = true;
    } catch (error) {
        console.error("Erro ao liberar entidade para edital:", error);
        Swal.fire({
            icon: "error",
            title: "Erro ao liberar entidade para edital",
            text: error.message,
        });
    }
};

const openRejectModal = (entityId) => {
    showRejectModal.value = true;
    entityToReject.value = enterprises.value.find((ent) => ent.id === entityId);
};

const closeRejectModal = () => {
    showRejectModal.value = false;
    rejectionReason.value = "";
};

const confirmReject = async () => {
    try {
        const entity = entityToReject.value;
        if (!entity) throw new Error("Entidade não encontrada");

        let endpoint;
        if (entity.type === "enterprise") {
            endpoint = `/enterprises/${entity.id}`;
        } else if (entity.type === "secretary") {
            endpoint = `/secretaries/update/by-admin/${entity.id}`;
        } else {
            throw new Error("Tipo de entidade desconhecido");
        }

        await axios.patch(endpoint, {
            isverified: false,
            reason: rejectionReason.value,
        });

        Swal.fire({ icon: "success", title: "Empresa rejeitada com sucesso" });
        await fetchData();
        closeRejectModal();
    } catch (error) {
        console.error("Error rejecting entity:", error);
        Swal.fire({
            icon: "error",
            title: "Erro ao rejeitar entidade",
            text: error.response ? error.response.data.message : error.message,
        });
    }
};

const closeAnalyzeModal = () => {
    showAnalyzeModal.value = false;
    entityToAnalyze.value = null;
};

const openDocument = (documentUrl) => {
    if (documentUrl) {
        window.open(documentUrl, "_blank");
    } else {
        Swal.fire({
            icon: "error",
            title: "Documento não disponível",
            text: "O link para o documento não está disponível.",
            showConfirmButton: true,
            confirmButtonText: "OK",
        });
    }
};

fetchData();
</script>