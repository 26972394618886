<template>
  <Disclosure
    as="nav"
    class="bg-white fixed w-full z-20 top-0 start-0 dark:bg-black shadow-md border-b-[1px] border-gray dark:border-boxdark lg:py-2"
    v-slot="{ open }"
  >
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 right-2 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="relative inline-flex items-center justify-center rounded-md p- focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon
              v-if="!open"
              class="block h-10 w-10"
              aria-hidden="true"
            />
            <XMarkIcon v-else class="block h-10 w-10" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-0 items-center justify-center sm:justify-between">
          <div class="flex items-center">
            <img
              src="@/assets/images/logo/oportunidade-jovem.png"
              class="lg:h-16 h-10"
              alt="Oportunidade Jovem"
            />
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex space-x-3">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              :class="[
                item.current ? '' : 'hover:text-primary duration-100',
                'px-3 py-2 text-sm font-medium flex justify-center items-start',
              ]"
              :aria-current="item.current ? 'page' : undefined"
            >
              <span>
                {{ item.name }}
              </span>
              <img
                v-if="item.icon ? item.icon : null"
                class="w-4 h-4 ml-1"
                :src="item.icon"
                alt="Icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            item.current ? '' : 'hover:text-primary duration-100',
            'block px-3 py-2 text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import user from "@/assets/images/icon/user-gray.svg";
import company from "@/assets/images/icon/company.svg";

const navigation = [
  { name: "Home", href: "https://contratai.mcolins.com.br", current: false },
  {
    name: "Cursos",
    href: "https://contratai.mcolins.com.br/#cursos",
    current: false,
  },
  {
    name: "Dúvidas",
    href: "https://contratai.mcolins.com.br/#duvidas",
    current: false,
  },
  {
    name: "Candidato",
    icon: user,
    href: "https://contratai.jm2.tec.br/login?usertype=candidate",
    current: false,
  },
  {
    name: "Empresa",
    icon: company,
    href: "https://contratai.jm2.tec.br/login?usertype=enterprise",
    current: false,
  },
];
</script>

<!-- </template> -->