<template>
  <div
    class="flex justify-between items-center shadow-md p-3 mb-2 cursor-pointer bg-white dark:bg-black border-[1px] border-stroke dark:border-bodydark2 rounded-lg"
    draggable="true" @dragstart="dragStart($event, card)" @click="showModal">
    <span class="text-black bg-prim dark:text-white font-medium text-[14px]">
      {{ card.title }}

    </span>

    <div>
      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <path
            d="M18.9445 9.1875L14.9445 5.1875M18.9445 9.1875L13.946 14.1859C13.2873 14.8446 12.4878 15.3646 11.5699 15.5229C10.6431 15.6828 9.49294 15.736 8.94444 15.1875C8.39595 14.639 8.44915 13.4888 8.609 12.562C8.76731 11.6441 9.28735 10.8446 9.946 10.1859L14.9445 5.1875M18.9445 9.1875C18.9445 9.1875 21.9444 6.1875 19.9444 4.1875C17.9444 2.1875 14.9445 5.1875 14.9445 5.1875M20.5 12C20.5 18.5 18.5 20.5 12 20.5C5.5 20.5 3.5 18.5 3.5 12C3.5 5.5 5.5 3.5 12 3.5"
            stroke="#7c7979" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>

      </svg>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import Swal from 'sweetalert2';
import axios from 'axios';

const CUTOFF_SCORE = 0.4;

const props = defineProps({
  card: Object,
});

const dragStart = (event, card) => {
  event.dataTransfer.setData('text/plain', card.id);
};

const fetchData = async (userQuery) => {
  const request = {
    n_candidates: 1,
    user_query: userQuery.replace('curso de ', '')
  }
  try {
    const response = await axios.post(`${import.meta.env.VITE_IA_API}/curses/query`, request);
    const data = response.data;
    console.log("Request para a api", request);
    console.log("resposta da api", data);
    if (data.curse_names && Array.isArray(data.curse_names) && data.curse_names.length > 0) {
      return {
        link: data.link,
        distances: data.distances[0]
      };
    } else {
      console.error('The response does not contain a valid "curse_names" property.');
      return null;
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return null;
  }
};

// const capitalizeWords = (str) => {
//   return str.replace(/\b\w/g, char => char.toUpperCase());
// };

const showModal = async () => {
  const description = props.card.description
    .replace(/[{}"]/g, '')
    .split(',');

  let listItems = '';
  for (const item of description) {
    const trimmedItem = item.trim();
    const result = await fetchData(trimmedItem);
    const listItemContent = `
      <li class="mb-2 text-[16px] capitalize-first">
        ${trimmedItem}
        ${await fetchData(trimmedItem).then(result => 
          result && typeof result.distances === 'number' && result.distances < CUTOFF_SCORE
            ? `<a href="${result.link}" target="_blank" rel="noopener noreferrer" class="text-primary underline">
                 <i class="fa-solid fa-arrow-up-right-from-square"></i>
               </a>`
            : ''
        )}
      </li>`;

    listItems += listItemContent;
  }

  Swal.fire({
    title: `<h2 class="leading-[1.2] text-[22px] max-w-[440px] mx-auto">${props.card.title}</h2>`,
    html: `<ul>${listItems}</ul>`,
    confirmButtonText: 'Fechar',
    didOpen: () => {
      const style = document.createElement('style');
      style.textContent = `
        .swal2-popup .swal2-html-container ul li.capitalize-first {
          text-transform: none;
        }
        .swal2-popup .swal2-html-container ul li.capitalize-first::first-letter {
          text-transform: capitalize;
        }
      `;
      document.head.appendChild(style);
    }
  });
};

</script>