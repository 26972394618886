<script setup lang="ts">
import { ref, onMounted } from 'vue'
import BreadcrumbDefault from '@/components/Breadcrumbs/BreadcrumbDefault.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import TableThree from '@/components/Tables/TableThree.vue';

const pageTitle = ref('Entrevistas Agendadas')
onMounted(async () => {
  localStorage.setItem("page", "Entrevistas Agendadas");
});
</script>

<template>
    <DefaultLayout>
        <BreadcrumbDefault :pageTitle="pageTitle" />
        <div>
            <TableThree />
        </div>
    </DefaultLayout>
</template>
