<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "@/api/axios.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const softSkills = ref([]);
const hardSkills = ref([]);
const pageTitle = ref("Habilidades");
const newSoftSkill = ref("");
const newSoftSkillCategory = ref("");
const newSoftSkillSummary = ref("");
const newHardSkill = ref("");
const newHardSkillCategory = ref("");
const newHardSkillSummary = ref("");
const isLoading = ref(false);
const selectedCategory = ref("Todas");
const softSkillSearchTerm = ref("");  // New ref for SoftSkills search term
const hardSkillSearchTerm = ref("");  // New ref for HardSkills search term

const showConfirmModal = ref(false);
const skillToDelete = ref(null);

const fetchSkills = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get("/skills");
    softSkills.value = response.data.filter(
      (skill) => skill.type === "sofskill"
    );
    hardSkills.value = response.data.filter(
      (skill) => skill.type === "hardskill"
    );
  } catch (error) {
    console.error("Erro ao buscar habilidades:", error);
  } finally {
    isLoading.value = false;
  }
};

const editSkill = (skill) => {
  console.log("Editando habilidade:", skill);
};

const confirmDelete = (id) => {
  skillToDelete.value = id;
  showConfirmModal.value = true;
};

const cancelDelete = () => {
  skillToDelete.value = null;
  showConfirmModal.value = false;
};

const confirmDeleteSkill = async () => {
  if (skillToDelete.value) {
    await deleteSkill(skillToDelete.value);
  }
  cancelDelete();
};

const deleteSkill = async (id) => {
  console.log("Excluindo habilidade com ID:", id);
  try {
    const response = await axios.delete(`/skills/${id}`);
    console.log("Habilidade excluída com sucesso:", response.data);
    await fetchSkills();
  } catch (error) {
    console.error("Erro ao excluir habilidade:", error);
  }
};

const addSkill = async (type) => {
  const skillToAdd =
    type === "sofskill" ? newSoftSkill.value : newHardSkill.value;
  const categoryToAdd =
    type === "sofskill"
      ? newSoftSkillCategory.value
      : newHardSkillCategory.value;
  const summaryToAdd =
    type === "sofskill" ? newSoftSkillSummary.value : newHardSkillSummary.value;

  if (!skillToAdd || !categoryToAdd || !summaryToAdd) {
    console.error("Todos os campos são obrigatórios");
    return;
  }

  try {
    const newSkill = {
      description: skillToAdd,
      category: categoryToAdd,
      summary: summaryToAdd,
      type: type,
      weight: 5, // Definindo weight como 5
    };

    const response = await axios.post("/skills", newSkill);

    if (type === "sofskill") {
      newSoftSkill.value = "";
      newSoftSkillCategory.value = "";
      newSoftSkillSummary.value = "";
    } else {
      newHardSkill.value = "";
      newHardSkillCategory.value = "";
      newHardSkillSummary.value = "";
    }

    await fetchSkills();
  } catch (error) {
    console.error('Erro ao adicionar habilidade', error);
  }
};

const filteredSoftSkills = computed(() => {
  const searchTerm = softSkillSearchTerm.value.toLowerCase();
  if (selectedCategory.value === "Todas") {
    return softSkills.value.filter(
      (skill) =>
        skill.description.toLowerCase().includes(searchTerm) ||
        skill.category.toLowerCase().includes(searchTerm) ||
        skill.summary.toLowerCase().includes(searchTerm)
    );
  }
  return softSkills.value.filter(
    (skill) =>
      skill.category === selectedCategory.value &&
      (skill.description.toLowerCase().includes(searchTerm) ||
        skill.category.toLowerCase().includes(searchTerm) ||
        skill.summary.toLowerCase().includes(searchTerm))
  );
});

const filteredHardSkills = computed(() => {
  const searchTerm = hardSkillSearchTerm.value.toLowerCase();
  if (selectedCategory.value === "Todas") {
    return hardSkills.value.filter(
      (skill) =>
        skill.description.toLowerCase().includes(searchTerm) ||
        skill.category.toLowerCase().includes(searchTerm) ||
        skill.summary.toLowerCase().includes(searchTerm)
    );
  }
  return hardSkills.value.filter(
    (skill) =>
      skill.category === selectedCategory.value &&
      (skill.description.toLowerCase().includes(searchTerm) ||
        skill.category.toLowerCase().includes(searchTerm) ||
        skill.summary.toLowerCase().includes(searchTerm))
  );
});

const allSkills = computed(() => {
  return [...softSkills.value, ...hardSkills.value].map((skill) => ({
    description: skill.description,
    category: skill.category,
    type: skill.type === "sofskill" ? "Soft Skill" : "Hard Skill",
  }));
});

onMounted(fetchSkills);
</script>


<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="container">
      <div class="mb-4">
        <label for="category-filter" class="block font-medium mb-2">Filtrar por Categoria:</label>
        <select id="category-filter" v-model="selectedCategory"
          class="border-[1px] border-gray px-4 py-2 bg-white dark:bg-black rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full md:w-auto">
          <option value="Todas">Todas</option>
          <option v-for="(skill, index) in allSkills" :key="index" :value="skill.category">
            {{ skill.description }} - {{ skill.type }}
          </option>
        </select>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- SoftSkills Section -->
        <div class="bg-white dark:bg-black shadow rounded-lg p-6">
          <h2 class="text-2xl font-bold mb-4">SoftSkills</h2>
          <p class="mb-4 text-gray-600">
            Refere-se a
            <span class="font-bold">habilidades interpessoais e comportamentais</span>, como comunicação, trabalho em
            equipe ou resolução de problemas.
          </p>
          <div class="mb-4">
            <input type="text" v-model="softSkillSearchTerm" placeholder="Pesquisar SoftSkills"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
          </div>
          <div class="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
            <input type="text" v-model="newSoftSkill" placeholder="Descrição"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
            <input type="text" v-model="newSoftSkillCategory" placeholder="Categoria"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
            <input type="text" v-model="newSoftSkillSummary" placeholder="Resumo"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
          </div>
          <button @click="addSkill('sofskill')"
            class="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark w-full font-medium">
            Adicionar
          </button>
          <div class="mt-4 grid grid-cols-1 gap-5">
            <div v-for="(skill, index) in filteredSoftSkills" :key="skill.id"
              class="bg-gray-2 dark:bg-black border-gray border-[1px] p-4 rounded-md shadow">
              <div class="flex flex-col justify-center items-start gap-3">
                <div>
                  <h3 class="font-bold">{{ skill.description }}</h3>
                  <p class="text-sm text-gray-600">{{ skill.category }}</p>
                  <p class="text-sm text-gray-600">{{ skill.summary }}</p>
                </div>
                <button @click="confirmDelete(skill.id)" class="text-white bg-red rounded-full px-2 text-[14px]">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- HardSkills Section -->
        <div class="bg-white dark:bg-black shadow rounded-lg p-6">
          <h2 class="text-2xl font-bold mb-4">HardSkills</h2>
          <p class="mb-4 text-gray-600">
            São <span class="font-bold">habilidades técnicas e específicas</span> necessárias para desempenhar
            determinada função, como programação, uso de software ou análise de dados.
          </p>
          <div class="mb-4">
            <input type="text" v-model="hardSkillSearchTerm" placeholder="Pesquisar HardSkills"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
          </div>
          <div class="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
            <input type="text" v-model="newHardSkill" placeholder="Descrição"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
            <input type="text" v-model="newHardSkillCategory" placeholder="Categoria"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
            <input type="text" v-model="newHardSkillSummary" placeholder="Resumo"
              class="border border-gray dark:bg-black px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-primary w-full" />
          </div>
          <button @click="addSkill('hardskill')"
            class="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark w-full font-medium">
            Adicionar
          </button>
          <div class="mt-4 grid grid-cols-1 gap-5">
            <div v-for="(skill, index) in filteredHardSkills" :key="skill.id"
              class="bg-gray-2 dark:bg-black border-gray border-[1px] p-4 rounded-md shadow">
              <div class="flex flex-col justify-center items-start gap-3">
                <div>
                  <h3 class="font-bold">{{ skill.description }}</h3>
                  <p class="text-sm text-gray-600">{{ skill.category }}</p>
                  <p class="text-sm text-gray-600">{{ skill.summary }}</p>
                </div>
                <button @click="confirmDelete(skill.id)" class="text-white bg-red rounded-full px-2 text-[14px]">
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
  <div v-if="showConfirmModal"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-6 rounded-md">
      <h2 class="text-xl font-bold mb-4">Confirmar Exclusão</h2>
      <p>Tem certeza de que deseja excluir esta habilidade?</p>
      <div class="mt-4 flex justify-end gap-4">
        <button @click="cancelDelete"
          class="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700">Cancelar</button>
        <button @click="confirmDeleteSkill"
          class="bg-red text-white px-4 py-2 rounded-md hover:bg-red-dark">Confirmar</button>
      </div>
    </div>
  </div>
</template>
