<script setup>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import CoursesAddComponent from "@/components/CoursesAdd/CoursesAdd.vue";
import { ref } from "vue";

const pageTitle = ref("Cursos Adicionados");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <CoursesAddComponent />
  </DefaultLayout>
</template>