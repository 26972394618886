<script setup lang="ts">
import { ref } from 'vue'
import BreadcrumbDefault from '@/components/Breadcrumbs/BreadcrumbDefault.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import TableCheckInterview from '@/components/Tables/TablesCompany/TableCheckInterview.vue';

const pageTitle = ref('Marcar entrevista')
</script>

<template>
    <DefaultLayout>
        <BreadcrumbDefault :pageTitle="pageTitle" />
        <div>
            <TableCheckInterview />
        </div>
    </DefaultLayout>
</template>
