<template>
  <div>
    <select @change="addToList" class="mb-2 w-full flex rounded border border-stroke py-2 pl-3 pr-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input">
      <option value="">Escolha uma habilidade...</option>
      <option v-for="item in items" :key="item.id" :value="item">{{ item }}</option>
    </select>
    <div class="grid justify-start grid-cols-2 items-center gap-1">
      <span v-for="(item, index) in selectedItems" :key="index" class="flex items-center justify-center rounded border-[.5px] border-stroke bg-gray px-2.5 py-1.5 font-medium dark:border-strokedark dark:bg-white/30">
        {{ item }} <button @click="removeItem(index)" class="text-[14px] font-light hover:text-red ml-2">x</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedItems: this.modelValue
    };
  },
  methods: {
    addToList(event) {
      const selectedItem = event.target.value;
      if (selectedItem && !this.selectedItems.includes(selectedItem)) {
        this.selectedItems.push(selectedItem);
        this.$emit('update:modelValue', this.selectedItems); // Emite o evento de atualização do modelValue
      }
    },
    removeItem(index) {
      this.selectedItems.splice(index, 1);
      this.$emit('update:modelValue', this.selectedItems); // Emite o evento de atualização do modelValue
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedItems = newVal;
    }
  }
};
</script>
