<script>
import axios from "@/api/axios";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
  setup() {
    const router = useRouter();
    const stageCondition = ref(false);

    const getIdCandidacyUrl = () => {
      let url = window.location.href;
      let segments = url.split("/");
      let id = segments[6];
      return id;
    };

    const getStage = () => {
      let url = window.location.href;
      let segments = url.split("/");
      let stageUrl = segments[8];
      return stageUrl;
    };

    let url = ref(`/candidacy/${getIdCandidacyUrl()}`);

    let candidacy = ref("");

    const fetchCandidacyId = async () => {
      try {
        let response = await axios.get(url.value);
        if (response.data != "" || response.data.length != 0) {
          candidacy.value = response.data;
          console.log(candidacy.value);
          setConditionNewStage(candidacy.value);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCandidacyId();

    const setConditionNewStage = (candidacy) => {
      if (parseInt(getStage()) < candidacy.job.groups.stages.length) {
        stageCondition.value = true;
      }
    };

    const newStage = async () => {
      try {
        let stage = parseInt(getStage());
        let data = {
          current_stage: stage + 1,
        };
        let response = await axios.put(url.value, data);
      } catch (error) {
        console.error(error);
      } finally {
        transactionOK();
      }
    };

    const disqualify = async () => {
      try {
        let data;
        if (candidacy.value.active == true) {
          data = {
            active: false,
          };
        } else {
          data = {
            active: true,
          };
        }
        let response = await axios.put(url.value, data);
      } catch (error) {
        console.error(error);
      } finally {
        transactionOK();
      }
    };

    const approve = () => {
      Swal.fire({
        title: "Candidato aprovado com sucesso!",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        router.push('/novas-vagas');
      });
    };

    const transactionOK = () => {
      Swal.fire({
        title: "Registro atualizado com sucesso!",
        text: "Candidatura atualizada!",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        router.go(-1);
      });
    };

    const back = () => {
      router.go(-1);
    };

    return {
      candidacy,
      stageCondition,
      back,
      disqualify,
      newStage,
      approve,
    };
  },
};
</script>

<template>
  <div class="flex">
    <button
      v-if="stageCondition"
      class="rounded-lg p-3 bg-primary text-white mr-1"
      @click="newStage"
    >
      Avançar Etapa
    </button>
    <button
      v-if="!stageCondition"
      class="rounded-lg p-3 bg-success text-white mr-1"
      @click="approve"
    >
      Aprovado
    </button>
    <button
      v-if="stageCondition"
      class="rounded-lg p-3 bg-red text-white"
      @click="disqualify"
    >
      {{ candidacy.active ? "Desclassificar" : "Reverter Classificação" }}
    </button>
  </div>
</template>
