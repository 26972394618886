<script setup lang="ts">
import { ref } from "vue";
import Vue from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ListChatBotComponent from "@/components/ChatBot/ListChatBot.vue";


const pageTitle = ref("Oportunidades");
</script>

<template >
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="bg-white dark:bg-black border border-stroke rounded-lg">
      <ListChatBotComponent />
    </div>
  </DefaultLayout>
</template>

