<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />

    <div class="mt-4 mb-8">
      <p class="leading-[1.4] mt-4 max-w-[940px] mb-6 text-[16px]">
        Nessa fase, após identificar seus interesses, habilidades e sonhos, você
        seguirá uma trilha de conhecimento organizada em um Kanban.<br />
        O Kanban é uma ferramenta visual que facilita o gerenciamento e
        acompanhamento das etapas do seu progresso.
      </p>
    </div>

    <div>
      <div
        class="flex lg:flex-row flex-col justify-between py-5 bg-gray-100 min-h-96"
      >
        <div
          v-if="isLoading"
          class="flex flex-col items-center justify-center mb-8 w-full"
        >
          <img
            src="@/assets/images/naza/loading.gif"
            class="w-[320px] mb-5"
            alt="Naza"
          />
          <p class="text-lg font-bold text-primary">Carregando trilha do conhecimento...</p>
        </div>
        <div v-else class="flex lg:flex-row flex-col justify-between py-5 bg-gray-100 min-h-96 w-full">
          <KanbanColumn
            v-for="status in columnStatuses"
            :key="status"
            :status="status"
            :cards="cards.filter((card) => card.status === status)"
            @moveCard="moveCard"
          >
          </KanbanColumn>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup>
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { ref, onMounted } from "vue";
import KanbanColumn from "./KanbanColumn.vue";
import axios from "@/api/axios.js";

const userId = localStorage.getItem("userId");
const candidateId = ref("");
const pageTitle = ref(
  `Trilha do Conhecimento (${localStorage.getItem("dream")}) `
);
let dream = ref("Desenvolvedor profissional");
const knowledgeTrailsData = ref([]);
const cards = ref([]);
const isLoading = ref(true); 

const fetchCandidate = async () => {
  try {
    const response = await axios.get(`/candidates/by-user/${userId}`);
    const candidateData = response.data;
    candidateId.value = candidateData.id;
    console.log("candidate id ", candidateId.value);
  } catch (error) {
    console.error("Erro ao carregar candidato:", error);
  }
};

const fetchKnowledgeTrails = async () => {
  isLoading.value = true; 
  try {
    const candidateIdNumber = Number(candidateId.value);
    console.log("Numero candidato ", candidateIdNumber);
    console.log("Numero id ", candidateId.value);
    const response = await axios.get(
      `/knowledge-trails/candidate/${candidateIdNumber}/dream/${localStorage.getItem(
        "dream"
      )}`
    );
    knowledgeTrailsData.value = response.data;
    console.log("Dados da trilha ", knowledgeTrailsData.value);
    cards.value = knowledgeTrailsData.value.map((etapa) => ({
      id: etapa.id,
      title: etapa.title,
      description: etapa.description,
      status: etapa.level || "Não iniciado",
    }));
    console.log("Trilha do conhecimento carregado com sucesso");
  } catch (error) {
    console.error("Erro ao carregar Trilha do conhecimento:", error);
  } finally {
    isLoading.value = false;
  }
};

const columnStatuses = ["Não iniciado", "Em andamento", "Concluído"];
const moveCard = async (cardId, newStatus) => {
  const card = cards.value.find((card) => card.id === cardId);
  if (card) {
    const oldStatus = card.status;
    card.status = newStatus;
    try {
      await axios.put(`/knowledge-trails/${cardId}`, { level: newStatus });
      console.log(`Card ${cardId} movido para ${newStatus}`);
    } catch (error) {
      console.error(`Erro ao mover o card ${cardId} para ${newStatus}:`, error);
      // Reverte a mudança de status no front-end se houver um erro
      card.status = oldStatus;
    }
  }
};

onMounted(async () => {
  localStorage.setItem("page", "Trilha do Conhecimento");
  await fetchCandidate();
  await fetchKnowledgeTrails();
});
</script>