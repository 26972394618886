<!-- CandidacyModal.vue -->
<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white dark:bg-black p-8 rounded-lg shadow-lg max-w-xl w-full overflow-y-auto h-[70svh] mt-[10vh] mx-[10vw]">
      <h2 class="text-lg font-semibold mb-4">{{ titleGroupModal }}</h2>
      <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="mb-10 ms-6 ml-10">
          <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
            <svg class="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
            </svg>
          </span>
          <h3 class="font-medium leading-tight">Análise de Perfil</h3>
          <p class="text-sm">O candidato deve enviar seu currículo atualizado juntamente com uma carta de apresentação destacando suas habilidades e experiências relevantes para a vaga.</p>
        </li>
        <li v-for="(item, index) in reversedStages" :key="item.id" class="mb-10 ms-6 ml-10">
          <span v-if="currentStage !== index + 1" class="absolute flex items-center justify-center w-8 h-8 bg-white dark:bg-black rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
            <svg class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"/>
            </svg>
          </span>
          <span v-if="currentStage === index + 1" class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
            <svg class="w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
            </svg>
          </span>
          <h3 class="font-medium leading-tight">{{ item.title }}</h3>
          <p class="text-sm">{{ item.description }}</p>
        </li>
      </ol>
      <button @click="closeModal" class="mt-4 px-4 py-2 bg-red-500 text-primary dark:text-white rounded-lg">Voltar</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    titleGroupModal: {
      type: String,
      required: true,
      default: ''
    },
    stages: {
      type: Array,
      required: true
    },
    currentStage: {
      type: Number,
      required: true
    },
  },
    computed: {
    reversedStages() {
      return [...this.stages].reverse();
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
    }
  }
}
</script>

<style scoped>
/* Adicione estilos específicos para o modal, se necessário */
</style>
