<template>
  <div :class="['kanban-column lg:border-r-[1px] lg:border-[#d4d5d7] last:border-r-0 lg:!mb-0 !mb-[20px]', statusClass]"
    @dragover.prevent @drop="drop($event)">

    <div class="flex justify-start items-center gap-[3px]">
      <svg width="15px" height="15px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none"
        stroke="#44546f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">

        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

        <g id="SVGRepo_iconCarrier">
          <circle cy="2.5" cx="5.5" r=".75" />
          <circle cy="8" cx="5.5" r=".75" />
          <circle cy="13.5" cx="5.5" r=".75" />
          <circle cy="2.5" cx="10.4957" r=".75" />
          <circle cy="8" cx="10.4957" r=".75" />
          <circle cy="13.5" cx="10.4957" r=".75" />
        </g>
      </svg>

      <h2 class="text-strokedark font-bold">{{ status }}</h2>
    </div>
    <div class="kanban-cards-container">
      <KanbanCard v-for="card in cards" :key="card.id" :card="card">
      </KanbanCard>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import KanbanCard from './KanbanCard.vue';

const props = defineProps({
  status: String,
  cards: Array,
});
const emit = defineEmits(['moveCard']);

const drop = event => {
  const cardId = event.dataTransfer.getData('text/plain');
  emit('moveCard', parseInt(cardId, 10), props.status);
};

// Computed property to determine the class based on the status
const statusClass = computed(() => {
  switch (props.status) {
    case 'Não iniciado':
      return 'toDo';
    case 'Em andamento':
      return 'progress';
    case 'Concluído':
      return 'concluded';
    default:
      return '';
  }
});
</script>

<style>
.concluded h2,
.progress h2,
.toDo h2 {
  line-height: 1;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
}

.concluded h2 {
  background-color: #1c3329;
  color: #7ee2b8;

}

.progress h2 {
  background-color: #1c2b41;
  color: #85b8ff;
}

.toDo h2 {
  background-color: #a1bdd914;
  color: #9fadbc;
}

.kanban-column {
  padding-right: 1.8rem;
  flex: 1;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: hidden;
}

.kanban-cards-container {
  overflow-y: auto;
  flex: 1;
  margin-top: 1rem;
}
</style>