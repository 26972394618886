<script setup lang="ts">
import { ref, onMounted } from "vue";
import axios from "@/api/axios";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import Swal from 'sweetalert2';

interface Dream {
  id: number;
  description: string;
}

const pageTitle = ref("Sonhos Profissionais");
const dreams = ref<Dream[]>([]);
const newDream = ref<string>("");
const showConfirmModal = ref(false);
const dreamToDelete = ref<number | null>(null);

const loadDreams = async () => {
  try {
    console.log("Loading dreams...");
    const response = await axios.get<Dream[]>("/dreams");
    console.log("API response:", response.data);
    dreams.value = response.data;
    console.log("Dreams loaded:", dreams.value);
  } catch (error) {
    console.error("Error loading dreams:", error);
  }
};

onMounted(() => {
  loadDreams();
});

const addDream = async () => {
  if (newDream.value.trim() !== "") {
    try {
      console.log("Adding new dream:", newDream.value);
      const response = await axios.post<Dream>("/dreams", {
        description: newDream.value,
      });
      const newDreamAdded = response.data;
      dreams.value.push(newDreamAdded);
      newDream.value = "";
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Sonho adicionado com sucesso!',
      });
      console.log("New dream added:", newDreamAdded);
      await loadDreams();
    } catch (error) {
      console.error("Error adding dream:", error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao adicionar sonho',
      });
    }
  }
};

const editDream = async (dream: Dream) => {
  const newDescription = prompt(
    "Enter the new description:",
    dream.description
  );
  if (newDescription !== null && newDescription.trim() !== "") {
    try {
      console.log("Editing dream:", dream.id);
      await axios.put(`/dreams/${dream.id}`, { description: newDescription });
      dream.description = newDescription;
      dreams.value.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Sonho editado com sucesso!',
      });
      console.log("Dream edited:", dream);
    } catch (error) {
      console.error("Error editing dream:", error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao editar sonho',
      });
    }
  }
};

const confirmDelete = (id: number) => {
  dreamToDelete.value = id;
  showConfirmModal.value = true;
};

const cancelDelete = () => {
  dreamToDelete.value = null;
  showConfirmModal.value = false;
};

const confirmDeleteDream = async () => {
  if (dreamToDelete.value !== null) {
    await deleteDream(dreamToDelete.value);
  }
  cancelDelete();
};

const deleteDream = async (id: number) => {
  try {
    console.log("Deleting dream:", id);
    await axios.delete(`/dreams/${id}`);
    dreams.value = dreams.value.filter((dream) => dream.id !== id);
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'Sonho excluído com sucesso!',
    });
    console.log("Dream deleted:", id);
  } catch (error) {
    console.error("Error deleting dream:", error);
    Swal.fire({
      icon: 'error',
      title: 'Erro',
      text: 'Erro ao excluir sonho',
    });
  }
};

const handleFileUpload = async (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = async (e) => {
    const content = e.target?.result;
    if (typeof content !== "string") return;

    let dreamsData: { description: string }[] = [];
    if (file.type === "application/json") {
      dreamsData = JSON.parse(content);
    } else if (file.type === "text/csv" || file.type === "text/plain") {
      const lines = content.split("\n");
      dreamsData = lines.map(line => ({ description: line.trim() })).filter(dream => dream.description !== "");
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Formato de arquivo não suportado',
      });
      return;
    }

    try {
      const response = await axios.post("/dreams/bulk", { dreams: dreamsData });
      dreams.value.push(...response.data);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Sonhos adicionados com sucesso!',
      });
      await loadDreams();
    } catch (error) {
      console.error("Error adding dreams:", error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao adicionar sonhos',
      });
    }
  };

  reader.readAsText(file);
};

</script>


<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="bg-white dark:bg-black shadow rounded-lg p-6">
      <h2 class="text-xl font-semibold mb-4">
        Adicionar Novo Sonho Profissional
      </h2>
      <form @submit.prevent="addDream" class="flex items-center space-x-2">
        <input v-model="newDream" type="text"
          class="bg-white dark:bg-black flex-1 border-gray border-[1px] focus:border-primary focus:ring focus:ring-blue-200 rounded-md p-2 text-[14px]"
          placeholder="Digite o novo sonho..." />
        <button type="submit" class="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Adicionar
        </button>
      </form>
      <div class="mt-4">
        <input type="file" @change="handleFileUpload" class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary file:text-white hover:file:bg-blue-700"/>
      </div>

      <!-- Dreams Table -->
      <div class="bg-white dark:bg-black shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4">Sonhos Profissionais</h2>
        <div class="overflow-x-auto">
          <table class="min-w-full divide-y divide-gray dark:divide-strokedark">
            <thead class="bg-[#46494e] text-white">
              <tr>
                <th scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider rounded-tl-[12px]">
                  Descrição
                </th>
                <th scope="col"
                  class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider w-[20%] rounded-tr-[12px]">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody class="bg-white dark:bg-black divide-y divide-gray dark:divide-strokedark">
              <tr v-for="(dream, index) in dreams" :key="dream.id"
                class="odd:bg-white odd:dark:bg-strokedark even:bg-gray even:dark:bg-black dark:border-strokedark">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div>
                    {{ dream.description }}
                  </div>
                </td>
                <td class="flex justify-end items-center gap-3 px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button @click="editDream(dream)"
                    class="text-indigo-600 dark:text-white hover:opacity-[0.7] mr-2 text-[16px] flex justify-center items-center gap-1">
                    <span>Editar</span>
                    <svg width="18px" height="18px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                      fill="#000000">
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                      <g id="SVGRepo_iconCarrier">
                        <title />
                        <g id="Complete">
                          <g id="edit">
                            <g>
                              <path d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8" fill="none"
                                stroke="#5b52e7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                              <polygon fill="none" points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                                stroke="#5b52e7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button @click="confirmDelete(dream.id)"
                    class="text-red hover:opacity-[0.7] text-[16px] flex justify-center items-center gap-1">
                    <span>Excluir</span>
                    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M10 12V17" stroke="#fb5454" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M14 12V17" stroke="#fb5454" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M4 7H20" stroke="#fb5454" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#fb5454"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M9 3H15C16.6569 3 18 4.34315 18 6V7H6V6C6 4.34315 7.34315 3 9 3Z"
                          stroke="#fb5454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Confirm Delete Modal -->
    <div v-if="showConfirmModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div class="bg-white dark:bg-black p-6 rounded-lg shadow-lg">
        <h3 class="text-xl font-semibold mb-4">Confirmar Exclusão</h3>
        <p class="mb-4">Você tem certeza de que deseja excluir este sonho?</p>
        <div class="flex justify-end space-x-2">
          <button @click="confirmDeleteDream"
            class="bg-red hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Confirmar
          </button>
          <button @click="cancelDelete" class="bg-gray-600 hover:bg-gray-700 text-blue font-bold py-2 px-4 rounded">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>