<script setup lang="ts">
import { ref } from "vue";
import Vue from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ChatBotComponent from "@/components/ChatBot/ChatBot.vue";

const pageTitle = ref("Naza");

function getTimeNow(): string {
  let date: Date = new Date();
  let hour: string = date.getHours().toString();
  let minutes: string = date.getMinutes().toString();
  return `${hour.length == 1 ? "0" + hour : hour}:${
    minutes.length == 1 ? "0" + minutes : minutes
  }`;
}
</script>

<template >
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="bg-white dark:bg-black border border-stroke rounded-lg">
      <ChatBotComponent />
    </div>
  </DefaultLayout>
</template>
  