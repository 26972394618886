<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from '@/api/axios.js';
import BreadcrumbDefault from '@/components/Breadcrumbs/BreadcrumbDefault.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const pageTitle = ref('Lista De Candidatos');
const candidates = ref([]);
const error = ref(null);

// Filtros
const keyword = ref('');
const location = ref('');
const interestArea = ref('');
const selectedSkills = ref([]);
const selectedBehaviors = ref([]);
const selectedType = ref('curriculum');

// Armazenamento das hard e soft skills
const hardSkills = ref([]);
const softSkills = ref([]);

// Função para buscar as habilidades
const fetchSkills = async () => {
  try {
    const response = await axios.get('/skills');
    const skills = response.data;
    console.log('Skills:', skills); // Verifique o formato dos dados
    softSkills.value = skills.filter(skill => skill.type === 'sofskill');
    hardSkills.value = skills.filter(skill => skill.type === 'hardskill');
  } catch (error) {
    console.error('Erro ao buscar habilidades:', error);
    error.value = error.message;
  }
};

// Função para buscar candidatos
const fetchCandidates = async () => {
  try {
    const response = await axios.get('/candidates');
    candidates.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar candidatos:', error);
    error.value = error.message;
  }
};

onMounted(() => {
  fetchCandidates();
  fetchSkills();
});

// Computed property para filtrar candidatos
const filteredCandidates = computed(() => {
  if (
    !keyword.value &&
    !location.value &&
    !interestArea.value &&
    !selectedSkills.value.length &&
    !selectedBehaviors.value.length
  ) {
    return candidates.value;
  }

  return candidates.value.filter(candidate => {
    const matchesKeyword = keyword.value
      ? candidate.fullname.toLowerCase().includes(keyword.value.toLowerCase()) ||
        (candidate.skills_soft && candidate.skills_soft.toLowerCase().includes(keyword.value.toLowerCase())) ||
        (candidate.skills_hard && candidate.skills_hard.toLowerCase().includes(keyword.value.toLowerCase()))
      : true;

    const matchesLocation = location.value
      ? candidate.city.toLowerCase().includes(location.value.toLowerCase())
      : true;
    const matchesInterestArea = interestArea.value
      ? candidate.interestArea.toLowerCase().includes(interestArea.value.toLowerCase())
      : true;
    const matchesSkills = selectedSkills.value.length
      ? selectedSkills.value.every(skill => candidate.skills_hard && candidate.skills_hard.toLowerCase().includes(skill.toLowerCase()))
      : true;
    const matchesBehaviors = selectedBehaviors.value.length
      ? selectedBehaviors.value.every(behavior => candidate.skills_soft && candidate.skills_soft.toLowerCase().includes(behavior.toLowerCase()))
      : true;

    return matchesKeyword && matchesLocation && matchesInterestArea && matchesSkills && matchesBehaviors;
  });
});

// Função para calcular a idade a partir da data de nascimento
const calculateAge = dateofbirth => {
  const birthDate = new Date(dateofbirth);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// Função para ordenar as skills por categorias
const sortSkills = skills => {
  const sorted = {};
  skills.forEach(skill => {
    if (!sorted[skill.category]) {
      sorted[skill.category] = [];
    }
    sorted[skill.category].push(skill);
  });
  return sorted;
};
</script>

<template>
  <DefaultLayout>
    <div>
      <BreadcrumbDefault :pageTitle="pageTitle" />
      <div v-if="error" class="text-red-600">
        Erro ao carregar candidatos: {{ error }}
      </div>

      <!-- Filtros na parte superior -->
      <div class="bg-white p-4 shadow-lg rounded-lg mb-6">
        <h3 class="text-lg font-semibold mb-4">Filtros</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label class="block text-sm font-medium text-gray-700">Ocupação ou palavra-chave</label>
            <input
              v-model="keyword"
              type="text"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Programador"
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Localização</label>
            <input
              v-model="location"
              type="text"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Busque por localidade..."
            />
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Área de atuação/interesse</label>
            <select
              v-model="interestArea"
              class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Selecione uma área</option>
              <option value="tecnologia">Tecnologia da Informação</option>
              <option value="saude">Saúde</option>
              <option value="educacao">Educação</option>
              <!-- Adicione outras áreas aqui -->
            </select>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700">Tipo</label>
            <div class="flex items-center space-x-4">
              <label class="flex items-center">
                <input
                  type="radio"
                  value="curriculum"
                  v-model="selectedType"
                  class="form-radio text-blue-600"
                />
                <span class="ml-2">Currículo</span>
              </label>
              <label class="flex items-center">
                <input
                  type="radio"
                  value="assessment"
                  v-model="selectedType"
                  class="form-radio text-blue-600"
                />
                <span class="ml-2">Assessment</span>
              </label>
              <label class="flex items-center">
                <input
                  type="radio"
                  value="video"
                  v-model="selectedType"
                  class="form-radio text-blue-600"
                />
                <span class="ml-2">Vídeo</span>
              </label>
            </div>
          </div>
        </div>
        <div class="grid lg:grid-cols-2 gap-4 mt-4">
          <!-- Seleção de Soft Skills -->
          <div class="bg-gray-100 p-4 rounded-lg h-64 overflow-y-auto">
            <label class="text-lg font-bold text-primary block mb-2">Soft Skills</label>
            <div v-if="softSkills.length === 0" class="text-gray-500">Carregando...</div>
            <div v-for="(skills, category) in sortSkills(softSkills)" :key="category">
              <h3 class="text-md font-semibold my-2">{{ category }}</h3>
              <div v-for="skill in skills" :key="skill.id" class="flex items-center mt-2">
                <input
                  type="checkbox"
                  :id="'soft-skill-' + skill.id"
                  v-model="selectedBehaviors"
                  :value="skill.description"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label :for="'soft-skill-' + skill.id" class="ml-2 text-sm text-gray-900">{{ skill.description }}</label>
              </div>
            </div>
          </div>

          <!-- Seleção de Hard Skills -->
          <div class="bg-gray-100 p-4 rounded-lg h-64 overflow-y-auto">
            <label class="text-lg font-bold text-primary block mb-2">Hard Skills</label>
            <div v-if="hardSkills.length === 0" class="text-gray-500">Carregando...</div>
            <div v-for="(skills, category) in sortSkills(hardSkills)" :key="category">
              <h3 class="text-md font-semibold my-2">{{ category }}</h3>
              <div v-for="skill in skills" :key="skill.id" class="flex items-center mt-2">
                <input
                  type="checkbox"
                  :id="'hard-skill-' + skill.id"
                  v-model="selectedSkills"
                  :value="skill.description"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label :for="'hard-skill-' + skill.id" class="ml-2 text-sm text-gray-900">{{ skill.description }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between mt-4">
          <button
            @click="() => {
              keyword.value = '';
              location.value = '';
              interestArea.value = '';
              selectedSkills.value = [];
              selectedBehaviors.value = [];
            }"
            class="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400"
          >
            Limpar Filtros
          </button>
          <button
            class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Mais Filtros
          </button>
        </div>
      </div>

      <!-- Lista de Candidatos abaixo dos filtros -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        <div
          v-for="candidate in filteredCandidates"
          :key="candidate.id"
          class="max-w-sm flex flex-col rounded overflow-hidden shadow-lg p-6 bg-white justify-center items-center"
        >
          <img
            v-if="candidate.imagepath"
            :src="candidate.imagepath"
            alt="Foto do candidato"
            class="w-32 object-cover mb-4 rounded-lg"
          />
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2">{{ candidate.fullname }}</div>
            <p class="text-gray-700 text-base">
              Idade: {{ calculateAge(candidate.dateofbirth) }}
            </p>
            <p class="text-gray-700 text-base">Cidade: {{ candidate.city }}</p>
            <p class="text-gray-700 text-base">Estado: {{ candidate.state }}</p>
            <p class="text-gray-700 text-base">
              SoftSkills:
              {{ candidate.skills_soft ? candidate.skills_soft.replace(/sf_/g, '') : 'N/A' }}
            </p>
            <p class="text-gray-700 text-base">
              HardSkills:
              {{ candidate.skills_hard ? candidate.skills_hard.replace(/hs_/g, '') : 'N/A' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>
