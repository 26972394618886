<script setup lang="ts">
</script>

<template>
  <div class="lg:flex justify-center items-center h-full overflow-hidden">
    <main>
      <div class="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
        <div class="hidden dark:block">
          <img src="@/assets/images/logo/oportunidade-jovem-dark.png" alt="Oportunidade Jovem"
            class="mb-8 w-[210px] mx-auto" />
        </div>
        <div class="dark:hidden">
          <img src="@/assets/images/logo/oportunidade-jovem.png" alt="Oportunidade Jovem"
            class="mb-8 w-[210px] mx-auto" />
        </div>
        <slot></slot>
      </div>
    </main>
  </div>
</template>
