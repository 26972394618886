<script>
import axios from "@/api/axios.js"; // Importe seu axios configurado
import Swal from 'sweetalert2';

export default {
  data() {
    return {

      filter: 'all',
      items: [], // Inicialmente vazio até que os dados sejam carregados
      newItem: {
        type: '',
        organ: '',
        function: '',
        name: '',
      },
      showNewItemModal: false,
      isEditing: false,
      editedItemIndex: -1,
    };
  },
  created() {
    this.fetchItems(); // Chama a função para buscar os itens quando o componente for criado
  },
  computed: {
    filteredItems() {
      if (this.filter === 'all') {
        return this.items;
      }
      return this.items.filter(item => item.type.toUpperCase() === this.filter);
    },
  },
  methods: {
    async fetchItems() {
      try {
        const response = await axios.get('/organ');
        this.items = response.data; // Atualiza a lista de itens com os dados recebidos da API
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },
    showModal() {
      this.showNewItemModal = true;
      // this.isEditing = false;
    },
    closeModal() {
      this.showNewItemModal = false;
      this.newItem.type = '';
      this.newItem.organ = '';
      this.newItem.function = '';
      this.newItem.name = '';
    },
    async saveNewItem() {
      if (!this.newItem.type || !this.newItem.organ || !this.newItem.function || !this.newItem.name) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, preencha todos os campos.',
        });
        return;
      }
      try {
        const response = await axios.post('/organ', this.newItem);
        this.items.push(response.data);
        this.closeModal();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Item salvo com sucesso.',
        });
      } catch (error) {
        console.error('Erro ao salvar novo item:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erro ao salvar novo item.',
        });
      }
    },

    editItem(item) {
      this.isEditing = true;
      this.newItem.type = item.type;
      this.newItem.organ = item.organ;
      this.newItem.function = item.function;
      this.newItem.name = item.name;
      this.editedItemId = item.id;
      this.editedItemIndex = this.items.findIndex(item => item.id === this.editedItemId);
      this.showModal();
    },
    async saveEditedItem() {
      try {
        const response = await axios.put(`/organ/${this.editedItemId}`, this.newItem);
        this.items[this.editedItemIndex] = response.data;
        this.closeModal();
      } catch (error) {
        console.error('Erro ao editar item:', error);
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.isDeleting = true;
          axios.delete(`/organ/${item.id}`)
            .then(() => {
              const index = this.items.indexOf(item);
              this.items.splice(index, 1);
              this.isDeleting = false;
              Swal.fire(
                'Excluído!',
                'Seu arquivo foi excluído.',
                'success'
              );
            })
            .catch(error => {
              console.error('Erro ao excluir item:', error);
              this.isDeleting = false;
              Swal.fire(
                'Erro!',
                'Ocorreu um erro ao excluir o arquivo.',
                'error'
              );
            });
        }
      });
    },

  },
};
</script>

<template>
  <div>
    <div class="bg-white dark:bg-black py-4 px-6 rounded-lg shadow-sm w-full">
      <div class="flex justify-between items-center mb-[40px]">
        <h1 class="text-[20px] font-bold text-black">Filtrar</h1>
        <button @click="showModal"
          class="flex justify-center items-center gap-2 bg-primary hover:bg-primary_hover py-2 px-5 font-bold text-white rounded-full">
          <span>NOVO</span>
          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0" />
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
            <g id="SVGRepo_iconCarrier">
              <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff" stroke-width="1.5"
                stroke-linecap="round" />
              <path
                d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" />
            </g>
          </svg>
        </button>
      </div>
      <div class="flex lg:flex-row flex-col justify-start lg:items-center items-start gap-4 mb-[30px]">
        <div class="flex items-center">
          <input type="radio" id="all" value="all" v-model="filter" class="hidden">
          <label for="all" class="cursor-pointer border-gray border-2 py-3 px-5 rounded-lg">Todos os órgãos</label>
        </div>
        <div class="flex items-center">
          <input type="radio" id="secretarias" value="SECRETARIA" v-model="filter" class="hidden">
          <label for="secretarias" class="cursor-pointer border-gray border-2 py-3 px-5 rounded-lg">Secretarias</label>
        </div>
        <div class="flex items-center">
          <input type="radio" id="empresas" value="EMPRESA" v-model="filter" class="hidden">
          <label for="empresas" class="cursor-pointer border-gray border-2 py-3 px-5 rounded-lg">Empresas</label>
        </div>
        <div class="flex items-center">
          <input type="radio" id="autarquias" value="AUTARQUIA" v-model="filter" class="hidden">
          <label for="autarquias" class="cursor-pointer border-gray border-2 py-3 px-5 rounded-lg">Autarquias</label>
        </div>
      </div>
      <table class="w-full border-collapse bg-white dark:bg-black">
        <thead>
          <tr
            class="bg-strokedark dark:bg-black border-b-[1px] border-[#edf2f7] dark:border-bodydark2 text-white text-[14px] leading-none text-center">
            <th class="py-3 px-4 rounded-tl-[12px]">TIPO DE ÓRGÃO</th>
            <th class="py-3 px-4">ÓRGÃO</th>
            <th class="py-3 px-4">FUNÇÃO</th>
            <th class="py-3 px-4">NOME COMPLETO</th>
            <th class="py-3 px-4 rounded-tr-[12px]">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredItems" :key="index"
            class="border-[1px] border-[#edf2f7] text-[14px] text-center leading-none odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark">
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px]">
              {{ item.type }}
            </td>
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px]">
              {{ item.organ }}
            </td>
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px]">
              {{ item.function }}
            </td>
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px]">
              {{ item.name }}
              <div v-if="item.files" class="mb-[5px]">
                <a v-for="file in item.files" :key="file" href="#" class="text-primary">
                  {{ file }}
                </a>
              </div>
            </td>
            <td class="py-[8px] px-[15px] cursor-pointer flex justify-center items-center gap-2">
              <button @click="editItem(item)" class="text-primary hover:opacity-75"><i
                  class="fa-solid fa-pen-to-square"></i></button>
              <button @click="deleteItem(item)" class="text-red-light hover:opacity-75"><i
                  class="fa-solid fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div v-if="showNewItemModal"
      class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080]">
      <div class="bg-white dark:bg-black  p-[20px] rounded-[8px] w-[400px]">
        <h2 v-if="!isEditing" class="mb-[20px] font-bold text-[18px]">Novo Órgão</h2>
        <h2 v-else class="mb-[20px] font-bold text-[18px]">Editar Órgão</h2>
        <div class="mb-[15px]">
          <label class="mb-[5px] block">Tipo de órgão</label>
          <select required v-model="newItem.type"
            class="bg-white dark:bg-black w-full p-[8px] border-[1px] border-gray-200 rounded-lg">
            <option value="" selected disabled>Selecione um tipo</option>
            <option value="SECRETARIA">Secretaria</option>
            <option value="EMPRESA">Empresa</option>
            <option value="AUTARQUIA">Autarquia</option>
          </select>
        </div>
        <div class="mb-[15px]">
          <label class="mb-[5px] block">Órgão</label>
          <input required type="text" v-model="newItem.organ"
            class="bg-white dark:bg-black w-full p-[8px] border-[1px] border-gray-200 rounded-lg">
        </div>
        <div class="mb-[15px]">
          <label class="mb-[5px] block">Função</label>
          <input required type="text" v-model="newItem.function"
            class="bg-white dark:bg-black w-full p-[8px] border-[1px] border-gray-200 rounded-lg">
        </div>
        <div class="mb-[15px]">
          <label class="mb-[5px] block">Nome completo</label>
          <input required type="text" v-model="newItem.name"
            class="bg-white dark:bg-black w-full p-[8px] border-[1px] border-gray-200 rounded-lg">
        </div>
        <div class="flex justify-between mt-[25px]">
          <button @click="closeModal" class="underline py-2 px-4 font-medium">Cancelar</button>
          <button v-if="!isEditing" @click="saveNewItem"
            class="bg-primary rounded-md py-2 px-5 text-white font-medium">Salvar</button>
          <button v-else @click="saveEditedItem" class="bg-primary rounded-md py-2 px-5 text-white font-medium">Salvar
            Alterações</button>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
input[type="radio"]:checked+label {
  background-color: #3C50E0;
  color: white;
}
</style>
