<script setup>
import { ref } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const enterprises = ref([]);
const pageTitle = ref("Adesão de Editais");
const showRejectModal = ref(false);
const enterpriseToReject = ref(null);
const rejectionReason = ref("");
const showAnalyzeModal = ref(false);
const enterpriseToAnalyze = ref(null);

const fetchEnterprises = async () => {
  try {
    const response = await axios.get(`notices/complete/${getIdUrl()}`);
    enterprises.value = response.data;
  } catch (error) {
    console.error("Error fetching enterprises:", error);
  } finally {
  }
};

const getIdUrl = () => {
  let url = window.location.href;
  let bar = url.lastIndexOf("/");
  return url.substring(bar + 1);
};

const analyzeEnterprise = async (enterprise) => {
  try {
    enterpriseToAnalyze.value = enterprise;
    showAnalyzeModal.value = true;
  } catch (error) {
    console.error("Error analyzing enterprise:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao analisar empresa",
      text: error.response ? error.response.data.message : error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const acceptEnterprise = async (propsId) => {
  try {
    let payload = {
      status: "accept",
    };
    let response = await axios.patch(`props-notice/${propsId}`, payload);
    console.log(response);
  } catch (error) {
    console.error("Error accepting enterprise:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao aceitar empresa",
      text: error.response ? error.response.data.message : error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  } finally {
    fetchEnterprises();
    Swal.fire({
      icon: "success",
      title: "Sucesso!",
      text: "Empresa habilitada com sucesso!",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const deleteEnterprise = (enterpriseId) => {
  console.log("Deleting enterprise with ID:", enterpriseId);
  // Implement delete logic here
};

const rejectProp = async (propsId) => {
  try {
    let payload = {
      status: "decline",
    };
    let response = await axios.patch(`props-notice/${propsId}`, payload);
    console.log(response);
  } catch (error) {
    console.error("Error accepting enterprise:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao aceitar empresa",
      text: error.response ? error.response.data.message : error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  } finally {
    fetchEnterprises();
    Swal.fire({
      icon: "success",
      title: "Sucesso!",
      text: "Empresa desabilitada com sucesso!",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const closeRejectModal = () => {
  showRejectModal.value = false;
  rejectionReason.value = ""; // Clear rejection reason when modal closes
};

const confirmReject = async () => {
  try {
    const response = await axios.patch(
      `/enterprises/${enterpriseToReject.value.id}`,
      {
        isverified: false,
        reason: rejectionReason.value, // Include rejection reason in request
      }
    );
    Swal.fire({
      icon: "success",
      title: "Empresa rejeitada com sucesso",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
    await fetchEnterprises(); // Refresh list after rejection
    closeRejectModal(); // Close modal after rejection
  } catch (error) {
    console.error("Error rejecting enterprise:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao rejeitar empresa",
      text: error.response ? error.response.data.message : error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const closeAnalyzeModal = () => {
  showAnalyzeModal.value = false;
  enterpriseToAnalyze.value = null;
};

fetchEnterprises();
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div>
      <div class="overflow-x-auto bg-white dark:bg-black shadow rounded-lg">
        <table class="min-w-full bg-white">
          <thead class="bg-graydark text-white">
            <tr>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Nome Fantasia
              </th>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Nome da Empresa
              </th>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Email
              </th>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Contato
              </th>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Status
              </th>
              <th class="px-6 py-3 text-center font-medium text-gray-600">
                Ações
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray">
            <tr
              v-for="(item, index) in enterprises.props"
              :key="index"
              class="text-center odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark"
            >
              <td class="px-6 py-4 flex justify-center items-center">
                {{ item.enterprise.tradename }}
              </td>
              <td class="px-5 py-4">
                {{ item.enterprise.corporatename }}
              </td>
              <td class="px-5 py-4">
                {{ item.enterprise.email }}
              </td>
              <td class="px-5 py-4">
                {{ item.enterprise.phone }}
              </td>
              <td v-if="item.status == null" class="px-5 py-4">À revisar</td>
              <td
                v-if="item.status != null"
                :class="
                  item.status == 'accept'
                    ? 'px-5 py-4 text-success'
                    : 'px-5 py-4 text-red'
                "
              >
                {{ item.status == "accept" ? "Habilitado" : "Rejeitado" }}
              </td>
              <td class="px-6 py-4 flex justify-center space-x-3">
                <button
                  @click="analyzeEnterprise(item.enterprise)"
                  class="flex justify-center items-center gap-1 font-medium text-sm hover:underline"
                >
                  <svg
                    fill="#64748b"
                    width="20px"
                    height="20px"
                    viewBox="-3.5 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <title>view</title>
                      <path
                        d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"
                      />
                    </g>
                  </svg>
                  <span>Vizualizar</span>
                </button>
                <button
                  v-if="item.status != 'accept'"
                  @click="acceptEnterprise(item.id)"
                  :class="
                    item.status == 'accept'
                      ? 'text-[#11ac3d] flex justify-center items-center gap-1 font-medium text-sm'
                      : 'text-[#11ac3d] flex justify-center items-center gap-1 font-medium text-sm hover:underline'
                  "
                >
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.65263 14.0304C6.29251 13.6703 6.29251 13.0864 6.65263 12.7263C7.01276 12.3662 7.59663 12.3662 7.95676 12.7263L11.6602 16.4297L19.438 8.65183C19.7981 8.29171 20.382 8.29171 20.7421 8.65183C21.1023 9.01195 21.1023 9.59583 20.7421 9.95596L12.3667 18.3314C11.9762 18.7219 11.343 18.7219 10.9525 18.3314L6.65263 14.0304Z"
                      fill="#11ac3d"
                    />
                    <path
                      clip-rule="evenodd"
                      d="M14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1ZM3 14C3 7.92487 7.92487 3 14 3C20.0751 3 25 7.92487 25 14C25 20.0751 20.0751 25 14 25C7.92487 25 3 20.0751 3 14Z"
                      fill="#11ac3d"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <span>Habilitar</span>
                </button>

                <button
                  v-if="item.status != 'decline'"
                  @click="rejectProp(item.id)"
                  class="text-red flex justify-center items-center gap-1 font-medium text-sm hover:underline"
                >
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z"
                      fill="#fb5454"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
                      fill="#fb5454"
                    />
                  </svg>
                  <span>{{
                    item.status == "accept" ? "Desabilitar" : "Rejeitar"
                  }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal de Rejeição -->
    <div
      v-if="showRejectModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white p-4 rounded-lg w-[450px]">
        <h2 class="text-lg font-semibold mb-4">Justificativa</h2>
        <textarea
          v-model="rejectionReason"
          placeholder="Digite aqui sua justificativa"
          class="w-full h-40 border text-sm border-gray px-3 py-2 rounded-md mb-2"
        ></textarea>
        <div class="flex justify-end">
          <button @click="closeRejectModal" class="px-4 py-2 mr-2 underline">
            Cancelar
          </button>
          <button
            @click="confirmReject"
            class="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary_hover"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal de Análise -->
    <div
      v-if="showAnalyzeModal"
      class="fixed inset-0 z-[99999] flex items-center justify-center bg-black bg-opacity-80"
    >
      <div class="bg-white dark:bg-black py-4 px-5 rounded-lg max-w-lg">
        <h2 class="text-lg font-semibold mb-4 border-b-[1px] border-gray pb-2">
          Detalhes da Empresa
        </h2>
        <div class="mb-2 text-sm">
          <strong>CNPJ:</strong> {{ enterpriseToAnalyze?.cnpj }}
        </div>
        <div class="mb-2 text-sm">
          <strong>Email:</strong> {{ enterpriseToAnalyze?.email }}
        </div>
        <div class="mb-2 text-sm">
          <strong>Nome Fantasia:</strong> {{ enterpriseToAnalyze?.tradename }}
        </div>
        <div class="mb-2 text-sm">
          <strong>Nome da Empresa:</strong>
          {{ enterpriseToAnalyze?.companyname }}
        </div>
        <div class="mb-2 text-sm">
          <strong>Cidade:</strong> {{ enterpriseToAnalyze?.city }}
        </div>
        <div class="mb-2 text-sm">
          <strong>Telefone Fixo:</strong>
          {{ enterpriseToAnalyze?.landlinephone }}
        </div>
        <div class="mb-4 text-sm">
          <img
            :src="enterpriseToAnalyze?.imagepath"
            alt="Logo da Empresa"
            class="max-w-full h-auto rounded-lg"
          />
        </div>
        <div class="flex justify-end">
          <button
            @click="closeAnalyzeModal"
            class="bg-bodydark2 hover:bg-bodydark1 px-4 py-2 rounded-md mr-2 text-white"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<style scoped>
.bg-red-700 {
  background-color: #b91c1c;
}

.bg-red-800:hover {
  background-color: #991b1b;
}
</style>