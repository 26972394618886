import "./assets/css/satoshi.css";
import "./assets/css/style.css";
import "jsvectormap/dist/css/jsvectormap.min.css";
import "flatpickr/dist/flatpickr.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import { createApp } from "vue";
import { createPinia } from "pinia";
import VueApexCharts from "vue3-apexcharts";
import Development from './views/Pages/Development/Development.vue'

import App from "./App.vue";

import router from "./router";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueApexCharts);

const avaliationApp = createApp(Development);
avaliationApp.use(router);
avaliationApp.mount('#avaliacao');

app.mount("#app");
