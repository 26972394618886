<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import InputGroup from "@/components/Auths/InputGroup.vue";
import ButtonDefault from "@/components/Buttons/ButtonDefault.vue";
import { fetchEnterprise } from "@/services/enterpriseService";
import { getAreas } from "@/services/areaService";
import type { Area } from "@/services/areaService";
import { getSkills } from "@/services/skillService";
import type { Skill } from "@/services/skillService";
import Swal from "sweetalert2";
import axios from "@/api/axios";
import { useRouter } from "vue-router";
import { createJobIA } from "@/services/jobPositionService";
const router = useRouter();

const areas = ref<Area[]>([]);
const skills = ref<Skill[]>([]);
const usertype = ref<string>(localStorage.getItem("usertype") || "");

const pageTitle = ref("Adicionar vaga");

// Refs nova vaga
const userId = localStorage.getItem("userId");
const enterpriseId = ref<number | null>(null);
const secretaryid = ref<number | null>(null);
const enterpriseData = ref();
const enterprisePhotoPath = ref("");
const jobArea = ref("");
const jobType = ref("");
const jobFormat = ref("");
const jobPosition = ref("");
const jobNumber = ref("");
const pcdJobs = ref("");
const jobSalary = ref("");
const jobStatus = ref("");
const jobInitDate = ref("");
const jobEndDate = ref("");
const selectedSkills = ref<number[]>([]);
const softSkills = ref<GroupedSkills>({});
const hardSkills = ref<GroupedSkills>({});
const selectedSoftSkills = ref<number[]>([]);
const selectedHardSkills = ref<number[]>([]);
const jobNotes = ref("");
const enterpriseType = ref("");
let processSelection = ref<Array<any>>([]);
let jobProccess = ref("");
let enterpriseIsEdital = ref<boolean | string>(false);
const fileNamePath = ref("");
const fileName = ref<string | null>(null);
const selectedFile = ref<File | null>(null);
const oldFilePath = ref<string | null>(null);
const enterpriseIsVerified = ref();
const enterpriseCanEditalJob = ref();
const idNotice = ref<number>(0);
const noticesOfEnterprise = ref<Notice[]>([]);

interface GroupedSkills {
  [key: string]: Skill[];
}

interface Notice {
  id: number;
  description: string;
  title: string;
  type: string;
  filename: string;
  date_init: string;
  date_end: string;
  docpath: string;
  id_secretary: number;
}

const groupByCategory = (skills: Skill[]) => {
  return skills.reduce((acc: GroupedSkills, skill: Skill) => {
    const category = skill.category || "Outras";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(skill);
    return acc;
  }, {});
};

const fetchAreas = async () => {
  try {
    const response = await getAreas();
    areas.value = response;
  } catch (error) {
    console.error("Error fetching areas:", error);
  }
};

const fetchSkill = async () => {
  try {
    const response = await getSkills();
    const soft = response.filter((skill) => skill.type.startsWith("s"));
    const hard = response.filter((skill) => skill.type.startsWith("h"));
    softSkills.value = sortSkills(groupByCategory(soft));
    hardSkills.value = groupByCategory(hard);
  } catch (error) {
    console.error("Error fetching skills:", error);
  }
};

const sortSkills = (skills: GroupedSkills): GroupedSkills => {
  const sortedSkills: GroupedSkills = {};
  for (const category in skills) {
    sortedSkills[category] = skills[category]
      .slice()
      .sort((a, b) => a.description.localeCompare(b.description));
  }
  return sortedSkills;
};

const fetchEnterpriseId = async () => {
  if (usertype.value == "enterprise") {
    try {
      if (userId) {
        const response = await fetchEnterprise(userId);
        enterpriseData.value = response;
        enterpriseId.value = enterpriseData.value.id;
        enterprisePhotoPath.value = enterpriseData.value.imagepath;
        enterpriseType.value = enterpriseData.value.type;
        enterpriseIsVerified.value = enterpriseData.value.isverified;
        enterpriseCanEditalJob.value = enterpriseData.value.caneditaljob;
        console.log("Jobimage: ", enterprisePhotoPath.value);
        console.log("Empresa carregada com sucesso:", enterpriseId.value);
      } else {
        console.error("Erro: userId é null ou undefined");
      }
    } catch (error) {
      console.error("Erro ao carregar Empresa:", error);
    }
  } else if (usertype.value == "secretary") {
    try {
      if (userId) {
        let response = await axios.post("auth/me");

        enterpriseData.value = response.data;
        secretaryid.value = enterpriseData.value.secretaries[0].id;
        enterprisePhotoPath.value =
          enterpriseData.value.secretaries[0].imagepath;
        enterpriseIsVerified.value =
          enterpriseData.value.secretaries[0].isverified;
        enterpriseCanEditalJob.value =
          enterpriseData.value.secretaries[0].caneditaljob;
        console.log("Jobimage: ", enterprisePhotoPath.value);
        console.log("Empresa carregada com sucesso:", enterpriseId.value);
      } else {
        console.error("Erro: userId é null ou undefined");
      }
    } catch (error) {
      console.error("Erro ao carregar Empresa:", error);
    }
  } else if (usertype.value == "userSecretary") {
    try {
      if (userId) {
        let response = await axios.post("auth/me");
        let secretaries = response.data.userSecretaries[0].secretary;
        secretaryid.value = secretaries.id;
        enterprisePhotoPath.value = secretaries.imagepath;
        enterpriseIsVerified.value = secretaries.isverified;
        enterpriseCanEditalJob.value = secretaries.caneditaljob;
        console.log("Jobimage: ", enterprisePhotoPath.value);
        console.log("Empresa carregada com sucesso:", enterpriseId.value);
      } else {
        console.error("Erro: userId é null ou undefined");
      }
    } catch (error) {
      console.error("Erro ao carregar Empresa:", error);
    }
  } else if (usertype.value == "userEnterprise") {
    try {
      let response = await axios.post("auth/me");
      let enterprise = response.data.userenterprises[0].enterprise;
      enterpriseId.value = enterprise.id;
      enterprisePhotoPath.value = enterprise.imagepath;
      enterpriseIsVerified.value = enterprise.isverified;
      enterpriseCanEditalJob.value = enterprise.caneditaljob;
    } catch (error) {
      console.error(error);
    }
  }
};

const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const uploadedFile = target.files?.[0];
  if (uploadedFile) {
    fileName.value = uploadedFile.name;
    selectedFile.value = uploadedFile;
    handleFileSubmit();
  }
};

const fetchProcess = async () => {
  try {
    let response = await axios.get("groupCandidacy");
    if (response) {
      processSelection.value = response.data;
      if (processSelection.value.length >= 1) {
        console.log(processSelection.value);
      } else {
        Swal.fire({
          title: "Informação Importante",
          text: "Antes de criar vagas, é necessário adicionar pelo menos um processo de seleção. Clique no botão abaixo para criar um novo processo de seleção.",
          icon: "info",
          confirmButtonText:
            '<i class="fa-solid fa-circle-plus"></i> Criar processo de seleção',
          confirmButtonAriaLabel: "Criar processo de seleção",
        }).then(() => {
          router.push("/processo-seletivo");
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const handleFileSubmit = async () => {
  if (selectedFile.value) {
    const formData = new FormData();
    formData.append("arquivo", selectedFile.value);
    try {
      const response = await axios.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (oldFilePath.value) {
        await deleteOldFile(oldFilePath.value);
      }

      const fileUrl = response.data.url;
      selectedFile.value = null; // Reset selectedFile after upload
      fileName.value = fileUrl; // Salva o caminho do novo arquivo
    } catch (error) {
      console.error("Erro ao enviar o arquivo para a API:", error);
      Swal.fire({
        title: "Erro",
        text: "Erro ao enviar os dados para a API. Por favor, tente novamente.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};

const deleteOldFile = async (filePath: string | null) => {
  if (filePath) {
    const fileName = filePath.replace(/.*\//, "");
    try {
      await axios.delete(`/files/${fileName}`);
    } catch (error) {
      console.error("Erro ao deletar o arquivo antigo:", error);
    }
  }
};

const verifyEnterprise = async () => {
  if (!enterpriseIsVerified.value) {
    Swal.fire({
      title: "Empresa não verificada",
      text: "Aguarde a verificação para criar vagas",
      icon: "info",
      confirmButtonText: "OK",
    });
    router.push("/");
  }
};

const fetchNoticeEnterprise = async () => {
  if (usertype.value == "enterprise") {
    try {
      let response = await axios.get("/props-notice/enterprise-accept");
      noticesOfEnterprise.value = response.data;
    } catch (error) {
      console.error(error);
    }
  } else if (
    usertype.value == "secretary" ||
    usertype.value == "userSecretary"
  ) {
    try {
      let response = await axios.get("/notices/complete-candidate");
      noticesOfEnterprise.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }
};

const handleSubmit = async () => {
  const selectedData = {
    area_id_area: jobArea.value,
    enterpriseid: enterpriseId.value,
    secretaryid: secretaryid.value,
    pcd_jobs: pcdJobs.value,
    jobs_number: jobNumber.value,
    skills_hard: selectedHardSkills.value.join("-"),
    skills_soft: selectedSoftSkills.value.join("-"),
    notes: jobNotes.value,
    start_date: jobInitDate.value,
    end_date: jobEndDate.value,
    salary: jobSalary.value,
    position: jobPosition.value,
    job_type: jobType.value,
    stats: jobStatus.value,
    image: enterprisePhotoPath.value,
    fk_groupcandidacy: jobProccess.value,
    isedital: enterpriseIsEdital.value,
    id_notice: idNotice.value,
    work_format: jobFormat.value,
    linkNotice:
      noticesOfEnterprise.value.find((item) => item.id === idNotice.value)
        ?.docpath || "",
  };

  try {
    const response = await axios.post("/job-positions", selectedData);
    let payload = {
      job_data: [
        {
          id: response.data.id_job,
          name: response.data.position,
        },
      ],
    };
    const responseIA = await createJobIA(payload);
    try {
      let data = {
        bond: true,
      };
      let response = await axios.put(
        `groupCandidacy/${jobProccess.value}`,
        data
      );
      console.log(response.data);
    } catch (error) {
      Swal.fire({
        title: "Erro!",
        text: "Os dados não foram enviados, tente novamente mais tarde.",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    } finally {
      Swal.fire({
        title: "Sucesso!",
        text: "Os dados foram enviados com sucesso.",
        icon: "success",
      });
      setTimeout(() => {
        router.push("/novas-vagas");
      }, 2000);
    }
  } catch (error) {
    console.error("Erro ao enviar os dados:", error);
    Swal.fire({
      title: "Erro!",
      text: "Ocorreu um erro ao enviar os dados.",
      icon: "error",
    });
  }
};

const checkPermisionUser = async () => {
  try {
    if (usertype.value == "userSecretary") {
      let authMe = await axios.post("auth/me");
      if (authMe.data.userSecretaries[0].permissionjob != "full") {
        Swal.fire({
          title: "Acesso Negado",
          text: "Usuário não possui acesso á esta área!",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

fetchNoticeEnterprise();
onMounted(async () => {
  checkPermisionUser();
  localStorage.setItem("page", "Vagas");
  fetchProcess();
  fetchAreas();
  fetchSkill();

  await fetchEnterpriseId();
  verifyEnterprise();
});

// Computed property para formatar o salário com "R$"
const formattedJobSalary = computed<string>({
  get() {
    // Formata o valor como moeda brasileira
    const value = parseFloat(
      jobSalary.value.replace(/[^0-9,]/g, "").replace(",", ".")
    );
    if (!isNaN(value)) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    }
    return "";
  },
  set(newValue: string) {
    // Remove todos os caracteres não numéricos e formata o valor
    const numericValue = newValue.replace(/[^0-9,]/g, "");
    jobSalary.value = numericValue;
  },
});
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="flex justify-center">
      <div class="bg-white dark:bg-black rounded-lg shadow p-[40px]">
        <form @submit.prevent="handleSubmit" class="max-w-[730px]">
          <div class="mb-5.5 mt-5">
            <label
              class="mb-3 block font-medium text-black dark:text-white"
              for="jobProccess"
              >Processo de Seleção</label
            >
            <select
              v-model="jobProccess"
              class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              name="jobProccess"
              id="jobProccess"
              required
            >
              <option value="">Selecione o processo seletivo</option>
              <option
                v-for="(item, index) in processSelection"
                :key="index"
                :value="item.id"
              >
                {{ item.title }} - ({{ item.stages.length }} Etapas)
              </option>
            </select>
          </div>

          <div class="flex flex-wrap justify-center items-center">
            <div
              v-if="noticesOfEnterprise.length != 0 && usertype == 'enterprise'"
              class="w-full mb-4 mr-auto"
            >
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="enterpriseIsEdital"
                >Edital</label
              >
              <select
                v-model="idNotice"
                class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                name="enterpriseIsEdital"
                id="enterpriseIsEdital"
              >
                <!-- <option value="" disabled selected>Selecione</option> -->
                <option value="0" selected>Vaga sem Edital</option>
                <option
                  v-for="(item, index) in noticesOfEnterprise"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>

            <div
              v-if="noticesOfEnterprise.length != 0 && usertype == 'secretary'"
              class="w-full mb-4 mr-auto"
            >
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="enterpriseIsEdital"
                >Edital</label
              >
              <select
                v-model="idNotice"
                class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                name="enterpriseIsEdital"
                id="enterpriseIsEdital"
              >
                <!-- <option value="" disabled selected>Selecione</option> -->
                <option value="0" selected>Vaga sem Edital</option>
                <option
                  v-for="(item, index) in noticesOfEnterprise"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="flex flex-wrap justify-center items-center gap-6">
            <div class="w-full lg:w-[348px] mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobArea"
                >Área</label
              >
              <select
                v-model="jobArea"
                class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                name="jobArea"
                id="jobArea"
              >
                <option value="" disabled selected>Selecione</option>
                <option
                  v-for="area in areas"
                  :key="area.id_area"
                  :value="area.id_area"
                >
                  {{ area.area_description }}
                </option>
              </select>
            </div>
            <div class="w-full lg:w-[348px] mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobType"
                >Tipo de vaga</label
              >
              <select
                v-model="jobType"
                class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                name="jobType"
                id="jobType"
              >
                <option value="" disabled selected>Selecione</option>
                <option value="EM">Emprego</option>
                <option value="ES">Estágio</option>
                <option value="BO">Bolsa</option>
                <option value="MO">Monitoria</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-[348px] mb-4">
            <label
              class="mb-2.5 block font-medium text-black dark:text-white"
              for="jobFormat"
              >Formato de Trabalho</label
            >
            <select
              v-model="jobFormat"
              class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              name="jobFormat"
              id="jobFormat"
            >
              <option value="" disabled selected>Selecione</option>
              <option value="hibrido">Hibrido</option>
              <option value="presencial">Presencial</option>
              <option value="home-office">Home-Office</option>
            </select>
          </div>

          <div class="mb-4">
            <label
              class="mb-2.5 block font-medium text-black dark:text-white"
              for="jobPosition"
              >Cargo</label
            >
            <input
              type="text"
              placeholder="Cargo da vaga"
              id="jobPosition"
              name="jobPosition"
              class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              v-model="jobPosition"
            />
          </div>

          <div class="flex flex-wrap justify-center items-center gap-6">
            <div class="mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobNumber"
                >Quantidade de vagas</label
              >
              <input
                type="text"
                placeholder="Digite a quantidade de vaga"
                id="jobNumber"
                name="jobNumber"
                class="lg:w-[348px] w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="jobNumber"
              />
            </div>

            <div class="mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="pcdJobs"
                >Vagas para PCD</label
              >
              <input
                type="text"
                placeholder="Número de vagas"
                id="pcdJobs"
                name="pcdJobs"
                class="lg:w-[348px] w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="pcdJobs"
              />
            </div>
          </div>

          <div class="flex flex-wrap justify-center items-center gap-6">
            <div class="mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobSalary"
                >Salário</label
              >
              <input
                type="text"
                placeholder="Em R$"
                id="jobSalary"
                name="jobSalary"
                class="w-[279px] lg:w-[348px] w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="jobSalary"
              />
            </div>

            <div class="w-full lg:w-[348px] mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobStatus"
                >Status</label
              >
              <select
                v-model="jobStatus"
                class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                name="jobStatus"
                id="jobStatus"
              >
                <option value="" disabled>Selecione</option>
                <option value="Ativa">Ativa</option>
                <option value="Cancelada">Cancelada</option>
              </select>
            </div>
          </div>

          <div class="flex flex-wrap justify-center items-center gap-6">
            <div class="mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobInitDate"
                >Data ínicio Inscrições</label
              >
              <input
                type="date"
                placeholder="Digite aqui"
                id="jobInitDate"
                name="jobInitDate"
                class="w-[279px] lg:w-[348px] w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="jobInitDate"
                required
              />
            </div>

            <div class="mb-4">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white"
                for="jobEndDate"
                >Data final Inscrições</label
              >
              <input
                type="date"
                placeholder="Digite aqui"
                id="jobEndDate"
                name="jobEndDate"
                class="w-[279px] lg:w-[348px] w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="jobEndDate"
                required
              />
            </div>
          </div>
          <div
            class="flex lg:flex-row flex-col justify-center items-center gap-[30px] mb-5.5 bg-gray dark:bg-black p-[20px] rounded-lg"
          >
            <div class="lg:w-[348px] mt-auto max-h-[300px] overflow-y-auto">
              <label
                class="text-[18px] font-bold text-primary sticky top-0 bg-gray dark:bg-black z-10 w-full block py-2"
                >Soft Skills</label
              >
              <div
                v-if="Object.keys(softSkills).length === 0"
                class="text-gray-500"
              >
                Carregando...
              </div>
              <div
                v-for="(skills, category) in sortSkills(softSkills)"
                :key="category"
              >
                <h3 class="text-[16px] font-semibold my-4">{{ category }}</h3>
                <div
                  v-for="skill in skills"
                  :key="skill.id"
                  class="flex items-center mt-3"
                >
                  <input
                    type="checkbox"
                    :id="'soft-skill-' + skill.id"
                    v-model="selectedSoftSkills"
                    :value="skill.description"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    :for="'soft-skill-' + skill.id"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {{ skill.description }}
                  </label>
                </div>
              </div>
            </div>

            <div class="lg:w-[348px] mb-auto max-h-[300px] overflow-y-auto">
              <label
                class="text-[18px] font-bold text-primary sticky top-0 bg-gray dark:bg-black z-10 w-full block py-2"
                >Hard Skills</label
              >
              <div
                v-if="Object.keys(hardSkills).length === 0"
                class="text-gray-500"
              >
                Carregando...
              </div>
              <div v-for="(skills, category) in hardSkills" :key="category">
                <h3 class="text-[16px] font-semibold my-4">{{ category }}</h3>
                <div
                  v-for="skill in skills"
                  :key="skill.id"
                  class="flex items-center mt-3"
                >
                  <input
                    type="checkbox"
                    :id="'hard-skill-' + skill.id"
                    v-model="selectedHardSkills"
                    :value="skill.description"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    :for="'hard-skill-' + skill.id"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {{ skill.description }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="enterpriseCanEditalJob" class="mb-5.5">
          <label
            class="mb-2.5 block font-medium text-black dark:text-white"
            for="jobEdital"
            >Carregar edital</label
          >
          <div
            class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
          >
            <input
              name="jobEdital"
              type="file"
              accept="application/pdf"
              id="jobEdital"
              @change="handleFileUpload"
              class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
            />
            <div class="flex flex-col items-center justify-center space-y-3">
              <span
                class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                    fill="#3C50E0"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                    fill="#3C50E0"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                    fill="#3C50E0"
                  />
                </svg>
              </span>

              <p class="text-sm font-medium">
                <span class="text-primary">Clique para fazer upload</span>
                ou arraste e solte
              </p>
              <p class="mt-1.5 text-sm font-medium">.PDF apenas</p>
              <p v-if="fileName" class="mt-2 text-sm text-primary text-center">
                Arquivo carregado: {{ fileName }}
              </p>
            </div>
          </div>
        </div> -->

          <div class="mb-5.5 mt-5">
            <label
              class="mb-3 block text-sm font-medium text-black dark:text-white"
              for="jobNotes"
              >Observações</label
            >
            <textarea
              v-model="jobNotes"
              class="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
              name="jobNotes"
              id="jobNotes"
              rows="6"
            ></textarea>
          </div>

          <div class="mb-3 mt-5">
            <button
              type="submit"
              class="rounded-lg bg-primary hover:opacity-[.8] text-white font-medium py-3 px-8 text-[20px]"
            >
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>
  </DefaultLayout>
</template>

<style>
select {
  line-height: 2.2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("@/assets/images/icon/arrow.svg") 94% center no-repeat;
  background-size: 22px;
  background-position-x: calc(100% - 22px);
  width: 100%;
}
</style>
