<script setup lang="ts">
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CandidatesTable from "@/components/Tables/CandidatesTable/CandidatesTable.vue";
import { ref } from "vue";

const pageTitle = ref("Candidatos");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <CandidatesTable />
  </DefaultLayout>
</template>

