import axios from '@/api/axios'; // ajuste o caminho conforme necessário

export const fetchEnterprise = async (userId: string): Promise<{ id: number }> => {
  try {
    const response = await axios.get(`/enterprises/by-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao carregar Empresa:', error);
    throw error;
  }
};
