<script setup>
import { ref } from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import UserPublicEnterpriseComponente from "@/components/UsersPublicEnterprise/UserPublicEnterpriseComponent.vue";

const pageTitle = ref("Usuários");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <UserPublicEnterpriseComponente />
  </DefaultLayout>
</template>