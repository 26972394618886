<template>
  <DefaultLayout>
    <div class="flex flex-col items-center justify-center bg-gray-100">
      <div
        class="flex lg:flex-row flex-col justify-center items-center bg-white py-8 px-10 rounded-lg shadow-lg gap-4 dark:bg-meta-4"
      >
        <div>
          <h1
            class="lg:text-[40px] text-[32px] font-semibold mb-4 leading-[1.2] dark:text-white"
          >
            Bem-vindo(a) à<br />
            <strong class="font-bold text-primary">oportunidade</strong> Jovem!
          </h1>
          <p class="leading-[1.2] dark:text-white">
            Conectamos empresas, candidatos e secretarias do estado de forma
            inteligente.<br />
            Crie seu perfil com IA e acesse vagas e editais públicos de maneira
            fácil e eficiente.
            <br />
            <br />
            <span class="font-bold text-primary">Boa sorte!</span>
          </p>
          <div class="mt-12" v-if="enterpriseIsVerified || secretaryIsVerified">
            <p class="ml-3 text-[12px] mb-[5px]">
              Começe a cadastras vagas agora!
            </p>
            <router-link to="/adicionar-vaga">
              <button
                class="flex justify-center font-bold border-[1px] border-primary text-white bg-primary hover:bg-white hover:text-primary duration-200 p-3 rounded-full w-50 text-center"
                type="submit"
              >
                CRIAR VAGAS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </router-link>
            <div
              v-if="
                (!enterpriseIsVerified && enterpriseName) ||
                (!secretaryIsVerified && secretaryCnpj)
              "
            >
              <AwaitingAnalysis />
            </div>
          </div>
        </div>
        <div>
          <img
            :src="homeIlustrator"
            alt="Home Illustration"
            class="mt-6 mx-auto lg:w-[550px] w-70 h-auto"
          />
        </div>
        <div></div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup>
import axios from "@/api/axios.js";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AwaitingAnalysis from "@/views/Pages/Helpers/AwaitingAnalysis.vue";
import homeIlustrator from "@/assets/images/illustration/illustration-home.svg";

const userId = localStorage.getItem("userId");
const usertype = localStorage.getItem("usertype");
const router = useRouter();
const enterpriseIsVerified = ref();
const secretaryIsVerified = ref();
const enterpriseName = ref();
const enterpriseCnpj = ref();
const candidateCpf = ref();
const adminCpf = ref();
const secretaryCnpj = ref();

const isPasswordChecked = async () => {
  const authMe = await axios.post("auth/me");
  if (
    (authMe.data.userSecretaries &&
      authMe.data.userSecretaries[0].checkPassword === false) ||
    (authMe.data.userenterprises &&
      authMe.data.userenterprises[0].checkPassword === false)
  ) {
    console.log(authMe.data);
    const { value: formValues } = await Swal.fire({
      title: "Primeiro acesso",
      icon: "info",
      html:
        '<p class="mb-2 p-2 text-sm">Altere sua senha para continuar usando a plataforma!</p>' +
        '<input id="swal-input1" class="swal2-input" type="password" placeholder="Nova senha">' +
        '<input id="swal-input2" class="swal2-input" type="password" placeholder="Confirmar senha">',
      focusConfirm: false,
      confirmButtonText: "Atualizar",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const newPassword = document.getElementById("swal-input1").value;
        const confirmPassword = document.getElementById("swal-input2").value;

        if (!newPassword || !confirmPassword) {
          Swal.showValidationMessage("Ambos os campos são necessários");
          return false;
        }

        if (newPassword !== confirmPassword) {
          Swal.showValidationMessage("As senhas não correspondem");
          return false;
        }

        return { newPassword, confirmPassword };
      },
      allowOutsideClick: false,
    });

    if (formValues) {
      try {
        await axios.patch("auth/me/password-simple", {
          password: formValues.newPassword,
        });

        Swal.fire({
          title: "Alteração concluída",
          text: "Senha alterada com sucesso",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Atualize os dados do usuário no Vuex store ou estado local, se necessário
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Falha ao atualizar senha. Por favor tente novamente",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  }
};

const checkUserType = async () => {
  if (usertype === "userSecretary" || usertype === "userEnterprise") {
    await isPasswordChecked();
  }

  if (usertype === "candidate") {
    try {
      const response = await axios.get(`/candidates/users/${userId}`);
      candidateCpf.value = response.data.cpf;
      localStorage.setItem("imagePath", response.data.imagepath);
    } catch (error) {
      console.error("Erro ao verificar o candidato:", error.response.data);
      Swal.fire({
        title: "Erro",
        text: "Ocorreu um erro ao verificar os dados do candidatos.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  } else if (usertype === "enterprise") {
    try {
      response = await axios.get(`/enterprises/users/${userId}`);
      enterpriseCnpj.value = response.data.cnpj;
      enterpriseIsVerified.value = response.data.isverified;
      enterpriseName.value = response.data.companyname;
      console.log("resposta api Empresa: ", response.data);
      localStorage.setItem("imagePath", response.data.imagepath);
    } catch (error) {
      console.error("Erro ao verificar a empresa:", error.response.data);
      Swal.fire({
        title: "Erro",
        text: "Ocorreu um erro ao verificar os dados da empresa.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  } else if (usertype === "secretary") {
    try {
      response = await axios.get(`/secretaries/complete/${userId}`);
      secretaryCnpj.value = response.data.cnpj;
      secretaryIsVerified.value = response.data.isverified;
      console.log("resposta api Secretaria: ", response.data);
      localStorage.setItem("imagePath", response.data.imagepath);
    } catch (error) {
      console.error("Erro ao verificar a secretaria:", error.response.data);
      // Swal.fire({
      //   title: "Erro",
      //   text: "Ocorreu um erro ao verificar os dados da Secretaria.",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
    }
  } else if (usertype === "admin") {
    try {
      response = await axios.get(`/admins/users/${userId}`);
      adminCpf.value = response.data.cpf;
    } catch (error) {
      console.error("Erro ao verificar o admin:", error.response.data);
      Swal.fire({
        title: "Erro",
        text: "Ocorreu um erro ao verificar os dados do Administrador.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};

onMounted(async () => {
  await checkUserType();
  if (usertype === "enterprise" && enterpriseCnpj.value === null) {
    router.push("/perfil");
  }

  if (usertype === "secretary" && secretaryCnpj.value === null) {
    router.push("/perfil");
  }

  if (usertype === "candidate" && candidateCpf.value === null) {
    router.push("/perfil");
  }

  if (usertype === "admin" && adminCpf.value === null) {
    router.push("/perfil");
  }
});
</script>
