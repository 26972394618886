<template>
  <div class="mb-4">
    <label class="mb-2.5 block font-medium text-black dark:text-white">{{ label }}</label>
    <div class="relative">
      <!-- Utilize v-model para vincular o valor do campo de entrada a uma variável reativa -->
      <input :type="type" :placeholder="placeholder" :class="customClasses" required
        class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
        :v-model="value" />
      <span class="absolute right-4 top-4">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';

const props = defineProps(['label', 'type', 'placeholder', 'customClasses']);
// Defina uma variável reativa para armazenar o valor do campo de entrada
const value = ref('');
</script>
