<script setup>
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import CalendarCard from "@/components/CalendarCard.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

const pageTitle = ref("Calender");
</script>

<template>
  <DefaultLayout>
    <div class="mx-auto max-w-7xl">
      <!-- Breadcrumb Start -->
      <BreadcrumbDefault :pageTitle="pageTitle" />
      <!-- Breadcrumb End -->
      <CalendarCard />
    </div>
  </DefaultLayout>
</template>
