<template>
  <div class="min-h-screen flex flex-col">
    <Navbar />
    <div class="flex-1 container mx-auto px-6 py-8">
      <h2 class="mt-22 ml-20 text-2xl font-bold text-primary"> <strong>Oportunidades</strong> para você!</h2>
      <ListChatBotComponent />
    </div>
    <Footer/>
  </div>
</template>

<script>
import ListChatBotComponent from "@/components/ChatBot/ListChatBot.vue";
import Navbar from "@/components/Navbar/NavbarView.vue";
import Footer from "@/components/Footer/FooterView.vue";
export default {
  name: 'WrapperComponent',
  components: {
    Navbar,
    ListChatBotComponent,
    Footer
  }
};
</script>
