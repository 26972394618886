<template>
    <div class="absolute z-999 inset-0 flex items-center justify-center bg-[#2e3a47b8]" v-if="show">
        <div class="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 class="text-2xl mb-4 font-bold">{{ title }}</h2>
            <div>
                <textarea
                    class="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="bio" id="bio" rows="6" required placeholder="Descreva aqui..."></textarea>
            </div>
            <div class="flex justify-end mt-4">
                <button class="bg-primary text-white px-4 py-2 rounded" @click="closeModal">Fechar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Qual o motivo da sua desistência?',
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
}
</script>