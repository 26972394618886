import { defineStore } from 'pinia';

type UserType = 'enterprise' | 'admin' | 'secretary' | 'userSecretary' | 'candidate' | 'userEnterprise';
type UserPermissionJob = 'full' | 'visible' | 'off' | null; 
type UserPermissionCandidateStage = 'full' | 'visible' | 'off' | null;
type UserPermissionNotice = 'full' | 'visible' | 'off' | null;

interface UserState {
    userType: UserType | null;
    userPermissionJob: UserPermissionJob;
    userPermissionCandidateStage: UserPermissionCandidateStage;
    userPermissionNotice: UserPermissionNotice;
}

export const useUserStore = defineStore('user', {
    state: (): UserState => ({
        userType: null,
        userPermissionJob: null,
        userPermissionCandidateStage: null,
        userPermissionNotice: null         
    }),

    getters: {
        isAdmin: (state) => state.userType === 'admin',
        isSecretary: (state) => state.userType === 'secretary',
        isUserSecretary: (state) => state.userType === 'userSecretary',
        isEnterprise: (state) => state.userType === 'enterprise',
        isUserEnterprise: (state) => state.userType === 'userEnterprise',
        isCandidate: (state) => state.userType === 'candidate',
        hasFullPermissionJob: (state) => state.userPermissionJob === 'full',
        hasFullPermissionCandidateStage: (state) => state.userPermissionCandidateStage === 'full',
        hasFullPermissionNotice: (state) => state.userPermissionNotice === 'full',
    },

    actions: {
        setUserType(userType: UserType) {
            this.userType = userType;
        },
        setUserPermissionJob(userPermissionJob: UserPermissionJob) {
            this.userPermissionJob = userPermissionJob;
        },
        setUserPermissionCandidateStage(userPermissionCandidateStage: UserPermissionCandidateStage){
            this.userPermissionCandidateStage = userPermissionCandidateStage;
        },
        setUserPermissionNotice(userPermissionNotice: UserPermissionNotice){
            this.userPermissionNotice = userPermissionNotice;
        },

        permissionLevelUser(permission: string) {
            switch (permission) {
                case 'advanced':
                    return this.hasFullPermissionJob &&
                           this.hasFullPermissionCandidateStage &&
                           this.hasFullPermissionNotice;
                case 'intermediate':
                    return this.userPermissionJob &&
                           this.userPermissionCandidateStage;
                case 'basic':
                    return false;
                default:
                    return false;
            }

        },
        hasPermission(permission: string): boolean {
            if (this.isAdmin) {
                return true;
            }
            switch (permission) {
                case 'adminAccess':
                    return this.isAdmin;
                case 'secretaryAccess':
                    return this.isSecretary;
                case 'userSecretaryAccess':
                    return this.isUserSecretary || this.isSecretary;
                case 'enterpriseAccess':
                    return this.isEnterprise;
                case 'userEnterpriseAccess':
                    return this.isUserEnterprise || this.isEnterprise;
                case 'candidateAccess':
                    return this.isCandidate; 
                default:
                    return false;
            }
        },
    },
});