<script setup lang="ts">
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CurriculumData from "@/views/Pages/Curriculum/CurriculumData.vue";
import DetailCandidacyUserComponent from "@/components/Company/candidacy/DetailCandidacyUserComponent.vue";
import { useRouter } from "vue-router";

import { ref } from "vue";

const pageTitle = ref("Detalhes do Candidato");
const router = useRouter();

const back = () => {
  router.go(-1);
};
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="mb-3">
      <button @click="back">Voltar</button>
    </div>
    <div class="mb-3">
      <CurriculumData />
    </div>
    <DetailCandidacyUserComponent />
    <CandidatesTable />
  </DefaultLayout>
</template>
