<script setup lang="ts">
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import TableNewHiring from "@/components/Tables/TablesCompany/TableNewHiring.vue";

const pageTitle = ref("Minhas Vagas");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div>
      <TableNewHiring />
    </div>
  </DefaultLayout>
</template>
