<script>
import { ref, onMounted } from "vue";
import axios from "@/api/axios";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { fetchEnterprise } from "@/services/enterpriseService";
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

export default {
  setup() {
    const userStore = useUserStore();
    const showToSecretaryAndEnterprise = computed(
      () =>
        userStore.isSecretary ||
        userStore.isEnterprise ||
        userStore.isUserEnterprise ||
        userStore.isUserSecretary
    );
    const router = useRouter();
    const conditionNewStep = ref(false);
    const conditionStep = ref(false);
    const stepToAdd = ref(false);
    const userId = localStorage.getItem("userId");
    const usertype = ref(localStorage.getItem("usertype"));
    const auth = ref();

    let stepsCandidate = ref([]);
    let stepsCandidateToAdd = ref([]);
    let isSaving = ref(false); // Para controlar o estado de salvamento

    let group = ref({
      title: "",
    });

    let steps = ref({
      title: "",
      order_number: stepsCandidateToAdd.value.length + 1, // Initialize with next step number
      description: "",
      fk_group: "",
    });

    function openCardSteps() {
      if (usertype.value == "userSecretary") {
        if (auth.value.userSecretaries[0].permissionstagecandidacy == "full") {
          if (stepsCandidateToAdd.value.length == 0) {
            conditionStep.value = !conditionStep.value;
          }
          conditionNewStep.value = !conditionNewStep.value;
        } else {
          Swal.fire({
            title: "Acesso Negado",
            text: "Usuário não possui acesso á esta área!",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      } else if (usertype.value == "userEnterprise") {
        if (auth.value.userenterprises[0].permissionstagecandidacy == "full") {
          console.log(auth.value.userenterprises[0].permissionstagecandidacy);
          if (stepsCandidateToAdd.value.length == 0) {
            conditionStep.value = !conditionStep.value;
          }
          conditionNewStep.value = !conditionNewStep.value;
        } else {
          Swal.fire({
            title: "Acesso Negado",
            text: "Usuário não possui acesso á esta área!",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      } else {
        conditionNewStep.value = !conditionNewStep.value;
      }
    }

    async function saveStepCandidacy() {
      const newSteps = {
        title: steps.value.title,
        order_number: steps.value.order_number,
        description: steps.value.description,
        fk_group: 1, // Supondo que fk_group seja um número válido
      };

      if (
        newSteps.title !== "" &&
        newSteps.order_number !== "" &&
        !isNaN(newSteps.order_number)
      ) {
        try {
          isSaving.value = true;
          console.log("Enviando dados para o servidor:", newSteps);
          const response = await axios.post("/stagecandidacy", newSteps);
          console.log("Resposta do servidor:", response.data);
          stepsCandidateToAdd.value.push(response.data);
          steps.value.title = "";
          steps.value.order_number = stepsCandidateToAdd.value.length + 1;
          steps.value.description = "";
          Swal.fire({
            title: "Etapa Adicionada",
            text: "A nova etapa foi adicionada com sucesso.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error(
            "Erro ao adicionar a etapa:",
            error.response || error.message || error
          );
          Swal.fire({
            title: "Erro",
            text: `Houve um problema ao adicionar a nova etapa: ${
              error.response?.data?.message || error.message
            }`,
            icon: "error",
            confirmButtonText: "OK",
          });
        } finally {
          isSaving.value = false;
        }
      } else {
        Swal.fire({
          title: "",
          text: "Por favor, preencha todos os campos obrigatórios.",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    }

    const deleteStepCandidacy = async (id, index) => {
      try {
        await axios.delete(`/stagecandidacy/${id}`);
        stepsCandidateToAdd.value.splice(index, 1);
        Swal.fire({
          title: "Etapa Apagada",
          text: "A etapa foi apagada com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(
          "Erro ao apagar a etapa:",
          error.response || error.message || error
        );
        Swal.fire({
          title: "Erro",
          text: `Houve um problema ao apagar a etapa: ${
            error.response?.data?.message || error.message
          }`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const verifyEnterprise = async () => {
      if (usertype.value == "enterprise") {
        const enterpriseData = await fetchEnterprise(userId);
        console.log("Estado de verificação: ", enterpriseData.isverified);
        if (enterpriseData.isverified) {
        } else {
          Swal.fire({
            title: "Empresa não verificada",
            text: "Aguarde a verificação para criar Processos de seleção",
            icon: "info",
            confirmButtonText: "OK",
          });
          router.push("/");
        }
      }
    };

    function addNewStep() {
      conditionStep.value = !conditionStep.value;
      steps.value.order_number = stepsCandidateToAdd.value.length + 1; // Initialize with next step number
    }

    const disableGroupCandidacy = async (id) => {
      if (usertype.value == "enterprise" || usertype == "secretary") {
        let data = {
          active: false,
        };
        try {
          let response = await axios.put(`/groupCandidacy/${id}`, data);
        } catch (error) {
          console.error(error);
        } finally {
          transactionOk();
        }
      } else {
        Swal.fire({
          title: "Acesso Negado",
          text: "Usuário não possui acesso á esta área!",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    };

    const sendCandidacy = async () => {
      try {
        const responseGroup = await axios.post("/groupCandidacy", group.value);
        if (responseGroup.data != "" || responseGroup.data != null) {
          try {
            for (let i = 0; i < stepsCandidateToAdd.value.length; i++) {
              stepsCandidateToAdd.value[i].fk_group = responseGroup.data.id;
              const responseStep = await axios.post(
                "/stagecandidacy",
                stepsCandidateToAdd.value[i]
              );
            }
          } catch (error) {
            console.error(error);
          } finally {
            transactionOk();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchGroupCandidacy = async () => {
      try {
        const response = await axios.get("/groupCandidacy");
        if (response.data != "" || response.data != null) {
          stepsCandidate.value = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    };

    function transactionOk() {
      Swal.fire({
        title: "Informações salvas",
        text: "Os dados foram salvos com sucesso.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setTimeout(() => {
        window.location.reload(); //If activated, after saving it will show everyone who has already been saved
      }, 2000);
    }

    function redirectToUpdate(id) {
      if (usertype.value == "userEnterprise") {
        if (auth.value.userenterprises[0].permissionstagecandidacy == "full") {
          router.push(`/processo-seletivo/editar/${id}`);
        } else {
          Swal.fire({
            title: "Acesso Negado",
            text: "Usuário não possui acesso á esta área!",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      } else if (usertype.value == "userSecretary") {
        if (auth.value.userSecretaries[0].permissionstagecandidacy == "full") {
          router.push(`/processo-seletivo/editar/${id}`);
        } else {
          Swal.fire({
            title: "Acesso Negado",
            text: "Usuário não possui acesso á esta área!",
            icon: "info",
            confirmButtonText: "OK",
          });
        }
      } else if (usertype.value == "enterprise") {
        router.push(`/processo-seletivo/editar/${id}`);
      } else if (usertype.value == "secretary") {
        router.push(`/processo-seletivo/editar/${id}`);
      }
    }

    function deleteIndexStep(i) {
      const step = stepsCandidateToAdd.value[i];
      deleteStepCandidacy(step.id, i);
    }

    fetchGroupCandidacy();
    onMounted(async () => {
      localStorage.setItem("page", "Processo de Seleção");
      await verifyEnterprise();
    });

    const checkPermisionUser = async () => {
      try {
        if (usertype.value == "userSecretary") {
          let authMe = await axios.post("auth/me");
          auth.value = authMe.data;
          if (
            authMe.data.userSecretaries[0].permissionstagecandidacy == "off"
          ) {
            Swal.fire({
              title: "Acesso Negado",
              text: "Usuário não possui acesso á esta área!",
              icon: "info",
              confirmButtonText: "OK",
            }).then(() => {
              router.push("/");
            });
          }
        } else if (usertype.value == "userEnterprise") {
          let authMe = await axios.post("auth/me");
          auth.value = authMe.data;
          if (
            authMe.data.userenterprises[0].permissionstagecandidacy == "off"
          ) {
            Swal.fire({
              title: "Acesso Negado",
              text: "Usuário não possui acesso á esta área!",
              icon: "info",
              confirmButtonText: "OK",
            }).then(() => {
              router.push("/");
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkPermisionUser();

    return {
      group,
      steps,
      stepsCandidate,
      conditionNewStep,
      conditionStep,
      stepToAdd,
      stepsCandidateToAdd,
      disableGroupCandidacy,
      redirectToUpdate,
      saveStepCandidacy,
      sendCandidacy,
      openCardSteps,
      addNewStep,
      deleteIndexStep,
      isSaving,
      showToSecretaryAndEnterprise,
    };
  },
};
</script>


<template>
  <div v-if="conditionNewStep" class="text-title-md2 mb-5">
    <div class="flex justify-start items-center gap-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
        />
      </svg>
      <h2
        class="text-[20px] font-semibold dark:text-white text-[#4c5b74] leading-none mt-[3px]"
      >
        Cadastro
      </h2>
    </div>
  </div>

  <div
    v-if="conditionNewStep"
    class="py-[30px] px-[35px] bg-white dark:bg-black w-full h-[auto] rounded-2xl shadow-md"
  >
    <div class="">
      <label class="mb-2.5 block font-medium text-black dark:text-white"
        >Título do Processo</label
      >
      <input
        v-model="group.title"
        required
        type="text"
        placeholder="Digite o título do processo seletivo"
        id="title-process"
        name="title"
        class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
        :class="{ 'border-red-500': !group.title && submitted }"
        @blur="submitted = true"
      />
      <span v-if="!group.title && submitted" class="text-red-500 text-sm">
        O título do processo é obrigatório.
      </span>
    </div>
    <div class="mt-4" v-if="stepsCandidateToAdd.length != 0">
      <div class="mt-4">
        <div class="my-[30px] border-t-[1px]">
          <h4 class="text-[18px] font-bold mt-[10px]">Nova Etapa</h4>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
          <table class="w-full text-left rtl:text-right dark:text-gray">
            <thead class="uppercase bg-gray dark:bg-gray-700 dark:text-gray">
              <tr class="bg-bodydark1 dark:bg-graydark text-center">
                <th
                  scope="col"
                  class="p-4 rounded-tl-[12px] border-r-[1px] border-gray dark:border-black"
                >
                  Ordem
                </th>
                <th
                  scope="col"
                  class="p-4 border-r-[1px] border-gray dark:border-black"
                >
                  Título
                </th>
                <th
                  scope="col"
                  class="p-4 border-r-[1px] border-gray dark:border-black"
                >
                  Descrição
                </th>
                <th scope="col" class="p-4 rounded-tr-[12px]">Apagar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in stepsCandidateToAdd"
                :key="index"
                class="bg-gray dark:bg-black text-center"
              >
                <td
                  class="p-4 font-medium text-gray-900 whitespace-nowrap border-r-[1px] border-white dark:border-graydark"
                >
                  {{ item.order_number }}
                </td>

                <td
                  class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border-r-[1px] border-white dark:border-graydark"
                >
                  {{ item.title }}
                </td>

                <td
                  class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border-r-[1px] border-white dark:border-graydark"
                >
                  {{ item.description }}
                </td>
                <td
                  class="p-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <button
                    class="rounded-full bg-red p-2 text-white"
                    @click="deleteIndexStep(index)"
                  >
                    <img src="/src/assets/images/icon/trash.svg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="conditionStep">
      <div class="mt-5 flex justify-center gap-5">
        <button
          @click="saveStepCandidacy"
          class="bg-primary hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
        >
          <span v-if="!isSaving">
            <i class="fa-solid fa-plus"></i> Adicionar Etapa</span
          >
          <span v-else>Salvando...</span>
        </button>
        <button
          class="bg-success hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
          @click="sendCandidacy"
        >
          <i class="fa-solid fa-floppy-disk"></i> Salvar
        </button>
        <button
          class="bg-red hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
          @click="openCardSteps"
        >
          <i class="fa-solid fa-xmark"></i> Cancelar
        </button>
      </div>
      <div class="my-[30px] border-t-[1px]">
        <h4 class="text-[18px] font-bold mt-[10px]">Nova Etapa</h4>
      </div>

      <div class="md:flex">
        <div class="md:w-[74%]">
          <label class="mb-2.5 block font-medium text-black dark:text-white"
            >Título da Etapa</label
          >
          <input
            type="text"
            placeholder="Digite o título da etapa"
            id="title-process"
            name="title"
            class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="steps.title"
            required
          />
        </div>

        <div class="md:ml-[3%] md:w-[23%]">
          <label
            class="mb-2.5 block font-medium text-black dark:text-white mt-2 md:mt-0"
            >Posição da etapa</label
          >
          <input
            type="text"
            placeholder="Digite a posição da etapa"
            id="title-process"
            name="title"
            class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="steps.order_number"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full">
          <label
            class="mb-2.5 block font-medium text-black dark:text-white mt-2"
          >
            Descrição da etapa
          </label>
          <textarea
            class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            placeholder="Digite a descrição da etapa"
            id=""
            v-model="steps.description"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="mt-5 flex justify-center">
      <button
        data-tooltip-target="tooltip-default"
        class="bg-primary hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
        @click="addNewStep"
        v-if="!conditionStep"
      >
        <i class="fa-solid fa-plus"></i> Adicionar Etapas
      </button>

      <button
        data-tooltip-target="tooltip-default"
        class="rounded-full bg-danger hover:bg-red-very-light w-[40px] h-[40px] text-white"
        @click="addNewStep"
        v-if="conditionStep && stepsCandidateToAdd.length != 0"
      >
        <i class="fa-solid fa-x"></i>
      </button>

      <div
        id="tooltip-default"
        role="tooltip"
        class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
      >
        Tooltip content
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>

    <div class="mt-2 flex gap-[10px] justify-end" v-if="conditionStep">
      <div></div>
      <div></div>
    </div>
  </div>

  <div v-if="!conditionNewStep">
    <div class="flex justify-end">
      <button
        v-if="showToSecretaryAndEnterprise"
        class="btn rounded-full bg-primary w-[40px] h-[40px] text-white"
        @click="openCardSteps"
      >
        +
      </button>
    </div>

    <div class="mt-5">
      <div v-if="stepsCandidate.length == 0" class="text-center">
        <p>
          Ainda não existem processos de seleção, clique
          <a
            class="text-primary cursor-pointer underline font-bold"
            @click="openCardSteps"
            >aqui</a
          >
          para criar um
        </p>
      </div>

      <div
        v-if="stepsCandidate.length != 0"
        class="relative overflow-x-auto shadow-md sm:rounded-lg"
      >
        <table class="w-full text-sm shadow">
          <thead
            class="text-center uppercase bg-boxdark text-white dark:bg-black dark:text-gray"
          >
            <tr>
              <th scope="col" class="p-3 rounded-tl-[12px]">Título</th>
              <th scope="col" class="p-3">Etapas</th>
              <th scope="col" class="p-3">Vinculo com Vaga</th>
              <th scope="col" v-if="showToSecretaryAndEnterprise" class="p-3">
                Editar
              </th>
              <th
                scope="col"
                v-if="showToSecretaryAndEnterprise"
                class="p-3 rounded-tr-[12px]"
              >
                Desativar
              </th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr
              v-for="(item, index) in stepsCandidate"
              :key="index"
              class="odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 hover:bg-gray even:dark:bg-black dark:border-strokedark"
            >
              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                {{ item.title }}
              </td>

              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                {{ item.stages.length }}
              </td>

              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                <button disabled v-if="!item.bond">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />

                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />

                    <g id="SVGRepo_iconCarrier">
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="#ef0b0b"
                        stroke-width="1.5"
                      />
                      <path
                        d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
                        stroke="#ef0b0b"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </g>
                  </svg>
                </button>
                <button disabled v-if="item.bond">
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <g id="SVGRepo_iconCarrier">
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="#219653"
                        stroke-width="1.5"
                      />
                      <path
                        d="M8.5 12.5L10.5 14.5L15.5 9.5"
                        stroke="#219653"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </button>
              </td>

              <td
                v-if="showToSecretaryAndEnterprise"
                class="p-3 font-medium whitespace-nowrap dark:text-white"
              >
                <button
                  class="rounded-full bg-primary p-1 hover:opacity-[0.8]"
                  @click="redirectToUpdate(item.id)"
                >
                  <img width="18" src="/src/assets/images/icon/editNote.svg" />
                </button>
              </td>

              <td
                v-if="showToSecretaryAndEnterprise"
                class="p-3 font-medium whitespace-nowrap dark:text-white"
              >
                <button
                  class="p-1 rounded-full bg-red hover:opacity-[0.8]"
                  @click="disableGroupCandidacy(item.id)"
                >
                  <img width="18" src="/src/assets/images/icon/trash.svg" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
</style>