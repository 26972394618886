<script setup>
import InputGroup from '@/components/Auths/InputGroup.vue'
</script>

<template>
    <div class="absolute z-999 inset-0 flex items-center justify-center bg-[#2e3a47b8]" v-if="show">
        <div class="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 class="text-2xl mb-4 font-bold">{{ title }}</h2>
            <div class="flex items-center gap-4">
                <img src="@/assets/images/user/user-02.png" alt="">
                <div>
                    <h6 class="font-bold text-[18px]">Lorenna F. de Martizo</h6>
                    <p class="font-light text-[14px] mt-1">Data do cadastro: <span class="font-medium">24/02/2024</span>
                    </p>
                </div>
            </div>
            <div class="mt-6">
                <div class="flex lg:flex-row flex-col justify-start items-center lg:gap-5">
                    <InputGroup label="Data da entrevista" type="date" class="w-full" />
                    <InputGroup label="Hora da entrevista" type="time" class="w-full" />
                </div>
                <div class="mb-5.5">
                    <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                        for="bio">Observação</label>
                    <textarea
                        class="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="bio" id="bio" rows="6" placeholder="Digite aqui..."></textarea>
                </div>
            </div>
            <div class="flex justify-end mt-4 gap-3">
                <button class="bg-primary text-white px-4 py-2 rounded">Enviar</button>
                <button class="text-slate-600 underline px-4 py-2 rounded" @click="closeModal">Voltar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Agendamento',
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
}
</script>