<template>
<div class="border border-stroke dark:border-strokedark border-max-w-md mx-auto p-6 bg-white dark:bg-boxdark rounded-lg shadow-md">

    <h2 class="text-2xl font-semibold text-center mb-6 dark:text-white">Alterar Senha</h2>
    <form @submit.prevent="changePassword">
      <div class="mb-4">
        <label for="current-password" class="block text-sm font-medium text-black dark:text-white">Senha Atual</label>
        <input
          type="password"
          id="current-password"
          v-model="currentPassword"
          placeholder="Digite sua senha atual"
          class="mt-4 w-full rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          required
        />
      </div>

      <div class="mb-4">
        <label for="new-password" class="block text-sm font-medium text-black dark:text-white">Nova Senha</label>
        <input
          type="password"
          id="new-password"
          v-model="newPassword"
          placeholder="Digite sua nova senha"
          class="mt-4 w-full rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          required
        />
      </div>

      <div class="mb-4">
        <label for="confirm-password" class="block text-sm font-medium text-black dark:text-white">Confirmar Nova Senha</label>
        <input
          type="password"
          id="confirm-password"
          v-model="confirmPassword"
          placeholder="Confirme sua nova senha"
          class="mt-4 w-full rounded border border-stroke bg-gray py-3 pl-5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
          required
        />
      </div>

      <button
        type="submit"
        class="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 dark:bg-indigo-500 dark:hover:bg-indigo-600"
      >
        Alterar Senha
      </button>
    </form>
  </div>
</template>

<script>
import axios from "@/api/axios.js";
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  },
  methods: {
    async changePassword() {
      // Verifica se a nova senha e a confirmação da nova senha coincidem
      if (this.newPassword !== this.confirmPassword) {
        Swal.fire({
          title: 'Erro',
          text: 'As novas senhas não coincidem. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      // Verifica se a nova senha é igual à senha atual
      if (this.currentPassword === this.newPassword) {
        Swal.fire({
          title: 'Erro',
          text: 'A nova senha não pode ser igual à senha atual.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      try {
        // Envia a senha atual e a nova senha para o backend
        const response = await axios.patch('/auth/me/password', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });

        if (response.data.success) {
          Swal.fire({
            title: 'Sucesso',
            text: 'Senha alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          // Limpa os campos após sucesso
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
        } else {
          Swal.fire({
            title: 'Sucesso',
            text: 'Senha alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
        }
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Senha atual incorreta. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    },
  },
};
</script>

