<script setup>
import { ref, onMounted, computed } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import MultiSelectView from "@/components/Forms/MultiSelect/MultiSelectView.vue";
import Swal from "sweetalert2";
import axios from "@/api/axios.js";
import { useRouter } from "vue-router";
import ButtonDefault from '@/components/Buttons/ButtonDefault.vue'
import DarkModeSwitcher from "@/components/Header/DarkModeSwitcher.vue"
import Navbar from "@/components/Navbar/NavbarView.vue"
import Footer from "@/components/Footer/FooterView.vue"

const router = useRouter();
const pageTitle = ref("Desenvolvimento Profissional");

const softSkills = ref([]);
const hardSkills = ref([]);

const soft = ref({});
const hard = ref({});
const dream = ref("");
const selectedSoftSkills = ref([]);
const selectedHardSkills = ref([]);
const isLoading = ref(false);
const userId = localStorage.getItem("userId");
const candidateId = ref("");
const professions = ref([]);
const profileIa = ref(null);
const doAgain = ref(false);




const fetchDreams = async () => {
    try {
        const response = await axios.get("/dreams");
        professions.value = response.data
            .map(item => item.description)
            .sort((a, b) => a.localeCompare(b));
    } catch (error) {
        console.error("Erro ao buscar sonhos profissionais:", error);
    }
};

const fetchCandidate = async () => {
    try {
        const response = await axios.get(`/candidates/by-user/${userId}`);
        const candidateData = response.data;
        candidateId.value = candidateData.id;
        if (candidateData.profileia) {
            profileIa.value = JSON.parse(candidateData.profileia); // Parse the JSON string
        }
        console.log("Perfil ia: ", profileIa.value);
        localStorage.setItem("imagePath", candidateData.imagepath);
        localStorage.setItem("userName", candidateData.fullname);
    } catch (error) {
        console.error("Erro ao carregar candidato:", error);
    }
};
const fetchSkills = async () => {
    try {
        const response = await axios.get("/skills");
        const data = response.data;
        console.log("Dados recebidos:", data);
        data.forEach((item) => {
            if (item.type.startsWith("s")) {
                softSkills.value.push(item.description);
                if (!soft.value[item.category]) soft.value[item.category] = [];
                soft.value[item.category].push(item.description);
            } else if (item.type.startsWith("h")) {
                hardSkills.value.push(item.description);
                if (!hard.value[item.category]) hard.value[item.category] = [];
                hard.value[item.category].push(item.description);
            }
        });
        softSkills.value.sort((a, b) => a.localeCompare(b));
        softSkills.value.sort((a, b) => a.localeCompare(b));

        console.log("Array de softskills :", softSkills.value);
        console.log("Array de hardskills :", hardSkills.value);

        console.log({ soft: soft.value, hard: hard.value });
    } catch (error) {
        console.error("Erro ao buscar habilidades:", error);
    }
};

function formatResponse(data) {
    let formattedHtml = '<div class="text-left">';



    // Verifica se há dados no roadmap
    if (data.road_map && data.road_map.etapas && data.road_map.etapas.length > 0) {
        formattedHtml += `<h2 class="text-xl font-bold mb-4 text-primary font-bold text-[26px] leading-[1]">Resultado Perfil IA</h2>`;

        // Itera pelas etapas do roadmap
        data.road_map.etapas.forEach((etapa, index) => {
            formattedHtml += `<div class="bg-white p-10 rounded lg:flex gap-5 mb-4">`;
            formattedHtml += `<div class="bg-gray bg-opacity-50 rounded shadow-lg p-5 flex-1">`;
            formattedHtml += `<h3 class="text-lg font-semibold">${etapa.titulo}</h3>`;

            // Verifica se há descrição nesta etapa
            if (etapa.descricao && etapa.descricao.length > 0) {
                formattedHtml += `<ul class="list-disc list-inside ml-4">`;
                etapa.descricao.forEach((desc, descIndex) => {
                    formattedHtml += `<li>${desc}</li>`;
                });
                formattedHtml += `</ul>`;
            } else {
                formattedHtml += `<p>Nenhuma descrição disponível.</p>`;
            }

            formattedHtml += `</div>`;
            formattedHtml += `</div>`;
        });
    } else {
        formattedHtml += `<p>Nenhuma etapa encontrada.</p>`;
    }
    
    formattedHtml += `<h2 class="text-2xl font-bold mb-4 text-primary text-center">Gostou?</h2>`;
    formattedHtml += '</div>';

    return formattedHtml;
}



const sendInformation = async () => {
    try {
        isLoading.value = true; // Define isLoading como true para mostrar que a requisição está em andamento
        const requestData = {
      soft_skills: selectedSoftSkills.value,
      hard_skills: selectedHardSkills.value,
      dream: dream.value,
    };
        const response = await axios.post(`${import.meta.env.VITE_IA_API}/give_responses`, requestData);
        console.log("Resposta da API:", response.data);
        isLoading.value = false;
        const formattedResponse = formatResponse(response.data);
        Swal.fire({
            title: "Perfil criado com sucesso!",
            html: formattedResponse,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: '<i class="fas fa-user-plus"></i> Criar Cadastro',
            cancelButtonText: '<i class="fas fa-redo-alt"></i> Fazer novamente',
        }).then((result) => {
            if (result.isConfirmed) {
                // Navegar para a primeira rota
                router.push('/cadastro');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Navegar para a segunda rota
                router.push('/avaliacao');
            }
        });
    } catch (error) {
        console.error("Erro ao enviar as informações:", error);
        isLoading.value = false;
        Swal.fire({
            title: "Erro!",
            text: "Ocorreu um erro ao enviar as informações. Por favor, tente novamente.",
            icon: "error",
            confirmButtonText: "OK",
        });
    }
};


onMounted(async () => {
    await fetchSkills();
    await fetchDreams();
});

</script>

<template>
    <Navbar />
    <div
      v-if="isLoading"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-[.7] z-[9999]"
    >
      <div class="bg-white p-5 rounded-lg shadow-md">
        <img
          src="@/assets/images/naza/andando.gif"
          class="lg:w-[480px] w-[320px]"
          alt="Loading..."
        />
        <div
          class="flex flex-col items-center justify-center bg-white dark:bg-black rounded-lg px-4 py-3"
        >
          <h1
            class="text-white bg-primary px-[20px] py-[8px] rounded-[22px] font-bold text-center text-[22px] mb-[10px]"
          >
            Olá, eu sou <strong>Naza</strong>!
          </h1>
          <p class="font-medium text-[18px] dark:text-white">
            Criando o seu perfil.
          </p>
          <span class="text-[14px] mt-[10px] dark:text-white">Aguarde...</span>
        </div>
      </div>
    </div>
    <div class="bg-white dark:bg-black border border-stroke rounded-lg mt-22 ml-9 p-8">
        <div class="mt-4 mb-8">
            <h2 class="text-primary font-bold text-[26px] leading-[1]">
                Qual é o seu <span class="font-black">sonho profissional?</span>
            </h2>
            <p class="leading-[1.2] mt-4 max-w-[640px] mb-6">
                Descubra qual é o seu objetivo de carreira, ou seja, o trabalho dos
                seus sonhos e as metas profissionais que deseja alcançar.
            </p>
            <div class="mb-4">
                <select
                    class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                    v-model="dream" required>
                    <option value="" disabled>Escolha seu sonho profissional</option>
                    <option v-for="profession in professions" :key="profession" :value="profession">{{ profession }}
                    </option>
                </select>


            </div>
        </div>
        <div class="mt-4 mb-8">
            <h1 class="text-primary font-bold text-[26px] leading-[1]">
                Conquiste seus <span class="font-black">sonhos!</span>
            </h1>
            <p class="leading-[1.2] mt-4 max-w-[640px] mb-6">
                O primeiro passo para uma carreira de sucesso é identificar seus
                interesses, habilidades e objetivos profissionais para direcionar sua
                busca por vagas e cursos relevantes.
            </p>
        </div>
        <div class="flex lg:flex-row flex-col justify-between items-start gap-3">
            <div class="w-full lg:w-1/2 bg-[#f8fafb] dark:bg-black border border-stroke rounded-lg shadow">
                <div class="p-4">
                    <img src="@/assets/images/icon/users-add.svg" class="w-[40px] mb-2" alt="" />
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        Soft Skills
                    </h5>
                    <p class="mb-3 leading-[1.2] font-normal text-gray-500 dark:text-gray-400">
                        Refere-se a
                        <span class="font-bold">habilidades interpessoais e comportamentais</span>, como comunicação,
                        trabalho em
                        equipe ou resolução de problemas.
                    </p>
                    <p class="inline-flex font-medium items-center text-blue-600 hover:underline mb-2">
                        Selecione suas habilidades
                    </p>
                    <div class="max-h-[300px] overflow-y-auto">
                        <div v-for="[key, value] in Object.entries(soft)" :key="key">
                            <label>
                                <br>
                                <strong>{{ key }}</strong>
                                <br>
                                <br>
                                <div v-for="skill in value" :key="skill">
                                    <label>
                                        <input type="checkbox" :value="skill" v-model="selectedSoftSkills" />
                                        {{ skill }}
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full lg:w-1/2 bg-[#f8fafb] dark:bg-black border border-stroke rounded-lg shadow">
                <div class="p-4">
                    <img src="@/assets/images/icon/security.svg" class="w-[40px] mb-2" alt="" />
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        Hard Skills
                    </h5>
                    <p class="mb-3 leading-[1.2] font-normal text-gray-500 dark:text-gray-400">
                        São <span class="font-bold">habilidades técnicas</span> e
                        específicas relacionadas a uma área de conhecimento ou trabalho,
                        como programação ou design gráfico.
                    </p>
                    <p class="inline-flex font-medium items-center text-blue-600 hover:underline mb-2">
                        Selecione suas habilidades
                    </p>
                    <div class="max-h-[300px] overflow-y-auto">
                        <div v-for="[key, value] in Object.entries(hard)" :key="key">
                            <label>
                                <br>
                                <strong>{{ key }}</strong><br />
                                <br>
                                <div v-for="skill in value" :key="skill">
                                    <label>
                                        <input type="checkbox" :value="skill" v-model="selectedHardSkills" />
                                        {{ skill }}
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button @click="sendInformation" class="w-full lg:w-1/4 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 mt-4 
      px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                </svg>
            </span>
            Enviar
        </button>

        <!-- <button @click="sendInformation" class="mt-4 px-6 py-3 text-white bg-primary rounded-lg hover:bg-primary-dark">
        Enviar
      </button> -->
    </div>
    <Footer />
</template>

<script>
export default {
    components: {
        MultiSelectView,
        ButtonDefault,
    },
};
</script>