<script setup>
import { ref, onMounted } from "vue";
import CandidacyModal from "@/components/Candicy/CandidacyModal.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ModalLocation from "@/components/Modal/ModalOne.vue";
import ModalJobs from "@/components/Modal/ModalJobsIn.vue";
import BrandOne from "@/assets/images/cards/cards-07.png";
import BrandTwo from "@/assets/images/cards/cards-08.png";
import {
  getJobPositions,
  fetchRecommendedJobs,
} from "@/services/jobPositionService";
import { fetchCandidate } from "@/services/candidateService";
import jobImageDefault from "@/assets/images/logo/oportunidade-jovem.png";
import { getAreas } from "@/services/areaService";
import axios from "@/api/axios";
import Swal from "sweetalert2";

const svgCorrectArrow = `
  <svg class="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500"
       aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
       fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
  </svg>
`;
const svgRightArrow = `
<svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
    <path stroke="currentColor" stroke-linecap="round"
    stroke-linejoin="round" stroke-width="2"
    d="M1 5h12m0 0L9 1m4 4L9 9" />
</svg>`;

const svgCheck = `
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>
`;

const pwdIcon = `
 <svg fill="#FFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 491.975 491.975" xml:space="preserve">
<g id="XMLID_28_">
	<path id="XMLID_31_" d="M225.315,96.963c26.748,0,48.479-21.706,48.479-48.481C273.794,21.699,252.063,0,225.315,0
		c-26.779,0-48.492,21.699-48.492,48.482C176.823,75.257,198.536,96.963,225.315,96.963z"/>
	<path id="XMLID_30_" d="M300.233,371.688c-12.883,44.732-54.121,77.583-102.946,77.583c-59.126,0-107.209-48.085-107.209-107.193
		c0-43.754,26.396-81.413,64.066-98.054V198.58c-61.69,18.581-106.764,75.847-106.764,143.498
		c0,82.649,67.247,149.897,149.906,149.897c60.238,0,112.159-35.801,135.966-87.169l-16.926-33.255
		C311.575,371.59,306.071,371.64,300.233,371.688z"/>
	<path id="XMLID_29_" d="M441.48,429.237l-64.939-127.672c-4.847-9.553-14.645-15.566-25.363-15.566h-83.173v-18.966h71.582
		c7.148,0,13.156-3.736,17.037-9.118c2.522-3.506,4.316-7.579,4.316-12.236c0-11.789-9.549-21.351-21.353-21.351h-43.125h-28.457
		V160c0-17.692-13.118-42.704-42.689-42.704c-23.584,0-42.703,19.122-42.703,42.704v139.372c0,24.058,19.503,43.561,43.562,43.561
		h78.265h29.284l57.024,112.117c5.011,9.875,15.011,15.573,25.389,15.573c4.35,0,8.761-0.994,12.883-3.104
		C443.054,460.401,448.628,443.251,441.48,429.237z"/>
</g>
</svg>
`;

const editalIcon = `
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>
`;

const pageTitle = ref(`Vagas Recomendadas (${localStorage.getItem("dream")}) `);

const brandData = ref([
  {
    img: BrandOne,
    name: "Recepcionista",
    city: "Parnaíba-PI",
    type: "Emprego",
    area: "Administrativo",
    number: "12",
    money: "R$ 1.500,00",
    date: "30/09/2024",
  },
  {
    img: BrandTwo,
    name: "Técnico em Informática",
    city: "Parnaíba-PI",
    type: "Emprego",
    area: "Administrativo",
    number: "12",
    money: "R$ 1.500,00",
    date: "30/09/2024",
  },
]);
const jobPositions = ref([]); // Definindo como array vazio inicialmente
const userId = localStorage.getItem("userId");
const candidateId = ref("");
const candidateSkills = ref([]);
const candidateSkillsSoft = ref([]);
const candidateSkillsHard = ref([]);
const job_position = ref("");
const dream_user = ref("");
const recommendedJobs = ref([]);
const recommendedJobsOld = ref([]);
const threshold = ref(0.65);
const loadingRecommendedJobs = ref(true);
const areas = ref([]);
const showOnlyAppliedJobs = ref(false);
const workFormatFilter = ref(""); // Adiciona esta linha para o filtro work_format
const showCandidacyModal = ref(false);
const titleGroupModal = ref("");
let hardSkillFormated = ref([]);
let stagesGroupModal = [];
let stage;

const fetchAreas = async () => {
  try {
    const response = await getAreas();
    areas.value = response;
  } catch (error) {
    console.error("Error fetching areas:", error);
  }
};

const getEnterpriseDetails = async (enterpriseId) => {
  if (enterpriseId != null) {
    try {
      const response = await axios.get(`/enterprises/${enterpriseId}`);
      return response.data;
    } catch (error) {
      console.error(
        `Erro ao buscar detalhes da empresa com id ${enterpriseId}:`,
        error
      );
      return null;
    }
  }
};

const fetchCandidateId = async () => {
  try {
    if (userId) {
      const candidateData = await fetchCandidate(userId);
      candidateSkills.value = candidateData.skillsCompetencies;
      const skills = candidateData.skillsCompetencies.split("-");
      candidateSkillsSoft.value = skills
        .filter((skill) => skill.startsWith("sf_"))
        .map((skill) => skill.substring(3))
        .join("-");
      candidateSkillsHard.value = skills
        .filter((skill) => skill.startsWith("hs_"))
        .map((skill) => skill.substring(3))
        .join("-");
      const profileia = JSON.parse(candidateData.profileia);
      dream_user.value = profileia.road_map.objetivo;
      hardSkillFormated.value = skills
        .filter((skill) => skill.startsWith("hs_"))
        .map((item) => item.replace("hs_", ""));
    } else {
      console.error("Erro: userId é null ou undefined");
    }
  } catch (error) {
    console.error("Erro ao carregar Candidato:", error);
  }
};

const getJobTypeDescription = (jobType) => {
  switch (jobType) {
    case "EM":
      return "Emprego";
    case "ES":
      return "Estágio";
    case "BO":
      return "Bolsa";
    case "MO":
      return "Monitoria";
    default:
      return "Tipo de vaga desconhecido";
  }
};

const loadRecommendedJobs = async () => {
  try {
    await fetchCandidateId();
    loadingRecommendedJobs.value = true;
    const allRecommendedJobs = await fetchRecommendedJobs(
      dream_user.value,
      hardSkillFormated.value
    );

    recommendedJobs.value = allRecommendedJobs;
    recommendedJobsOld.value = allRecommendedJobs;

    loadingRecommendedJobs.value = false;
    console.log("Jobs ordenados ******: ", recommendedJobs.value);
  } catch (error) {
    console.error("Erro ao obter vagas recomendadas:", error);
    loadingRecommendedJobs.value = false;
  }
};

const verifyCandidacy = (item) => {
  return item.some((element) => element.candidate.userid == userId);
};

onMounted(async () => {
  localStorage.setItem("page", "Vagas Recomendadas");
  await loadRecommendedJobs();
});

// Definindo uma estrutura de objeto que atua como a interface desejada
const jobPositionStructure = {
  id_job: 0,
  area_id_area: 0,
  enterpriseid: 0,
  pcd_jobs: "",
  jobs_number: 0,
  skills_hard: "",
  skills_soft: "",
  notes: "",
  image: "",
  start_date: "",
  end_date: "",
  salary: 0,
  position: "",
  job_type: "",
  enterpriseid: "",
};

const candidacyOn = (group, candidacys) => {
  candidacys.forEach((candidacy) => {
    if (candidacy.candidate.userid == userId) {
      stage = candidacy.current_stage;
    }
  });
  titleGroupModal.value = group.title;
  stagesGroupModal = group.stages;
  showCandidacyModal.value = true;
};
const candidacyOff = () => {
  titleGroupModal.value = "";
  stagesGroupModal.value = [];
  showCandidacyModal.value = false;
};

const createCandidacy = async (idJob) => {
  let userCandidacy;
  try {
    userCandidacy = await axios.get(`/candidates/by-user/${userId}`);
  } catch (error) {
    console.error(error);
    return;
  }

  const body = {
    fk_candidate: parseInt(userCandidacy.data.id),
    fk_job: idJob,
    date_candidacy: "2024-06-24",
    active: true,
    current_stage: 0,
  };

  try {
    const response = await axios.post("/candidacy", body);
    if (response.data) {
      Swal.fire({
        title: "Candidatura enviada",
        text: "Sua candidatura foi enviada com sucesso!",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        // Atualize o estado dos dados ao invés de recarregar a página
        // Atualizar a lista de candidaturas para refletir a mudança de estado
        const jobIndex = recommendedJobs.value.findIndex(
          (job) => job.id_job === idJob
        );
        if (jobIndex !== -1) {
          recommendedJobs.value[jobIndex].candidacys.push({
            candidate: { userid: userId },
          });
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};

const filterJobs = (e) => {
  let filter = e.target.value;
  if (filter == "todos") {
    recommendedJobs.value = recommendedJobsOld.value;
  } else {
    recommendedJobs.value = recommendedJobsOld.value.filter(
      (job) => job.work_format == e.target.value
    );
  }
};

const jobsOnCandidacy = (e) => {
  if (e.target.checked) {
    let result = [];
    recommendedJobs.value.forEach((element) => {
      element.candidacys.forEach((candidacy) => {
        if (candidacy.candidate.userid == userId) {
          result.push(element);
        }
      });
    });
    recommendedJobs.value = result;
  } else {
    recommendedJobs.value = recommendedJobsOld.value;
  }
};
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <CandidacyModal
      :show="showCandidacyModal"
      :titleGroupModal="titleGroupModal"
      :stages="stagesGroupModal"
      :currentStage="stage"
      @update:show="showCandidacyModal = $event"
    />

    <div class="tabs">
      <div class="bg-white dark:bg-black p-5 rounded-lg shadow-md">
        <!-- Botão para alternar entre todas as vagas e apenas vagas candidatas -->
        <div class="mb-4 flex justify-end items-center space-x-4">
          <label
            class="dark:bg-black flex items-center space-x-2 p-2 bg-white rounded-lg shadow-sm cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <input
              type="checkbox"
              @click="jobsOnCandidacy($event)"
              value="check"
              class="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring focus:ring-blue-500 focus:ring-opacity-25"
            />
            <span class="text-sm text-gray-700 dark:text-gray-300">
              Vagas Candidatadas
            </span>
          </label>
          <!-- Adiciona o seletor de work_format -->
          <label
            class="bg-white dark:bg-black flex items-center space-x-2 p-2rounded-lg shadow-sm cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <select
              placeholder="Selecione"
              @change="filterJobs($event)"
              class="dark:bg-black form-select p-2 bg-white border border-gray-300 rounded-lg shadow-sm focus:ring focus:ring-blue-500 focus:ring-opacity-25 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300"
            >
              <option value="todos">Todos</option>
              <option value="hibrido">Híbrido</option>
              <option value="presencial">Presencial</option>
              <option value="home-office">Home-Office</option>
            </select>
          </label>
        </div>

        <!-- Conteúdo existente -->
        <div
          class="text-center text-2xl text-gray-900 dark:text-white flex flex-col items-center justify-center z-99"
          v-if="loadingRecommendedJobs"
        >
          <div class="flex flex-col items-center justify-center mb-8">
            <!-- <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray h-24 w-24 mb-4"></div> -->
            <img
              src="@/assets/images/naza/loading.gif"
              class="w-[320px] mb-5"
              alt="Naza"
            />
            <p class="text-lg font-bold text-primary">{{ currentMessage }}</p>
          </div>
        </div>
        <div v-else-if="recommendedJobs.length === 0">
          <div class="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
              />
            </svg>
            <h5 class="text-primary font-bold text-[18px] ml-1">
              Nenhuma vaga recomendada encontrada.
            </h5>
          </div>
          <p class="mt-[15px] text-[14px]">
            Continue se qualificando para melhorar suas chances de encontrar uma
            vaga que se encaixe em suas habilidades!
          </p>
        </div>

        <div v-if="openTab === 1">
          <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-5">
            <div
              v-for="(job, index) in recommendedJobs"
              :key="index"
              :class="`max-w-sm bg-white dark:bg-black`"
            >
              <img
                class="rounded-t-lg lg:h-[140px] mx-auto rounded"
                :src="job.image"
                alt=""
              />
              <div class="p-5">
                <div class="flex flex-row">
                  <h5
                    class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
                  >
                    {{ job.position }}
                  </h5>
                  <div class="ml-auto flex gap-2">
                    <button
                      v-if="
                        job.pcd_jobs !== null &&
                        job.pcd_jobs !== '' &&
                        job.pcd_jobs !== '0'
                      "
                      title="Vaga PCD"
                      class="bg-primary text-white rounded-full p-2 size-8"
                    >
                      <div v-html="pwdIcon"></div>
                    </button>
                    <a
                      :href="job.linkNotice"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        v-if="job.id_notice !== 0"
                        title="Vaga de Edital"
                        class="bg-green-700 hover:bg-green-600 text-white rounded-full p-2 size-8"
                      >
                        <div v-html="editalIcon"></div>
                      </button>
                    </a>
                  </div>
                </div>
                <div class="ml-auto"></div>

                <div>
                  <ul>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Empresa: </span
                        >{{ job.tradename }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Cidade: </span>{{ job.city }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Tipo: </span>{{ job.job_type }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Formato: </span>
                        <span class="capitalize">{{ job.work_format }}</span>
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Área: </span>{{ job.area }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Número de vagas: </span
                        >{{ job.jobs_number }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Número de vagas PCD: </span
                        >{{ job.pcd_jobs }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Salário: </span>{{ job.salary }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Data final: </span
                        >{{ job.end_date }}
                      </p>
                    </li>
                    <li class="flex items-center">
                      <div v-html="svgCorrectArrow"></div>
                      <p
                        class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3"
                      >
                        <span class="font-bold">Observações: </span
                        >{{ job.notes }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="flex justify-start items-center gap-3 mt-4">
                  <button
                    id="candidacy"
                    v-if="!verifyCandidacy(job.candidacys)"
                    @click="createCandidacy(job.id_job)"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    Candidatar-se
                    <div v-html="svgRightArrow"></div>
                  </button>

                  <button
                    v-else
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-success rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
                    @click="candidacyOn(job.groups, job.candidacys)"
                  >
                    Acompanhe
                    <div v-html="svgCheck"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="openTab === 2">Estágio</div>
          <div v-if="openTab === 3">Bolsa</div>
          <div v-if="openTab === 4">Mentoria</div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>


<script>
export default {
  name: "PinkTabsWithModal",
  components: {
    ModalLocation,
    ModalJobs,
  },
  data() {
    return {
      showModal: false,
      showModalJobs: false,
      openTab: 1,
      states: [1, 2],
      state: 1,

      messages: [
        "Carregando vagas recomendadas...",
        "Processando seus dados...",
        "Analisando métricas...",
        "Aguarde...",
      ],
      currentIndex: 0,
    };
  },

  computed: {
    currentMessage() {
      return this.messages[this.currentIndex];
    },
  },

  mounted() {
    this.startLoading();
  },

  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },

    startLoading() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.messages.length;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.active {
  background-color: rgb(60 80 224 / var(--tw-text-opacity));
}

.loader {
  border-top-color: #3c50e0;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
