<script setup lang="ts">
import { ref, onMounted } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import axios from "@/api/axios";
import image from "@/assets/images/user/company-verify.svg";
import Swal from "sweetalert2";
import CandidacyModal from '@/components/Candicy/CandidacyModal.vue';

const pageTitle = ref("Empresas");
const companies = ref<Array<any>>([]);
const imagem = image;

const showModal = ref(false);
const selectedCompanyJobs = ref<Array<any>>([]);
const showCandidacyModal = ref(false); // Novo estado para o modal de status
const userId = localStorage.getItem("userId");
const titleGroupModal = ref("");
interface Stage {
  id: number;
  title: string;
  description: string;
}
let stagesGroupModal = ref<Stage[]>([]);
let stage = ref<number>(1);

const fetchEnterprisesAndJobs = async () => {
  try {
    const response = await axios.get("enterprises/jobs-verified");
    companies.value = response.data.map((company: any) => ({
      ...company,
      jobs: company.jobs.map((job: any) => ({
        ...job,
        applied: false, // Adiciona o estado `applied` para cada vaga
      })),
    }));
  } catch (error) {
    console.error(error);
  }
};

const candidacyOn = (group: any, candidacys: any) => {
  candidacys.forEach((candidacy: any) => {
    if (candidacy.candidate.userid == userId) {
      stage = candidacy.current_stage;
    }
  });
  titleGroupModal.value = group.title;
  stagesGroupModal = group.stages;
  showCandidacyModal.value = true;
};

const candidacyOff = () => {
  titleGroupModal.value = "";
  stagesGroupModal.value = [];
  showCandidacyModal.value = false;
};

const getImage = (index: any): string => {
  if (!companies.value[index].imagepath || companies.value[index].imagepath.startsWith("http://localhost:3002")) {
    return imagem;
  } else {
    return companies.value[index].imagepath;
  }
};

const openModal = (jobs: Array<any>) => {
  selectedCompanyJobs.value = jobs.map(job => ({ ...job }));
  showModal.value = true;
};

const createCandidacy = async (idJob: number) => {
  try {
    // Obtém os dados do usuário
    const userCandidacy = await axios.get(`/candidates/by-user/${userId}`);

    // Cria o corpo da requisição
    const body = {
      fk_candidate: parseInt(userCandidacy.data.id),
      fk_job: idJob,
      date_candidacy: new Date().toISOString().split("T")[0],
      active: true,
      current_stage: 0,
    };

    // Envia a requisição para criar a candidatura
    const response = await axios.post("/candidacy", body);

    if (response.data) {
      Swal.fire({
        title: "Candidatura enviada",
        text: "Sua candidatura foi enviada com sucesso!",
        icon: "success",
        confirmButtonText: "OK",
      })
        .then(() => {
          fetchEnterprisesAndJobs();
        });
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: "Erro",
      text: "Houve um problema ao enviar a candidatura.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

const applyForJob = async (job: any) => {
  try {
    if (!job.applied) {
      await createCandidacy(job.id_job);
      // showCandidacyModal.value = true;
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Candidatura enviada!",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          popup: "swal2-popup", // Certifique-se de que a classe está sendo aplicada
        },
      }).then(() => {
        showCandidacyModal.value = true; // Exibir o modal de progresso após o SweetAlert2
      });
    } else {
      showCandidacyModal.value = true;
    }
    showModal.value = false;
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: "Erro",
      text: "Houve um problema ao enviar a candidatura.",
      icon: "error",
      confirmButtonText: "OK",
      customClass: {
        popup: "swal2-popup", // Use a classe personalizada
      },
    });
  }
};

const verifyCandidacy = (item: any) => {
  return item.some((element: any) => element.candidate.userid == userId);
};

onMounted(async () => {
  fetchEnterprisesAndJobs();
  localStorage.setItem("page", "Empresas");
});
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="flex justify-center">
      <div class="lg:mt-[20px]">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">

          <div v-for="(company, index) in companies" :key="index"
            class="max-w-sm px-3 pt-4 pb-3 bg-white border border-gray shadow dark:bg-black dark:border-graydark">
            <div class="flex justify-start items-center gap-1">
              <img :src="getImage(index)" class="h-[30px]" />
              <a href="#">
                <h5 class="text-base font-bold tracking-tight leading-none">
                  {{
                    company.tradename
                      ? company.tradename
                      : "Empresa confidencial"
                  }}
                </h5>
              </a>
            </div>

            <div
              class="flex justify-start items-center mt-3 mb-2 gap-[1px] border-b-[1px] border-gray dark:border-graydark pb-3">
              <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 11L11 13L15 9M19 10.2C19 14.1764 15.5 17.4 12 21C8.5 17.4 5 14.1764 5 10.2C5 6.22355 8.13401 3 12 3C15.866 3 19 6.22355 19 10.2Z"
                  stroke="#a2aab5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="font-normal text-sm mt-[2px]">
                {{
                  company.city
                    ? company.city + "/" + company.state
                    : "Localização indisponível"
                }}
              </p>
            </div>

            <div v-if="company.jobs.length != 0" class="flex justify-between ml-1">
              <button @click="openModal(company.jobs)"
                class="inline-flex font-medium items-center text-primary dark:text-white hover:underline">
                {{
                  company.jobs.length != 1
                    ? company.jobs.length + " Vagas"
                    : company.jobs.length + " Vaga"
                }}

                <svg class="w-3 h-3 ms-2 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 18 18">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 z-[9999] overflow-auto bg-smoke-light flex items-center justify-center">
      <div
        class="bg-white dark:bg-black relative px-8 py-5 w-full max-w-4xl m-auto flex-col flex rounded-lg shadow-lg max-h-[90vh] overflow-y-auto">
        <span class="absolute top-0 right-0 p-4">
          <button @click="showModal = false">
            <svg class="h-7 w-7 font-bold text-primary hover:text-primary_hover" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </span>
        <h2
          class="text-2xl mb-8 text-center font-bold border-b-[1px] border-gray dark:border-graydark underline text-primary pb-4">
          Vagas
          Disponíveis</h2>
        <div v-for="job in selectedCompanyJobs" :key="job.id_job"
          class="mb-6 border-b border-gray dark:border-graydark pb-4">
          <h3 class="text-xl font-bold mb-2 text-primary">{{ job.position }}</h3>
          <div class="p-4 border-gray dark:border-graydark border bg-gray-2 dark:bg-black rounded-lg">
            <p class="mb-2">
              <strong><span class="text-primary text-[20px]">•</span> Habilidades Técnicas:</strong> {{ job.skills_hard
              }}
            </p>
            <p class="mb-2">
              <strong><span class="text-primary text-[20px]">•</span> Habilidades Interpessoais:</strong> {{
                job.skills_soft }}
            </p>
            <p>
              <strong><span class="text-primary text-[20px]">•</span> Salário:</strong> {{ job.salary }}
            </p>
          </div>
          <div class="flex justify-center items-center">
            <button v-if="!verifyCandidacy(job.candidacys)"
              class="bg-primary hover:bg-primary_hover text-white mt-4 p-2 rounded-lg font-bold w-50"
              @click="applyForJob(job)">
              Candidatar-se
            </button>
            <button v-else class="bg-success hover:bg-success text-white mt-4 p-2 rounded-lg font-bold w-50"
              @click="candidacyOn(job.groups, job.candidacys)">
              Acompanhe
            </button>
          </div>

        </div>
      </div>
    </div>

    <!-- Modal de Progresso -->
    <CandidacyModal :show="showCandidacyModal" :titleGroupModal="titleGroupModal" :stages="stagesGroupModal"
      :currentStage="stage" @update:show="showCandidacyModal = $event" class="high-z-index" style="z-index: 9999;" />
  </DefaultLayout>
</template>

<style scoped>
.bg-smoke-light {
  background-color: rgba(0, 0, 0, 0.5);
}

.high-z-index {
  z-index: 9999 !important;
}

/* CSS Global ou Scoped */
.swal2-popup {
  z-index: 99999 !important;
}
</style>
