<script setup lang="ts">
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import UnderConstructionImage from "/src/assets/images/helpers/underConstrutionImage.png";

const pageTitle = ref("Página em Construção");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div
      class="bg-white dark:bg-black border border-stroke rounded-lg flex items-center justify-center"
    >
      <img :src="UnderConstructionImage" alt="" />
    </div>
  </DefaultLayout>
</template>
