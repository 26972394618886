import axios from "@/api/axios.js";

const API_URL = '/areas';

export interface Area {
  id_area: number;
  area_description: string;
}

export const getAreas = async (): Promise<Area[]> => {
  const response = await axios.get(API_URL);
  return response.data;
};
