<script setup lang="ts">
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { ref, onMounted, computed } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { format } from "date-fns";
import { useUserStore } from "@/stores/UserStore";

const pageTitle = ref("Edital");

const userStore = useUserStore();
const showToSecretaryAndEnterprise = computed(() => userStore.isSecretary || userStore.isEnterprise);

const router = useRouter();

const usertype = ref<string>(localStorage.getItem("usertype") || "");

// Configurações de upload
const selectedFile = ref<File | null>(null);
const conditionAddNotice = ref<boolean>(false);
const notices = ref<Array<any>>([]);
const idToUpdate = ref<number>(0);
const create = ref<boolean>(false);
const update = ref<boolean>(false);
const formNotice = ref<any>({
  title: "",
  description: "",
  type: "",
  filename: "",
  date_init: "",
  date_end: "",
  docpath: "",
});

const handleFileUpload = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files && files.length > 0) {
    selectedFile.value = files[0];
  }
};

const openCardNotice = () => {
  checkPermisionUser();
  create.value = !create.value;
  conditionAddNotice.value = !conditionAddNotice.value;
  if (!conditionAddNotice.value) {
    create.value = false;
    update.value = false;
    clearForm();
  }
};

const fetchNotices = async () => {
  try {
    let response = await axios.get("/notices/complete");
    notices.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const openDocument = (file: string) => {
  window.open(file, "_blank");
};

const uploadFile = async () => {
  try {
    let responseChat = await uploadFileAPIChat("create");
    if (responseChat.data && responseChat.data.json_file_name) {
      try {
        formNotice.value.docpath = `${import.meta.env.VITE_CHAT_OPORTUNIDADE}/get-pdf/${responseChat.data.json_file_name}`;
        formNotice.value.filename = responseChat.data.json_file_name;
        let responseNotice = await axios.post("/notices", formNotice.value);
        console.log(responseNotice.data);
        console.log("filename->", responseChat.data.json_file_name);
      } catch (error) {
        console.error(error);
      }
    }
  } catch (error) {
    console.error("Erro ao enviar dados à API:", error); // Exibe o erro no console para depuração
    Swal.fire("Erro", "Erro ao enviar dados à API", "error");
  } finally {
    Swal.fire("Sucesso", "Edital salvo com sucesso!", "success");
    clearForm();
    create.value = false;
    conditionAddNotice.value = !conditionAddNotice.value;
    fetchNotices();
  }
};

const uploadFileAPIChat = async (method: string): Promise<any> => {
  if (method == "create") {
    if (!selectedFile.value) {
      Swal.fire("Erro", "Por favor, selecione um arquivo.", "error");
      return "";
    } else {
      const formData = new FormData();
      formData.append("pdf_file", selectedFile.value);
      formData.append("title", selectedFile.value.name);
      formData.append("description", formNotice.value.description);
      try {
        const responseChat = await axios.post(
          `${import.meta.env.VITE_CHAT_OPORTUNIDADE}/upload/pdf/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        return responseChat;
      } catch (error) {
        console.error(error);
        return "";
      }
    }
  } else {
    if (selectedFile.value != null || selectedFile.value != undefined) {
      const formData = new FormData();
      formData.append("pdf_file", selectedFile.value);
      formData.append("title", selectedFile.value.name);
      formData.append("description", formNotice.value.description);
      try {
        const responseChat = await axios.post(
          `${import.meta.env.VITE_CHAT_OPORTUNIDADE}/upload/pdf/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return responseChat;
      } catch (error) {
        console.error(error);
        return "";
      }
    } else {
      return "";
    }
  }
};

const redirectToListProps = (id: any) => {
  router.push(`adesao-de-editais/${id}`);
  // router.push(`/perfil-empresa/${id}`);
};

const updateFileAndNotice = async (id: any) => {
  try {
    let responseChat = await uploadFileAPIChat("update");
    if (responseChat != "") {
      formNotice.value.docpath = `${import.meta.env.VITE_CHAT_OPORTUNIDADE}/get-pdf/${responseChat.data.json_file_name}`;
      formNotice.value.filename = responseChat.data.json_file_name;
    }
    let responseUpdate = await axios.patch(`/notices/${id}`, formNotice.value);
    console.log(responseUpdate);
  } catch (error) {
    console.error(error);
  } finally {
    fetchNotices();
    Swal.fire("Sucesso", "Edital atualizado com sucesso!", "success");
    clearForm();
    update.value = false;
    conditionAddNotice.value = !conditionAddNotice.value;
  }
};

const setButton = () => { };

const updateNotice = async (notice: any) => {
  let dateInit = format(notice.date_init, "yyyy-MM-dd");
  let dateEnd = format(notice.date_end, "yyyy-MM-dd");
  formNotice.value.title = notice.title;
  formNotice.value.description = notice.description;
  formNotice.value.type = notice.type;
  formNotice.value.date_init = dateInit;
  formNotice.value.date_end = dateEnd;
  formNotice.value.docpath = notice.docpath;
  formNotice.value.filename = notice.filename;
  idToUpdate.value = notice.id;
  update.value = !update.value;
  conditionAddNotice.value = !conditionAddNotice.value;
};



const clearForm = () => {
  selectedFile.value = null;
  formNotice.value = {};
  idToUpdate.value = 0;
};

const checkPermisionUser = async () => {
  try {
    let authMe = await axios.post("auth/me");
    console.log("Auth: ", authMe.data);
    if (!authMe.data.userSecretaries[0].secretary.isverified || authMe.data.userSecretaries[0].permissionnotice === 'visible') {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      }).then(() => {
        router.push("/");
      });
    }
  } catch (error) {
    console.error(error);
  }
};


onMounted(async () => {
  fetchNotices();
});
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="flex justify-end mb-3">
      <button v-if="showToSecretaryAndEnterprise" @click="openCardNotice" :class="!conditionAddNotice
        ? 'text-white rounded-full w-[40px] h-[40px] bg-primary'
        : 'text-white rounded-full w-[40px] h-[40px] bg-red'
        ">
        {{ !conditionAddNotice ? "+" : "X" }}
      </button>
    </div>

    <div v-if="create && conditionAddNotice"
      class="bg-white shadow p-10 rounded-lg max-w-7xl dark:border-strokedark dark:bg-boxdark">
      <div class="mb-6">
        <label for="title-input" class="block text-sm font-medium mb-2 mt-3 ml-3">Título</label>
        <input id="title-input" v-model="formNotice.title" placeholder="Digite aqui o título"
          class="w-full shadow dark:bg-boxdark p-3 border text-sm border-gray rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
      </div>
      <div class="mb-6">
        <label for="description-input" class="block text-sm font-medium mb-2 ml-3">Descrição</label>
        <textarea rows="5" id="description-input" v-model="formNotice.description" placeholder="Digite aqui a descrição"
          class="w-full dark:bg-boxdark p-3 border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"></textarea>
      </div>
      <div class="mb-6">
        <label for="editalType" class="block text-sm font-medium mb-2 ml-2">Tipo de edital</label>
        <select id="editalType" v-model="formNotice.type"
          class="w-full p-3 border border-gray text-sm shadow rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option value="" disabled>Selecione</option>
          <option value="candidate">Candidato</option>
          <option value="enterprise">Empresa</option>
        </select>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label for="jobInitDate" class="block text-sm font-medium text-gray-700 mb-2 ml-2">Data ínicio</label>
          <input type="date" id="jobInitDate" v-model="formNotice.date_init"
            class="w-full dark:bg-boxdark p-3 border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
        <div>
          <label for="jobEndDate" class="block text-sm font-medium text-gray-700 mb-2 ml-2">Data final</label>
          <input type="date" id="jobEndDate" v-model="formNotice.date_end"
            class="w-full p-3 dark:bg-boxdark border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
      </div>
      <div class="border-1 border-gray p-6 text-center mb-6 rounded-lg bg-gray dark:bg-black">
        <p class="font-semibold mb-2">UPLOAD DE ARQUIVOS</p>
        <p class="mb-4 text-bodydark2 text-sm">Arraste e solte ou</p>

        <input type="file" @change="handleFileUpload" class="block mx-auto mb-2 cursor-pointe text-bodydark2 text-sm" />
        <p class="mt-3 text-bodydark2 text-sm">
          Somente arquivo em .PDF com tamanho máximo de 15 MB.
        </p>
      </div>
      <div class="flex justify-center space-x-4">
        <button @click="uploadFile"
          class="px-6 py-3 bg-primary text-white font-medium rounded-lg hover:bg-primary_hover">
          Salvar
        </button>
      </div>
    </div>

    <div v-if="update" class="bg-white shadow p-10 rounded-lg max-w-7xl dark:border-strokedark dark:bg-boxdark">
      <div class="mb-6">
        <label for="title-input" class="block text-sm font-medium mb-2 mt-3 ml-3">Título</label>
        <input id="title-input" v-model="formNotice.title" placeholder="Digite aqui o título"
          class="w-full shadow dark:bg-boxdark p-3 border text-sm border-gray rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
      </div>
      <div class="mb-6">
        <label for="description-input" class="block text-sm font-medium mb-2 ml-3">Descrição</label>
        <textarea rows="5" id="description-input" v-model="formNotice.description" placeholder="Digite aqui a descrição"
          class="w-full dark:bg-boxdark p-3 border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"></textarea>
      </div>
      <div class="mb-6">
        <label for="editalType" class="block text-sm font-medium mb-2 ml-2">Tipo de edital</label>
        <select id="editalType" v-model="formNotice.type"
          class="w-full p-3 border border-gray text-sm shadow rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
          <option value="" disabled>Selecione</option>
          <option value="candidate">Candidato</option>
          <option value="enterprise">Empresa</option>
        </select>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label for="jobInitDate" class="block text-sm font-medium text-gray-700 mb-2 ml-2">Data ínicio</label>
          <input type="date" id="jobInitDate" v-model="formNotice.date_init"
            class="w-full dark:bg-boxdark p-3 border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
        <div>
          <label for="jobEndDate" class="block text-sm font-medium text-gray-700 mb-2 ml-2">Data final</label>
          <input type="date" id="jobEndDate" v-model="formNotice.date_end"
            class="w-full p-3 dark:bg-boxdark border border-gray shadow text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
      </div>
      <div class="border-1 border-gray p-6 text-center mb-6 rounded-lg bg-gray dark:bg-black">
        <p class="font-semibold mb-2">UPLOAD DE ARQUIVOS</p>
        <p class="mb-4 text-bodydark2 text-sm">Arraste e solte ou</p>

        <input type="file" @change="handleFileUpload" class="block mx-auto mb-2 cursor-pointe text-bodydark2 text-sm" />
        <p class="mt-3 text-bodydark2 text-sm">
          Somente arquivo em .PDF com tamanho máximo de 15 MB.
        </p>
      </div>
      <div class="flex justify-center space-x-4">
        <button @click="updateFileAndNotice(idToUpdate)"
          class="px-6 py-3 bg-primary text-white font-medium rounded-lg hover:bg-primary_hover">
          Atualizar
        </button>
      </div>
    </div>

    <div v-if="!conditionAddNotice">
      <div v-if="notices.length == 0" class="p-2 text-center rounded-lg bg-white dark:bg-black mt-3">
        Não existem editais cadastrados, clique
        <span class="cursor-pointer text-primary border-b" @click="openCardNotice">aqui</span>
        para criar um.
      </div>

      <div v-if="notices.length != 0" class="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
        <table class="w-full">
          <thead class="text-center uppercase bg-boxdark text-white dark:bg-black dark:text-gray">
            <tr>
              <th scope="col" class="p-3 rounded-tl-[12px]">Titulo</th>
              <th scope="col" class="p-3">Descrição</th>
              <th scope="col" class="p-3">Documento</th>
              <th v-if="showToSecretaryAndEnterprise" scope="col" class="p-3">Visualizar Canidaturas</th>
              <th v-if="showToSecretaryAndEnterprise" scope="col" class="p-3 rounded-tr-[12px]">Editar</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr class="odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark"
              v-for="(item, index) in notices" :key="index">
              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                {{ item.title }}
              </td>

              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                {{ item.description }}
              </td>

              <td class="p-3 font-medium whitespace-nowrap dark:text-white">
                <span @click="openDocument(item.docpath)"
                  class="overflow-hidden whitespace-nowrap text-ellipsis border-b text-primary cursor-pointer max-w-xs"
                  style="display: inline-block; max-width: 325px;">
                  {{ item.filename }}
                </span>
              </td>

              <td v-if="showToSecretaryAndEnterprise" class="p-3 font-medium whitespace-nowrap dark:text-white">
                <button class="bg-success p-2 rounded-full" @click="redirectToListProps(item.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#e8eaed">
                    <path
                      d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
                  </svg>
                </button>
              </td>

              <td v-if="showToSecretaryAndEnterprise" class="p-3 font-medium whitespace-nowrap dark:text-white" @click="updateNotice(item)">
                <button class="bg-primary p-2 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                    fill="#e8eaed">
                    <path
                      d="M160-400v-80h280v80H160Zm0-160v-80h440v80H160Zm0-160v-80h440v80H160Zm360 560v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T863-380L643-160H520Zm300-263-37-37 37 37ZM580-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z" />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </DefaultLayout>
</template>
