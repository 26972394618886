<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "@/api/axios";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const candidacyJobsComplete = ref({
      position: "",
      groups: {
        stages: [],
      },
      candidacys: [],
    });

    const router = useRouter();
    const showModalUser = ref(false);
    let candidateModal = ref("");
    const cursorX = ref(0);
    const cursorY = ref(0);

    const fetchCandidacyComplete = async () => {
      try {
        const response = await axios.get(`/job-positions/${getIdUrl()}`);
        candidacyJobsComplete.value = response.data;
        console.log(candidacyJobsComplete.value);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      }
    };

    const mountBody = () => {
      for (let i = 0; i < candidacyJobsComplete.value.candidacys.length; i++) {
        let id =
          "userIndex" + candidacyJobsComplete.value.candidacys[i].current_stage;

        let principalDiv = document.getElementById(id);

        let div1 = document.createElement("div");
        div1.classList.add("flex", "justify-center");

        let name = document.createElement("div");
        name.classList.add("p-3");
        name.textContent =
          candidacyJobsComplete.value.candidacys[i].candidate.fullname;

        let img = document.createElement("img");
        img.src = candidacyJobsComplete.value.candidacys[i].candidate.imagepath;
        img.classList.add("w-25", "m-2", "rounded-full");

        if (candidacyJobsComplete.value.candidacys[i].active == false) {
          img.classList.add("opacity-30");
        }

        img.addEventListener("mouseover", () => {
          showModal(candidacyJobsComplete.value.candidacys[i].candidate);
        });
        img.addEventListener("mouseout", () => {
          showModal(candidacyJobsComplete.value.candidacys[i].candidate);
        });
        img.addEventListener("click", () => {
          openUser(
            candidacyJobsComplete.value.candidacys[i].candidate.userid,
            candidacyJobsComplete.value.candidacys[i].id,
            candidacyJobsComplete.value.candidacys[i].current_stage
          );
        });

        div1.appendChild(img);

        principalDiv.appendChild(div1);
      }
    };

    const openUser = (idCandidate, idCandidacy, stage) => {
      router.push(
        `/candidate/${idCandidate}/candidacy/${idCandidacy}/stage/${stage}`
      );
    };

    const showModal = (candidate) => {
      showModalUser.value = !showModalUser.value;
      candidateModal.value = candidate;
    };

    const getIdUrl = () => {
      let url = window.location.href;
      let bar = url.lastIndexOf("/");
      return url.substring(bar + 1);
    };

    const voltar = () => {
      router.go(-1);
    };

    const handleMouseMove = (event) => {
      cursorX.value = event.pageX;
      cursorY.value = event.pageY;
    };

    onMounted(async () => {
      document.addEventListener("mousemove", handleMouseMove);
      await fetchCandidacyComplete();
      mountBody();
    });

    return {
      candidateModal,
      showModalUser,
      candidacyJobsComplete,
      voltar,
      cursorX,
      cursorY,
      showModal,
    };
  },
});
</script>

<template>
  <div class="p-6 body">
    <div class="flex justify-between mb-4">
      <h1 class="text-2xl font-bold">
        Vaga: {{ candidacyJobsComplete.position }}
      </h1>
      <button class="text-blue-500">Filtros</button>
    </div>

    <button class="mb-4 px-4 py-2 bg-gray-200 rounded" @click="voltar">
      Voltar
    </button>

    <div class="p-4 overflow-x-auto mt-5">
      <div class="flex space-x-4 justify-center">
        <div class="w-1/4 p-4 bg-white dark:bg-black rounded-lg shadow-md">
          <h2 class="text-lg font-bold mb-2">Análise de Perfil</h2>
          <hr class="mb-4" />
          <div id="userIndex0" class="space-y-4"></div>
        </div>

        <div
          class="w-1/4 p-4 bg-white dark:bg-black rounded-lg shadow-md"
          v-for="(stage, index) in candidacyJobsComplete.groups.stages"
          :key="index"
        >
          <h2 class="text-lg font-bold mb-2">{{ stage.title }}</h2>
          <hr class="mb-4" />
          <div class="text-center mb-2">
            {{ stage.description }}
          </div>
          <div :id="'userIndex' + (index + 1)" class="space-y-4"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="cursorFollow bg-white dark:bg-black w-[15vw] shadow-2xl rounded-lg"
    v-if="showModalUser"
    :style="{ left: cursorX + 'px', top: cursorY + 'px' }"
  >
    <div class="flex justify-center">
      <img :src="candidateModal.imagepath" class="img w-[10vw]" />
    </div>
    <div class="pl-2 pr-2">
      <hr />
    </div>
    <div class="p-3 text-sm">
      <p class="p-1">
        <strong>
          {{ candidateModal.fullname }}
        </strong>
      </p>
      <p class="p-1">
        <strong> Formação: </strong>
        {{ candidateModal.completedCourses }}
      </p>
      <p class="p-1">
        <strong> Instituição </strong>
        {{ candidateModal.educationInstitution }}
      </p>
      <p class="p-1">
        <strong> Interesse:</strong>
        {{ candidateModal.sectorOfInterest }}
      </p>
      <p class="p-1">
        <strong>Cidade:</strong>
        {{ candidateModal.city }}
      </p>
      <p></p>
    </div>
  </div>
</template>

<style scoped>
.cursor {
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.cursorFollow {
  position: fixed;
  pointer-events: none;
  z-index: 10000;
}

.body {
  margin-bottom: 50vh;
}
</style>
