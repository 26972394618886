<script setup lang="ts">
import { ref } from 'vue'
import BreadcrumbDefault from '@/components/Breadcrumbs/BreadcrumbDefault.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import CurriculumData from './CurriculumData.vue'


const pageTitle = ref('Currículo')
</script>

<template>
    <DefaultLayout>
        <BreadcrumbDefault :pageTitle="pageTitle" />
        <div>
        <CurriculumData/>
        </div>
    </DefaultLayout>
</template>
