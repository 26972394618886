<script setup>
import { ref, onMounted } from "vue";
import axios from "@/api/axios.js";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Swal from "sweetalert2";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const pageTitle = ref("Áreas");

const areas = ref([]);
const isLoading = ref(false);
const showConfirmModal = ref(false);
const areaToDelete = ref(null);
const newAreaDescription = ref("");

// Função para buscar áreas
const fetchAreas = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get("/areas");
    areas.value = response.data;
  } catch (error) {
    console.error("Erro ao buscar áreas:", error);
  } finally {
    isLoading.value = false;
  }
};

// Função para adicionar uma nova área
const addArea = async () => {
  if (!newAreaDescription.value) {
    Swal.fire({
      icon: "error",
      title: "Erro",
      text: "O campo de descrição da área é obrigatório",
    });
    return;
  }

  try {
    await axios.post("/areas", { area_description: newAreaDescription.value });
    newAreaDescription.value = "";
    await fetchAreas();
    Swal.fire({
      icon: "success",
      title: "Sucesso",
      text: "Área adicionada com sucesso!",
    });
  } catch (error) {
    console.error("Erro ao adicionar área:", error);
    Swal.fire({
      icon: "error",
      title: "Erro",
      text: "Erro ao salvar dados na API",
    });
  }
};

// Função para editar uma área
const editArea = (area) => {
  // Implementação da edição de área
  console.log("Editando área:", area);
};

// Função para confirmar a exclusão
const confirmDelete = (id) => {
  areaToDelete.value = id;
  showConfirmModal.value = true;
};

// Função para cancelar a exclusão
const cancelDelete = () => {
  areaToDelete.value = null;
  showConfirmModal.value = false;
};

// Função para confirmar a exclusão da área
const confirmDeleteArea = async () => {
  if (areaToDelete.value) {
    await deleteArea(areaToDelete.value);
  }
  cancelDelete();
};

// Função para excluir uma área
const deleteArea = async (id) => {
  try {
    await axios.delete(`/areas/${id}`);
    await fetchAreas();
    Swal.fire({
      icon: "success",
      title: "Sucesso",
      text: "Área excluída com sucesso!",
    });
  } catch (error) {
    console.error("Erro ao excluir área:", error);
    Swal.fire({
      icon: "error",
      title: "Erro",
      text: "Erro ao excluir dados na API",
    });
  }
};

onMounted(fetchAreas);
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="container mx-auto">
      <h1 class="text-2xl font-bold mb-4">Gerenciar Áreas</h1>

      <!-- Formulário para adicionar nova área -->
      <div class="bg-white dark:bg-black shadow rounded-lg p-6 mb-6">
        <h2 class="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
          Adicionar Nova Área
        </h2>
        <form @submit.prevent="addArea" class="flex items-center space-x-2">
          <input
            v-model="newAreaDescription"
            type="text"
            placeholder="Descrição da Nova Área"
            class="bg-white dark:bg-black flex-1 border-gray border-[1px] focus:border-primary focus:ring focus:ring-blue-200 rounded-md p-2 text-sm"
          />
          <button
            type="submit"
            class="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
          >
            Adicionar
          </button>
        </form>
      </div>

      <!-- Tabela de Áreas -->
      <div class="bg-white dark:bg-black shadow rounded-lg p-6">
        <h2 class="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
          Áreas Cadastradas
        </h2>
        <div class="overflow-x-auto">
          <table
            class="min-w-full divide-y divide-gray-200 dark:divide-strokedark"
          >
            <thead class="bg-[#46494e] text-white">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider rounded-tl-md"
                >
                  Descrição da Área
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-end text-xs font-medium uppercase tracking-wider rounded-tr-md"
                >
                  Ações
                </th>
              </tr>
            </thead>
            <tbody
              class="bg-white dark:bg-black divide-y divide-gray dark:divide-strokedark"
            >
              <tr
                v-for="area in areas"
                :key="area.id_area"
                class="odd:bg-white odd:dark:bg-strokedark even:bg-gray even:dark:bg-black"
              >
                <td class="px-6 py-4 whitespace-nowrap dark:text-gray">
                  {{ area.area_description }}
                </td>
                <td
                  class="flex justify-end items-center gap-3 px-6 py-4 whitespace-nowrap font-medium"
                >
                  <button
                    @click="editArea(area)"
                    class="text-indigo-600 dark:text-white hover:opacity-70 flex items-center gap-1"
                  >
                    <span>Editar</span>
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <title />
                        <g id="Complete">
                          <g id="edit">
                            <g>
                              <path
                                d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
                                fill="none"
                                stroke="#5b52e7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <polygon
                                fill="none"
                                points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
                                stroke="#5b52e7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                  <button
                    @click="confirmDelete(area.id_area)"
                    class="text-red dark:text-white hover:opacity-70 flex items-center gap-1"
                  >
                    <span>Excluir</span>
                    <svg
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M10 12V17"
                          stroke="#fb5454"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 12V17"
                          stroke="#fb5454"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4 7H20"
                          stroke="#fb5454"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                          stroke="#fb5454"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                          stroke="#fb5454"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal de Confirmação -->
    <div
      v-if="showConfirmModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div
        class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-sm w-full"
      >
        <h3 class="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
          Tem certeza que deseja excluir?
        </h3>
        <div class="flex justify-end gap-4">
          <button
            @click="cancelDelete"
            class="px-4 py-2 rounded-md bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200"
          >
            Cancelar
          </button>
          <button
            @click="confirmDeleteArea"
            class="px-4 py-2 rounded-md bg-red text-white"
          >
            Excluir
          </button>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<style scoped>
/* Adicione seus estilos aqui */
</style>
