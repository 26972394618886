<script setup lang="ts">
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import CardBoxView from "@/components/CardBox/CardBoxView.vue";
import ChartOne from "@/components/Charts/ChartOne.vue";
import ChartTwo from "@/components/Charts/ChartTwo.vue";

const pageTitle = ref("Dashboard");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <!-- Header Cards -->
        <CardBoxView />
      </div>


      <div class="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
      </div>

    </div>
  </DefaultLayout>
</template>