import axios from '@/api/axios'; // Ajuste o caminho conforme necessário

export interface Candidate {
  id: number;
  fullname: string;
  cellphone: string;
  cpf: string;
  dateofbirth: string;
  gender: string;
  maritalstatus: string;
  state: string;
  city: string;
  email: string;
  educationallevel: string;
  biography: string;
  imagepath: string;
  sex: string;
  uniqueRegistration: string;
  pcd: string;
  disability: string;
  pcdCProofPath: string;
  zipCode: string;
  street: string;
  neighborhood: string;
  numberHouse: string;
  complement: string;
  socialProgram: string;
  internship: string;
  incomeLevel: string;
  numberPeopleHome: string;
  totalIncome: string;
  employmentLink: string;
  // academicBackground: string;
  educationInstitution: string;
  institutionType: string;
  completedCourses: string;
  skillsCompetencies: string;
  skills_soft: string;
  skills_hard: string;
  coverLetter: string;
  certificatePath: string;
  videoPresentationPath: string;
  desiredJobTypes: string;
  preferredGeographicLocation: string;
  expectedSalary: string;
  sectorOfInterest: string;
  // socialNetworks: string;
  linkedin: string;
  instagram: string;
  facebook: string;
  xtwitter: string;
  tiktok: string;
}

export const fetchCandidate = async (userId: string): Promise<Candidate> => {
  try {
    const response = await axios.get(`/candidates/by-user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao carregar Candidato:', error);
    throw error;
  }
};
