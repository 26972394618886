<script>
import { ref, onMounted } from "vue";
import axios from "@/api/axios";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
  setup() {
    const conditionSteps = ref(false);
    const router = useRouter();
    
    const process = ref({
      title: "",
    });

    let stepsToAdd = ref({
      title: "",
      order_number: 1, // Initialize with 1 as the default value
      description: "",
      fk_group: getIdUrl(),
    });

    const steps = ref([]);

    const fetchGroupCandidacy = async () => {
      try {
        const response = await axios.get(`/groupCandidacy/${getIdUrl()}`);
        process.value.title = response.data.title;
        response.data.stages.forEach((element, i) => {
          steps.value.push(element);
        });
      } catch (error) {
        console.error(error);
      }
    };

    const updateProcessAndStepsCandidacy = async () => {
      try {
        const responseGroup = await axios.put(
          `/groupCandidacy/${getIdUrl()}`,
          process.value
        );
        for (let i = 0; i < steps.value.length; i++) {
          const responseStep = await axios.put(
            `/stagecandidacy/${steps.value[i].id}`,
            steps.value[i]
          );
        }
        transactionOk();
      } catch (error) {
        console.error(error);
      } finally {
        transactionOk();
      }
    };

    const addStepToGroupCandidacy = async () => {
      try {
        if (
          stepsToAdd.value.title != "" &&
          stepsToAdd.value.order_number != ""
        ) {
          const response = await axios.post(
            "/stagecandidacy",
            stepsToAdd.value
          );
          transactionOk();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const deleteStep = async (id) => {
      try {
        const response = await axios.delete(`/stagecandidacy/${id}`);
        if (
          response.status != 400 ||
          response.status != 404 ||
          response.status != 403 ||
          response.status != 500 ||
          response.status != 505
        ) {
          transactionOk();
        }
      } catch (error) {
        console.error(error);
      }
    };

    function transactionOk() {
      Swal.fire({
        title: "Informações salvas",
        text: "Os dados foram salvos com sucesso.",
        icon: "success",
        confirmButtonText: "OK",
      });
      router.push("/processo-seletivo");
    }

    function openCardCreateStep() {
      conditionSteps.value = !conditionSteps.value;
    }

    function getIdUrl() {
      let url = window.location.href;
      let bar = url.lastIndexOf("/");
      return url.substring(bar + 1);
    }

    function redirectToListGroupCandidacy() {
      router.push("/processo-seletivo");
    }

    const checkPermisionUser = async () => {
      try {
        let authMe = await axios.post("auth/me");
        let id = authMe.data.userSecretaries[0].secretary.id;
        if (authMe.data.userSecretaries[0].permissionstagecandidacy != "full") {
          Swal.fire({
            title: "Acesso Negado",
            text: "Usuário não possui acesso á esta área!",
            icon: "info",
            confirmButtonText: "OK",
          }).then(() => {
            router.push("/");
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkPermisionUser();

    onMounted(() => {
      fetchGroupCandidacy();
    });

    const addNewStep = () => {
      stepsToAdd.value.order_number = steps.value.length + 1; 
      addStepToGroupCandidacy();
      steps.value.push({ ...stepsToAdd.value }); 
      stepsToAdd.value = { 
        title: "",
        order_number: steps.value.length + 1,
        description: "",
        fk_group: getIdUrl(),
      };
      conditionSteps.value = false; 
    };

    return {
      process,
      steps,
      conditionSteps,
      stepsToAdd,
      updateProcessAndStepsCandidacy,
      redirectToListGroupCandidacy,
      addStepToGroupCandidacy,
      openCardCreateStep,
      deleteStep,
      addNewStep, // Expose addNewStep function
    };
  },
};
</script>


<template>
  <div class="flex justify-start items-center gap-1 mb-[20px]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
      />
    </svg>
    <h2
      class="text-[20px] font-semibold dark:text-white text-[#4c5b74] leading-none mt-[3px]"
    >
      Atualizar
    </h2>
  </div>

  <div
    class="py-[20px] px-[30px] bg-white dark:bg-black w-full h-[auto] right-[1.5vw] rounded-2xl shadow-2xl"
  >
    <div class="mt-4">
      <label class="mb-2.5 block font-medium text-black dark:text-white"
        >Título do Processo</label
      >
      <input
        type="text"
        placeholder="Digite o título do processo seletivo"
        id="title-process"
        name="title"
        class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
        v-model="process.title"
      />

      <div v-if="!conditionSteps">
        <div class="text-center mt-4">
          <strong> {{ steps.length == 1 ? "Etapa" : "Etapas" }} </strong>
        </div>

        <div
          v-for="(item, index) in steps"
          :key="index"
          class="mt-3 border-gray border-b-[1px]"
        >
          <div class="md:flex">
            <div class="md:w-[74%]">
              <label class="mb-2.5 block font-medium text-black dark:text-white"
                >Título da Etapa</label
              >
              <input
                type="text"
                placeholder="Digite o título da etapa"
                id="title-process"
                name="title"
                class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="item.title"
              />
            </div>

            <div class="md:ml-[3%] md:w-[23%]">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white mt-2 md:mt-0"
                >Posição da etapa</label
              >
              <input
                type="text"
                placeholder="Digite a posição da etapa"
                id="title-process"
                name="title"
                class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                v-model="item.order_number"
              />
            </div>
          </div>
          <div class="md:flex">
            <div class="w-full">
              <label
                class="mb-2.5 block font-medium text-black dark:text-white mt-2"
              >
                Descrição da etapa
              </label>
              <textarea
                class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                placeholder="Digite a descrição da etapa"
                id=""
                v-model="item.description"
              ></textarea>
            </div>
          </div>
          <div class="flex justify-center mt-3 mb-8">
            <button
              class="rounded-full bg-red p-2 text-white"
              @click="deleteStep(item.id)"
            >
              <img src="/src/assets/images/icon/trash.svg" />
            </button>
          </div>
        </div>
      </div>

      <div class="mt-3" v-if="conditionSteps">
        <div class="text-center mt-4">
          <strong> Nova Etapa </strong>
        </div>

        <div class="md:flex">
          <div class="md:w-[74%]">
            <label class="mb-2.5 block font-medium text-black dark:text-white"
              >Título da Etapa</label
            >
            <input
              type="text"
              placeholder="Digite o título da etapa"
              id="title-process"
              name="title"
              class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              v-model="stepsToAdd.title"
            />
          </div>

          <div class="md:ml-[3%] md:w-[23%]">
            <label
              class="mb-2.5 block font-medium text-black dark:text-white mt-2 md:mt-0"
              >Posição da etapa</label
            >
            <input
              type="text"
              placeholder="Digite a posição da etapa"
              id="title-process"
              name="title"
              class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              v-model="stepsToAdd.order_number"
            />
          </div>
        </div>
        <div class="md:flex">
          <div class="w-full">
            <label
              class="mb-2.5 block font-medium text-black dark:text-white mt-2"
            >
              Descrição da etapa
            </label>
            <textarea
              class="w-full rounded-lg border border-stroke bg-transparent p-4 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              placeholder="Digite a descrição da etapa"
              id=""
              v-model="stepsToAdd.description"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-5">
        <button
          @click="openCardCreateStep"
          class="btn rounded-full bg-primary w-[40px] h-[40px] text-white"
          v-if="!conditionSteps"
        >
          +
        </button>

        <button
          @click="openCardCreateStep"
          class="btn rounded-full bg-red w-[40px] h-[40px] text-white"
          v-if="conditionSteps"
        >
          X
        </button>
      </div>

      <div class="mt-2 flex justify-end gap-2">
  <div>
    <button
      class="bg-primary hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
      @click="updateProcessAndStepsCandidacy"
      v-if="!conditionSteps"
    >
      Atualizar
    </button>
  </div>
  <div>
    <button
      class="bg-red hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
      @click="redirectToListGroupCandidacy"
    >
      Cancelar
    </button>
  </div>

        <div>


          <button
            class="bg-primary hover:opacity-[.8] px-[20px] h-[40px] rounded-[5px] text-white font-medium text-[18px]"
            @click="addStepToGroupCandidacy"
            v-if="conditionSteps"
          >
            Adicionar Etapa
          </button>
        </div>
      </div>
    </div>
  </div>
</template>