<script setup>
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import JobsXlsxComponent from "@/components/JobsXlsx/JobsXlsxComponent.vue";

const pageTitle = ref("Adicionar Vagas");
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <JobsXlsxComponent />
  </DefaultLayout>
</template>