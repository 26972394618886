<template>
    <div class="flex flex-col items-center justify-center mt-10">
      <div class="max-w-lg p-6 bg-white shadow-md rounded-lg">
        <h2 class="text-2xl text-primary font-bold mb-4">Aguardando Análise</h2>
        <p class="text-gray-200 mb-4">
          Sua empresa ainda está aguardando aprovação para começar a cadastrar vagas. Por favor, aguarde o processo de análise.
        </p>
        <router-link to="/perfil" class="bg-primary hover:bg-opacity-75 text-white py-2 px-4 rounded-lg text-center inline-block">
          Alterar cadastro
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AguardandoAnalise'
  };
  </script>
  
  <style scoped>
  </style>
  