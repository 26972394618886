<script setup>
import { ref, onMounted } from "vue";
import courseService from "@/services/courseService";
import * as XLSX from "xlsx";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import axios from "@/api/axios.js";

const pageTitle = ref("Cursos");
const courses = ref([]);
const loadingCourses = ref(false);
let showModal = ref(false);
let aulas = ref([]);
let video = ref("");
let loader = ref(true);

let coursesAdd = [];

const defaultThumbnailUrl =
  "https://static.vecteezy.com/ti/vetor-gratis/p1/8333611-estilo-de-icone-de-curso-online-gratis-vetor.jpg";

onMounted(async () => {
  localStorage.setItem("page", "Cursos");
  loadingCourses.value = true;
  try {
    const coursesData = await courseService.getCourses();
    if (coursesData) {
      courses.value = coursesData;
      let response = await axios.get("courses/complete");
      if (response.data && response.data.length != 0) {
        response.data.forEach((curso) => {
          courses.value.push(curso);
        });
      }
    }

    loadingCourses.value = false;

  } catch (error) {
    console.error(error);
    loadingCourses.value = false;
  }
});

const openModal = (course) => {
  loader.value = true;
  showModal.value = !showModal.value;
  aulas.value = course;
  video.value = transformarLink(course[0].link);
  loader.value = false;
};

const transformarLink = (link) => {
  const lastSlashIndex = link.lastIndexOf("/");
  const baseUrl = link.substring(0, lastSlashIndex + 1);
  const novoLink = baseUrl + "preview";
  return novoLink;
};

const showVideo = (link) => {
  loader.value = true;
  video.value = transformarLink(link);
  loader.value = false;
};

</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="flex lg:flex-row flex-col justify-start items-center gap-4">
      <div
        class="text-center text-2xl text-gray-900 dark:text-white flex flex-col items-center justify-center z-99 mx-auto"
        v-if="loadingCourses">
        <div class="text-center">
          <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <h5 class="text-primary font-bold text-[18px]">
          Carregando Cursos ...
        </h5>
      </div>
      <div v-else>
        <div v-if="courses.length === 0" class="text-center">
          Nenhum curso disponível.
        </div>
        <div class="flex flex-col items-center">
          <ul v-if="courses.length > 0" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
            <li v-for="course in courses" :key="course.ID"
              class="transition-transform duration-300 transform hover:scale-105">
              <div
                class="relative flex flex-col mt-6 bg-white dark:bg-black shadow-md bg-clip-border rounded-xl dark:border-boxdark dark:border-[1px]">
                <div class="relative mx-4 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl">
                  <img class="block w-full mt-4 rounded-xl" :src="course.thumbnail_url" :alt="course.post_title" />
                </div>
                <div class="p-6">
                  <h5
                    class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    {{ course.post_title }}
                  </h5>

                  <p v-if="course.post_content == ''" v-html="course.post_content
                    .replace(/<span>/g, '')
                    .replace(/<\/span>/g, '')
                    " class="block font-sans text-base antialiased font-light leading-[1.2] text-inherit"></p>

                  <p v-if="course.post_content != ''"
                    class="block font-sans text-base antialiased font-light leading-[1.2] text-inherit mb-4"
                    v-html="course.post_content"></p>

                  <div class="flex justify-start items-start gap-4 mb-3 mt-[20px]">
                    <span v-if="course.additional_info"
                      class="block text-sm px-3 py-1 bg-dark text-white rounded-full shadow-md w-1/5 text-center mt-1">
                      {{
                        course.additional_info.course_price_type &&
                          course.additional_info.course_price_type[0]
                            .trim()
                            .toLowerCase() === "free"
                          ? "Livre"
                          : "Pago"
                      }}
                    </span>

                    <div class="border-l-[1px] border-bodydark pl-3 font-medium text-sm leading-[1.2]">
                      <span class="font-light text-[12px]">Categoria:</span>
                      <p>{{ course.course_category[0].name }}</p>
                    </div>
                  </div>
                </div>
                <div class="p-6 pt-0">
                  <a v-if="course.guid"
                    class="cursor-pointer align-middle flex w-full justify-center select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-dark text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                    target="_blank" rel="noopener noreferrer" :href="course.guid">Saiba mais</a>
                  <a v-if="!course.guid" @click="openModal(course.aulas)"
                    class="cursor-pointer align-middle flex w-full justify-center select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-dark text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                    target="_blank" rel="noopener noreferrer">
                    Saiba mais
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="fixed inset-0 z-[99999] flex items-center justify-center bg-black bg-opacity-80">
      <div class="bg-black h-[100vh] w-full overflow-x-hidden overflow-y-auto">
        <div class="fixed w-full bg-black z-20">
          <div class="flex justify-between items-center px-5 py-5 border-b-[1px] border-boxdark shadow-lg">

            <h2 class="font-bold text-lg text-grayLight">Conteúdo do curso</h2>

            <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z"
                fill="#f1f1f1" />
            </svg>

            <div class="text-lg">
              <button @click="openModal('')" class="flex justify-center items-center gap-2">
                <p class="font-medium text-grayLight">Sair</p>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4" stroke="#f1f1f1"
                    stroke-width="1.5" stroke-linecap="round" />
                  <path d="M10 12H20M20 12L17 9M20 12L17 15" stroke="#f1f1f1" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="flex lg:flex-row flex-col-reverse justify-between items-start mt-[4.4rem]">

          <div class="flex flex-col h-full relative border-r-[1px] border-boxdark left-0 z-10 bg-black w-100">
            <div class="flex flex-col h-full lg:fixed pt-[10px] pb-[70px]">
              <div class="bg-black flex-1 z-1 overflow-x-hidden overflow-y-auto">
                <p class="cursor-pointer text-grayLight flex justify-start items-center pl-5 pr-2 py-2 border-b-[1px] border-boxdark hover:bg-boxdark font-medium"
                  v-for="(item, index) in aulas" :key="index">
                  <svg fill="#f1f1f1" width="18px" height="18px" viewBox="0 0 1069 1069" style="
                            fill-rule: evenodd;
                            clip-rule: evenodd;
                            stroke-linejoin: round;
                            stroke-miterlimit: 2;
                          " version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                    xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                      <rect height="1066.67" id="Video-player" style="fill: none" width="1066.67" x="1.515" y="0.143" />
                      <g>
                        <path
                          d="M653.802,660.183c9.682,-5.579 15.648,-15.903 15.648,-27.077c0,-11.174 -5.966,-21.498 -15.648,-27.077c-0,0 -207.519,-119.571 -207.519,-119.571c-9.669,-5.571 -21.576,-5.563 -31.238,0.021c-9.662,5.584 -15.613,15.897 -15.613,27.056c-0,0 -0,239.142 -0,239.142c-0,11.159 5.951,21.472 15.613,27.056c9.662,5.584 21.569,5.592 31.238,0.021c0,-0 207.519,-119.571 207.519,-119.571Zm-78.196,-27.077l-113.674,65.498c-0,0.001 -0,-130.996 -0,-130.996l113.674,65.498Z"
                          style="fill-opacity: 0.5" />
                        <path
                          d="M45.265,325.143l-0,458.333c-0,52.49 20.852,102.831 57.968,139.948c37.117,37.117 87.458,57.969 139.949,57.969c165.508,-0 417.825,-0 583.333,-0c52.491,-0 102.832,-20.852 139.948,-57.969c37.117,-37.117 57.969,-87.458 57.969,-139.948l-0,-458.333c-0,-52.49 -20.852,-102.831 -57.969,-139.948c-37.116,-37.117 -87.457,-57.969 -139.948,-57.969c-165.508,0 -417.825,0 -583.333,0c-52.491,0 -102.832,20.852 -139.949,57.969c-37.116,37.117 -57.968,87.458 -57.968,139.948Zm62.5,56.213l-0,402.12c-0,35.915 14.267,70.358 39.662,95.754c25.396,25.396 59.84,39.663 95.755,39.663c165.508,-0 417.825,-0 583.333,-0c35.915,-0 70.359,-14.267 95.754,-39.663c25.396,-25.396 39.663,-59.839 39.663,-95.754l-0,-458.333c-0,-35.915 -14.267,-70.358 -39.663,-95.754c-25.395,-25.396 -59.839,-39.663 -95.754,-39.663c-165.508,0 -417.825,0 -583.333,0c-35.915,0 -70.359,14.267 -95.755,39.663c-23.909,23.91 -37.955,55.84 -39.516,89.467l676.937,0c17.248,0 31.25,14.003 31.25,31.25c0,17.248 -14.002,31.25 -31.25,31.25l-677.083,0Zm123.177,-160.38c18.253,0 33.073,14.82 33.073,33.073c-0,18.254 -14.82,33.074 -33.073,33.074c-18.254,-0 -33.074,-14.82 -33.074,-33.074c0,-18.253 14.82,-33.073 33.074,-33.073Zm104.166,0c18.254,0 33.074,14.82 33.074,33.073c-0,18.254 -14.82,33.074 -33.074,33.074c-18.253,-0 -33.073,-14.82 -33.073,-33.074c0,-18.253 14.82,-33.073 33.073,-33.073Zm104.167,0c18.254,0 33.073,14.82 33.073,33.073c0,18.254 -14.819,33.074 -33.073,33.074c-18.254,-0 -33.073,-14.82 -33.073,-33.074c-0,-18.253 14.819,-33.073 33.073,-33.073Z" />
                      </g>
                    </g>
                  </svg>
                  <button class="p-2 text-sm" @click="showVideo(item.link)">
                    {{ item.titulo }}
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-center items-start pt-5 px-5 w-full h-full">
            <div v-if="loader" class="flex justify-center mt-2 mb-2">
              <svg aria-hidden="true" class="w-8 h-8 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
            </div>
            <iframe v-if="!loader" :src="video" class="w-full lg:h-[75vh] h-[35vh] shadow-lg rounded-lg" frameborder="0"
              allow="autoplay"></iframe>


            <div>
              <h1 class="text-grayLight text-lg font-bold mt-3">
                Redação para MKT Digital - Daniel Oliveira
              </h1>
              <p class="text-sm">Marketing</p>
              <p class="mt-3 leading-[1.2]">"Aprenda a criar designs incríveis com Canva. Domine ferramentas de design
                gráfico e crie materiais
                visuais impressionantes, mesmo sem experiência prévia."</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>