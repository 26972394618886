import axios from "@/api/axios.js";

const API_URL = '/skills';

export interface Skill {
  id: number;
  description: string;
  weight: number;
  type: string;
  summary: string;
  category: string;
}

export const getSkills = async (): Promise<Skill[]> => {
  const response = await axios.get(API_URL);
  return response.data;
};
