<script lang="ts">
import { useRouter } from "vue-router";
import { ref } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";

export default {
  setup() {
    let conditionCreateUser = ref<boolean>(false);
    let conditionModalUser = ref<boolean>(false);
    let users = ref<Array<any>>([]);
    let user: any;
    let userNameModal = ref<string>("");
    let userEmailModal = ref<string>("");
    let userPositionModal = ref<string>("");
    const isDisabled = ref(false);

    let optionsPermission = ref<Array<any>>([
      {
        name: "Básico (apenas visualizar)",
        value: "basic",
      },
      {
        name: "Intermediário (todas as ações em Vagas, apenas visualizar em Editais e Processo Seleção)",
        value: "intermediate",
      },
      {
        name: "Avançado (todas as ações em todos os recursos)",
        value: "advanced",
      },
    ]);

    let sendUser = ref({
      fullname: "",
      email: "",
      permission: "", // Novo campo para a permissão única
      password: "",
      permissionjob: "",
      permissionnotice: "",
      permissionstagecandidacy: "",
      position: "",
    });

    const openModalUserCreated = (user: any): void => {
      userNameModal.value = user.fullname;
      userEmailModal.value = user.email;
      userPositionModal.value = user.position;
      conditionModalUser.value = !conditionModalUser.value;
    };

    const clearVariables = () => {
      userNameModal.value = "";
      userEmailModal.value = "";
      userPositionModal.value = "";
    };

    const closeModalUserCreated = () => {
      conditionModalUser.value = false;
    };

    const openCreateUser = (): void => {
      conditionCreateUser.value = !conditionCreateUser.value;
    };

    const closeCreateUser = (): void => {
      conditionCreateUser.value = !conditionCreateUser.value;
    };

    const fetchUsersSecretary = async (): Promise<any> => {
      user = await axios.post("auth/me");
      if (
        user.data.usertype == "secretary" ||
        user.data.usertype == "userSecretary"
      ) {
        try {
          let response = await axios.get("usersercretaries");
          users.value = response.data;
        } catch (error) {
          console.error(error);
        }
      } else if (
        user.data.usertype == "enterprise" ||
        user.data.usertype == "userEnterprise"
      ) {
        try {
          let response = await axios.get("userenterprises");
          users.value = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUsersSecretary();

    const genereateUserSecretary = async (): Promise<any> => {
      if (
        sendUser.value &&
        (user.data.usertype == "secretary" ||
          user.data.usertype == "userSecretary")
      ) {
        sendUser.value.password = sendUser.value.email;
        try {
          let response = await axios.post("usersercretaries", sendUser.value);
        } catch (error) {
          console.error(error);
        } finally {
          Swal.fire({
            title: "Sucesso",
            text: "Usuário criado com sucesso!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            fetchUsersSecretary();
            openCreateUser();
          });
        }
      } else if (
        sendUser.value &&
        (user.data.usertype == "enterprise" ||
          user.data.usertype == "userEnterprise")
      ) {
        sendUser.value.password = sendUser.value.email;
        try {
          let response = await axios.post("userenterprises", sendUser.value);
        } catch (error) {
          console.error(error);
        } finally {
          Swal.fire({
            title: "Sucesso",
            text: "Usuário criado com sucesso!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            fetchUsersSecretary();
            openCreateUser();
          });
        }
      }
      clearVariables();
    };

    const handlePermissionChange = () => {
      switch (sendUser.value.permission) {
        case "advanced":
          sendUser.value.permissionjob = "full";
          sendUser.value.permissionnotice = "full";
          sendUser.value.permissionstagecandidacy = "full";
          break;
        case "intermediate":
          sendUser.value.permissionjob = "full";
          sendUser.value.permissionnotice = "visible";
          sendUser.value.permissionstagecandidacy = "full";
          break;
        case "basic":
          sendUser.value.permissionjob = "visible";
          sendUser.value.permissionnotice = "visible";
          sendUser.value.permissionstagecandidacy = "visible";
          break;
        default:
          sendUser.value.permissionjob = "off";
          sendUser.value.permissionnotice = "off";
          sendUser.value.permissionstagecandidacy = "off";
      }
    };

    return {
      sendUser,
      optionsPermission,
      conditionCreateUser,
      conditionModalUser,
      users,
      userNameModal,
      userEmailModal,
      userPositionModal,
      openCreateUser,
      closeCreateUser,
      openModalUserCreated,
      closeModalUserCreated,
      genereateUserSecretary,
      isDisabled,
      handlePermissionChange,
    };
  },
};
</script>

<template>
  <div
    v-if="conditionModalUser"
    class="fixed inset-0 z-99 flex items-center justify-center bg-black dark:bg-white dark:bg-opacity-5 bg-opacity-50 p-4"
  >
    <div
      class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto h-[40svh] mt-[8vh] flex flex-col"
    >
      <div class="text-center mb-3 flex justify-between items-center">
        <h4 class="mx-auto text-2xl">
          <strong>Usuário </strong> {{ userNameModal }}
        </h4>
        <button
          @click="closeModalUserCreated"
          title="Fechar"
          class="text-primary px-4 py-2 hover:text-primary_hover"
        >
          <i class="fa-regular fa-circle-xmark text-3xl"></i>
        </button>
      </div>
      <div
        class="flex flex-col items-start p-8 text-lg text-left gap-4 bg-black bg-opacity-10 rounded-lg dark:bg-white dark:bg-opacity-5 dark:text-white"
      >
        <p><strong>Nome:</strong> {{ userNameModal }}</p>
        <p><strong>E-mail:</strong> {{ userEmailModal }}</p>
        <!-- <p><strong>Senha:</strong> {{ userEmailModal }}</p> -->
        <p><strong>Cargo:</strong> {{ userPositionModal }}</p>
      </div>
    </div>
  </div>

  <div
    v-if="conditionCreateUser"
    class="fixed inset-0 z-99 flex items-center justify-center bg-black dark:bg-white dark:bg-opacity-5 bg-opacity-50 p-4"
  >
    <div class="flex justify-center">
      <div class="bg-white dark:bg-black w-[100%] p-[0.8%] rounded-lg shadow">
        <div class="flex justify-center">
          <div class="lg:w-[70%] sm:w-[95%]">
            <div class="flex items-center justify-between">
              <div
                class="text-black dark:text-white mb-6 font-bold mt-8 border-b-[1px] border-gray pb-2"
              >
                Dados do usuário
              </div>
              <button
                @click="closeCreateUser"
                title="Fechar"
                class="text-primary px-4 py-2 hover:text-primary_hover"
              >
                <i class="fa-regular fa-circle-xmark text-3xl"></i>
              </button>
            </div>

            <form @submit.prevent="genereateUserSecretary">
              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseCpf"
                    >Nome</label
                  >
                  <input
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseCpf"
                    id="enterpriseCpf"
                    placeholder="Nome completo"
                    v-model="sendUser.fullname"
                    required
                  />
                </div>
              </div>
              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseEmail"
                    >Email</label
                  >
                  <input
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseEmail"
                    id="enterpriseEmail"
                    placeholder="E-mail do usuário"
                    v-model="sendUser.email"
                    required
                  />
                </div>
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="cargo"
                    >Cargo</label
                  >
                  <input
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="cargo"
                    id="cargo"
                    v-model="sendUser.position"
                    placeholder="Cargo do usuário"
                    required
                  />
                </div>
              </div>
              <div
                class="text-black dark:text-white mb-6 font-bold mt-8 border-b-[1px] border-gray pb-2"
              >
                Permissões
              </div>
              <div class="flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="permission"
                    >Permissão</label
                  >
                  <select
                    required
                    v-model="sendUser.permission"
                    @change="handlePermissionChange"
                    id="permission"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  >
                    <option value="">Selecione</option>
                    <option
                      v-for="option in optionsPermission"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="flex justify-center mt-3 mb-3">
                <button
                  type="submit"
                  class="inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary_hover focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-end mb-5 mt-5">
    <button
      :class="
        !conditionCreateUser
          ? 'btn rounded-full bg-primary w-[40px] h-[40px] text-white'
          : 'btn rounded-full bg-red w-[40px] h-[40px] text-white'
      "
      @click="openCreateUser"
    >
      {{ !conditionCreateUser ? "+" : "X" }}
    </button>
  </div>

  <div v-if="users.length == 0 && !conditionCreateUser" class="text-center">
    <p>
      Não existem registros no momento, clique
      <span class="text-primary border-b cursor-pointer" @click="openCreateUser"
        >aqui</span
      >
      para criar um.
    </p>
  </div>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table v-if="users.length != 0" class="w-full shadow">
      <thead class="text-center uppercase bg-black dark:bg-black text-gray">
        <tr>
          <th scope="col" class="p-3 rounded-tl-[12px]">Nome</th>
          <th scope="col" class="p-3">E-mail</th>
          <th scope="col" class="p-3">Cargo</th>
          <th scope="col" class="p-3 rounded-tr-[12px]">Ações</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr
          v-for="(item, index) in users"
          :key="index"
          class="odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark hover:bg-gray"
        >
          <td class="p-3 font-medium whitespace-nowrap dark:text-white">
            {{ item.fullname }}
          </td>

          <td class="p-3 font-medium whitespace-nowrap dark:text-white">
            {{ item.email }}
          </td>

          <td class="p-3 font-medium whitespace-nowrap dark:text-white">
            {{ item.position }}
          </td>

          <td
            class="p-3 font-medium whitespace-nowrap dark:text-white flex justify-center gap-3"
          >
            <button
              @click="openModalUserCreated(item)"
              class="bg-primary hover:bg-primary_hover p-2 rounded-full"
              title="Visualizar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              >
                <path
                  d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                />
              </svg>
            </button>

            <button
              @click="openModalUserCreated(item)"
              class="bg-primary hover:bg-primary_hover p-2 rounded-full"
              title="Editar"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#fff"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
