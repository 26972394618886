<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import customAxios from "@/api/axios.js";
import { fetchVecanciesFabrica } from "@/services/vecanciesService";

export default {
  setup() {
    const jobPositions = ref([]);
    const jobPositionsFabrica = ref([]);
    const jobsXlsx = ref([]);
    const searchQuery = ref("");
    const filterType = ref("");
    const filterRegion = ref("");
    const loader = ref(false);

    const selectedType = ref("");
    const selectedRegion = ref("");

    const fetchJobPositions = async (type, region) => {
      try {
        loader.value = true;
        const response = await axios.get(
          "https://themosvagas.com.br/wp-json/api-externa/v1/resultados",
          {
            params: {
              tipo: type || "jovem-aprendiz",
              regiao: region || "parnaiba",
              area: "",
              term: "",
              paged: 1,
              total: 21,
            },
            headers: {
              Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
            },
          }
        );

        if (response.status !== 200) {
          loader.value = false;
          throw new Error("Erro ao buscar dados das vagas");
        } else {
          const jobData = response.data;
          console.log("Dados recebidos da API:", jobData);

          jobPositions.value = jobData.map((job) => formatJobData(job));
          console.log("Dados formatados:", jobPositions.value);
        }
      } catch (error) {
        jobPositions.value = [];
        console.error("Erro ao buscar dados da API:", error);
      } finally {
        loader.value = false;
      }
    };

    const fetchFabricaData = async (type, region) => {
      loader.value = true;
      try {
        const fabricaData = await fetchVecanciesFabrica();
        jobPositionsFabrica.value = fabricaData.filter(
          (job) =>
            (!type || job.type === type) && (!region || job.region === region)
        );
      } catch (error) {
        jobPositionsFabrica.value = [];
        console.error("Erro ao buscar dados da API:", error);
      } finally {
        loader.value = false;
      }
    };

    const formatJobData = (job) => {
      const companyMatch = job.post_content.match(
        /<h5>Empresa<\/h5>\r\n- (.+?)\r\n/
      );
      const companyname = companyMatch
        ? companyMatch[1]
        : "Empresa Confidencial";

      const regionMatch = job.post_content.match(
        /<h5>Região<\/h5>\r\n- (.+?)\r\n/
      );
      const region = regionMatch ? regionMatch[1] : "Região Não Disponível";

      return {
        id: job.ID,
        position: job.post_title.replace(/&#8211; PI \| \d+ vaga\(s\)/, ""),
        area: job.area[0].name,
        companyname: companyname,
        logo: "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
        jobs_number: job.total_vagas || 1,
        post_permalink: job.post_permalink,
        region: region,
        type: job.tipo[0].name,
      };
    };

    const fetchJobsXlsx = async () => {
      let result = await customAxios.get("jobsXlsx");
      if (result.data) {
        jobsXlsx.value = result.data;
      }
    };

    const updateFiltersAndFetch = async () => {
      selectedType.value = filterType.value;
      selectedRegion.value = filterRegion.value;
      await fetchJobPositions(selectedType.value, selectedRegion.value);
      await fetchFabricaData(selectedType.value, selectedRegion.value);
    };

    onMounted(() => {
      fetchJobPositions();
      fetchFabricaData();
      fetchJobsXlsx();
    });

    const combinedJobPositions = computed(() => {
      return [
        ...jobPositions.value,
        ...jobPositionsFabrica.value,
        ...jobsXlsx.value,
      ];
    });

    const filteredJobPositions = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return combinedJobPositions.value.filter((job) =>
        job.position.toLowerCase().includes(query)
      );
    });

    return {
      updateFiltersAndFetch,
      jobPositions,
      jobPositionsFabrica,
      searchQuery,
      filterType,
      filterRegion,
      loader,
      filteredJobPositions,
    };
  },
};
</script>

<template>
  <div class="py-4">
    <div class="mb-4">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Pesquisar vagas..."
        class="w-full p-2 py-3 border border-stroke text-[15px] text-bodydark2 rounded-md bg-white dark:bg-black"
      />
    </div>
    <div class="mb-4 flex lg:flex-row flex-col gap-4 lg:space-x-4">
      <select
        v-model="filterType"
        class="p-2 border border-stroke text-[15px] text-bodydark2 rounded-md bg-white dark:bg-black"
      >
        <option value="">Jovem Aprendiz</option>
        <option value="estagio">Estágio</option>
      </select>
      <select
        v-model="filterRegion"
        class="p-2 border border-stroke text-[15px] text-bodydark2 rounded-md bg-white dark:bg-black"
      >
        <option value="">Estados</option>
        <option value="acre">Acre</option>
        <option value="alagoas">Alagoas</option>
        <option value="amapa">Amapá</option>
        <option value="amazonas">Amazonas</option>
        <option value="bahia">Bahia</option>
        <option value="ceara">Ceará</option>
        <option value="distrito-federal">Distrito Federal</option>
        <option value="espirito-santo">Espírito Santo</option>
        <option value="goias">Goiás</option>
        <option value="maranhao">Maranhão</option>
        <option value="mato-grosso">Mato Grosso</option>
        <option value="mato-grosso-do-sul">Mato Grosso do Sul</option>
        <option value="minas-gerais">Minas Gerais</option>
        <option value="para">Pará</option>
        <option value="paraiba">Paraíba</option>
        <option value="parana">Paraná</option>
        <option value="pernambuco">Pernambuco</option>
        <option value="piaui">Piauí</option>
        <option value="rio-de-janeiro">Rio de Janeiro</option>
        <option value="rio-grande-do-norte">Rio Grande do Norte</option>
        <option value="rio-grande-do-sul">Rio Grande do Sul</option>
        <option value="rondonia">Rondônia</option>
        <option value="roraima">Roraima</option>
        <option value="santa-catarina">Santa Catarina</option>
        <option value="sao-paulo">São Paulo</option>
        <option value="sergipe">Sergipe</option>
        <option value="tocantins">Tocantins</option>
      </select>
      <button
        @click="updateFiltersAndFetch"
        class="p-2 bg-primary hover:bg-primary_hover text-white duration-100 rounded-md lg:w-[400px]"
      >
        Buscar
      </button>
    </div>

    <div v-if="loader" class="flex justify-center w-full mt-2 mb-2">
      <svg
        aria-hidden="true"
        class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
    <div
      v-if="!loader"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      <div
        v-if="filteredJobPositions.length === 0"
        class="text-center text-gray-600"
      >
        Nenhuma vaga encontrada.
      </div>
      <div
        v-for="job in filteredJobPositions"
        :key="job.id"
        class="w-full max-w-xl bg-white hover:bg-whiter duration-100 border border-gray rounded-lg shadow-[0px_0px_6px_#0000001a] hover:shadow-[0px_0px_10px_#1d4ed8] dark:bg-black dark:border-boxdark"
      >
        <div
          class="bg-white dark:bg-black rounded-lg flex flex-col items-center py-5 px-3"
        >
          <h5
            class="mb-1 text-[18px] font-bold text-gray-900 dark:text-white text-center leading-none"
          >
            {{ job.position }}
          </h5>
          <p class="text-sm dark:text-gray">
            Área: <span class="font-medium">{{ job.area }}</span>
          </p>
          <p class="text-sm dark:text-gray">
            Empresa: <span class="font-medium">{{ job.companyname }}</span>
          </p>
          <div class="flex lg:flex-row flex-col lg:gap-0 gap-2 mt-4 md:mt-6">
            <a
              :href="job.post_permalink"
              target="_blank"
              class="items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Visualizar
            </a>
            <span
              href="#"
              class="py-2 px-4 ms-2 text-sm font-medium text-blue-700 focus:outline-none bg-white rounded-lg border border-gray hover:bg-gray-100 dark:bg-black dark:text-gray dark:border-boxdark"
            >
              Número de vagas: <strong>{{ job.jobs_number }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.normal-text {
  font-weight: normal;
}
</style>
