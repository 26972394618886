<template>
  <div class="min-h-screen flex flex-col">
    <Navbar />
    <div class="flex-1 container mx-auto px-6 py-8">
      <button @click="goBack" class="mt-22 ml-20 text-xl font-bold text-primary hover:text-black">
        <i class="fa-solid fa-arrow-left-long"></i>
        Voltar
      </button>
      <ChatBotComponent />
    </div>
    <Footer/>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import ChatBotComponent from "@/components/ChatBot/ChatBot.vue";
import Navbar from "@/components/Navbar/NavbarView.vue";
import Footer from "@/components/Footer/FooterView.vue";

export default {
  name: 'WrapperComponent',
  components: {
    Navbar,
    ChatBotComponent,
    Footer
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack,
    };
  },
};
</script>
