<script setup>
import InputGroup from '@/components/Auths/InputGroup.vue'
</script>

<template>
    <div class="absolute z-999 inset-0 flex items-center justify-center bg-[#2e3a47b8]" v-if="show">
        <div class="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 class="text-2xl mb-4 font-bold">{{ title }}</h2>
            <div>
                <InputGroup label="Endereço do aluno(a):" type="text" placeholder="Digite aqui seu endereço" />
                <InputGroup label="Endereço da empresa:" type="text" placeholder="Digite aqui o endereço da empresa" />
            </div>
            <div>
                <h6>Distância: [1.457km]</h6>
                <h6>Duração: [20horas e 29min]</h6>
            </div>
            <div class="flex justify-end mt-4">
                <button class="bg-primary text-white px-4 py-2 rounded" @click="closeModal">Fechar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Calcule a distância',
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
}
</script>