import axios from "@/api/axios.js";

const API_URL = '/job-positions';

export interface JobPosition {
  id_job: number;
  area_id_area: number;
  enterpriseid: number;
  pcd_jobs: string;
  jobs_number: number;
  skills_hard: string;
  skills_soft: string;
  notes: string;
  image: string;
  start_date: string;
  end_date: string;
  salary: number;
  position: string;
  job_type: string;
}

export const getJobPositions = async (): Promise<JobPosition[]> => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const createJobIA = async (
  job: any
): Promise<any> => {
  try {
    let resp: any = await axios.post(`${import.meta.env.VITE_IA_API}/add_job`, job);
  } catch (error) {
    console.error(error)
  }
}

export const fetchRecommendedJobs = async (
  dream_user: string,
  hard_skill: Array<any>
): Promise<any> => {
  try {
    let payload: any = {
      query_texts: [dream_user]
    }
    let response: any = await axios.post(`${import.meta.env.VITE_IA_API}/query_jobs`, payload)

    let payloadJobs: any = {
      ids: [],
      hard_skills: hard_skill
    };
    if (response.data) {
      response.data.results.forEach((element: any) => {
        payloadJobs.ids.push(element.id)
      });
    }
    let jobsResults: any = await axios.post(`${API_URL}/order-jobs`, payloadJobs);
    if (jobsResults.data) {
      return jobsResults.data;
    }

  } catch (error) {
    console.error(error)
  }

};
