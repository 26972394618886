<script setup lang="ts">
import userPhotoDefault from "@/assets/images/user/defaultUser.jpg";
import axios from "@/api/axios.js";
import http from "axios";
import Swal from "sweetalert2";
import { onMounted, onUnmounted, onUpdated, ref, watch } from "vue";
import { useRouter } from "vue-router";
import brasil from "@/components/Helpers/brasil.json";
import ChangePassword from '@/components/ChangePassword.vue';
import { getAreas } from "@/services/areaService";
import type { Area } from "@/services/areaService";
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();
const showToUser = computed(
      () =>
        userStore.isAdmin ||
        userStore.isSecretary ||
        userStore.isUserSecretary ||
        userStore.isEnterprise ||
        userStore.isUserEnterprise ||
        userStore.isCandidate
    );
const conditionDisability = ref(false);
const conditionCertificate = ref(false);
let fileCertificate = ref<any>("");
let videoCandidate = ref<any>("");
let cardPresentationFile = ref<any>("");
const usertype = ref<string>("");
const stateUserType = ref<number>();
const router = useRouter();
const openOtherFiles = ref(false);
let nameOhetFile = ref<string>("");
let otherFileEnterprise = ref<any>();
let arrayOtherFiles = ref<Array<any>>([]);
const areas = ref<Area[]>([]);
const deficienceOnwed = ref<Array<any>>([]);
const certificateOnwed = ref<Array<any>>([]);
const conditionVideo = ref<boolean>(false);
const candidateOldVideo = ref<any>("");
const rows = ref<number>(0);

onMounted(() => {
  fetchUserType();
  statesCandidate.value = brasil;
  statesEnterprise.value = brasil;
  statesAdmin.value = brasil;

  if (localStorage.getItem("candidateState")) {
    candidateState.value = localStorage.getItem("candidateState")!;
    getCandidatesCities();
  }
  if (localStorage.getItem("enterpriseState")) {
    enterpriseState.value = localStorage.getItem("enterpriseState")!;
    getEnterpriseCities();
  }
  if (localStorage.getItem("adminState")) {
    adminState.value = localStorage.getItem("adminState")!;
    getAdminCities();
  }
  fetchAreas();
});

// User data
const userId = localStorage.getItem("userId");
const userPhoto = ref<string>(userPhotoDefault);
const selectedFile = ref<File | null>(null);
const photoPath = ref<string | null>(null);

const statesCandidate = ref<{
  [key: string]: { sigla: string; nome: string; cidades: string[] };
}>({});

const statesEnterprise = ref<{
  [key: string]: { sigla: string; nome: string; cidades: string[] };
}>({});

const statesAdmin = ref<{
  [key: string]: { sigla: string; nome: string; cidades: string[] };
}>({});

const selectedCandidatesCities = ref<string[]>([]);
const selectedEnterpriseCities = ref<string[]>([]);
const selectedAdminCities = ref<string[]>([]);

const getCandidatesCities = () => {
  console.log("Candidate State:", candidateState.value);
  selectedCandidatesCities.value =
    statesCandidate.value[candidateState.value]?.cidades || [];
};

const getEnterpriseCities = () => {
  console.log("Enterprise State:", enterpriseState.value);
  selectedEnterpriseCities.value =
    statesEnterprise.value[enterpriseState.value]?.cidades || [];
};
const getAdminCities = () => {
  selectedAdminCities.value =
    statesAdmin.value[adminState.value]?.cidades || [];
};

const fetchAreas = async () => {
  try {
    const response = await getAreas();
    areas.value = response;
  } catch (error) {
    console.error("Error fetching areas:", error);
  }
};
// Candidate data

// Candidates ref
const candidateId = ref("");
const candidateFullName = ref("");
const candidateCellPhone = ref("");
const candidateCpf = ref("");
const candidateDateOfBirth = ref("");
const candidateGender = ref("");
const candidateGenderIdentity = ref("");
const candidateSexualOrientation = ref("");
const candidateBirthplace = ref("");
const candidateMaritalStatus = ref("");
const candidateState = ref("");
const candidateCity = ref("");
const candidateEmail = ref("");
const candidateEducationalLevel = ref("");
const candidateBiography = ref("");
const candidatePhotoPath = ref<string | null>(null);
const candidateSex = ref("");
const candidateUniqueRegistration = ref("");
const candidatePcd = ref("");
const candidateCid = ref("");
const candidateZipCode = ref("");
const candidateStreet = ref("");
const candidateNeighborhood = ref("");
const candidateNumberHouse = ref("");
const candidateComplement = ref("");
const candidateSocialProgram = ref("");
const candidateInternship = ref("");
const candidateIncomeLevel = ref("");
const candidateNumberPeopleHome = ref("");
const candidateTotalIncome = ref("");
const candidateEmploymentLink = ref("");
// const candidateAcademicBackground = ref('');
const candidateEducationInstitution = ref("");
const candidateInstitutionType = ref("");
const candidateCompletedCourses = ref("");
const candidateSkillsCompetencies = ref("");
const candidateCoverLetter = ref("");
let candidateCertificatePath = ref<Array<any>>([]);
const candidateVideoPresentationPath = ref("");
const candidateCardPresentationPath = ref("");
const candidateDesiredJobTypes = ref("");
const candidatePreferredGeographicLocation = ref("");
const candidateExpectedSalary = ref("");
const candidateSectorOfInterest = ref("");
// const candidateSocialNetworks = ref('');
const candidateLinkedin = ref("");
const candidateInstagram = ref("");
const candidateFacebook = ref("");
const candidateXtwitter = ref("");
const candidateTiktok = ref("");

const candidateDisability = ref<Array<any>>([]);
let candidateNameDisability = ref<string>("");
let candidateFileDeficience = ref<any>("");

// enterprise refs
const enterpriseId = ref("");
const enterpriseType = ref("");
const enterpriseIsVerified = ref(true);
const enterpriseSubType = ref("");
const enterpriseCompanyName = ref("");
const enterpriseCorporateName = ref("");
const enterpriseTradeName = ref("");
const enterpriseStateRegistration = ref("");
const enterpriseLandlinePhone = ref("");
const enterpriseCompanyEmail = ref("");
const enterpriseFullName = ref("");
const enterpriseCellPhone = ref("");
const enterpriseCpf = ref("");
const enterprisePosition = ref("");
const enterpriseEmail = ref("");
const enterpriseAcceptsPcdPosition = ref("");
const enterpriseSector = ref("");
const enterpriseCompanyDescription = ref("");
const enterpriseZipCode = ref("");
// const culturalAndOrganizationalValues = ref("");
// const enterpriseMissionAndVision = ref("");
const enterpriseMission = ref("");
const enterpriseVision = ref("");
const enterpriseValues = ref("");
const enterpriseNumberOfEmployees = ref(0);
const enterpriseInstitutionalWebsite = ref("");
const enterpriseInstagram = ref("");
const enterpriseSocialContract = ref("");
const enterpriseState = ref<string>("");
const enterpriseCity = ref<string>("");
const enterpriseAddress = ref<string>("");
const enterprisePostalCode = ref<string>("");
const enterprisePostalNumber = ref<string>("");
let enterpriseCnpjCard = ref<any>();
let oldCardCnpj: string = "";
let otherFilesArrayFetch = ref<Array<any>>([]);

//admin refs
const adminFullName = ref("");
const adminDateofbirth = ref("");
const adminGender = ref("");
const adminCpf = ref("");
// const adminCity = ref("");
const adminState = ref("");
const adminMaritalstatus = ref("");
const adminSexualOrientation = ref("");
const adminGenderIdentity = ref("");
const adminBirthplace = ref("");
const adminOffice = ref("");
const adminSecretary = ref("");
const adminOrgan = ref("");
// const adminImagepath = ref("");
const admindateId = ref("");
const adminEmail = ref("");
const canEdit = ref(false);

//secretary refs
let secretaryId = ref("");
let secretaryIsVerified = ref();
let secretaryCompanyName = ref("");
let secretaryCorporateName = ref("");
let secretaryTradeName = ref("");
let secretaryStateRegistration = ref("");
let secretaryLandlinePhone = ref("");
let secretaryCompanyEmail = ref("");
let secretaryFullName = ref("");
let secretaryCellPhone = ref("");
let secretaryCpf = ref("");
let secretaryPosition = ref("");
let secretaryEmail = ref("");
let secretaryAcceptsPcdPosition = ref("");
let secretarySector = ref("");
let secretaryCompanyDescription = ref("");
let secretaryMission = ref("");
let secretaryVision = ref("");
let secretaryValues = ref("");
let secretaryNumberOfEmployees = ref();
let secretaryInstitutionalWebsite = ref("");
let secretaryInstagram = ref("");
let secretaryState = ref<string>("");
let secretaryCity = ref<string>("");
let secretaryAddress = ref<string>("");
let secretaryPostalCode = ref<string>("");
let secretaryCnpj = ref<String>("");
let secretaryCnpjCard = ref<any>();

const fetchAddress = async () => {
  if (candidateZipCode.value) {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${candidateZipCode.value}/json/`
      );
      const addressData = response.data;
      if (!addressData.erro) {
        candidateStreet.value = addressData.logradouro;
        candidateNeighborhood.value = addressData.bairro;
        candidateComplement.value = addressData.complemento || ""; // Verifica se existe complemento
      } else {
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  }
};

// Assistir mudanças no CEP e buscar endereço automaticamente
watch(candidateZipCode, (newZipCode) => {
  if (typeof newZipCode === "string" && newZipCode.length === 8) {
    fetchAddress();
  }
});

// Definindo tipos
interface AddressEnterpriseData {
  localidade: string;
  logradouro: string;
  uf: string;
  erro?: boolean;
}
// Função para buscar o endereço da empresa com base no CEP
const fetchEnterpriseAddress = async () => {
  if (enterprisePostalCode.value) {
    try {
      const response = await axios.get<AddressEnterpriseData>(
        `https://viacep.com.br/ws/${enterprisePostalCode.value}/json/`
      );
      console.log("Resposta da API:", response);
      const addressEnterpriseData = response.data;
      if (!addressEnterpriseData.erro) {
        enterpriseAddress.value = addressEnterpriseData.logradouro; // endereço
        enterpriseCity.value = addressEnterpriseData.localidade; // cidade
        enterpriseState.value = addressEnterpriseData.uf; // estado
        getEnterpriseCities();
      } else {
        console.error("CEP não encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  }
};

const deleteVideoPath = async (video: any) => {
  try {
    let payload = {
      videoPresentationPath: "",
    };
    let response = await axios.patch(
      `/candidates/${candidateId.value}`,
      payload
    );
    if (response) {
      deleteOldFile(video);
    }
  } catch (error) {
    console.error(error);
  } finally {
    fetchCandidate();
  }
};

const deleteCardPresentationPath = async (video: any) => {
  try {
    let payload = {
      resume: "",
    };
    let response = await axios.patch(
      `/candidates/${candidateId.value}`,
      payload
    );
    if (response) {
      deleteOldFile(video);
    }
  } catch (error) {
    console.error(error);
  } finally {
    fetchCandidate();
  }
};

// Assistir mudanças no CEP e buscar endereço automaticamente
watch(enterprisePostalCode, (newZipCode) => {
  console.log("Novo CEP:", newZipCode); // Log para depuração
  if (newZipCode.length === 8) {
    fetchEnterpriseAddress();
  }
});

const enterpriseCnpj = ref<string>("");

const fetchCnpjData = async () => {
  const cnpjOnlyNumbers = enterpriseCnpj.value?.replace(/[^\d]/g, "") || null;

  try {
    if (!cnpjOnlyNumbers) {
      throw new Error("CNPJ inválido");
    }

    const options = {
      method: "GET",
      url: `/receitaws/v1/cnpj/${cnpjOnlyNumbers}`,
      headers: { Accept: "application/json" },
    };

    const response = await http.request(options);
    const data = response.data;

    enterpriseAddress.value = data.logradouro;
    enterpriseState.value = data.uf;
    enterprisePostalCode.value = data.cep;
    enterprisePostalNumber.value = data.numero;
    enterpriseLandlinePhone.value = data.telefone;
    enterpriseCompanyEmail.value = data.email;
    enterpriseCompanyDescription.value = data.atividade_principal[0].text;
    enterpriseCompanyName.value = data.nome;
    enterpriseTradeName.value = data.fantasia || "";

    console.log("Dados do CNPJ carregados com sucesso:", data);
  } catch (error) {}
};

const handleCnpjInput = () => {
  fetchCnpjData(); // Chama fetchCnpjData quando o input do CNPJ muda
};

const selectOrgan = ref("");

let oldImage: string = "";

interface Enterprise {
  companyname: string;
  corporatename: string;
  tradename: string;
  city: string;
  cnpj: string | null;
  stateregistration: string | null;
  accessenterprise: string;
}

const fetchEnterpriseData = async () => {
  try {
    const response = await axios.get("/enterprises");
    const enterprise: Enterprise = response.data[0];
    enterpriseCompanyName.value = enterprise.companyname;
    enterpriseCorporateName.value = enterprise.corporatename;
    enterpriseTradeName.value = enterprise.tradename;
    enterpriseCnpj.value = enterprise.cnpj || "";
    enterpriseCity.value = enterprise.city;
    enterpriseStateRegistration.value = enterprise.stateregistration || "";
    canEdit.value = enterprise.accessenterprise === "admin";
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  // fetchEnterpriseData();
});

async function getTypesFromOrgan() {
  try {
    const response = await axios.get("/organ");
    const dataGov = response.data;
    selectOrgan.value = dataGov.map((chunk: { organ: any }) => chunk.organ);
    console.log(selectOrgan.value);
    // Extrair os "type" dos objetos
    const types = dataGov.map((organ: { organ: any }) => organ.organ);

    return types;
  } catch (error) {
    console.error("Erro ao buscar os types da rota /organ:", error);
    throw new Error("Erro ao buscar os types da rota /organ");
  }
}

const fetchUserType = async () => {
  try {
    const response = await axios.get(`/users/${userId}`);
    const userData = response.data;
    usertype.value = userData.usertype;
    console.log("Tipo de usuário carregado com sucesso:", usertype.value);
    if (usertype.value === "candidate") {
      fetchCandidate();
    } else if (usertype.value === "enterprise") {
      fetchEnterprise();
    } else if (usertype.value === "admin") {
      fetchAdmin();
    } else if (usertype.value === "secretary") {
      fetchSecretary();
    } else if (usertype.value === "userSecretary") {
      fetchUserSecretary();
    } else if (usertype.value == "userEnterprise") {
      fetchUserEnterprise();
    }
  } catch (error) {
    console.error("Erro ao carregar tipo de usuário:", error);
  }
};

const fetchSecretary = async () => {
  try {
    const response = await axios.get(`/secretaries/complete/${userId}`);
    let secretaryData = response.data;

    secretaryId.value = secretaryData.id;
    secretaryIsVerified.value = secretaryData.isverified;
    secretaryCompanyName.value = secretaryData.companyname;
    secretaryCorporateName.value = secretaryData.corporatename;
    secretaryTradeName.value = secretaryData.tradename;
    secretaryCnpj.value = secretaryData.cnpj;
    secretaryStateRegistration.value = secretaryData.stateregistration;
    secretaryAddress.value = secretaryData.address;
    secretaryState.value = secretaryData.state;
    secretaryPostalCode.value = secretaryData.postalcode;
    secretaryCity.value = secretaryData.city;
    secretaryLandlinePhone.value = secretaryData.landlinephone;
    secretaryCompanyEmail.value = secretaryData.companyemail;
    secretaryFullName.value = secretaryData.fullname;
    secretaryCellPhone.value = secretaryData.cellphone;
    secretaryCpf.value = secretaryData.cpf;
    secretaryPosition.value = secretaryData.position;
    secretaryEmail.value = secretaryData.email;
    secretaryAcceptsPcdPosition.value = secretaryData.acceptspcdposition;
    secretaryCompanyDescription.value = secretaryData.companydescription;
    secretarySector.value = secretaryData.sector;
    secretaryMission.value = secretaryData.mission;
    secretaryVision.value = secretaryData.vision;
    secretaryValues.value = secretaryData.values;
    secretaryNumberOfEmployees.value = secretaryData.numberofemployees;
    secretaryInstitutionalWebsite.value = secretaryData.institutionalwebsite;
    secretaryInstagram.value = secretaryData.instagram;
    secretaryCnpjCard.value = secretaryData.cnpjcard;
    userPhoto.value = secretaryData.imagepath;

    oldImage = secretaryData.imagepath;
    oldCardCnpj = secretaryData.cnpjcard;
    enterpriseCnpjCard.value = secretaryData.cnpjcard;
    otherFilesArrayFetch.value = secretaryData.otherFile;

    console.log("Secretaria recuperada com sucesso!", secretaryData);
    console.log(enterpriseEmail.value);
  } catch (error) {
    console.error(error);
  }
};

const fetchUserSecretary = async () => {
  try {
    let response = await axios.post("auth/me");
    let userSecretary = response.data.userSecretaries[0];
    let secretaryData = response.data.userSecretaries[0].secretary;

    secretaryId.value = secretaryData.id;
    secretaryIsVerified.value = secretaryData.isverified;
    secretaryCompanyName.value = secretaryData.companyname;
    secretaryCorporateName.value = secretaryData.corporatename;
    secretaryTradeName.value = secretaryData.tradename;
    secretaryCnpj.value = secretaryData.cnpj;
    secretaryStateRegistration.value = secretaryData.stateregistration;
    secretaryAddress.value = secretaryData.address;
    secretaryState.value = secretaryData.state;
    secretaryPostalCode.value = secretaryData.postalcode;
    secretaryCity.value = secretaryData.city;
    secretaryLandlinePhone.value = secretaryData.landlinephone;
    secretaryCompanyEmail.value = secretaryData.companyemail;

    secretaryFullName.value = userSecretary.fullname;
    secretaryCellPhone.value = userSecretary.cellphone;
    secretaryCpf.value = userSecretary.cpf;
    secretaryPosition.value = userSecretary.position;
    secretaryEmail.value = userSecretary.email;

    secretaryAcceptsPcdPosition.value = secretaryData.acceptspcdposition;
    secretaryCompanyDescription.value = secretaryData.companydescription;
    secretarySector.value = secretaryData.sector;
    secretaryMission.value = secretaryData.mission;
    secretaryVision.value = secretaryData.vision;
    secretaryValues.value = secretaryData.values;
    secretaryNumberOfEmployees.value = secretaryData.numberofemployees;
    secretaryInstitutionalWebsite.value = secretaryData.institutionalwebsite;
    secretaryInstagram.value = secretaryData.instagram;
    secretaryCnpjCard.value = secretaryData.cnpjcard;
    userPhoto.value = secretaryData.imagepath;
    oldImage = secretaryData.imagepath;
    oldCardCnpj = secretaryData.cnpjcard;
    enterpriseCnpjCard.value = secretaryData.cnpjcard;
    otherFilesArrayFetch.value = secretaryData.otherFile;
  } catch (error) {
    console.error(error);
  }
};

const fetchUserEnterprise = async () => {
  try {
    let response = await axios.post("auth/me");

    let userEnterprise = response.data.userenterprises[0];
    let enterpriseData = response.data.userenterprises[0].enterprise;

    enterpriseId.value = enterpriseData.id;
    enterpriseIsVerified.value = enterpriseData.isverified;
    enterpriseCompanyName.value = enterpriseData.companyname;
    enterpriseCorporateName.value = enterpriseData.corporatename;
    enterpriseTradeName.value = enterpriseData.tradename;
    enterpriseCnpj.value = enterpriseData.cnpj;
    enterpriseStateRegistration.value = enterpriseData.stateregistration;
    enterpriseAddress.value = enterpriseData.address;
    enterpriseState.value = enterpriseData.state;
    enterprisePostalCode.value = enterpriseData.postalcode;
    enterprisePostalNumber.value = enterpriseData.postalNumber;
    enterpriseCity.value = enterpriseData.city;
    enterpriseLandlinePhone.value = enterpriseData.landlinephone;
    enterpriseCompanyEmail.value = enterpriseData.companyemail;

    enterpriseFullName.value = userEnterprise.fullname;
    enterpriseCellPhone.value = userEnterprise.cellphone;
    enterpriseCpf.value = userEnterprise.cpf;
    enterprisePosition.value = userEnterprise.position;
    enterpriseEmail.value = userEnterprise.email;

    enterpriseAcceptsPcdPosition.value = enterpriseData.acceptsPcdPosition;
    enterpriseCompanyDescription.value = enterpriseData.companyDescription;
    enterpriseSector.value = enterpriseData.sector;
    enterpriseMission.value = enterpriseData.mission;
    enterpriseVision.value = enterpriseData.vision;
    enterpriseValues.value = enterpriseData.values;
    enterpriseNumberOfEmployees.value = enterpriseData.numberOfEmployees;
    enterpriseInstitutionalWebsite.value = enterpriseData.institutionalWebsite;
    enterpriseInstagram.value = enterpriseData.instagram;
    enterpriseCnpjCard.value = enterpriseData.cnpjcard;
    enterpriseSocialContract.value = enterpriseData.socialcontract;
    userPhoto.value = enterpriseData.imagepath;
    oldImage = enterpriseData.imagepath;
    oldCardCnpj = enterpriseData.cnpjcard;
    enterpriseCnpjCard.value = enterpriseData.cnpjcard;
    otherFilesArrayFetch.value = enterpriseData.otherFile;
  } catch (error) {
    console.error(error);
  }
};

const fetchAdmin = async () => {
  try {
    const response = await axios.get(`/admins/users/${userId}`);
    const adminData = response.data;
    admindateId.value = adminData.id;
    adminFullName.value = adminData.fullname;
    adminDateofbirth.value = adminData.dateofbirth;
    adminGender.value = adminData.gender;
    adminCpf.value = adminData.cpf;
    adminEmail.value = adminData.email;
    adminMaritalstatus.value = adminData.maritalstatus;
    adminSexualOrientation.value = adminData.sexualOrientation;
    adminGenderIdentity.value = adminData.genderIdentity;
    adminBirthplace.value = adminData.birthplace;
    adminOffice.value = adminData.office;
    adminSecretary.value = adminData.secretary;
    oldImage = adminData.imagepath;
    userPhoto.value = adminData.imagepath;
  } catch (error) {
    console.error("Erro ao carregar tipo de usuário:", error);
  }
};

const fetchCandidate = async () => {
  try {
    const response = await axios.get(`/candidates/by-user/${userId}`);
    const candidateData = response.data;
    userPhoto.value = candidateData.imagepath;
    oldImage = candidateData.imagepath;
    candidateId.value = candidateData.id;
    candidateFullName.value = candidateData.fullname;
    candidateCellPhone.value = candidateData.cellphone;
    candidateCpf.value = candidateData.cpf;
    candidateDateOfBirth.value = candidateData.dateofbirth;
    candidateGender.value = candidateData.gender;
    candidateGenderIdentity.value = candidateData.genderIdentity;
    candidateSexualOrientation.value = candidateData.sexualOrientation;
    candidateBirthplace.value = candidateData.birthplace;
    candidateMaritalStatus.value = candidateData.maritalstatus;
    candidateState.value = candidateData.state;
    candidateCity.value = candidateData.city;
    candidateEmail.value = candidateData.email;
    candidateEducationalLevel.value = candidateData.educationallevel;
    candidateBiography.value = candidateData.biography;
    photoPath.value = candidateData.imagepath;
    candidateSex.value = response.data.sex;
    candidateUniqueRegistration.value = response.data.uniqueRegistration;
    candidatePcd.value = response.data.pcd;
    candidateCid.value = response.data.cid;
    candidateZipCode.value = response.data.zipCode;
    candidateStreet.value = response.data.street;
    candidateNeighborhood.value = response.data.neighborhood;
    candidateNumberHouse.value = response.data.numberHouse;
    candidateComplement.value = response.data.complement;
    candidateSocialProgram.value = response.data.socialProgram;
    candidateInternship.value = response.data.internship;
    candidateIncomeLevel.value = response.data.incomeLevel;
    candidateNumberPeopleHome.value = response.data.numberPeopleHome;
    candidateTotalIncome.value = response.data.totalIncome;
    candidateEmploymentLink.value = response.data.employmentLink;
    // candidateAcademicBackground.value = response.data.academicBackground;
    candidateEducationInstitution.value = response.data.educationInstitution;
    candidateInstitutionType.value = response.data.institutionType;
    candidateCompletedCourses.value = response.data.completedCourses;
    candidateSkillsCompetencies.value = formateSkills(
      response.data.skillsCompetencies
    );
    candidateCoverLetter.value = response.data.coverLetter;
    candidateVideoPresentationPath.value = response.data.videoPresentationPath;
    candidateCardPresentationPath.value = response.data.resume;
    candidateDesiredJobTypes.value = response.data.desiredJobTypes;
    candidatePreferredGeographicLocation.value =
      response.data.preferredGeographicLocation;
    candidateExpectedSalary.value = response.data.expectedSalary;
    candidateSectorOfInterest.value = response.data.sectorOfInterest;
    // candidateSocialNetworks.value = response.data.socialNetworks;
    candidateLinkedin.value = response.data.linkedin;
    candidateInstagram.value = response.data.instagram;
    candidateFacebook.value = response.data.facebook;
    candidateXtwitter.value = response.data.xtwitter;
    candidateTiktok.value = response.data.tiktok;
    deficienceOnwed.value = candidateData.deficience;
    certificateOnwed.value = candidateData.certificate;
    candidateOldVideo.value = response.data.videoPresentationPath;
    console.log("Candidato carregado com sucesso:", candidateData);
    if (
      candidateVideoPresentationPath.value == null ||
      candidateVideoPresentationPath.value == undefined
    ) {
      candidateVideoPresentationPath.value = "";
    }
    if (
      candidateCardPresentationPath.value == null ||
      candidateCardPresentationPath.value == undefined
    ) {
      candidateCardPresentationPath.value = "";
    }
    if (deficienceOnwed.value.length != 0) {
      candidatePcd.value = "sim";
    }
  } catch (error) {
    console.error("Erro ao carregar candidato:", error);
  }
};

const formateSkills = (text: any) => {
  if (text) {
    const regex = /(?:-?sf_|-?hs_)/g;
    const textoFormatado = text.replace(regex, "\n");
    rows.value = textoFormatado.split("\n").length - 1;
    return textoFormatado;
  } else {
    return "";
  }
};

const fetchEnterprise = async () => {
  try {
    const response = await axios.get(`/enterprises/by-user/${userId}`);
    const enterpriseData = response.data;
    enterpriseId.value = enterpriseData.id;
    // enterpriseType.value = enterpriseData.type;
    enterpriseIsVerified.value = enterpriseData.isverified;
    // enterpriseSubType.value = enterpriseData.subtype;
    enterpriseCompanyName.value = enterpriseData.companyname;
    enterpriseCorporateName.value = enterpriseData.corporatename;
    enterpriseTradeName.value = enterpriseData.tradename;
    enterpriseCnpj.value = enterpriseData.cnpj;
    enterpriseStateRegistration.value = enterpriseData.stateregistration;
    enterpriseAddress.value = enterpriseData.address;
    enterpriseState.value = enterpriseData.state;
    enterprisePostalCode.value = enterpriseData.postalcode;
    enterprisePostalNumber.value = enterpriseData.postalNumber;
    enterpriseCity.value = enterpriseData.city;
    enterpriseLandlinePhone.value = enterpriseData.landlinephone;
    enterpriseCompanyEmail.value = enterpriseData.companyemail;
    enterpriseFullName.value = enterpriseData.fullname;
    enterpriseCellPhone.value = enterpriseData.cellphone;
    enterpriseCpf.value = enterpriseData.cpf;
    enterprisePosition.value = enterpriseData.position;
    enterpriseEmail.value = enterpriseData.email;
    enterpriseAcceptsPcdPosition.value = enterpriseData.acceptsPcdPosition;
    enterpriseCompanyDescription.value = enterpriseData.companyDescription;
    enterpriseSector.value = enterpriseData.sector;
    // culturalAndOrganizationalValues.value =
    //   enterpriseData.culturalAndOrganizationalValues;
    // missionAndVision.value = enterpriseData.missionAndVision;
    enterpriseMission.value = enterpriseData.mission;
    enterpriseVision.value = enterpriseData.vision;
    enterpriseValues.value = enterpriseData.values;
    enterpriseNumberOfEmployees.value = enterpriseData.numberOfEmployees;
    enterpriseInstitutionalWebsite.value = enterpriseData.institutionalWebsite;
    enterpriseInstagram.value = enterpriseData.instagram;
    enterpriseCnpjCard.value = enterpriseData.cnpjcard;
    enterpriseSocialContract.value = enterpriseData.socialcontract;
    userPhoto.value = enterpriseData.imagepath;
    oldImage = enterpriseData.imagepath;
    oldCardCnpj = enterpriseData.cnpjcard;
    enterpriseCnpjCard.value = enterpriseData.cnpjcard;
    otherFilesArrayFetch.value = enterpriseData.otherFile;

    console.log("Empresa carregada com sucesso:", enterpriseData);
  } catch (error) {
    console.error("Erro ao carregar empresa:", error);
  }
};

const handleAdminSubmit = async () => {
  try {
    const response = await axios.patch(`/admins/${admindateId.value}`, {
      fullname: adminFullName.value,
      cpf: adminCpf.value,
      dateofbirth: adminDateofbirth.value,
      gender: adminGender.value,
      genderIdentity: adminGenderIdentity.value,
      sexualOrientation: adminSexualOrientation.value,
      maritalstatus: adminMaritalstatus.value,
      birthplace: adminBirthplace.value,
      office: adminOffice.value,
      secretary: adminSecretary.value,
      email: adminEmail.value,
    });

    console.log({ response });

    console.log("Resposta da API: ", response.data);

    await handlePhotoSubmit();
    router.push("/perfil");
  } catch (error) {
    console.error(error);
    Swal.fire({
      title: "Erro",
      text: "Erro ao enviar os dados para a API. Por favor, tente novamente.",
      icon: "error",
      confirmButtonText: "OK",
    });

    console.error("Erro ao enviar os dados para a API:", error);
  } finally {
    Swal.fire({
      title: "Informações salvas",
      text: "Os dados foram salvos com sucesso.",
      icon: "success",
      confirmButtonText: "OK",
    });
  }
};

const deleteCertificate = async (file: any) => {
  try {
    let response = await axios.delete(`/certificate/file/${file}`);
  } catch (error) {
    console.error(error);
  } finally {
    fetchCandidate();
  }
};

const deleteDeficienceDocument = async (file: any) => {
  try {
    let response = await axios.delete(`/deficience/file/${file}`);
  } catch (error) {
    console.error(error);
  } finally {
    fetchCandidate();
  }
};

const sendDocDisability = async (payload: any) => {
  try {
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("arquivo", payload.file);
    let response = await axios.post("deficience", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const sendDocCertificate = async (payload: any) => {
  try {
    let formData = new FormData();
    formData.append("arquivo", payload);
    let response = await axios.post("certificate", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const sendVideoApresentation = async (video: any): Promise<any> => {
  try {
    let formData = new FormData();
    formData.append("arquivo", video);
    let response = await axios.post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.data.url) {
      await deleteOldFile(candidateOldVideo.value);
    }
    return response.data.url;
  } catch (error) {
    console.error(error);
  }
};

const deleteAtualVideo = () => {
  videoCandidate.value = "";
};
const deleteAtualCardPresentation = () => {
  cardPresentationFile.value = "";
};

const handleSubmit = async () => {
  try {
    let response: any;
    if (usertype.value === "candidate") {
      if (candidateDisability.value.length != 0) {
        candidateDisability.value.forEach((element: any) => {
          sendDocDisability(element);
        });
      }
      if (candidateCertificatePath.value.length != 0) {
        candidateCertificatePath.value.forEach((element: any) => {
          sendDocCertificate(element);
        });
      }
      if (videoCandidate.value != "") {
        candidateVideoPresentationPath.value = await sendVideoApresentation(
          videoCandidate.value
        );
      }
      if (cardPresentationFile.value != "") {
        candidateCardPresentationPath.value = await sendVideoApresentation(
          cardPresentationFile.value
        );
      }
      response = await axios.patch(`/candidates/${candidateId.value}`, {
        fullname: candidateFullName.value,
        cellphone: candidateCellPhone.value,
        cpf: candidateCpf.value,
        dateofbirth: candidateDateOfBirth.value,
        gender: candidateGender.value,
        genderIdentity: candidateGenderIdentity.value,
        sexualOrientation: candidateSexualOrientation.value,
        birthplace: candidateBirthplace.value,
        maritalstatus: candidateMaritalStatus.value,
        state: candidateState.value,
        city: candidateCity.value,
        email: candidateEmail.value,
        educationallevel: candidateEducationalLevel.value,
        biography: candidateBiography.value,
        sex: candidateSex.value,
        uniqueRegistration: candidateUniqueRegistration.value,
        pcd: candidatePcd.value,
        cid: candidateCid.value,
        zipCode: candidateZipCode.value,
        street: candidateStreet.value,
        neighborhood: candidateNeighborhood.value,
        numberHouse: candidateNumberHouse.value,
        complement: candidateComplement.value,
        socialProgram: candidateSocialProgram.value,
        internship: candidateInternship.value,
        incomeLevel: candidateIncomeLevel.value,
        numberPeopleHome: candidateNumberPeopleHome.value,
        totalIncome: candidateTotalIncome.value,
        employmentLink: candidateEmploymentLink.value,
        // academicBackground: candidateAcademicBackground.value,
        educationInstitution: candidateEducationInstitution.value,
        institutionType: candidateInstitutionType.value,
        completedCourses: candidateCompletedCourses.value,
        skillsCompetencies: candidateSkillsCompetencies.value,
        coverLetter: candidateCoverLetter.value,
        certificatePath: "",
        videoPresentationPath: candidateVideoPresentationPath.value,
        desiredJobTypes: candidateDesiredJobTypes.value,
        preferredGeographicLocation: candidatePreferredGeographicLocation.value,
        expectedSalary: candidateExpectedSalary.value,
        sectorOfInterest: candidateSectorOfInterest.value,
        // socialNetworks: candidateSocialNetworks.value,
        linkedin: candidateLinkedin.value,
        instagram: candidateInstagram.value,
        facebook: candidateFacebook.value,
        xtwitter: candidateXtwitter.value,
        tiktok: candidateTiktok.value,
        resume: candidateCardPresentationPath.value,
      });
      console.log("Resposta da API: ", response.data);
      Swal.fire({
        title: "Informações salvas",
        text: "Os dados foram salvos com sucesso.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        router.push("/desenvolvimento");
      });
    } else if (usertype.value === "enterprise") {
      if (arrayOtherFiles.value.length != 0) {
        arrayOtherFiles.value.forEach((element) => {
          updateOtherFiles(element);
        });
      }
      response = await axios.patch(`/enterprises/${enterpriseId.value}`, {
        // type: enterpriseType.value,
        // subtype: enterpriseSubType.value,
        companyname: enterpriseCompanyName.value,
        corporatename: enterpriseCorporateName.value,
        tradename: enterpriseTradeName.value,
        cnpj: enterpriseCnpj.value,
        stateregistration: enterpriseStateRegistration.value,
        address: enterpriseAddress.value,
        state: enterpriseState.value,
        postalcode: enterprisePostalCode.value,
        postalNumber: enterprisePostalNumber.value,
        city: enterpriseCity.value,
        landlinephone: enterpriseLandlinePhone.value,
        companyemail: enterpriseCompanyEmail.value,
        fullname: enterpriseFullName.value,
        cellphone: enterpriseCellPhone.value,
        cpf: enterpriseCpf.value,
        position: enterprisePosition.value,
        email: enterpriseEmail.value,
        acceptsPcdPosition: enterpriseAcceptsPcdPosition.value,
        companyDescription: enterpriseCompanyDescription.value,
        sector: enterpriseSector.value,
        // culturalAndOrganizationalValues: culturalAndOrganizationalValues.value,
        // missionAndVision: missionAndVision.value,
        mission: enterpriseMission.value,
        vision: enterpriseVision.value,
        values: enterpriseValues.value,
        numberOfEmployees: enterpriseNumberOfEmployees.value,
        institutionalWebsite: enterpriseInstitutionalWebsite.value,
        instagram: enterpriseInstagram.value,
        cnpjcard:
          oldCardCnpj != enterpriseCnpjCard.value
            ? await hadleCardCnpjEnterprise()
            : oldCardCnpj,
        socialcontract: enterpriseSocialContract.value,
      });

      if (enterpriseIsVerified.value) {
        Swal.fire({
          title: "Informações salvas",
          text: "Os dados foram salvos com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/perfil");
        });
      } else {
        Swal.fire({
          title: "Cadastro em análise",
          text: "Sua empresa foi cadastrada com sucesso. Por favor, aguarde a aprovação antes de começar a cadastrar vagas.",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      }
    } else if (usertype.value === "secretary") {
      if (arrayOtherFiles.value.length != 0) {
        arrayOtherFiles.value.forEach((element) => {
          updateOtherFilesSecretary(element);
        });
      }
      response = await axios.patch("/secretaries/update", {
        cnpj: secretaryCnpj.value,
        state: secretaryState.value,
        city: secretaryCity.value,
        position: secretaryPosition.value,
        email: secretaryEmail.value,
        companyname: secretaryCompanyName.value,
        corporatename: secretaryCorporateName.value,
        stateregistration: secretaryStateRegistration.value,
        postalcode: secretaryPostalCode.value,
        landlinephone: secretaryLandlinePhone.value,
        companyemail: secretaryCompanyEmail.value,
        fullname: secretaryFullName.value,
        cellphone: secretaryCellPhone.value,
        address: secretaryAddress.value,
        sector: secretarySector.value,
        instagram: secretaryInstagram.value,
        cpf: secretaryCpf.value,
        tradename: secretaryTradeName.value,
        mission: secretaryMission.value,
        vision: secretaryVision.value,
        values: secretaryValues.value,
        cnpjcard:
          oldCardCnpj != enterpriseCnpjCard.value
            ? await hadleCardCnpjEnterprise()
            : oldCardCnpj,
        numberofemployees: secretaryNumberOfEmployees.value,
        institutionalwebsite: secretaryInstitutionalWebsite.value,
        acceptspcdposition: secretaryAcceptsPcdPosition.value,
        companydescription: secretaryCompanyDescription.value,
      });
      if (response) {
        localStorage.setItem("companyName", secretaryCorporateName.value);
      }
      if (secretaryIsVerified.value) {
        Swal.fire({
          title: "Informações salvas",
          text: "Os dados foram salvos com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      } else {
        Swal.fire({
          title: "Cadastro em análise",
          text: "Sua empresa foi cadastrada com sucesso. Por favor, aguarde a aprovação antes de começar a cadastrar vagas.",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      }
      console.log("Dados atualizados", response);
    } else if (usertype.value === "userSecretary") {
      try {
        let payload = {
          fullname: secretaryFullName.value,
          cpf: secretaryCpf.value,
          email: secretaryEmail.value,
          cellphone: secretaryCellPhone.value,
        };
        let response = await axios.patch("/usersercretaries/update", payload);
        if (response) {
          localStorage.setItem("userName", secretaryFullName.value);
        }
      } catch (error) {
        console.error(error);
      } finally {
        Swal.fire({
          title: "Informações salvas",
          text: "Os dados foram salvos com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      }
    } else if (usertype.value === "userEnterprise") {
      try {
        let payload = {
          fullname: enterpriseFullName.value,
          cpf: enterpriseCpf.value,
          email: enterpriseEmail.value,
          cellphone: enterpriseCellPhone.value,
        };
        let response = await axios.patch("userenterprises/update", payload);
        if (response) {
          localStorage.setItem("userName", secretaryFullName.value);
        }
      } catch (error) {
        console.error(error);
      } finally {
        Swal.fire({
          title: "Informações salvas",
          text: "Os dados foram salvos com sucesso.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          router.push("/");
        });
      }
    }
  } catch (error) {
    Swal.fire({
      title: "Erro",
      text: "Erro ao enviar os dados para a API. Por favor, tente novamente.",
      icon: "error",
      confirmButtonText: "OK",
    });
    console.error("Erro ao enviar os dados para a API:", error);
  }
  await handlePhotoSubmit();
};

const addNewDocumentDisability = () => {
  conditionDisability.value = !conditionDisability.value;
};

const showInputFileCertificate = () => {
  conditionCertificate.value = !conditionCertificate.value;
};

const handleCancel = async () => {
  window.location.href = "/";
};

const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    selectedFile.value = file;
    userPhoto.value = URL.createObjectURL(file);
  }
};

const handlePhotoSubmit = async () => {
  if (selectedFile.value) {
    const formData = new FormData();
    formData.append("arquivo", selectedFile.value);
    try {
      const response = await axios.post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await deleteOldFile(oldImage);
      userPhoto.value = `${
        response.data.url
      }?timestamp=${new Date().getTime()}`;
      updatePhotoRegister(userPhoto.value);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Erro",
        text: "Erro ao enviar os dados para a API. Por favor, tente novamente.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};

//function delete old image of server disk
const deleteOldFile = async (file: string): Promise<any> => {
  if (file) {
    //replace string for only fileName
    const url = file.replace(/.*\//, "");
    try {
      const response = await axios.delete(`/files/img/${url}`);
    } catch (error) {
      console.error(error);
    }
  }
};

//function update imgpath on user enterprise or candidate
const updatePhotoRegister = async (photo: string) => {
  console.log(usertype.value);
  let route: string = "";
  let id: string = "";

  if (usertype.value == "enterprise") {
    id = enterpriseId.value;
    route = `/enterprises/${id}`;
  } else if (usertype.value == "candidate") {
    id = candidateId.value;
    route = `/candidates/${id}`;
  } else if (usertype.value == "admin") {
    id = admindateId.value;
    route = `/admins/${id}`;
  } else if (usertype.value == "secretary") {
    route = "/secretaries/update";
  }

  if (photo) {
    try {
      //update imagepath with a new photo
      const response = await axios.patch(route, {
        imagepath: photo,
      });
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.setItem("imagePath", photo);
    }
  }
};

const deletePhoto = async () => {
  // try {
  //   await axios.delete(`/files/${selectedFile.value?.filename}`);
  //   handlePhotoCancel();
  // } catch (error) {
  //   console.error(error);
  // }
};

const updatePhoto = () => {
  const fileInput = document.querySelector<HTMLInputElement>(
    '#FileUpload input[type="file"]'
  );
  if (fileInput) {
    fileInput.click();
  }
};

watch(enterpriseType, (newValue) => {
  if (newValue === "pri") {
    enterpriseSubType.value = "";
  }
});
watch(candidateState, () => {
  localStorage.setItem("candidateState", candidateState.value);
});

watch(enterpriseState, () => {
  localStorage.setItem("enterpriseState", enterpriseState.value);
});

const returnFile = () => {
  enterpriseCnpjCard.value = oldCardCnpj;
};

const deleteOtherFiles = async (file: any) => {
  try {
    await axios.delete(`other-files-enterprise/file/${file}`);
  } catch (error) {
    console.error(error);
  } finally {
    Swal.fire({
      title: "Informações salvas",
      text: "Os dados foram salvos com sucesso.",
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      window.location.reload();
    });
  }
};

const deleteOtherFilesSecretary = async (file: any) => {
  try {
    await axios.delete(`other-files-secretaries/file/${file}`);
  } catch (error) {
    console.error(error);
  } finally {
    Swal.fire({
      title: "Informações salvas",
      text: "Os dados foram salvos com sucesso.",
      icon: "success",
      confirmButtonText: "OK",
    }).then(() => {
      window.location.reload();
    });
  }
};

const openNewVideo = () => {
  conditionVideo.value = !conditionVideo.value;
};

const hadleCardCnpjEnterprise = async (): Promise<any> => {
  try {
    let formData = new FormData();
    formData.append("arquivo", enterpriseCnpjCard.value);
    let response = await axios.post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response) {
      await deleteOldFile(oldCardCnpj);
      return response.data.url;
    }
  } catch (error) {
    console.error(error);
  }
};

const updateOtherFiles = async (file: any): Promise<any> => {
  try {
    let formData = new FormData();
    formData.append("arquivo", file);
    let response = await axios.post("other-files-enterprise", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateOtherFilesSecretary = async (file: any): Promise<any> => {
  try {
    let formData = new FormData();
    formData.append("arquivo", file);
    let response = await axios.post("other-files-secretaries", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const openNewOtherFilesEnterprise = () => {
  openOtherFiles.value = !openOtherFiles.value;
};

const selectOtherFile = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    otherFileEnterprise.value = file;
  }
};

const saveOtherFile = () => {
  if (otherFileEnterprise.value != null && nameOhetFile.value != "") {
    let modifiedFile = new File(
      [otherFileEnterprise.value],
      nameOhetFile.value + ".pdf",
      {
        type: otherFileEnterprise.value.type,
      }
    );
    arrayOtherFiles.value.push(modifiedFile);
    openNewOtherFilesEnterprise();
  } else {
    Swal.fire({
      title: "Campos Vazios",
      text: "Necessário selecionar o arquivo e digitar o nome escolhido.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
  otherFileEnterprise.value = "";
  nameOhetFile.value = "";
};

const openFilePdf = (filePath: string) => {
  window.open(filePath, "_blank");
};

const sendFileCertificate = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    fileCertificate.value = file;
  }
};

const setVideo = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    videoCandidate.value = file;
  }
};

const setCardPresentation = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    cardPresentationFile.value = file;
  }
};

const addNewFileCertificate = () => {
  if (
    fileCertificate.value != null &&
    fileCertificate.value != undefined &&
    fileCertificate.value != ""
  ) {
    candidateCertificatePath.value.push(fileCertificate.value);
    fileCertificate.value = "";
    showInputFileCertificate();
  } else {
    Swal.fire({
      title: "Dados incompletos",
      text: "Preencher todos os campos para salvar documento.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

const saveDeficience = () => {
  let data = {
    file: candidateFileDeficience.value,
    name: candidateNameDisability.value,
  };
  if (data.name != "" && data.file != "") {
    candidateDisability.value.push(data);
    data = {
      file: "",
      name: "",
    };
    candidateFileDeficience.value = "";
    candidateNameDisability.value = "";
    conditionDisability.value = !conditionDisability.value;
  } else {
    Swal.fire({
      title: "Dados incompletos",
      text: "Preencher todos os campos para salvar documento.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

const selectFileDeficience = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    candidateFileDeficience.value = file;
  }
};

const removeOtherFile = (i: number) => {
  arrayOtherFiles.value.splice(i, 1);
};

const removeFileDisability = (i: number) => {
  candidateDisability.value.splice(i, 1);
};

const removeFileCertificate = (i: number) => {
  candidateCertificatePath.value.splice(i, 1);
};

const selectEnterpriseCardCnpj = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    enterpriseCnpjCard.value = file;
  }
};

const removeEnterpriseCnpjCard = () => {
  enterpriseCnpjCard.value = null;
};
</script>

<template>
  <div class="grid grid-cols-5 gap-8 lg:mb-18 sm:mb-2">
    <div class="col-span-5 xl:col-span-2">
      <div
        class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
      >
        <div class="border-b border-stroke py-4 px-7 dark:border-strokedark">
          <div v-if="usertype === 'candidate' || usertype === 'admin'">
            <h3 class="font-medium text-black dark:text-white">
              Foto de perfil
            </h3>
          </div>
          <div
            v-if="
              usertype === 'enterprise' ||
              usertype === 'secretary' ||
              usertype === 'userSecretary' ||
              usertype === 'userEnterprise'
            "
          >
            <h3 class="font-medium text-black dark:text-white">
              Sua logomarca
            </h3>
          </div>
        </div>

        <div class="p-7">
          <div v-if="usertype === 'admin'">
            <form @submit.prevent="handlePhotoSubmit">
              <div class="mb-4 flex items-center gap-3">
                <div class="h-14 w-14 rounded-full">
                  <img
                    v-if="userPhoto"
                    class="h-14 w-14 rounded-full"
                    :src="userPhoto"
                    alt="User"
                  />
                  <img
                    v-else
                    class="h-14 w-14 rounded-full"
                    :src="userPhotoDefault"
                    alt="Default User"
                  />
                </div>
                <div>
                  <span class="mb-1.5 font-medium text-black dark:text-white"
                    >Edite sua foto</span
                  >
                  <span class="flex gap-2.5">
                    <button
                      class="text-sm font-medium hover:text-primary"
                      @click="deletePhoto"
                    >
                      Deletar
                    </button>
                    <button
                      class="text-sm font-medium hover:text-primary"
                      @click="updatePhoto"
                    >
                      Alterar
                    </button>
                  </span>
                </div>
              </div>

              <div
                id="FileUpload"
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
              >
                <input
                  type="file"
                  accept="image/*"
                  required
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  @change="handleFileChange"
                />
                <div
                  class="flex flex-col items-center justify-center space-y-3"
                >
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0"
                      />
                    </svg>
                  </span>
                  <p class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p class="mt-1.5 text-sm font-medium">SVG, PNG, JPG ou GIF</p>
                  <p class="text-sm font-medium">(max, 800 X 800px)</p>
                </div>
              </div>
            </form>
            <div v-if="showToUser">
              <ChangePassword />
            </div>
          </div>

          <div v-if="usertype === 'candidate'">
            <form @submit.prevent="handlePhotoSubmit">
              <!-- User Photo Section -->
              <div class="mb-4 flex items-center gap-3">
                <div class="h-14 w-14 rounded-full">
                  <img
                    v-if="userPhoto"
                    class="h-14 w-14 rounded-full"
                    :src="userPhoto"
                    alt="User"
                  />
                  <img
                    v-else
                    class="h-14 w-14 rounded-full"
                    :src="userPhotoDefault"
                    alt="Default User"
                  />
                </div>
                <div>
                  <span class="mb-1.5 font-medium text-black dark:text-white"
                    >Edite sua foto</span
                  >
                  <span class="flex gap-2.5">
                    <button
                      class="text-sm font-medium hover:text-primary"
                      @click="deletePhoto"
                    >
                      Deletar
                    </button>
                    <button
                      class="text-sm font-medium hover:text-primary"
                      @click="updatePhoto"
                    >
                      Alterar
                    </button>
                  </span>
                </div>
              </div>

              <!-- File Upload Section -->
              <div
                id="FileUpload"
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
              >
                <input
                  type="file"
                  accept="image/*"
                  required
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  @change="handleFileChange"
                />
                <div
                  class="flex flex-col items-center justify-center space-y-3"
                >
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0"
                      />
                    </svg>
                  </span>
                  <p class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p class="mt-1.5 text-sm font-medium">SVG, PNG, JPG ou GIF</p>
                  <p class="text-sm font-medium">(max, 800 X 800px)</p>
                </div>
              </div>
            </form>
            <div v-if="showToUser">
              <changePassword />
            </div>
          </div>

          <div v-if="usertype === 'enterprise' || usertype == 'userEnterprise'">
            <form @submit.prevent="handlePhotoSubmit">
              <!-- User Photo Section -->
              <div class="mb-4 flex items-center gap-3">
                <div class="h-14 w-14 rounded-full">
                  <img
                    v-if="userPhoto"
                    class="h-14 w-14 rounded-full"
                    :src="userPhoto"
                    alt="User"
                  />
                  <img
                    v-else
                    class="h-14 w-14 rounded-full"
                    :src="userPhotoDefault"
                    alt="Default User"
                  />
                </div>
                <div>
                  <span class="mb-1.5 font-medium text-black dark:text-white"
                    >Edite sua logomarca</span
                  >
                  <span class="flex gap-2.5">
                    <button
                      :disabled="usertype == 'userEnterprise'"
                      class="text-sm font-medium hover:text-primary"
                      @click="deletePhoto"
                    >
                      Deletar
                    </button>
                    <button
                      :disabled="usertype == 'userEnterprise'"
                      class="text-sm font-medium hover:text-primary"
                      @click="updatePhoto"
                    >
                      Alterar
                    </button>
                  </span>
                </div>
              </div>

              <div
                id="FileUpload"
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
              >
                <input
                  :disabled="usertype == 'userEnterprise'"
                  type="file"
                  accept="image/*"
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  @change="handleFileChange"
                  required
                />

                <div
                  class="flex flex-col items-center justify-center space-y-3"
                >
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0"
                      />
                    </svg>
                  </span>
                  <p class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p class="mt-1.5 text-sm font-medium">SVG, PNG, JPG ou GIF</p>
                  <p class="text-sm font-medium">(max, 800 X 800px)</p>
                </div>
              </div>
              <div v-if="showToUser">
                <ChangePassword />
              </div>
            </form>
          </div>

          <div v-if="usertype === 'secretary' || usertype === 'userSecretary'">
            <form @submit.prevent="handlePhotoSubmit">
              <!-- User Photo Section -->
              <div class="mb-4 flex items-center gap-3">
                <div class="h-14 w-14 rounded-full">
                  <img
                    v-if="userPhoto"
                    class="h-14 w-14 rounded-full"
                    :src="userPhoto"
                    alt="User"
                  />
                  <img
                    v-else
                    class="h-14 w-14 rounded-full"
                    :src="userPhotoDefault"
                    alt="Default User"
                  />
                </div>

                <div>
                  <span class="mb-1.5 font-medium text-black dark:text-white"
                    >Edite sua logomarca</span
                  >
                  <span class="flex gap-2.5">
                    <button
                      :disabled="usertype == 'userSecretary'"
                      class="text-sm font-medium hover:text-primary"
                      @click="deletePhoto"
                    >
                      Deletar
                    </button>
                    <button
                      :disabled="usertype == 'userSecretary'"
                      class="text-sm font-medium hover:text-primary"
                      @click="updatePhoto"
                    >
                      Alterar
                    </button>
                  </span>
                </div>
              </div>

              <div
                id="FileUpload"
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
              >
                <input
                  :disabled="usertype == 'userSecretary'"
                  type="file"
                  accept="image/*"
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  @change="handleFileChange"
                  required
                />

                <div
                  class="flex flex-col items-center justify-center space-y-3"
                >
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0"
                      />
                    </svg>
                  </span>
                  <p class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p class="mt-1.5 text-sm font-medium">SVG, PNG, JPG ou GIF</p>
                  <p class="text-sm font-medium">(max, 800 X 800px)</p>
                </div>
              </div>
            </form>
            <div v-if="showToUser">
                <ChangePassword />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-5 xl:col-span-3">
      <div
        class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
      >
        <div class="border-b border-stroke py-4 px-7 dark:border-strokedark">
          <h3 class="font-medium text-black dark:text-white">
            Informe seus dados
          </h3>
        </div>
        <div class="p-7">
          <!-- Formulário de Candidato -->
          <div v-if="usertype === 'candidate'">
            <form @submit.prevent="handleSubmit">
              <div class="mb-[20px]">Dados pessoais</div>
              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="name"
                  >Nome completo</label
                >
                <div class="relative">
                  <span class="absolute left-4.5 top-4">
                    <svg
                      class="fill-current"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                          fill=""
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    v-model="candidateFullName"
                    class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Digite aqui seu nome"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="cpf"
                    >CPF</label
                  >
                  <input
                    required
                    v-model="candidateCpf"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="cpf"
                    id="cpf"
                    placeholder="000.000.000-10"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="phone"
                    >Data de nascimento</label
                  >
                  <input
                    v-model="candidateDateOfBirth"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="date"
                    name="phone"
                    id="phone"
                    placeholder="12/12/1999"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="gender"
                    >Gênero</label
                  >
                  <select
                    v-model="candidateGender"
                    class="w-full rounded border border-stroke bg-gray py-2 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="gender"
                    id="gender"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                    <option value="nao-binario">Não-binárie</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateGenderIdentity"
                    >Identidade de Gênero</label
                  >
                  <select
                    v-model="candidateGenderIdentity"
                    class="w-full rounded border border-stroke bg-gray py-2 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateGenderIdentity"
                    id="candidateGenderIdentity"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="cisgenero">Cisgênero</option>
                    <option value="transgênero/transexual/travesti">
                      Transgênero/Transexual/Travesti
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateSexualOrientation"
                    >Orientação Sexual</label
                  >
                  <select
                    v-model="candidateSexualOrientation"
                    class="w-full rounded border border-stroke bg-gray py-2 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateSexualOrientation"
                    id="candidateSexualOrientation"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="assexual">Assexual</option>
                    <option value="bissexual">Bissexual</option>
                    <option value="heterossexual">Heterossexual</option>
                    <option value="homosexual">Homossexual</option>
                    <option value="pansexual">Pansexual</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateBirthplace"
                    >Naturalidade</label
                  >
                  <input
                    v-model="candidateBirthplace"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateBirthplace"
                    id="candidateBirthplace"
                    placeholder="Ex: Teresinense"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="civil"
                    >Estado civil</label
                  >
                  <select
                    v-model="candidateMaritalStatus"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="civil"
                    id="civil"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="solteiro">Solteiro(a)</option>
                    <option value="casado">Casado(a)</option>
                    <option value="divorciado">Divorciado(a)</option>
                    <option value="viuvo">Viúvo(a)</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateUniqueRegistration"
                    >Cadastro Único</label
                  >
                  <input
                    v-model="candidateUniqueRegistration"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateUniqueRegistration"
                    id="candidateUniqueRegistration"
                    placeholder="Digite seu Cadastro Único"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateCid"
                    >CID</label
                  >
                  <input
                    v-model="candidateCid"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateCid"
                    id="candidateCid"
                    placeholder="Digite o código CID"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidatePcd"
                    >Candidato PCD?
                  </label>
                  <select
                    v-model="candidatePcd"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidatePcd"
                    id="candidatePcd"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>

              <label
                v-if="
                  (deficienceOnwed && deficienceOnwed.length != 0) ||
                  candidateDisability.length != 0
                "
                class="mb-3 block text-sm font-medium text-black dark:text-white"
                for="candidatePcdCProofPath"
                >Laudo médico</label
              >

              <div v-if="deficienceOnwed.length != 0">
                <div
                  class="flex justify-between mb-2"
                  v-for="(item, index) in deficienceOnwed"
                  :key="index"
                >
                  <div
                    type="text"
                    class="w-[90%] cursor-pointer rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                    @click="openFilePdf(item.docpath)"
                    disabled
                  >
                    {{ item.name }}
                  </div>

                  <div
                    class="p-2 rounded bg-red text-white button-save-disability"
                  >
                    <img
                      @click="deleteDeficienceDocument(item.filename)"
                      class="mt-[15%]"
                      src="/src/assets/images/icon/trash.svg"
                    />
                  </div>
                </div>
              </div>
              <div v-if="candidateDisability.length != 0">
                <div
                  class="flex justify-between mb-2"
                  v-for="(item, index) in candidateDisability"
                  :key="index"
                >
                  <div
                    type="text"
                    class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                  >
                    {{ item.name + " - Documento: " + item.file.name }}
                  </div>

                  <div
                    class="ml-[1%] p-2 rounded bg-red text-white button-save-disability"
                  >
                    <img
                      @click="removeFileDisability(index)"
                      class="mt-[15%]"
                      src="/src/assets/images/icon/trash.svg"
                    />
                  </div>
                </div>
              </div>

              <div v-if="candidatePcd == 'sim' && conditionDisability">
                <label
                  v-if="conditionDisability"
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateNameDisability"
                  >Tipo de deficiência
                </label>
                <div v-if="conditionDisability" class="mb-5.5 flex flex-col">
                  <div class="w-full">
                    <select
                      v-model="candidateNameDisability"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      name="candidateNameDisability"
                      id="candidateNameDisability"
                    >
                      <option disabled selected value="">Selecione</option>
                      <option value="auditiva">Auditiva</option>
                      <option value="autismo">Autismo</option>
                      <option value="fisica">Fisica</option>
                      <option value="intelectual">Intelectual</option>
                      <option value="visual">Visual</option>
                      <option value="outros">Outro</option>
                    </select>
                  </div>
                </div>

                <div v-if="conditionDisability" class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidatePcdCProofPath"
                    >Laudo médico, se houver</label
                  >
                  <div
                    class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                  >
                    <input
                      @change="selectFileDeficience"
                      name="candidatePcdCProofPath"
                      type="file"
                      accept="application/pdf"
                      id="candidatePcdCProofPath"
                      class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    />
                    <div
                      class="flex flex-col items-center justify-center space-y-3"
                    >
                      <span
                        class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                            fill="#3C50E0"
                          />
                        </svg>
                      </span>
                      <div
                        v-if="candidateFileDeficience == ''"
                        class="text-center"
                      >
                        <p class="text-sm font-medium">
                          <span class="text-primary"
                            >Clique para fazer upload</span
                          >
                          ou arraste e solte
                        </p>
                        <p class="mt-1.5 text-sm font-medium">
                          PDF, JPG ou PNG
                        </p>
                      </div>
                      <div v-if="candidateFileDeficience != ''">
                        <p>{{ candidateFileDeficience.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end">
                  <div
                    v-if="conditionDisability"
                    type="none"
                    class="rounded bg-primary p-2 text-white button-save-disability"
                    @click="saveDeficience"
                  >
                    Salvar
                  </div>
                </div>
              </div>

              <div
                v-if="candidatePcd == 'sim'"
                class="flex justify-center mt-3"
              >
                <div
                  @click="addNewDocumentDisability"
                  :class="
                    !conditionDisability
                      ? 'w-[40px] text-center h-[40px] bg-primary rounded-full text-white button-save-disability'
                      : 'w-[40px] text-center h-[40px] bg-red rounded-full text-white button-save-disability'
                  "
                >
                  <p class="mt-[17%]">
                    {{ conditionDisability ? "X" : "+" }}
                  </p>
                </div>
              </div>

              <!-- Contact section -->
              <div class="mb-[20px] mt-[30px]">Contato</div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="phone"
                    >Celular</label
                  >
                  <input
                    v-model="candidateCellPhone"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="(99) 99999.9999"
                  />
                </div>
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="email"
                    >E-mail</label
                  >
                  <div class="relative">
                    <span class="absolute left-4.5 top-4">
                      <svg
                        class="fill-current"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                            fill=""
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      v-model="candidateEmail"
                      :disabled="true"
                      title="No momento não é possivel alterar esse campo"
                      class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary cursor-not-allowed opacity-50"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="email@gmail.com"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateZipCode"
                    >CEP</label
                  >
                  <input
                    v-model="candidateZipCode"
                    @change="fetchAddress"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateZipCode"
                    id="candidateZipCode"
                    placeholder="00000-000"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateStreet"
                    >Logradouro</label
                  >
                  <input
                    v-model="candidateStreet"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateStreet"
                    id="candidateStreet"
                    placeholder="Digite sua rua"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateNeighborhood"
                    >Bairro</label
                  >
                  <input
                    v-model="candidateNeighborhood"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateNeighborhood"
                    id="candidateNeighborhood"
                    placeholder="Digite seu bairro"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateNumberHouse"
                    >Número</label
                  >
                  <input
                    v-model="candidateNumberHouse"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateNumberHouse"
                    id="candidateNumberHouse"
                    placeholder="Digite o número da casa"
                  />
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateComplement"
                  >Complemento</label
                >
                <input
                  v-model="candidateComplement"
                  class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  type="text"
                  name="candidateComplement"
                  id="candidateComplement"
                  placeholder="Digite o complemento"
                />
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    for="candidateState"
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    >Estado</label
                  >
                  <select
                    v-model="candidateState"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateState"
                    id="candidateState"
                    @change="getCandidatesCities"
                  >
                    <option disabled selected value="">Selecione</option>
                    <!-- Render options dynamically from JSON data -->
                    <option
                      v-for="(state, sigla) in statesCandidate"
                      :key="sigla"
                      :value="sigla"
                    >
                      {{ state.nome }}
                    </option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    for="candidateCity"
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    >Cidade</label
                  >
                  <select
                    v-model="candidateCity"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateCity"
                    id="candidateCity"
                  >
                    <option disabled selected value="">
                      Selecione uma cidade
                    </option>
                    <!-- Render cities based on selected state -->
                    <option
                      v-for="city in selectedCandidatesCities"
                      :key="city"
                    >
                      {{ city }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateSocialProgram"
                    >Já participa (ou) de algum programa social?</label
                  >
                  <select
                    v-model="candidateSocialProgram"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateSocialProgram"
                    id="candidateSocialProgram"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateInternship"
                    >Já fez algum estágio?</label
                  >
                  <select
                    v-model="candidateInternship"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateInternship"
                    id="candidateInternship"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateIncomeLevel"
                    >Possui alguma renda? qual?</label
                  >
                  <select
                    v-model="candidateIncomeLevel"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateIncomeLevel"
                    id="candidateIncomeLevel"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="660">R$ até 660,00</option>
                    <option value="1320">R$ até 1.320,00</option>
                    <option value="1980">R$ até 1.980,00</option>
                    <option value="2640">R$ até 2.640,00</option>
                    <option value="3960">R$ até 3.960,00 ou mais</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateNumberPeopleHome"
                    >Quantas pessoas moram com você?</label
                  >
                  <select
                    v-model="candidateNumberPeopleHome"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateNumberPeopleHome"
                    id="candidateNumberPeopleHome"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4 ou mais</option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateTotalIncome"
                    >Renda total da família</label
                  >
                  <select
                    v-model="candidateTotalIncome"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateTotalIncome"
                    id="candidateTotalIncome"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="660">R$ até 660,00</option>
                    <option value="1320">R$ até 1.320,00</option>
                    <option value="1980">R$ até 1.980,00</option>
                    <option value="2640">R$ até 2.640,00</option>
                    <option value="3960">R$ até 3.960,00 ou mais</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateEmploymentLink"
                    >Já teve algum vínculo empregatício?</label
                  >
                  <select
                    v-model="candidateEmploymentLink"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateEmploymentLink"
                    id="candidateEmploymentLink"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
              </div>

              <!-- Section Graduation -->
              <div class="mb-[20px] mt-[30px]">Formação acadêmica</div>
              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateEducationInstitution"
                    >Instituição de ensino</label
                  >
                  <input
                    v-model="candidateEducationInstitution"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateEducationInstitution"
                    id="candidateEducationInstitution"
                    placeholder="Digite a sua instituição"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateInstitutionType"
                    >Tipo de Instituição</label
                  >
                  <select
                    v-model="candidateInstitutionType"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateInstitutionType"
                    id="candidateInstitutionType"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="publica">Pública</option>
                    <option value="privada">Privada</option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="Username"
                  >Grau de ensino</label
                >
                <select
                  v-model="candidateEducationalLevel"
                  class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="civil"
                  id="civil"
                >
                  <option disabled selected value="">Selecione</option>
                  <option value="fundamental">Fundamental</option>
                  <option value="medio">Médio</option>
                  <option value="superior">Superior (Graduação)</option>
                  <option value="posgraducao">Pós-graduação</option>
                  <option value="mestrado">Mestrado</option>
                  <option value="doutorado">Doutorado</option>
                </select>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateCompletedCourses"
                  >Cursos Realizados</label
                >
                <textarea
                  v-model="candidateCompletedCourses"
                  class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="candidateCompletedCourses"
                  id="candidateCompletedCourses"
                  rows="3"
                  placeholder="Cite os seus cursos realizados"
                ></textarea>
              </div>

              <div class="mb-5.5" v-if="candidateSkillsCompetencies != ''">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateSkillsCompetencies"
                  >Habilidades e competências</label
                >
                <textarea
                  v-model="candidateSkillsCompetencies"
                  disabled
                  class="w-full pt-0 rounded border border-stroke bg-gray-200 cursor-not-allowed opacity-50 py-3 px-4.5 font-normal text-black focus-visible:outline-none dark:border-strokedark dark:bg-gray-700 dark:text-white dark:opacity-50"
                  name="candidateSkillsCompetencies"
                  id="candidateSkillsCompetencies"
                  :rows="rows"
                  placeholder="Suas habilidades"
                  title="Você pode alterar essas informações em Desenvolvimento Profissional"
                ></textarea>
              </div>

              <!-- Education section -->
              <div class="mb-[20px] mt-[30px]">Documentação</div>
              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateCertificatePath"
                  >Certificados de Qualificação</label
                >
                <div v-if="certificateOnwed.length != 0">
                  <div
                    class="flex justify-between mb-2"
                    v-for="(item, index) in certificateOnwed"
                    :key="index"
                  >
                    <div
                      type="text"
                      class="w-[90%] cursor-pointer rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                      @click="openFilePdf(item.docpath)"
                    >
                      {{ item.filename }}
                    </div>

                    <div
                      class="ml-[1%] p-2 rounded bg-red text-white button-save-disability"
                    >
                      <img
                        @click="deleteCertificate(item.filename)"
                        class="mt-[15%]"
                        src="/src/assets/images/icon/trash.svg"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="conditionCertificate"
                  class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                >
                  <input
                    @change="sendFileCertificate($event)"
                    name="candidateCertificatePath"
                    type="file"
                    accept="application/pdf"
                    id="candidateCertificatePath"
                    class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  />
                  <div
                    class="flex flex-col items-center justify-center space-y-3"
                  >
                    <span
                      class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                          fill="#3C50E0"
                        />
                      </svg>
                    </span>
                    <div v-if="fileCertificate == ''" class="text-center">
                      <p class="text-sm font-medium">
                        <span class="text-primary"
                          >Clique para fazer upload</span
                        >
                        ou arraste e solte
                      </p>
                      <p class="mt-1.5 text-sm font-medium">PDF, JPG ou PNG</p>
                    </div>
                    <div v-if="fileCertificate != ''" class="text-center">
                      <p>{{ fileCertificate.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="candidateCertificatePath.length != 0">
                <div
                  class="flex justify-between mb-2"
                  v-for="(item, index) in candidateCertificatePath"
                  :key="index"
                >
                  <div
                    type="text"
                    class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                  >
                    {{ item.name }}
                  </div>

                  <div
                    class="ml-[1%] p-2 rounded bg-red text-white button-save-disability"
                  >
                    <img
                      @click="removeFileCertificate(index)"
                      class="mt-[15%]"
                      src="/src/assets/images/icon/trash.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="flex justify-center mt-3">
                <div
                  @click="showInputFileCertificate"
                  :class="
                    !conditionCertificate
                      ? 'w-[40px] text-center h-[40px] bg-primary rounded-full text-white button-save-disability'
                      : 'w-[40px] text-center h-[40px] bg-red rounded-full text-white button-save-disability'
                  "
                >
                  <p class="mt-[17%]">
                    {{ conditionCertificate ? "X" : "+" }}
                  </p>
                </div>
              </div>
              <div v-if="conditionCertificate" class="flex justify-end">
                <div
                  @click="addNewFileCertificate"
                  class="p-3 rounded bg-primary text-white button-save-disability"
                >
                  Salvar
                </div>
              </div>

              <div class="mb-[20px] mt-[30px]">Emprego</div>
              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateDesiredJobTypes"
                    >Tipos de vagas desejadas</label
                  >
                  <select
                    v-model="candidateDesiredJobTypes"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateDesiredJobTypes"
                    id="candidateDesiredJobTypes"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="híbrido">Híbrido</option>
                    <option value="presencial">Presencial</option>
                    <option value="home-office">Home Office</option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateExpectedSalary"
                    >Salário esperado</label
                  >
                  <select
                    v-model="candidateExpectedSalary"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="candidateExpectedSalary"
                    id="candidateExpectedSalary"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="1412-2000">R$ 1,412 - R$ 2000</option>
                    <option value="2000-3000">R$ 2.000 - R$ 3.000</option>
                    <option value="3000-4000">R$ 3.000 - R$ 4.000</option>
                    <option value="4000-5000">R$ 4.000 - R$ 5.000</option>
                    <option value="5000-6000">R$ 5.000 - R$ 6.000</option>
                    <option value="6000+">Acima de R$ 6.000</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateSectorOfInterest"
                    >Área de interesse</label
                  >
                  <select
                    v-model="candidateSectorOfInterest"
                    class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                    name="candidateSectorOfInterest"
                    id="candidateSectorOfInterest"
                  >
                    <option value="">Selecione</option>
                    <option
                      v-for="area in areas"
                      :key="area.id_area"
                      :value="area.id_area"
                    >
                      {{ area.area_description }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="bio"
                  >Biografia</label
                >
                <div class="relative">
                  <span class="absolute left-4.5 top-4">
                    <svg
                      class="fill-current"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8" clip-path="url(#clip0_88_10224)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                          fill=""
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                          fill=""
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_88_10224">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <textarea
                    v-model="candidateBiography"
                    class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="bio"
                    id="bio"
                    rows="6"
                    placeholder="Descreva sobre você..."
                  ></textarea>
                </div>
              </div>

              <div class="mb-5.5 mt-5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateCardPresentationPath"
                  >Carta de apresentação</label
                >

                <div v-if="candidateCardPresentationPath == ''">
                  <div
                    class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                  >
                    <input
                      @change="setCardPresentation($event)"
                      name="candidateCardPresentationPath"
                      type="file"
                      accept=".pdf"
                      id="candidateCardPresentationPath"
                      class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    />
                    <div
                      class="flex flex-col items-center justify-center space-y-3"
                    >
                      <span
                        class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                            fill="#3C50E0"
                          />
                        </svg>
                      </span>
                      <div v-if="cardPresentationFile == ''">
                        <p class="text-center text-center font-medium">
                          <span class="text-primary"
                            >Clique para fazer upload</span
                          >
                          ou arraste e solte
                        </p>
                        <p class="mt-1.5 text-sm text-center font-medium">
                          Apenas PDF
                        </p>
                      </div>
                      <div v-if="cardPresentationFile != ''">
                        {{ cardPresentationFile.name }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="cardPresentationFile != ''"
                    class="flex justify-center"
                  >
                    <div
                      class="bg-red p-3 text-white rounded cursor-pointer"
                      @click="deleteAtualCardPresentation"
                    >
                      Apagar
                    </div>
                  </div>
                </div>

                <div v-if="candidateCardPresentationPath != ''">
                  <div class="flex justify-between mb-2">
                    <div
                      type="text"
                      class="w-[90%] cursor-pointer rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                      @click="openFilePdf(candidateCardPresentationPath)"
                    >
                      {{ candidateCardPresentationPath }}
                    </div>

                    <div
                      class="ml-[1%] p-2 rounded bg-red text-white button-save-disability"
                    >
                      <img
                        @click="
                          deleteCardPresentationPath(
                            candidateCardPresentationPath
                          )
                        "
                        class="mt-[15%]"
                        src="/src/assets/images/icon/trash.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-5.5 mt-5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="candidateVideoPresentationPath"
                  >Video de apresentação</label
                >

                <div v-if="candidateVideoPresentationPath == ''">
                  <div
                    class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                  >
                    <input
                      @change="setVideo($event)"
                      name="candidateVideoPresentationPath"
                      type="file"
                      accept="video/*"
                      id="candidateVideoPresentationPath"
                      class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    />
                    <div
                      class="flex flex-col items-center justify-center space-y-3"
                    >
                      <span
                        class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                            fill="#3C50E0"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                            fill="#3C50E0"
                          />
                        </svg>
                      </span>
                      <div v-if="videoCandidate == ''">
                        <p class="text-center font-medium">
                          <span class="text-primary"
                            >Clique para fazer upload</span
                          >
                          ou arraste e solte
                        </p>
                        <p class="mt-1.5 text-sm text-center font-medium">
                          MP4, 3GP ou AVI
                        </p>
                      </div>
                      <div v-if="videoCandidate != ''">
                        {{ videoCandidate.name }}
                      </div>
                    </div>
                  </div>
                  <div v-if="videoCandidate != ''" class="flex justify-center">
                    <div
                      class="bg-red p-3 text-white rounded cursor-pointer"
                      @click="deleteAtualVideo"
                    >
                      Apagar
                    </div>
                  </div>
                </div>

                <div v-if="candidateVideoPresentationPath != ''">
                  <iframe
                    :src="candidateVideoPresentationPath"
                    class="w-[100%] h-[40vh]"
                  ></iframe>

                  <div class="flex justify-center">
                    <div
                      @click="deleteVideoPath(candidateVideoPresentationPath)"
                      class="p-3 cursor-pointer pt-2 text-center rounded mt-3 bg-red text-white"
                    >
                      Apagar
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-[20px] mt-[30px]">Redes Sociais</div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateLinkedin"
                    >Linkedin</label
                  >
                  <input
                    v-model="candidateLinkedin"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateLinkedin"
                    id="candidateLinkedin"
                    placeholder="@seunome"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateInstagram"
                    >Instagram</label
                  >
                  <input
                    v-model="candidateInstagram"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateInstagram"
                    id="candidateInstagram"
                    placeholder="@seunome"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateFacebook"
                    >Facebook</label
                  >
                  <input
                    v-model="candidateFacebook"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateFacebook"
                    id="candidateFacebook"
                    placeholder="@seunome"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateXtwitter"
                    >X (ex Twitter)</label
                  >
                  <input
                    v-model="candidateXtwitter"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateXtwitter"
                    id="candidateXtwitter"
                    placeholder="@seunome"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateTiktok"
                    >TikTok</label
                  >
                  <input
                    v-model="candidateTiktok"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateTiktok"
                    id="candidateTiktok"
                    placeholder="@seunome"
                  />
                </div>
              </div>

              <!-- Botões Fixados -->
              <div
                class="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-up-sm pr-10 z-99 dark:border-strokedark dark:bg-boxdark"
              >
                <!-- <p class="text-right mb-2 font-small text-black dark:text-white">Alterar informações</p> -->
                <div class="flex justify-end gap-4">
                  <button
                    class="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                    type="button"
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                  <button
                    class="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <!-- Formulário de ADMIN -->
          <div v-if="usertype === 'admin'">
            <form @submit.prevent="handleAdminSubmit">
              <div class="mb-[20px] mt-[30px]">Dados pessoais</div>
              <div class="w-full sm:w-1/2">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="gender"
                  >Secretaria</label
                >
                <select
                  v-model="adminSecretary"
                  class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="organ"
                  id="organ"
                >
                  <option disabled selected value="">Selecione</option>
                  <option value="ADAP">ADAP</option>
                  <option value="ADH">ADH</option>
                  <option value="AGESPISA">AGESPISA</option>
                  <option value="AGRESPI">AGRESPI</option>
                  <option value="BADESPI">BADESPI</option>
                  <option value="CBMPI">CBMPI</option>
                  <option value="CCOM">CCOM</option>
                  <option value="CDT">CDT</option>
                  <option value="CENDEFOL">CENDEFOL</option>
                  <option value="CFLP">CFLP</option>
                  <option value="PGE">PGE</option>
                  <option
                    v-for="organ in selectOrgan"
                    :key="organ"
                    :value="organ"
                  >
                    {{ organ }}
                  </option>
                </select>
              </div>
              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="name"
                  >Nome completo</label
                >
                <div class="relative">
                  <span class="absolute left-4.5 top-4">
                    <svg
                      class="fill-current"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                          fill=""
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    v-model="adminFullName"
                    class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Digite aqui seu nome"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="cpf"
                    >CPF</label
                  >
                  <input
                    required
                    v-model="adminCpf"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="cpf"
                    id="cpf"
                    placeholder="000.000.000-10"
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="adminDateofbirth"
                    >Data de nascimento</label
                  >
                  <input
                    v-model="adminDateofbirth"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="date"
                    name="adminDateofbirth"
                    id="adminDateofbirth"
                    placeholder="12/12/1999"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="gender"
                    >Gênero</label
                  >
                  <select
                    v-model="adminGender"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="gender"
                    id="gender"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                    <option value="nao-binario">Não-binárie</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="adminGenderIdentity"
                    >Identidade de Gênero</label
                  >
                  <select
                    v-model="adminGenderIdentity"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="adminGenderIdentity"
                    id="adminGenderIdentity"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="cisgenero">Cisgênero</option>
                    <option value="transgênero/transexual/travesti">
                      Transgênero/Transexual/Travesti
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="adminSexualOrientation"
                    >Orientação Sexual</label
                  >
                  <select
                    v-model="adminSexualOrientation"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="adminSexualOrientation"
                    id="adminSexualOrientation"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="assexual">Assexual</option>
                    <option value="bissexual">Bissexual</option>
                    <option value="heterossexual">Heterossexual</option>
                    <option value="homosexual">Homossexual</option>
                    <option value="pansexual">Pansexual</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateBirthplace"
                    >Naturalidade</label
                  >
                  <input
                    v-model="adminBirthplace"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateBirthplace"
                    id="candidateBirthplace"
                    placeholder="Ex: Teresinense"
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="civil"
                    >Estado civil</label
                  >
                  <select
                    v-model="adminMaritalstatus"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    name="civil"
                    id="civil"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option value="solteiro">Solteiro(a)</option>
                    <option value="casado">Casado(a)</option>
                    <option value="divorciado">Divorciado(a)</option>
                    <option value="viuvo">Viúvo(a)</option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="candidateUniqueRegistration"
                    >Cargo</label
                  >
                  <input
                    v-model="adminOffice"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="candidateUniqueRegistration"
                    id="candidateUniqueRegistration"
                    placeholder="Cargo Destinado"
                  />
                </div>
              </div>

              <div
                class="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-up-sm pr-10 z-99 dark:border-strokedark dark:bg-boxdark"
              >
                <!-- <p class="text-right mb-2 font-small text-black">Alterar informações</p> -->
                <div class="flex justify-end gap-4">
                  <button
                    class="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                    type="button"
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                  <button
                    class="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </form>
          </div>

          <!-- Formulário de Enterprise -->
          <div v-if="usertype === 'enterprise' || usertype == 'userEnterprise'">
            <form @submit.prevent="handleSubmit">
              <div
                class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark py-4 px-7"
              >
                <div class="mb-[20px] mt-[10px]">Dados do Responsável</div>

                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseFullName"
                      >Nome completo</label
                    >
                    <div class="relative">
                      <span class="absolute left-4.5 top-4">
                        <svg
                          class="fill-current"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                              fill=""
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                      <input
                        v-model="enterpriseFullName"
                        class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="text"
                        name="enterpriseFullName"
                        id="enterpriseFullName"
                        placeholder="Digite aqui seu nome"
                        required
                      />
                    </div>
                  </div>

                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="phone"
                      >Celular</label
                    >
                    <input
                      v-model="enterpriseCellPhone"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="(99) 99999.9999"
                      required
                    />
                  </div>
                </div>

                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseCpf"
                      >CPF</label
                    >
                    <input
                      v-model="enterpriseCpf"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="enterpriseCpf"
                      id="enterpriseCpf"
                      placeholder="000.000.000-10"
                      required
                    />
                  </div>

                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="cargo"
                      >Cargo</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterprisePosition"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="cargo"
                      id="cargo"
                      placeholder="Digite aqui seu cargo"
                      required
                    />
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="email"
                    >E-mail do responsável</label
                  >
                  <div class="relative">
                    <span class="absolute left-4.5 top-4">
                      <svg
                        class="fill-current"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                            fill=""
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      v-model="enterpriseEmail"
                      class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="email@gmail.com"
                      required
                    />
                  </div>
                </div>
              </div>
              <!-- Botões Fixados -->
              <div
                class="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-up-sm pr-10 z-99 dark:border-strokedark dark:bg-boxdark"
              >
                <!-- <p class="text-right mb-2 font-small text-black">Alterar informações</p> -->
                <div class="flex justify-end gap-4">
                  <button
                    class="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                    type="button"
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                  <button
                    class="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
              </div>

              <div
                class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark py-4 px-7 mt-5"
              >
                <div class="mb-[40px] mt-[20px]">Dados da Empresa</div>

                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="cnpj"
                      >CNPJ</label
                    >
                    <input
                      v-model="enterpriseCnpj"
                      @input="handleCnpjInput"
                      :readonly="canEdit"
                      :disabled="usertype == 'userEnterprise'"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="cnpj"
                      id="cnpj"
                      placeholder="00.000.000/0001-00"
                      required
                    />
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="estadual"
                      >Inscrição Estadual</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseStateRegistration"
                      :readonly="canEdit"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="estadual"
                      id="estadual"
                      placeholder="Digite aqui a inscrição estadual"
                      required
                    />
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="razao"
                    >Razão Social</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseCorporateName"
                    :readonly="canEdit"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="razao"
                    id="razao"
                    placeholder="Digite aqui a razão social"
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseTradeName"
                    >Nome Fantasia</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseTradeName"
                    :readonly="canEdit"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseTradeName"
                    id="enterpriseTradeName"
                    placeholder="Digite aqui o nome fantasia"
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseCompanyDescription"
                    >Descrição da empresa</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseCompanyDescription"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseCompanyDescription"
                    id="enterpriseCompanyDescription"
                    placeholder="Descreva o que sua empresa faz ou os produtos/serviços que oferece"
                    required
                  />
                </div>

                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterprisePostalCode"
                      >CEP</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterprisePostalCode"
                      @change="fetchEnterpriseAddress"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="enterprisePostalCode"
                      id="enterprisePostalCode"
                      placeholder="Digite seu CEP"
                      required
                    />
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="phonefix"
                      >Telefone fixo</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseLandlinePhone"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="phonefix"
                      id="phonefix"
                      placeholder="(xx) 00000-0000"
                      required
                    />
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterprisePostalNumber"
                      >Numero</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterprisePostalNumber"
                      @change="fetchEnterpriseAddress"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="enterprisePostalNumber"
                      id="enterprisePostalNumber"
                      placeholder=""
                      required
                    />
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="fantasia"
                    >Endereço</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseAddress"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="fantasia"
                    id="fantasia"
                    placeholder="Digite aqui seu endereço completo"
                    required
                  />
                </div>

                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseState"
                      >Estado</label
                    >
                    <select
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseState"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      name="enterpriseState"
                      id="enterpriseState"
                      @change="getEnterpriseCities"
                      required
                    >
                      <option disabled selected value="">Selecione</option>
                      <option
                        v-for="(state, key) in statesEnterprise"
                        :key="key"
                        :value="key"
                      >
                        {{ state.nome }}
                      </option>
                    </select>
                  </div>

                  <div class="w-full sm:w-1/2 mt-4 sm:mt-0">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseCity"
                      >Cidade</label
                    >
                    <select
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseCity"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      name="enterpriseCity"
                      id="enterpriseCity"
                    >
                      <option disabled selected value="">Selecione</option>
                      <option
                        v-for="city in selectedEnterpriseCities"
                        :key="city"
                        :value="city"
                      >
                        {{ city }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="emailcompany"
                    >E-mail da empresa</label
                  >
                  <div class="relative">
                    <span class="absolute left-4.5 top-4">
                      <svg
                        class="fill-current"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                            fill=""
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      v-model="enterpriseCompanyEmail"
                      disabled
                      class="w-full cursor-not-allowed opacity-50 rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="email"
                      name="emailcompany"
                      id="emailcompany"
                      placeholder="email@gmail.com"
                      required
                    />
                  </div>
                </div>
              </div>

              <div
                class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark py-4 px-7 mt-5"
              >
                <div class="mb-[40px] mt-[20px]">Outros dados</div>
                <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseAcceptsPcdPosition"
                      >Aceita vagas PCD?</label
                    >
                    <select
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseAcceptsPcdPosition"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      name="enterpriseAcceptsPcdPosition"
                      id="enterpriseAcceptsPcdPosition"
                      required
                    >
                      <option disabled selected value="">Selecione</option>
                      <option value="sim">Sim</option>
                      <option value="nao">Não</option>
                    </select>
                  </div>
                  <div class="w-full sm:w-1/2">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseSector"
                      >Área de atuação</label
                    >

                    <select
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseSector"
                      class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                      name="enterpriseSector"
                      id="enterpriseSector"
                    >
                      <option value="">Selecione</option>
                      <option
                        v-for="area in areas"
                        :key="area.id_area"
                        :value="area.id_area"
                      >
                        {{ area.area_description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseMission"
                    >Missão</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseMission"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseMission"
                    id="enterpriseMission"
                    placeholder="Objetivo atual."
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseVision"
                    >Visão</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseVision"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseVision"
                    id="enterpriseVision"
                    placeholder="Objetivo futuro."
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseValues"
                    >Valores</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseValues"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseValues"
                    id="enterpriseValues"
                    placeholder="Princípios que guiam a organização."
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <div class="w-full">
                    <label
                      class="mb-3 block text-sm font-medium text-black dark:text-white"
                      for="enterpriseNumberOfEmployees"
                      >Número de funcionários</label
                    >
                    <input
                      :disabled="usertype == 'userEnterprise'"
                      v-model="enterpriseNumberOfEmployees"
                      class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary appearance-none"
                      type="number"
                      name="enterpriseNumberOfEmployees"
                      id="enterpriseNumberOfEmployees"
                      placeholder="Exemplo: 10, 50, 100, 500, etc."
                      min="1"
                      required
                    />
                  </div>
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseInstitutionalWebsite"
                    >Site comporativo</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseInstitutionalWebsite"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseInstitutionalWebsite"
                    id="enterpriseInstitutionalWebsite"
                    placeholder="Exemplo: www.nomedasuaempresa.com"
                    required
                  />
                </div>

                <div class="mb-5.5">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseInstagram"
                    >Instagram</label
                  >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    v-model="enterpriseInstagram"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseInstagram"
                    id="enterpriseInstagram"
                    placeholder="Exemplo: @suaempresa"
                  />
                </div>
              </div>
            </form>

            <div
              class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark py-4 px-7 mt-5"
            >
              <div class="mb-[40px] mt-[20px]">Documetação</div>
              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseCnpjCard"
                  required
                  >Cartão CNPJ</label
                >
                <div
                  v-if="!enterpriseCnpjCard"
                  class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    @change="selectEnterpriseCardCnpj($event)"
                    name="enterpriseCnpjCard"
                    type="file"
                    accept="application/pdf"
                    id="enterpriseCnpjCard"
                    class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    required
                  />
                  <div
                    class="flex flex-col items-center justify-center space-y-3"
                  >
                    <span
                      class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                          fill="#3C50E0"
                        />
                      </svg>
                    </span>
                    <p v-if="!enterpriseCnpjCard" class="text-sm font-medium">
                      <span class="text-primary">Clique para fazer upload</span>
                      ou arraste e solte
                    </p>
                    <p
                      v-if="!enterpriseCnpjCard"
                      class="mt-1.5 text-sm font-medium"
                    >
                      .PDF apenas
                    </p>
                    <p v-if="enterpriseCnpjCard">
                      {{ enterpriseCnpjCard.name }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="oldCardCnpj != '' && !enterpriseCnpjCard"
                  class="flex justify-end"
                >
                  <button
                    @click="returnFile"
                    class="p-2 text-white bg-red rounded"
                  >
                    Cancelar
                  </button>
                </div>
                <div v-if="enterpriseCnpjCard">
                  <div class="flex justify-between mb-3">
                    <input
                      v-if="enterpriseCnpjCard.name"
                      type="text"
                      :value="enterpriseCnpjCard.name"
                      disabled
                      class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                    />
                    <div v-if="!enterpriseCnpjCard.name" class="w-[90%]">
                      <a v-if="enterpriseCnpjCard">
                        <button
                          :disabled="usertype == 'userEnterprise'"
                          @click="openFilePdf(enterpriseCnpjCard)"
                          class="w-full text-left rounded border border-stroke py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:text-white dark:focus:border-primary"
                        >
                          <strong>Documento carregado: </strong>
                          <span
                            class="text-blue-500 underline cursor-pointer hover:no-underline"
                          >
                            enterpriseCnpjCard.pdf
                            <i class="fa-regular fa-file"></i>
                          </span>
                        </button>
                      </a>
                    </div>
                    <button
                      :disabled="usertype == 'userEnterprise'"
                      class="p-2 rounded bg-red text-white"
                      @click="removeEnterpriseCnpjCard()"
                    >
                      <img src="/src/assets/images/icon/trash.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="rounded-lg border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark py-4 px-7 mt-5"
            >
              <label
                class="mb-3 mt-3 block text-sm font-medium text-black dark:text-white"
                for="enterpriseSocialContract"
                >Outros Documentos</label
              >

              <div
                v-if="otherFilesArrayFetch && otherFilesArrayFetch.length != 0"
              >
                <div
                  class="flex justify-between mb-3"
                  v-for="(item, index) in otherFilesArrayFetch"
                  :key="index"
                >
                  <button
                    :disabled="usertype == 'userEnterprise'"
                    class="w-[90%] text-left rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                    @click="openFilePdf(item.file)"
                  >
                    {{ item.filename }}
                  </button>
                  <button
                    :disabled="usertype == 'userEnterprise'"
                    class="p-2 rounded bg-red text-white"
                    @click="deleteOtherFiles(item.filename)"
                  >
                    <img src="/src/assets/images/icon/trash.svg" />
                  </button>
                </div>
              </div>
              <div v-if="arrayOtherFiles.length != 0">
                <div
                  class="flex justify-between mb-3"
                  v-for="(item, index) in arrayOtherFiles"
                  :key="index"
                >
                  <input
                    type="text"
                    :value="item.name"
                    disabled
                    class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                  />
                  <button
                    :disabled="usertype == 'userEnterprise'"
                    class="p-2 rounded bg-red text-white"
                    @click="removeOtherFile(index)"
                  >
                    <img src="/src/assets/images/icon/trash.svg" />
                  </button>
                </div>
              </div>

              <div v-if="openOtherFiles" class="mb-5.5">
                <div
                  class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                >
                  <input
                    :disabled="usertype == 'userEnterprise'"
                    name="enterpriseSocialContract"
                    type="file"
                    accept="application/pdf"
                    id="enterpriseSocialContract"
                    class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                    @change="selectOtherFile($event)"
                  />
                  <div
                    class="flex flex-col items-center justify-center space-y-3"
                  >
                    <span
                      class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                          fill="#3C50E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                          fill="#3C50E0"
                        />
                      </svg>
                    </span>
                    <p v-if="!otherFileEnterprise" class="text-sm font-medium">
                      <span class="text-primary">Clique para fazer upload</span>
                      ou arraste e solte
                    </p>
                    <p
                      v-if="!otherFileEnterprise"
                      class="mt-1.5 text-sm font-medium"
                    >
                      .PDF apenas
                    </p>
                    <p v-if="otherFileEnterprise">
                      {{ otherFileEnterprise.name }}
                    </p>
                  </div>
                </div>
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="email"
                  >Nome do arquivo</label
                >
                <input
                  :disabled="usertype == 'userEnterprise'"
                  v-model="nameOhetFile"
                  type="text"
                  placeholder="Escolha um nome para seu arquivo"
                  class="w-full rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                />
                <div class="flex justify-end">
                  <button
                    :disabled="usertype == 'userEnterprise'"
                    class="rounded bg-primary p-2 text-white mt-2"
                    @click="saveOtherFile"
                  >
                    Salvar Arquivo
                  </button>
                </div>
              </div>
              <div class="flex justify-center">
                <p>{{ openOtherFiles ? "Cancelar" : "Adicionar arquivos" }}</p>
              </div>
              <div class="flex justify-center mt-3">
                <button
                  :disabled="usertype == 'userEnterprise'"
                  @click="openNewOtherFilesEnterprise"
                  class="rounded-full bg-primary w-[30px] h-[30px] text-white"
                >
                  {{ openOtherFiles ? "X" : "+" }}
                </button>
              </div>
            </div>
          </div>

          <!-- Formulário de Secretary -->
          <div v-if="usertype === 'secretary' || usertype === 'userSecretary'">
            <form @submit.prevent="handleSubmit">
              <div class="mb-[20px] mt-[10px]">Dados do Responsável</div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseFullName"
                    >Nome completo</label
                  >
                  <div class="relative">
                    <span class="absolute left-4.5 top-4">
                      <svg
                        class="fill-current"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.8">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                            fill=""
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                    <input
                      v-model="secretaryFullName"
                      class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="enterpriseFullName"
                      id="enterpriseFullName"
                      placeholder="Digite aqui seu nome"
                      required
                    />
                  </div>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="phone"
                    >Celular</label
                  >
                  <input
                    v-model="secretaryCellPhone"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="(99) 99999.9999"
                    required
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseCpf"
                    >CPF</label
                  >
                  <input
                    v-model="secretaryCpf"
                    class="w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="text"
                    name="enterpriseCpf"
                    id="enterpriseCpf"
                    placeholder="000.000.000-10"
                    required
                  />
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="cargo"
                    >Cargo</label
                  >
                  <input
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryPosition"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    type="text"
                    name="cargo"
                    id="cargo"
                    placeholder="Digite aqui seu cargo"
                    required
                  />
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="email"
                  >E-mail do responsável</label
                >
                <div class="relative">
                  <span class="absolute left-4.5 top-4">
                    <svg
                      class="fill-current"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                          fill=""
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    v-model="secretaryEmail"
                    class="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@gmail.com"
                    required
                  />
                </div>
              </div>

              <!-- Botões Fixados -->
              <div
                class="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-up-sm pr-10 z-99 dark:border-strokedark dark:bg-boxdark"
              >
                <div class="flex justify-end gap-4">
                  <button
                    class="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                    type="button"
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                  <button
                    class="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
              </div>

              <div class="mb-[40px] mt-[60px] border-t-2 border-gray pt-2">
                Dados da Empresa
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="cnpj"
                    >CNPJ</label
                  >
                  <input
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryCnpj"
                    @input="handleCnpjInput"
                    :readonly="canEdit"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    type="text"
                    name="cnpj"
                    id="cnpj"
                    placeholder="00.000.000/0001-00"
                    required
                  />
                </div>
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="estadual"
                    >Inscrição Estadual</label
                  >
                  <input
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryStateRegistration"
                    :readonly="canEdit"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    type="text"
                    name="estadual"
                    id="estadual"
                    placeholder="Digite aqui a inscrição estadual"
                    required
                  />
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="razao"
                  >Razão Social</label
                >
                <input
                  v-model="secretaryCorporateName"
                  :disabled="usertype == 'userSecretary'"
                  :readonly="canEdit"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="razao"
                  id="razao"
                  placeholder="Digite aqui a razão social"
                  required
                />
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseTradeName"
                  >Nome Fantasia</label
                >
                <input
                  :disabled="usertype == 'userSecretary'"
                  v-model="secretaryTradeName"
                  :readonly="canEdit"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="enterpriseTradeName"
                  id="enterpriseTradeName"
                  placeholder="Digite aqui o nome fantasia"
                  required
                />
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseCompanyDescription"
                  >Descrição da empresa</label
                >
                <input
                  :disabled="usertype == 'userSecretary'"
                  v-model="secretaryCompanyDescription"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="enterpriseCompanyDescription"
                  id="enterpriseCompanyDescription"
                  placeholder="Descreva o que sua empresa faz ou os produtos/serviços que oferece"
                  required
                />
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="fantasia"
                  >Endereço</label
                >
                <input
                  :disabled="usertype == 'userSecretary'"
                  v-model="secretaryAddress"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="fantasia"
                  id="fantasia"
                  placeholder="Digite aqui seu endereço completo"
                  required
                />
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseState"
                    >Estado</label
                  >
                  <select
                    v-model="secretaryState"
                    :disabled="usertype == 'userSecretary'"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    name="enterpriseState"
                    id="enterpriseState"
                    @change="getEnterpriseCities"
                    required
                  >
                    <option disabled selected value="">Selecione</option>
                    <option
                      v-for="(state, key) in statesEnterprise"
                      :key="key"
                      :value="key"
                    >
                      {{ state.nome }}
                    </option>
                  </select>
                </div>

                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterprisePostalCode"
                    >CEP</label
                  >
                  <input
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryPostalCode"
                    @change="fetchEnterpriseAddress"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    type="text"
                    name="enterprisePostalCode"
                    id="enterprisePostalCode"
                    placeholder="Digite seu CEP"
                    required
                  />
                </div>
              </div>

              <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseCity"
                    >Cidade</label
                  >
                  <select
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryCity"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    name="enterpriseCity"
                    id="enterpriseCity"
                  >
                    <option disabled selected value="">Selecione</option>
                    <option
                      v-for="city in selectedEnterpriseCities"
                      :key="city"
                      :value="city"
                    >
                      {{ city }}
                    </option>
                  </select>
                </div>
                <div class="w-full sm:w-1/2">
                  <label
                    class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="phonefix"
                    >Telefone fixo</label
                  >
                  <input
                    :disabled="usertype == 'userSecretary'"
                    v-model="secretaryLandlinePhone"
                    :class="
                      usertype != 'userSecretary'
                        ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                        : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    "
                    type="text"
                    name="phonefix"
                    id="phonefix"
                    placeholder="(xx) 00000-0000"
                    required
                  />
                </div>
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="emailcompany"
                  >E-mail da empresa</label
                >
                <div class="relative">
                  <span class="absolute left-4.5 top-4">
                    <svg
                      class="fill-current"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                          fill=""
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                          fill=""
                        />
                      </g>
                    </svg>
                  </span>
                  <input
                    v-model="secretaryCompanyEmail"
                    disabled
                    class="w-full cursor-not-allowed opacity-50 rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    type="email"
                    name="emailcompany"
                    id="emailcompany"
                    placeholder="email@gmail.com"
                    required
                  />
                </div>
              </div>

              <!-- <div class="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                <div class="w-full sm:w-1/2">
                  <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseAcceptsPcdPosition">Aceita vagas PCD?</label>
                  <select :disabled="usertype == 'userSecretary'" v-model="secretaryAcceptsPcdPosition" :class="usertype != 'userSecretary'
                    ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                    : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    " name="enterpriseAcceptsPcdPosition" id="enterpriseAcceptsPcdPosition" required>
                    <option disabled selected value="">Selecione</option>
                    <option value="sim">Sim</option>
                    <option value="nao">Não</option>
                  </select>
                </div>
                <div class="w-full sm:w-1/2">
                  <label class="mb-3 block text-sm font-medium text-black dark:text-white" for="secretarySector">Área de
                    atuação</label>
                  <select v-model="secretarySector"
                    class="w-full rounded-lg border border-stroke bg-transparent py-[10px] pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
                    name="secretarySector" id="secretarySector" :disabled="usertype == 'userSecretary'" :class="usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                      " required>
                    <option value="">Selecione</option>
                    <option v-for="area in areas" :key="area.id_area" :value="area.id_area">
                      {{ area.area_description }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="mb-5.5">
                <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseMission">Missão</label>
                <input :disabled="usertype == 'userSecretary'" v-model="secretaryMission" :class="usertype != 'userSecretary'
                  ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  " type="text" name="enterpriseMission" id="enterpriseMission" placeholder="Objetivo atual." required />
              </div>

              <div class="mb-5.5">
                <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseVision">Visão</label>
                <input :disabled="usertype == 'userSecretary'" v-model="secretaryVision" :class="usertype != 'userSecretary'
                  ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  " type="text" name="enterpriseVision" id="enterpriseVision" placeholder="Objetivo futuro." required />
              </div>

              <div class="mb-5.5">
                <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseValues">Valores</label>
                <input :disabled="usertype == 'userSecretary'" v-model="secretaryValues" :class="usertype != 'userSecretary'
                  ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                  : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  " type="text" name="enterpriseValues" id="enterpriseValues"
                  placeholder="Princípios que guiam a organização." required />
              </div>

              <div class="mb-5.5">
                <div class="w-full">
                  <label class="mb-3 block text-sm font-medium text-black dark:text-white"
                    for="enterpriseNumberOfEmployees">Número de funcionários</label>
                  <input :disabled="usertype == 'userSecretary'" v-model="secretaryNumberOfEmployees" :class="usertype != 'userSecretary'
                    ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                    : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                    " type="number" name="enterpriseNumberOfEmployees" id="enterpriseNumberOfEmployees"
                    placeholder="Exemplo: 10, 50, 100, 500, etc." min="1" required />
                </div>
              </div> -->

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseInstitutionalWebsite"
                  >Site comporativo</label
                >
                <input
                  :disabled="usertype == 'userSecretary'"
                  v-model="secretaryInstitutionalWebsite"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="enterpriseInstitutionalWebsite"
                  id="enterpriseInstitutionalWebsite"
                  placeholder="Exemplo: www.nomedasuaempresa.com"
                  required
                />
              </div>

              <div class="mb-5.5">
                <label
                  class="mb-3 block text-sm font-medium text-black dark:text-white"
                  for="enterpriseInstagram"
                  >Instagram</label
                >
                <input
                  :disabled="usertype == 'userSecretary'"
                  v-model="secretaryInstagram"
                  :class="
                    usertype != 'userSecretary'
                      ? 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary'
                      : 'w-full rounded border border-stroke bg-gray py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-50 cursor-not-allowed'
                  "
                  type="text"
                  name="enterpriseInstagram"
                  id="enterpriseInstagram"
                  placeholder="Exemplo: @suaempresa"
                />
              </div>
            </form>

            <!-- <div class="mb-[40px] mt-[60px] border-t-2 border-gray pt-2">Documentação</div>
            <div class="mb-5.5">
              <label class="mb-3 block text-sm font-medium text-black dark:text-white" for="enterpriseCnpjCard"
                required>Cartão CNPJ</label>
              <div v-if="!enterpriseCnpjCard"
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5">
                <input :disabled="usertype == 'userSecretary'" @change="selectEnterpriseCardCnpj($event)"
                  name="enterpriseCnpjCard" type="file" accept="application/pdf" id="enterpriseCnpjCard"
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none" required />
                <div class="flex flex-col items-center justify-center space-y-3">
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0" />
                    </svg>
                  </span>
                  <p v-if="!enterpriseCnpjCard" class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p v-if="!enterpriseCnpjCard" class="mt-1.5 text-sm font-medium">
                    .PDF apenas
                  </p>
                  <p v-if="enterpriseCnpjCard">
                    {{ enterpriseCnpjCard.name }}
                  </p>
                </div>
              </div>
              <div v-if="oldCardCnpj != '' && !enterpriseCnpjCard" class="flex justify-end">
                <button :disabled="usertype == 'userSecretary'" @click="returnFile"
                  class="p-2 text-white bg-red rounded">
                  Cancelar
                </button>
              </div>
              <div v-if="enterpriseCnpjCard">
                <div class="flex justify-between mb-3">
                  <input v-if="enterpriseCnpjCard.name" type="text" :value="enterpriseCnpjCard.name" disabled
                    class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60" />
                  <div v-if="!enterpriseCnpjCard.name" class="w-[90%]">
                    <a v-if="enterpriseCnpjCard">
                      <button @click="openFilePdf(enterpriseCnpjCard)"
                        class="w-full text-left rounded border border-stroke py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:text-white dark:focus:border-primary">
                        <strong>Documento carregado: </strong>
                        <span class="text-blue-500 underline cursor-pointer hover:no-underline">
                          enterpriseCnpjCard.pdf
                          <i class="fa-regular fa-file"></i>
                        </span>
                      </button>
                    </a>
                  </div>
                  <button :disabled="usertype == 'userSecretary'" class="p-2 rounded bg-red text-white"
                    @click="removeEnterpriseCnpjCard()">
                    <img src="/src/assets/images/icon/trash.svg" />
                  </button>
                </div>
              </div>
            </div> -->

            <label
              class="mb-3 mt-3 block text-sm font-medium text-black dark:text-white"
              for="enterpriseSocialContract"
              >Outros Documentos</label
            >

            <div
              v-if="otherFilesArrayFetch && otherFilesArrayFetch.length != 0"
            >
              <div
                class="flex justify-between mb-3"
                v-for="(item, index) in otherFilesArrayFetch"
                :key="index"
              >
                <button
                  :disabled="usertype == 'userSecretary'"
                  class="w-[90%] text-left rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                  @click="openFilePdf(item.file)"
                >
                  {{ item.filename }}
                </button>
                <button
                  :disabled="usertype == 'userSecretary'"
                  class="p-2 rounded bg-red text-white"
                  @click="deleteOtherFilesSecretary(item.filename)"
                >
                  <img src="/src/assets/images/icon/trash.svg" />
                </button>
              </div>
            </div>

            <div v-if="arrayOtherFiles.length != 0">
              <div
                class="flex justify-between mb-3"
                v-for="(item, index) in arrayOtherFiles"
                :key="index"
              >
                <input
                  type="text"
                  :value="item.name"
                  disabled
                  class="w-[90%] rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
                />
                <button
                  :disabled="usertype == 'userSecretary'"
                  class="p-2 rounded bg-red text-white"
                  @click="removeOtherFile(index)"
                >
                  <img src="/src/assets/images/icon/trash.svg" />
                </button>
              </div>
            </div>

            <div v-if="openOtherFiles" class="mb-5.5">
              <div
                class="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border-2 border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
              >
                <input
                  :disabled="usertype == 'userSecretary'"
                  name="enterpriseSocialContract"
                  type="file"
                  accept="application/pdf"
                  id="enterpriseSocialContract"
                  class="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                  @change="selectOtherFile($event)"
                />
                <div
                  class="flex flex-col items-center justify-center space-y-3"
                >
                  <span
                    class="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                        fill="#3C50E0"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                        fill="#3C50E0"
                      />
                    </svg>
                  </span>
                  <p v-if="!otherFileEnterprise" class="text-sm font-medium">
                    <span class="text-primary">Clique para fazer upload</span>
                    ou arraste e solte
                  </p>
                  <p
                    v-if="!otherFileEnterprise"
                    class="mt-1.5 text-sm font-medium"
                  >
                    .PDF apenas
                  </p>
                  <p v-if="otherFileEnterprise">
                    {{ otherFileEnterprise.name }}
                  </p>
                </div>
              </div>
              <label
                class="mb-3 block text-sm font-medium text-black dark:text-white"
                for="email"
                >Nome do arquivo</label
              >
              <input
                :disabled="usertype == 'userSecretary'"
                v-model="nameOhetFile"
                type="text"
                placeholder="Escolha um nome para seu arquivo"
                class="w-full rounded border border-stroke bg-gray py-3 pl-4 pr-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary opacity-60"
              />
              <div class="flex justify-end">
                <button
                  :disabled="usertype == 'userSecretary'"
                  class="rounded bg-primary p-2 text-white mt-2"
                  @click="saveOtherFile"
                >
                  Salvar Arquivo
                </button>
              </div>
            </div>

            <div class="flex justify-center">
              <p>{{ openOtherFiles ? "Cancelar" : "Adicionar arquivos" }}</p>
            </div>

            <div class="flex justify-center mt-3">
              <button
                :disabled="usertype == 'userSecretary'"
                @click="openNewOtherFilesEnterprise"
                class="rounded-full bg-primary w-[30px] h-[30px] text-white"
              >
                {{ openOtherFiles ? "X" : "+" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-save-disability {
  cursor: pointer;
}

select {
  padding-bottom: 6.8px !important;
  padding-top: 6.8px !important;
}
</style>