<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div>
      <div class="overflow-x-auto bg-white dark:bg-black shadow rounded-lg">
        <table class="min-w-full bg-white  text-[base] md:text-[lg]">
          <thead class="bg-graydark text-white dark:bg-opacity-75">
            <tr class="text-[14px]">
              <th class="px-6 py-3 text-center text-gray-600">
                Tipo
              </th>
              <th class="px-6 py-3 text-center text-gray-600">
                Nome Fantasia
              </th>
              <th class="px-6 py-3 text-center text-gray-600">
                Razão Social
              </th>
              <th class="px-6 py-3 text-center text-gray-600">
                Contato
              </th>
              <th class="px-6 py-3 text-center text-gray-600">
                Ações
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr
              v-for="(entity, index) in entities"
              :key="index"
              class="text-center text-[base] md:text-[lg] odd:bg-white odd:dark:bg-strokedark even:bg-slate-200 even:dark:bg-black dark:border-strokedark"
            >
              <td class="px-6 py-4 text-center">{{ entityType(entity) }}</td>
              <td class="px-6 py-4  text-[base] md:text-[lg]">
                {{ entity.tradename || "Não informado" }}
              </td>
              <td class="px-6 py-4  text-[base] md:text-[lg]">
                {{ entity.corporatename || "Não informado" }}
              </td>
              <td class="px-6 py-4  text-[base] md:text-[lg]">
                {{ entity.landlinephone || "Não informado" }}
              </td>
              <td class="px-6 py-4  text-[base] md:text-[lg] text-center space-x-3">
                <button
                  @click="analyzeEntity(entity)"
                  class="text-blue-500 hover:underline"
                >
                  <i class="fa-solid fa-eye"></i>
                  Visualizar
                </button>
                <button
                  @click="acceptEntity(entity.id)"
                  class="text-green-500 hover:underline"
                >
                  Aceitar
                </button>
                <button
                  @click="deleteEntity(entity.id)"
                  class="text-red hover:underline">
                  Rejeitar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal de Rejeição -->
    <div
      v-if="showRejectModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="bg-white p-4 rounded-lg max-w-md">
        <h2 class="text-lg font-semibold mb-4">Justificativa</h2>
        <textarea
          v-model="rejectionReason"
          class="w-full h-40 border px-3 py-2 rounded-md mb-2"
        ></textarea>
        <div class="flex justify-end">
          <button
            @click="closeRejectModal"
            class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2 hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            @click="confirmReject"
            class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal de Análise -->
    <div
      v-if="showAnalyzeModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
    >
      <div
        class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto h-[85svh] mt-[8vh] modal-show"
      >
        <div class="mt-6 flex justify-between items-center">
          <h2
            class="text-2xl font-semibold text-center text-gray-900 dark:text-gray-100"
          >
            Detalhes
          </h2>
          <button
            @click="closeAnalyzeModal"
            title="Fechar"
            class="text-primary px-4 py-2 hover:text-primary_hover"
          >
            <i class="fa-regular fa-circle-xmark text-3xl"></i>
          </button>
        </div>
        <div
          class="bg-slate-200 bg-opacity-40 dark:bg-black-2 dark:bg-opacity-5 p-6 rounded-lg"
        >
          <div class="mb-6 text-center">
            <img
              v-if="entityToAnalyze?.imagepath"
              :src="entityToAnalyze?.imagepath"
              alt="Logo"
              class="max-w-[150px] h-auto mx-auto rounded-full shadow-md"
            />
            <img
              v-else
              :src="photoDefault"
              alt="Logo"
              class="max-w-[150px] h-auto mx-auto rounded-full shadow-md"
            />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >CNPJ:</strong
              >
              <span class="block">{{ entityToAnalyze?.cnpj }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Email:</strong
              >
              <span class="block">{{ entityToAnalyze?.email }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Nome Fantasia:</strong
              >
              <span class="block">{{ entityToAnalyze?.tradename }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Nome da Empresa:</strong
              >
              <span class="block">{{ entityToAnalyze?.corporatename }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Cidade:</strong
              >
              <span class="block">{{ entityToAnalyze?.city }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Telefone Fixo:</strong
              >
              <span class="block">{{ entityToAnalyze?.landlinephone }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Código Postal:</strong
              >
              <span class="block">{{ entityToAnalyze?.postalcode }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Endereço:</strong
              >
              <span class="block">{{ entityToAnalyze?.address }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Valores Culturais e Organizacionais:</strong
              >
              <span class="block">{{
                entityToAnalyze?.culturalAndOrganizationalValues
              }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Missão e Visão:</strong
              >
              <span class="block">{{ entityToAnalyze?.missionAndVision }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Missão:</strong
              >
              <span class="block">{{ entityToAnalyze?.mission }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Visão:</strong
              >
              <span class="block">{{ entityToAnalyze?.vision }}</span>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Valores:</strong
              >
              <span class="block">{{ entityToAnalyze?.values }}</span>
            </div>
            <!-- Add more fields as needed -->
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Website Institucional:</strong
              >
              <a
                :href="entityToAnalyze?.institutionalWebsite"
                target="_blank"
                class="block text-blue-500 hover:underline"
                >{{ entityToAnalyze?.institutionalWebsite }}</a
              >
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Instagram:</strong
              >
              <a
                :href="`https://instagram.com/${entityToAnalyze?.instagram}`"
                target="_blank"
                class="block text-blue-500 hover:underline"
                >{{ entityToAnalyze?.instagram }}</a
              >
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300"
                >Cartão CNPJ:</strong
              >
              <button
                @click="openDocument(entityToAnalyze?.cnpjcard)"
                class="block text-blue-500 hover:underline"
              >
                Ver Documento
              </button>
            </div>
            <div>
              <strong class="block text-gray-700 dark:text-gray-300">Outros Arquivos:</strong>
              <a
                @click="openDocument(entityToAnalyze?.cnpjcard)"
                :href="entityToAnalyze?.otherFilesLink"
                target="_blank"
                class="block text-blue-500 hover:underline"
                >Ver Arquivos</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<style>
.modal-show::-webkit-scrollbar {
  width: 5px;
}

.modal-show::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-show::-webkit-scrollbar-thumb {
  background-color: #888888a2;
  border-radius: 5px;
}
</style>

<script setup>
import { ref, onMounted } from "vue";
import axios from "@/api/axios.js";
import Swal from "sweetalert2";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const entities = ref([]);
const pageTitle = ref("Empresas Pendentes");
const showRejectModal = ref(false);
const entityToReject = ref(null);
const rejectionReason = ref("");
const showAnalyzeModal = ref(false);
const entityToAnalyze = ref(null);
import photoDefault from "@/assets/images/user/defaultUser.jpg";

const fetchData = async () => {
  try {
    const enterprises = await fetchEntities("/enterprises/not-verified");
    console.log("aqui**********", enterprises);
    const secretaries = await fetchEntities("/secretaries/not-verified");
    entities.value = [...enterprises, ...secretaries];
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchEntities = async (endpoint) => {
  try {
    const response = await axios.get(endpoint);
    return response.data.map((entity) => ({
      ...entity,
      type: endpoint.includes("enterprises") ? "enterprise" : "secretary",
    }));
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    return [];
  }
};

const entityType = (entity) => {
  return entity.type === "enterprise" ? "Empresa" : "Secretaria";
};

const analyzeEntity = async (response) => {
  console.log(response);
  entityToAnalyze.value = {
    imagepath: response.imagepath,
    fullname: response.fullname,
    cellphone: response.cellphone,
    cpf: response.cpf,
    position: response.position,
    email: response.email,
    cnpj: response.cnpj,
    corporatename: response.corporatename,
    tradename: response.tradename,
    city: response.city,
    landlinephone: response.landlinephone,
    postalcode: response.postalcode,
    address: response.address,
    culturalAndOrganizationalValues: response.culturalAndOrganizationalValues,
    missionAndVision: response.missionAndVision,
    mission: response.mission,
    vision: response.vision,
    values: response.values,
    institutionalWebsite: response.institutionalWebsite,
    instagram: response.instagram,
    cnpjcard: response.cnpjcard,
    cnpjcard: response.cnpjcard,
  };
  showAnalyzeModal.value = true;
};

const acceptEntity = async (entityId) => {
  try {
    const entity = entities.value.find((ent) => ent.id === entityId);
    if (!entity) throw new Error("Entidade não encontrada");

    const endpoint =
      entity.type === "enterprise"
        ? `/enterprises/${entityId}`
        : `/secretaries/update/by-admin/${entityId}`;
    const token = localStorage.getItem("authToken");
    await axios.patch(
      endpoint,
      { isverified: true },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    Swal.fire({ icon: "success", title: "Entidade aceita com sucesso!" });
    entities.value = entities.value.filter((ent) => ent.id !== entityId);
  } catch (error) {
    console.error("Erro ao aceitar entidade:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao aceitar entidade",
      text: error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const deleteEntity = async (entityId) => {
  try {
    const entity = entities.value.find((ent) => ent.id === entityId);
    if (!entity) throw new Error("Entidade não encontrada");

    const endpoint =
      entity.type === "enterprise"
        ? `/enterprises/${entityId}`
        : `/secretaries/delete/${entityId}`;
    const token = localStorage.getItem("authToken");
    await axios.delete(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });

    Swal.fire({ icon: "success", title: "Entidade deletada com sucesso!" });
    entities.value = entities.value.filter((ent) => ent.id !== entityId);
  } catch (error) {
    console.error("Erro ao deletar entidade:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao deletar entidade",
      text: error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const closeRejectModal = () => {
  showRejectModal.value = false;
  rejectionReason.value = "";
};

const confirmReject = async () => {
  try {
    const entity = entityToReject.value;
    if (!entity) throw new Error("Entidade não encontrada");

    const endpoint =
      entity.type === "enterprise"
        ? `/enterprises/${entity.id}`
        : `/secretaries/update/by-admin/${entity.id}`;
    await axios.patch(endpoint, {
      isverified: false,
      reason: rejectionReason.value,
    });

    Swal.fire({ icon: "success", title: "Entidade rejeitada com sucesso!" });
    await fetchData();
    closeRejectModal();
  } catch (error) {
    console.error("Erro ao rejeitar entidade:", error);
    Swal.fire({
      icon: "error",
      title: "Erro ao rejeitar entidade",
      text: error.message,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

const closeAnalyzeModal = () => {
  showAnalyzeModal.value = false;
  entityToAnalyze.value = null;
};

const openDocument = (documentUrl) => {
  if (documentUrl) {
    window.open(documentUrl, "_blank");
  } else {
    Swal.fire({
      icon: "error",
      title: "Documento não disponível",
      text: "O link para o documento não está disponível.",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
  }
};

onMounted(fetchData);
</script>
