<script setup>
import { ref } from 'vue'

import ProductOne from '@/assets/images/user/user-02.png'
import ProductTwo from '@/assets/images/user/user-07.png'
import ModalCheck from '@/components/Modal/ModalCheck.vue';

const products = ref([
    {
        id: 1,
        name: 'Lorenna F. de Martizo',
        date: '24/02/2024',
        imageSrc: ProductOne
    },
    {
        id: 2,
        name: 'Fernanda Soares da Silva',
        date: '21/02/2024',
        imageSrc: ProductTwo
    }
])
</script>

<template>
    <div class="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div class="py-6 px-4 md:px-6 xl:px-7.5">
            <h4 class="text-xl font-bold text-black dark:text-white">Candidatos</h4>
        </div>

        <!-- Table Header -->
        <div
            class="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
            <div class="col-span-3 flex items-center">
                <p class="font-medium">Nome</p>
            </div>
            <div class="col-span-2 hidden items-center sm:flex">
                <p class="font-medium">Data de Cadastro</p>
            </div>
            <div class="col-span-1 flex items-center">
                <p class="font-medium">Agendamentos</p>
            </div>
        </div>

        <!-- Table Rows -->
        <div v-for="product in products" :key="product.id"
            class="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
            <div class="col-span-3 flex items-center">
                <div class="flex flex-col gap-4 sm:flex-row sm:items-center">
                    <div class="h-12.5 w-15 rounded-md">
                        <img :src="product.imageSrc" :alt="`Product: ${product.name}`" />
                    </div>
                    <p class="text-sm font-medium text-black dark:text-white">{{ product.name }}</p>
                </div>
            </div>
            <div class="col-span-2 hidden items-center sm:flex">
                <p class="text-sm font-medium text-black dark:text-white">{{ product.date }}</p>
            </div>
            <div class="col-span-1 flex items-center">
                <p class="text-sm font-medium text-black dark:text-white">
                    <button @click="showModal = true" class="text-primary hover:text-green-600 underline transition">
                        Fazer agendamento
                    </button>
                    <ModalCheck :show="showModal" @close="showModal = false" />
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        ModalCheck,
    },
    data() {
        return {
            showModal: false,
            ModalCheck: false,
        }
    },

}
</script>
