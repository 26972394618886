<!-- type Sla = {
  category: string
  description: string
  id: number
  summary: string
  type: string
  weight: number
}[] -->

<script setup>
import { ref, onMounted, computed } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import MultiSelectView from "@/components/Forms/MultiSelect/MultiSelectView.vue";
import Swal from "sweetalert2";
import axios from "@/api/axios.js";
import { useRouter } from "vue-router";
import ButtonDefault from "@/components/Buttons/ButtonDefault.vue";
import Tabs from "@/components/Tables/Tabs.vue";

const router = useRouter();
const pageTitle = ref("Desenvolvimento Profissional");

const softSkills = ref([]);
const hardSkills = ref([]);

const candidateComplete = ref();

const soft = ref({});
const hard = ref({});
const dream = ref("");
const selectedSoftSkills = ref([]);
const selectedHardSkills = ref([]);
const isLoading = ref(false);
const userId = localStorage.getItem("userId");
const candidateId = ref("");
const professions = ref([]);
const profileIa = ref(null);
const doAgain = ref(false);

const fetchDreams = async () => {
  try {
    const response = await axios.get("/dreams");
    professions.value = response.data
      .map((item) => item.description)
      .sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error("Erro ao buscar sonhos profissionais:", error);
  }
};

const backToProfiles = () => {
  doAgain.value = !doAgain.value;
};

const fetchCandidate = async () => {
  try {
    const response = await axios.get(`/candidates/by-user/${userId}`);
    const candidateData = response.data;
    candidateComplete.value = response.data;
    candidateId.value = candidateData.id;
    if (candidateData.profileia) {
      profileIa.value = JSON.parse(candidateData.profileia); // Parse the JSON string
    }
    console.log("Perfil ia: ", profileIa.value);
    localStorage.setItem("imagePath", candidateData.imagepath);
    localStorage.setItem("userName", candidateData.fullname);
    localStorage.setItem("dream", profileIa.value.road_map.objetivo);
  } catch (error) {
    console.error("Erro ao carregar candidato:", error);
  }
};

const fetchSkills = async () => {
  try {
    const response = await axios.get("/skills");
    const data = response.data;
    console.log("Dados recebidos:", data);
    data.forEach((item) => {
      if (item.type.startsWith("s")) {
        softSkills.value.push(item.description);
        if (!soft.value[item.category]) soft.value[item.category] = [];
        soft.value[item.category].push(item.description);
      } else if (item.type.startsWith("h")) {
        hardSkills.value.push(item.description);
        if (!hard.value[item.category]) hard.value[item.category] = [];
        hard.value[item.category].push(item.description);
      }
    });
    softSkills.value.sort((a, b) => a.localeCompare(b));
    softSkills.value.sort((a, b) => a.localeCompare(b));

    console.log("Array de softskills :", softSkills.value);
    console.log("Array de hardskills :", hardSkills.value);

    console.log({ soft: soft.value, hard: hard.value });
  } catch (error) {
    console.error("Erro ao buscar habilidades:", error);
  }
};

const sendSkills = async () => {
  try {
    const softSkillsArray = selectedSoftSkills.value;
    const hardSkillsArray = selectedHardSkills.value;

    const allSkills = [...softSkillsArray, ...hardSkillsArray];
    const skillsCompetencies = {
      skills_soft: softSkillsArray.map((skill) => `sf_${skill}`).join(", "),
      skills_hard: hardSkillsArray.map((skill) => `hs_${skill}`).join(", "),
      skillsCompetencies: [
        ...softSkillsArray.map((skill) => `sf_${skill}`),
        ...hardSkillsArray.map((skill) => `hs_${skill}`),
      ].join("-"),
    };

    console.log("Dados enviados:", skillsCompetencies);

    const response = await axios.patch(
      `/candidates/${candidateId.value}`,
      skillsCompetencies
    );
    console.log("Skills enviadas com sucesso:", response.data);
  } catch (error) {
    console.error("Erro ao enviar skills:", error);
  }
};

function formatResponse(data) {
  let formattedHtml = '<div class="text-left">';

  // Verifica se há dados no roadmap
  if (
    data.road_map &&
    data.road_map.etapas &&
    data.road_map.etapas.length > 0
  ) {
    formattedHtml += `<h2 class="text-xl font-bold mb-4 text-primary font-bold text-[26px] text-center mt-4 leading-[1]">Resultado Perfil IA</h2>`;

    // Itera pelas etapas do roadmap
    data.road_map.etapas.forEach((etapa, index) => {
      formattedHtml += `<div class="bg-white py-5 px-3 rounded lg:flex gap-5">`;
      formattedHtml += `<div class="bg-[#fbfbfb] border-[1px] border-stroke p-5 flex-1">`;
      formattedHtml += `<h3 class="text-lg font-semibold">${etapa.titulo}</h3>`;

      // Verifica se há descrição nesta etapa
      if (etapa.descricao && etapa.descricao.length > 0) {
        formattedHtml += `<ul class="list-disc list-inside ml-4">`;
        etapa.descricao.forEach((desc, descIndex) => {
          formattedHtml += `<li>${desc}</li>`;
        });
        formattedHtml += `</ul>`;
      } else {
        formattedHtml += `<p>Nenhuma descrição disponível.</p>`;
      }

      formattedHtml += `</div>`;
      formattedHtml += `</div>`;
    });
  } else {
    formattedHtml += `<p>Nenhuma etapa encontrada.</p>`;
  }

  formattedHtml += "</div>";

  return formattedHtml;
}

const saveCandidateProfileIa = async (response) => {
  try {
    const saveResponse = await axios.patch(`/candidates/${candidateId.value}`, {
      profileia: response,
    });
    doAgain.value = false;
    console.log("Perfil IA do candidato salvo com sucesso:", saveResponse.data);
  } catch (error) {
    console.error("Erro ao salvar perfil IA do candidato:", error);
  }
};

const saveProfileIa = async (saveData) => {
  try {
    const saveResponse = await axios.post(`/profileia`, {
      candidateid: candidateId.value,
      dream: saveData.dream,
      skills: saveData.skills,
      roadmap: saveData.roadmap,
      softskills: saveData.softskills,
      hardskills: saveData.hardskills,
      profileia: saveData.profileia,
    });
    console.log("Perfil IA salvo com sucesso:", saveResponse.data);
  } catch (error) {
    console.error("Erro ao salvar perfil IA:", error);
  }
};

const repeatProfileIa = () => {
  console.log("Repetir Perfil IA chamado");
  doAgain.value = true;
  console.log("doAgain atualizado para:", doAgain.value);
};

const sendInformation = async () => {
  try {
    isLoading.value = true; // Define isLoading como true para mostrar que a requisição está em andamento

    // Dados a serem enviados para a API
    const requestData = {
      soft_skills: selectedSoftSkills.value,
      hard_skills: selectedHardSkills.value,
      dream: dream.value,
    };

    // Exibe os dados enviados para a API no console para fins de debug
    console.log("Dados enviados para API:", requestData);
    console.log("Soft Skills:", selectedSoftSkills.value);
    console.log("Hard Skills:", selectedHardSkills.value);

    // Envia a requisição POST para o endpoint de respostas da API
    const response = await axios.post(
      `${import.meta.env.VITE_IA_API}/give_responses`,
      requestData
    );

    // Salva as etapas do roadmap de aprendizado na base de dados
    const roadmap = response.data.road_map.etapas;

    console.log(roadmap);

    for (const etapa of roadmap) {
      const descricao = {
        candidateId: candidateId.value,
        title: etapa.titulo,
        description: etapa.conteudos,
        level: "Não iniciado",
        dreamname: dream.value,
      };
      try {
        // Envia a descrição da etapa para o endpoint de trilhas de conhecimento
        await axios.post("/knowledge-trails/", descricao);
        console.log(`Etapa '${etapa.titulo}' enviada para a API.`);
      } catch (error) {
        console.error(
          `Erro ao enviar etapa '${etapa.titulo}' para a API:`,
          error
        );
        // Tratamento de erro, se necessário
      }
    }

    const saveData = {
      skills: [
        ...(Array.isArray(selectedSoftSkills.value)
          ? selectedSoftSkills.value
          : []),
        ...(Array.isArray(selectedHardSkills.value)
          ? selectedHardSkills.value
          : []),
      ],
      softskills: [
        ...(Array.isArray(selectedSoftSkills.value)
          ? selectedSoftSkills.value
          : []),
      ],
      hardskills: [
        ...(Array.isArray(selectedHardSkills.value)
          ? selectedHardSkills.value
          : []),
      ],
      profileia: response.data,
      dream: dream.value,
      roadmap: response.data.road_map.etapas,
    };
    console.log("response.data: ", response.data);
    console.log("Save Data: ", saveData);

    saveProfileIa(saveData);

    // Salva o perfil e habilidades na tabela do candidato
    saveCandidateProfileIa(response.data);

    // Envia as habilidades para a API após salvar as etapas e o perfil
    sendSkills();

    // Exibe a resposta da API formatada no console
    console.log("Resposta da API:", response.data);

    // Define isLoading como false após o término da requisição
    isLoading.value = false;

    // Formata a resposta da API para exibição em um Swal (SweetAlert)
    const formattedResponse = formatResponse(response.data);

    localStorage.setItem("dream", dream.value);

    // Exibe um Swal de sucesso com a resposta formatada da API
    Swal.fire({
      title: "Sucesso!",
      html: formattedResponse,
      icon: "success",
      confirmButtonText: "OK",
    });

    // Redireciona para a página de vagas após o sucesso (se necessário)
    router.push("/vagas");
  } catch (error) {
    console.error("Erro ao enviar as informações:", error);

    // Define isLoading como false em caso de erro
    isLoading.value = false;

    // Exibe um Swal de erro ao usuário
    Swal.fire({
      title: "Erro!",
      text: "Ocorreu um erro ao enviar as informações. Por favor, tente novamente.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }
};

const updateProfileIa = async (profile) => {
  localStorage.setItem("dream", profile.dream);

  const LOCAL_STORAGE_KEY = "recommendedJobs";
  localStorage.removeItem(LOCAL_STORAGE_KEY);

  selectedSoftSkills.value = profile.softskills;
  selectedHardSkills.value = profile.hardskills;
  await sendSkills();
  await saveCandidateProfileIa(profile.profileia);
  Swal.fire({
    title: "Sucesso!",
    text: "Perfil atualizado com sucesso.",
    icon: "success",
    confirmButtonText: "OK",
  }).then(() => {
    router.push("/vagas");
  });
};

onMounted(async () => {
  localStorage.setItem("page", "Desenvolvimento Proﬁssional");
  await fetchCandidate();
  await fetchSkills();
  await fetchDreams();
});
</script>

<template>
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <button
      v-if="doAgain"
      class="p-2 text-primary rounded mb-3"
      @click="backToProfiles"
    >
      Voltar
    </button>
    <div
      v-if="isLoading"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-[.8] z-[9999]"
    >
      <div class="p-5">
        <img
          src="@/assets/images/naza/andando.gif"
          class="lg:w-[450px] w-[300px]"
          alt="Loading..."
        />
        <div class="flex flex-col items-center justify-center px-4 py-3 mt-6">
          <h1
            class="text-primary bg-white px-[20px] py-[8px] rounded-[22px] font-bold text-center text-[22px] mb-[10px]"
          >
            Olá, eu sou <strong>Naza</strong>!
          </h1>
          <p class="font-medium text-[18px] text-white">
            Criando o seu perfil.
          </p>
          <span class="text-[14px] mt-[10px] text-white">Aguarde...</span>
        </div>
      </div>
    </div>

    <div v-if="profileIa && !doAgain">
      <div class="rounded flex-1 mb-4">
        <div class="mb-4 bg-white dark:bg-black p-10 rounded">
          <h2
            class="text-xl font-bold mb-4 text-primary text-[26px] leading-[1]"
          >
            Resultados Perfil IA
          </h2>
          <div>
            <Tabs>
              <template #dream1>
                <div class="flex justify-start mb-3">
                  <button
                    disabled
                    v-if="
                      candidateComplete.profileIa[0] &&
                      candidateComplete.profileIa[0].profileia ==
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-3 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Perfil em uso
                  </button>

                  <button
                    v-if="!candidateComplete.profileIa[0]"
                    @click="repeatProfileIa"
                    class="w-full lg:w-1/3 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Criar novo Perfil
                  </button>

                  <button
                    @click="updateProfileIa(candidateComplete.profileIa[0])"
                    v-if="
                      candidateComplete.profileIa[0] &&
                      candidateComplete.profileIa[0].profileia !=
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-5 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"
                      />
                    </svg>
                    Utilizar esse perfil
                  </button>
                </div>
                <div
                  v-if="
                    candidateComplete.profileIa[0] &&
                    candidateComplete.profileIa[0].length != 0
                  "
                >
                  <div class="mb-4">
                    <div
                      class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1 mb-4"
                    >
                      <p class="text-xl">
                        Sonho:
                        <span class="text-primary font-bold">{{
                          candidateComplete.profileIa[0].dream ||
                          "Não especificado"
                        }}</span>
                      </p>
                    </div>

                    <div class="lg:flex gap-5">
                      <!-- Cursos -->
                      <div
                        class="bg-white dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Cursos</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="
                            candidateComplete.profileIa[0].roadmap.lengh != 0
                          "
                        >
                          <p class="mb-4"><strong>Fundamentos:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[0].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  class="capitalize"
                                  v-for="(
                                    conteudo, conteudoIndex
                                  ) in etapa.conteudos"
                                  :key="conteudoIndex"
                                >
                                  {{ conteudo || "Conteúdo não especificado" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p><strong>Habilidades:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[0].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(
                                    habilidade, habilidadeIndex
                                  ) in etapa.habilidades"
                                  :key="habilidadeIndex"
                                >
                                  {{
                                    habilidade || "Habilidade não especificada"
                                  }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p>Dados do curso não disponíveis.</p>
                        </div>
                      </div>
                      <!-- Roadmap -->
                      <div
                        class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Roadmap</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="profileIa.road_map"
                        >
                          <p class="mb-4">
                            <strong>Objetivo:</strong>
                            {{
                              candidateComplete.profileIa[0].dream ||
                              "Não especificado"
                            }}
                          </p>
                          <p><strong>Soft Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[0].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[0].softskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma soft skill especificada.</p>
                          <p><strong>Hard Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[0].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[0].hardskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma hard skill especificada.</p>
                          <p><strong>Etapas:</strong></p>
                          <ul
                            class="list-none"
                            v-if="
                              candidateComplete.profileIa[0].roadmap.length > 0
                            "
                          >
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[0].roadmap"
                              :key="index"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(desc, descIndex) in etapa.descricao"
                                  :key="descIndex"
                                >
                                  {{ desc || "Descrição não especificada" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p v-else>Nenhuma etapa especificada.</p>
                        </div>
                        <div v-else>
                          <p>Dados do roadmap não disponíveis.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #dream2>
                <div class="flex justify-center mb-3">
                  <button
                    disabled
                    v-if="
                      candidateComplete.profileIa[1] &&
                      candidateComplete.profileIa[1].profileia ==
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-3 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>

                    Perfil em uso
                  </button>

                  <button
                    v-if="!candidateComplete.profileIa[1]"
                    @click="repeatProfileIa"
                    class="w-full lg:w-1/3 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Criar novo Perfil
                  </button>

                  <button
                    @click="updateProfileIa(candidateComplete.profileIa[1])"
                    v-if="
                      candidateComplete.profileIa[1] &&
                      candidateComplete.profileIa[1].profileia !=
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-5 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"
                      />
                    </svg>
                    Utilizar esse perfil
                  </button>
                </div>

                <div
                  v-if="
                    candidateComplete.profileIa[1] &&
                    candidateComplete.profileIa[1].length != 0
                  "
                >
                  <div class="mb-4">
                    <div
                      class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1 mb-4"
                    >
                      <p class="text-xl">
                        Sonho:
                        <span class="text-primary font-bold">{{
                          candidateComplete.profileIa[1].dream ||
                          "Não especificado"
                        }}</span>
                      </p>
                    </div>

                    <div class="lg:flex gap-5">
                      <!-- Cursos -->
                      <div
                        class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Cursos</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="
                            candidateComplete.profileIa[1].roadmap.lengh != 0
                          "
                        >
                          <p class="mb-4"><strong>Fundamentos:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[1].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  class="capitalize"
                                  v-for="(
                                    conteudo, conteudoIndex
                                  ) in etapa.conteudos"
                                  :key="conteudoIndex"
                                >
                                  {{ conteudo || "Conteúdo não especificado" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p><strong>Habilidades:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[1].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(
                                    habilidade, habilidadeIndex
                                  ) in etapa.habilidades"
                                  :key="habilidadeIndex"
                                >
                                  {{
                                    habilidade || "Habilidade não especificada"
                                  }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p>Dados do curso não disponíveis.</p>
                        </div>
                      </div>
                      <!-- Roadmap -->
                      <div
                        class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Roadmap</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="profileIa.road_map"
                        >
                          <p class="mb-4">
                            <strong>Objetivo:</strong>
                            {{
                              candidateComplete.profileIa[1].dream ||
                              "Não especificado"
                            }}
                          </p>
                          <p><strong>Soft Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[1].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[1].softskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma soft skill especificada.</p>
                          <p><strong>Hard Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[1].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[1].hardskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma hard skill especificada.</p>
                          <p><strong>Etapas:</strong></p>
                          <ul
                            class="list-none"
                            v-if="
                              candidateComplete.profileIa[1].roadmap.length > 0
                            "
                          >
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[1].roadmap"
                              :key="index"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(desc, descIndex) in etapa.descricao"
                                  :key="descIndex"
                                >
                                  {{ desc || "Descrição não especificada" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p v-else>Nenhuma etapa especificada.</p>
                        </div>
                        <div v-else>
                          <p>Dados do roadmap não disponíveis.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #dream3>
                <div class="flex justify-end mb-3">
                  <button
                    disabled
                    v-if="
                      candidateComplete.profileIa[2] &&
                      candidateComplete.profileIa[2].profileia ==
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-3 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    Perfil em uso
                  </button>

                  <button
                    v-if="!candidateComplete.profileIa[2]"
                    @click="repeatProfileIa"
                    class="w-full lg:w-1/3 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Criar novo Perfil
                  </button>

                  <button
                    @click="updateProfileIa(candidateComplete.profileIa[2])"
                    v-if="
                      candidateComplete.profileIa[2] &&
                      candidateComplete.profileIa[2].profileia !=
                        candidateComplete.profileia
                    "
                    class="w-full lg:w-1/3 bg-meta-5 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"
                      />
                    </svg>
                    Utilizar esse perfil
                  </button>
                </div>
                <div
                  v-if="
                    candidateComplete.profileIa[2] &&
                    candidateComplete.profileIa[2].length != 0
                  "
                >
                  <div class="mb-4">
                    <div
                      class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1 mb-4"
                    >
                      <p class="text-xl">
                        Sonho:
                        <span class="text-primary font-bold">{{
                          candidateComplete.profileIa[2].dream ||
                          "Não especificado"
                        }}</span>
                      </p>
                    </div>

                    <div class="lg:flex gap-5">
                      <!-- Cursos -->
                      <div
                        class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Cursos</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="
                            candidateComplete.profileIa[2].roadmap.lengh != 0
                          "
                        >
                          <p class="mb-4"><strong>Fundamentos:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[2].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  class="capitalize"
                                  v-for="(
                                    conteudo, conteudoIndex
                                  ) in etapa.conteudos"
                                  :key="conteudoIndex"
                                >
                                  {{ conteudo || "Conteúdo não especificado" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p><strong>Habilidades:</strong></p>
                          <ul class="list-disc list-inside">
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[2].roadmap"
                              :key="index"
                              class="list-none mb-2"
                            >
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(
                                    habilidade, habilidadeIndex
                                  ) in etapa.habilidades"
                                  :key="habilidadeIndex"
                                >
                                  {{
                                    habilidade || "Habilidade não especificada"
                                  }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p>Dados do curso não disponíveis.</p>
                        </div>
                      </div>
                      <!-- Roadmap -->
                      <div
                        class="bg-white text-gray-200 dark:bg-black dark:text-white rounded-lg border-[1px] border-bodydark1 p-5 flex-1"
                      >
                        <h3 class="text-lg font-semibold">Roadmap</h3>
                        <div
                          class="rounded-lg bg-gray-2 dark:bg-black border-[1px] border-bodydark1 py-4 px-8 mt-3"
                          v-if="profileIa.road_map"
                        >
                          <p class="mb-4">
                            <strong>Objetivo:</strong>
                            {{
                              candidateComplete.profileIa[2].dream ||
                              "Não especificado"
                            }}
                          </p>
                          <p><strong>Soft Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[2].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[2].softskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma soft skill especificada.</p>
                          <p><strong>Hard Skills:</strong></p>
                          <ul
                            class="list-disc list-inside"
                            v-if="
                              candidateComplete.profileIa[2].skills.length > 0
                            "
                          >
                            <li
                              v-for="(skill, index) in candidateComplete
                                .profileIa[2].hardskills"
                              :key="index"
                            >
                              {{ skill }}
                            </li>
                          </ul>
                          <p v-else>Nenhuma hard skill especificada.</p>
                          <p><strong>Etapas:</strong></p>
                          <ul
                            class="list-none"
                            v-if="
                              candidateComplete.profileIa[2].roadmap.length > 0
                            "
                          >
                            <li
                              v-for="(etapa, index) in candidateComplete
                                .profileIa[2].roadmap"
                              :key="index"
                            >
                              <p class="font-semibold">
                                {{ etapa.titulo || "Título não especificado" }}
                              </p>
                              <ul class="list-disc list-inside">
                                <li
                                  v-for="(desc, descIndex) in etapa.descricao"
                                  :key="descIndex"
                                >
                                  {{ desc || "Descrição não especificada" }}
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p v-else>Nenhuma etapa especificada.</p>
                        </div>
                        <div v-else>
                          <p>Dados do roadmap não disponíveis.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Tabs>
          </div>
        </div>
        <!-- 
        <div class="flex justify-between">
          <button @click="repeatProfileIa"
            class="w-full lg:w-1/3 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            Refazer perfil
          </button>
          <button
            class="w-full lg:w-1/4 bg-meta-3 text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
            </svg>
            Utilizar esse sonho
          </button>
        </div> -->
      </div>
    </div>

    <div
      v-if="doAgain || !profileIa"
      class="bg-white dark:bg-black border border-stroke rounded-lg py-5 px-5"
    >
      <div class="mt-4 mb-8">
        <h2 class="text-primary font-bold text-[26px] leading-[1]">
          Qual é o seu <span class="font-black">sonho profissional?</span>
        </h2>
        <p class="leading-[1.2] mt-4 max-w-[640px] mb-6">
          Descubra qual é o seu objetivo de carreira, ou seja, o trabalho dos
          seus sonhos e as metas profissionais que deseja alcançar.
        </p>
        <div class="mb-4">
          <select
            class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="dream"
            required
          >
            <option value="" disabled>Escolha seu sonho profissional</option>
            <option
              v-for="profession in professions"
              :key="profession"
              :value="profession"
            >
              {{ profession }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-4 mb-8">
        <h1 class="text-primary font-bold text-[26px] leading-[1]">
          Conquiste seus <span class="font-black">sonhos!</span>
        </h1>
        <p class="leading-[1.2] mt-4 max-w-[640px] mb-6">
          O primeiro passo para uma carreira de sucesso é identificar seus
          interesses, habilidades e objetivos profissionais para direcionar sua
          busca por vagas e cursos relevantes.
        </p>
      </div>
      <div class="flex lg:flex-row flex-col justify-between items-start gap-3">
        <div
          class="w-full lg:w-1/2 bg-[#f8fafb] dark:bg-black border border-stroke rounded-lg shadow"
        >
          <div class="p-4">
            <img
              src="@/assets/images/icon/users-add.svg"
              class="w-[40px] mb-2"
              alt=""
            />
            <h5
              class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
            >
              Soft Skills
            </h5>
            <p
              class="mb-3 leading-[1.2] font-normal text-gray-500 dark:text-gray-400"
            >
              Refere-se a
              <span class="font-bold"
                >habilidades interpessoais e comportamentais</span
              >, como comunicação, trabalho em equipe ou resolução de problemas.
            </p>
            <p
              class="inline-flex font-medium items-center text-blue-600 hover:underline mb-2"
            >
              Selecione suas habilidades
            </p>
            <div class="max-h-[300px] overflow-y-auto">
              <div v-for="[key, value] in Object.entries(soft)" :key="key">
                <label>
                  <br />
                  <strong>{{ key }}</strong>
                  <br />
                  <br />
                  <div v-for="skill in value" :key="skill">
                    <label>
                      <input
                        type="checkbox"
                        :value="skill"
                        v-model="selectedSoftSkills"
                      />
                      {{ skill }}
                    </label>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full lg:w-1/2 bg-[#f8fafb] dark:bg-black border border-stroke rounded-lg shadow"
        >
          <div class="p-4">
            <img
              src="@/assets/images/icon/security.svg"
              class="w-[40px] mb-2"
              alt=""
            />
            <h5
              class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
            >
              Hard Skills
            </h5>
            <p
              class="mb-3 leading-[1.2] font-normal text-gray-500 dark:text-gray-400"
            >
              São <span class="font-bold">habilidades técnicas</span> e
              específicas relacionadas a uma área de conhecimento ou trabalho,
              como programação ou design gráfico.
            </p>
            <p
              class="inline-flex font-medium items-center text-blue-600 hover:underline mb-2"
            >
              Selecione suas habilidades
            </p>
            <div class="max-h-[300px] overflow-y-auto">
              <div v-for="[key, value] in Object.entries(hard)" :key="key">
                <label>
                  <br />
                  <strong>{{ key }}</strong
                  ><br />
                  <br />
                  <div v-for="skill in value" :key="skill">
                    <label>
                      <input
                        type="checkbox"
                        :value="skill"
                        v-model="selectedHardSkills"
                      />
                      {{ skill }}
                    </label>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        @click="sendInformation"
        class="w-full lg:w-1/4 bg-primary text-white rounded-md inline-flex items-center justify-center gap-2.5 py-4 mt-4 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
            />
          </svg>
        </span>
        Enviar
      </button>
    </div>
  </DefaultLayout>
</template>

<script>
export default {
  components: {
    MultiSelectView,
    ButtonDefault,
    Tabs,
  },
};
</script>

<style>
div:where(.swal2-container){
  z-index: 9999!important;
}
div:where(.swal2-container) div:where(.swal2-popup){
  width:60em!important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
  background-color: #3C50E0!important;
  padding: 10px 50px;
}
</style>