<script setup lang="ts">
import { ref } from "vue";
import DataStatsOne from '@/components/DataStats/DataStatsOne.vue'
import ChartOne from '@/components/Charts/ChartOne.vue'
import ChartTwo from '@/components/Charts/ChartTwo.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";

const pageTitle = ref("Orgãos");
</script>

<template>
    <DefaultLayout>
        <div>
            <BreadcrumbDefault :pageTitle="pageTitle" />
            <DataStatsOne />
        </div>

        <div class="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <ChartOne />
            <ChartTwo />
        </div>
    </DefaultLayout>
</template>
