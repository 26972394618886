<script lang="ts">
import { defineComponent, ref } from "vue";
import axios from "axios";
import imageBot from "@/assets/images/icon/nazaHead.png";
import gifLoading from "@/assets/images/icon/loading.gif";
import imagemGeneric from "@/assets/images/user/user-generic.svg";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    let imagemBot = imageBot;
    let imagemUser = localStorage.getItem("imagePath");
    let gifLod = gifLoading;
    const message = ref<string>("");
    const file = ref<string | null>(null);
    let fileName: string = "";
    const route = useRoute();
    const routeStyles = route.meta.styles || {};

    function getFileName(): void {
      let url = window.location.href;
      let bar = url.lastIndexOf("/");
      file.value = url.substring(bar + 1);
      fileName = file.value;
    }
    const sendMessage = async () => {
      if (message.value.trim() !== "" && file.value?.trim() !== "") {
        createMesageUser();
        const objectRequest = {
          question: message.value,
          pdf_name: file.value,
        };
        message.value = "";

        try {
          const response = await axios.post(
            `${import.meta.env.VITE_CHAT_OPORTUNIDADE}/chat/`,
            objectRequest
          );

          if (response.data) {
            createMessageNasa(response.data.answer);
          } else {
            // Tratar caso de erro na resposta, se necessário
          }
        } catch (error) {
          console.error("Erro ao enviar mensagem:", error);
          // Tratar erro de requisição, se necessário
        }
      }
    };

    function getTimeNow(): string {
      let date: Date = new Date();
      let hour: string = date.getHours().toString();
      let minutes: string = date.getMinutes().toString();
      return `${hour.length == 1 ? "0" + hour : hour}:${
        minutes.length == 1 ? "0" + minutes : minutes
      }`;
    }

    function createMesageUser(): void {
      let button: HTMLElement | null = document.getElementById("buttonMessage");
      let text: HTMLElement | null = document.getElementById("textMessage");

      if (text && button) {
        text.setAttribute("placeholder", "Carregando...");
        button.classList.add("disabled");
        text.classList.add("disabled");

        const principalDiv: HTMLDivElement = document.createElement("div");
        principalDiv.classList.add(
          "flex",
          "flex-row",
          "justify-end",
          "mt-2",
          "mb-2"
        );

        const divMessage: HTMLDivElement = document.createElement("div");
        divMessage.classList.add("mr-3");

        const pText: HTMLElement = document.createElement("p");
        pText.classList.add("leading-[1.2]", "lg:text-[18px]", "mt-[10px]");
        pText.textContent = (text as HTMLTextAreaElement).value; // Cast para HTMLTextAreaElement para acessar `value`
        divMessage.appendChild(pText);

        const divAvatar: HTMLDivElement = document.createElement("div");
        divAvatar.classList.add("flex-shrink-0", "items-end", "text-center");

        const spanAvatar: HTMLSpanElement = document.createElement("span");
        spanAvatar.classList.add(
          "inline-block",
          "h-10",
          "w-10",
          "rounded-full",
          "text-white",
          "text-center",
          "leading-10"
        );

        const imgElement: HTMLImageElement = document.createElement("img");
        imgElement.classList.add("rounded-full");
        if (imagemUser !== null) {
          imgElement.src = imagemUser;
        } else {
          imgElement.src = imagemGeneric;
        }

        spanAvatar.appendChild(imgElement);
        divAvatar.appendChild(spanAvatar);

        const pHour: HTMLElement = document.createElement("p");
        pHour.classList.add("text-[10px]", "text-gray-500", ".leading-[1.2]");
        pHour.textContent = getTimeNow();

        divAvatar.appendChild(pHour);

        principalDiv.appendChild(divMessage);
        principalDiv.appendChild(divAvatar);

        const container: HTMLElement | null = document.getElementById("chat");
        if (container) {
          container.appendChild(principalDiv);
          createLoading();
        }
      }
    }

    function createMessageNasa(message: string): void {
      let loadingDiv: HTMLElement | null = document.getElementById("loading");
      let button: HTMLElement | null = document.getElementById("buttonMessage");
      let text: HTMLElement | null = document.getElementById("textMessage");

      if (loadingDiv && button && text) {
        button.classList.remove("disabled");
        text.classList.remove("disabled");
        text.setAttribute("placeholder", "Pergunte aqui...");
        loadingDiv.remove();

        const principalDiv: HTMLDivElement = document.createElement("div");
        principalDiv.classList.add("flex", "mt-2", "mb-2");

        const divAvatar: HTMLDivElement = document.createElement("div");
        divAvatar.classList.add("flex-shrink-0", "text-center");

        const spanAvatar: HTMLSpanElement = document.createElement("span");
        spanAvatar.classList.add(
          "inline-block",
          "rounded-full",
          "text-white",
          "text-center",
          "leading-10"
        );

        const imgElement: HTMLImageElement = document.createElement("img");
        imgElement.classList.add("w-20");
        imgElement.src = imagemBot;

        spanAvatar.appendChild(imgElement);
        divAvatar.appendChild(spanAvatar);

        const pName: HTMLElement = document.createElement("p");
        pName.classList.add("text-[12px]", "text-gray-500", "leading-[1.2]");
        pName.textContent = "Naza";

        const pHour: HTMLElement = document.createElement("p");
        pHour.classList.add("text-[10px]", "text-gray-500", "leading-[1.2]");
        pHour.textContent = getTimeNow();

        divAvatar.appendChild(pName);
        divAvatar.appendChild(pHour);

        const divMessage: HTMLDivElement = document.createElement("div");
        divMessage.classList.add("ml-3");

        const pText: HTMLElement = document.createElement("p");
        pText.classList.add("leading-[1.2]", "lg:text-[18px]", "mt-[10px]");
        pText.textContent = message;
        divMessage.appendChild(pText);

        principalDiv.appendChild(divAvatar);
        principalDiv.appendChild(divMessage);

        const container: HTMLElement | null = document.getElementById("chat");
        if (container) {
          container.appendChild(principalDiv);
        }
      }
    }

    function createLoading(): void {
      const loading: HTMLDivElement = document.createElement("div");
      loading.id = "loading";
      loading.classList.add("mt-3");

      const img: HTMLImageElement = document.createElement("img");
      img.src = gifLod;
      img.width = 40;

      loading.appendChild(img);

      const container: HTMLElement | null = document.getElementById("chat");
      if (container) {
        container.appendChild(loading);
      }
    }

    getFileName();

    return {
      getTimeNow,
      sendMessage,
      fileName,
      message,
      routeStyles,
    };
  },
});
</script>

<template>
  <div :style="routeStyles as any" id="chat" class="overflow-hidden chat-bot">
    <div class="flex">
      <div class="flex-shrink-0 text-center">
        <span class="w-20 inline-block rounded-full text-white text-center">
          <img class="w-full" src="@/assets/images/icon/nazaHead.png" />
        </span>
        <p class="text-[12px] text-gray-500 leading-[1]">Naza</p>
        <p class="text-[10px] text-gray-500 leading-[1.2]">
          {{ getTimeNow() }}
        </p>
      </div>

      <div class="ml-3 mt-[10px]">
        <p class="leading-[1.2] lg:text-[18px] mt-[15px]">
          Olá, meu nome é Naza, estou aqui para responder suas perguntas sobre o
          documento:<br /> <span class="font-bold">{{ fileName }}</span>.
        </p>
      </div>
    </div>
  </div>
  <div
    class="sendmessage flex lg:flex-row flex-col lg:items-stretch items-center"
  >
    <textarea
      v-model="message"
      id="textMessage"
      class="p-[10px] rounded-[12px] lg:w-[90%] w-full border-[1px] border-stroke"
      placeholder="Pergunte aqui..."
      cols="30"
      rows="2"
      @keydown.enter.prevent="sendMessage"
    ></textarea>
    <button
      v-on:click="sendMessage"
      id="buttonMessage"
      class="rounded-[8px] text-white bg-primary hover:bg-bodydark2 ml-2 lg:w-[9%] w-full mt-[10px] lg:mt-0 py-[10px] lg:py-0"
      type="submit"
    >
      Enviar
    </button>
  </div>
</template>

<style scoped>
.chat-bot {
  padding: 2rem;
  height: 60vh;
  overflow: auto;
}

.sendmessage {
  position: relative;
  bottom: 0;
  padding: 1.5rem;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
</style>
