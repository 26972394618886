import axios from 'axios';

const courseService = {
  async getCourses() {
    try {
      const response = await axios.get('https://fabricadegenios.com.br/wp-json/tutor/v1/courses', {
        auth: {
          username: import.meta.env.VITE_FABRICA_API_KEY,
          password: import.meta.env.VITE_FABRICA_SECRET_KEY
        }
      });
      return response.data.data.posts;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
};

export default courseService;
