<script setup lang="ts">
import ButtonDefault from "@/components/Buttons/ButtonDefault.vue";
import { onMounted, ref } from "vue";
import axios from "@/api/axios";
import { fetchEnterprise } from "@/services/enterpriseService";
import { useRouter } from "vue-router";
import pwd from "@/assets/images/icon/pwd.svg?url";
import Swal from "sweetalert2";
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const router = useRouter();
const userStore = useUserStore();
const usertype = ref(localStorage.getItem("usertype"));
const auth = ref<any>();
const pwdIcon = `
 <svg fill="#FFF" class="w-6 h-6" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 491.975 491.975" xml:space="preserve">
<g id="XMLID_28_">
	<path id="XMLID_31_" d="M225.315,96.963c26.748,0,48.479-21.706,48.479-48.481C273.794,21.699,252.063,0,225.315,0
		c-26.779,0-48.492,21.699-48.492,48.482C176.823,75.257,198.536,96.963,225.315,96.963z"/>
	<path id="XMLID_30_" d="M300.233,371.688c-12.883,44.732-54.121,77.583-102.946,77.583c-59.126,0-107.209-48.085-107.209-107.193
		c0-43.754,26.396-81.413,64.066-98.054V198.58c-61.69,18.581-106.764,75.847-106.764,143.498
		c0,82.649,67.247,149.897,149.906,149.897c60.238,0,112.159-35.801,135.966-87.169l-16.926-33.255
		C311.575,371.59,306.071,371.64,300.233,371.688z"/>
	<path id="XMLID_29_" d="M441.48,429.237l-64.939-127.672c-4.847-9.553-14.645-15.566-25.363-15.566h-83.173v-18.966h71.582
		c7.148,0,13.156-3.736,17.037-9.118c2.522-3.506,4.316-7.579,4.316-12.236c0-11.789-9.549-21.351-21.353-21.351h-43.125h-28.457
		V160c0-17.692-13.118-42.704-42.689-42.704c-23.584,0-42.703,19.122-42.703,42.704v139.372c0,24.058,19.503,43.561,43.562,43.561
		h78.265h29.284l57.024,112.117c5.011,9.875,15.011,15.573,25.389,15.573c4.35,0,8.761-0.994,12.883-3.104
		C443.054,460.401,448.628,443.251,441.48,429.237z"/>
</g>
</svg>
`;

const editalIcon = `
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>
`;

type jobResponse = {
  area_id_area: number;
  end_date: string;
  enterpriseid: number;
  id_job: number;
  image: string;
  job_type: string;
  jobs_number: number;
  notes: string;
  pcd_jobs: string;
  position: string;
  salary: number;
  skills_hard: string;
  skills_soft: string;
  start_date: string;
  candidacys: Array<any>;
  id_notice: number;
};

type FilterJob = {
  id: number;
  idJob: number; // Adicione idJob aqui, se ele existe na sua API
  name: string;
  category: string;
  cargo: string;
  qtd: number;
  money: string;
  startSelection: string;
  finalSelection: string;
  status: string;
  candidacy?: any;
  pcd?: string;
  id_notice?: number;
};

const jobs = ref<FilterJob[]>([]);

const fetchjobs = async (enterpriseId: number) => {
  if (usertype.value == "enterprise" || usertype.value == "userEnterprise") {
    try {
      // Search for company vacancies based on enterpriseId
      const response = await axios.get(
        `/job-positions/enterprise/${enterpriseId}`
      );
      jobs.value = response.data.map((chunk: jobResponse) => ({
        id: chunk.area_id_area,
        idJob: chunk.id_job, // Aqui mapeie para idJob se existir na sua API
        name: chunk.position,
        category: chunk.job_type,
        cargo: chunk.position,
        qtd: chunk.jobs_number,
        money: chunk.salary,
        startSelection: chunk.start_date,
        finalSelection: chunk.end_date,
        status: "Ativo",
        candidacy: chunk.candidacys,
        pcd: chunk.pcd_jobs,
        id_notice: chunk.id_notice,
      }));
    } catch (error) {
      console.error("Erro ao carregar vagas de emprego:", error);
    }
  } else if (
    usertype.value == "secretary" ||
    usertype.value == "userSecretary"
  ) {
    try {
      // Search for company vacancies based on enterpriseId
      const response = await axios.get(
        `/job-positions/secretary/${enterpriseId}`
      );
      jobs.value = response.data.map((chunk: jobResponse) => ({
        id: chunk.area_id_area,
        idJob: chunk.id_job, // Aqui mapeie para idJob se existir na sua API
        name: chunk.position,
        category: chunk.job_type,
        cargo: chunk.position,
        qtd: chunk.jobs_number,
        money: chunk.salary,
        startSelection: chunk.start_date,
        finalSelection: chunk.end_date,
        status: "Ativo",
        candidacy: chunk.candidacys,
        pcd: chunk.pcd_jobs,
        id_notice: chunk.id_notice,
      }));
    } catch (error) {
      console.error("Erro ao carregar vagas de emprego:", error);
    }
  }
};

const initialize = async () => {
  if (usertype.value == "enterprise") {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("Usuário não encontrado no localStorage");
      }

      // Fetch company ID based on userId
      const enterprise = await fetchEnterprise(userId);
      if (!enterprise || !enterprise.id) {
        throw new Error("Empresa não encontrada para o usuário");
      }

      // Call fetchjobs with the enterpriseId
      await fetchjobs(enterprise.id);
    } catch (error) {
      console.error("Erro ao inicializar:", error);
    }
  } else if (usertype.value == "secretary") {
    let authMe = await axios.post("auth/me");
    let id = authMe.data.secretaries[0].id;
    await fetchjobs(id);
  } else if (usertype.value == "userSecretary") {
    let authMe = await axios.post("auth/me");
    auth.value = authMe.data;
    let id = authMe.data.userSecretaries[0].secretary.id;
    if (authMe.data.userSecretaries[0].permissionjob != "off") {
      await fetchjobs(id);
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      }).then(() => {
        router.push("/");
      });
    }
  } else if (usertype.value == "userEnterprise") {
    let authMe = await axios.post("auth/me");
    auth.value = authMe.data;
    let id = authMe.data.userenterprises[0].enterprise.id;
    if (authMe.data.userenterprises[0].permissionjob != "off") {
      await fetchjobs(id);
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      }).then(() => {
        router.push("/");
      });
    }
  }
};

function redirectToUpdate(id: number): void {
  if (usertype.value == "enterprise" || usertype.value == "secretary") {
    router.push(`/atualizar-vaga/${id}`);
  } else if (usertype.value == "userEnterprise") {
    if (auth.value.userenterprises[0].permissionjob == "full") {
      router.push(`/atualizar-vaga/${id}`);
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  } else if (usertype.value == "userSecretary") {
    if (auth.value.userSecretaries[0].permissionjob == "full") {
      router.push(`/atualizar-vaga/${id}`);
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  }
}

function redirectToShowCandidacy(id: number): void {
  router.push(`/candidates/${id}`);
}

const redirectToAddHiring = () => {
  if (usertype.value == "secretary" || usertype.value == "enterprise") {
    router.push("/adicionar-vaga");
  } else if (usertype.value == "userEnterprise") {
    if (auth.value.userenterprises[0].permissionjob == "full") {
      router.push("/adicionar-vaga");
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  } else if (usertype.value == "userSecretary") {
    if (auth.value.userSecretaries[0].permissionjob == "full") {
      router.push("/adicionar-vaga");
    } else {
      Swal.fire({
        title: "Acesso Negado",
        text: "Usuário não possui acesso á esta área!",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  }
};

onMounted(() => {
  initialize();
});

const showToSecretaryAndEnterprise = computed(
  () =>
    userStore.isSecretary ||
    userStore.isEnterprise ||
    userStore.isUserEnterprise ||
    userStore.isUserSecretary
);
</script>


<template>
  <DefaultLayout>
    <div class="py-6">
      <div class="my-3">
        <div v-if="showToSecretaryAndEnterprise" class="mb-5.5 inline-block">
          <button
            @click="redirectToAddHiring"
            class="flex justify-center rounded-[22px] bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90"
          >
            Criar vagas
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 ml-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div
          v-for="job in jobs"
          :key="job.id"
          class="bg-white rounded-lg px-6 py-3 border-[#dee3ea] border-[1px] flex flex-col justify-between dark:bg-boxdark"
        >
          <div class="flex items-center justify-between mb-[30px]">
            <h2 class="uppercase font-extrabold text-black dark:text-white">
              {{ job.name }}
            </h2>
            <button
              v-if="job.pcd !== null && job.pcd !== '' && job.pcd !== '0'"
              title="Vaga PCD"
              class="bg-primary text-white rounded-full p-2"
            >
              <div v-html="pwdIcon"></div>
            </button>

            <button
              v-if="job.id_notice !== 0"
              title="Vaga de Edital"
              class="bg-green-700 text-white rounded-full p-2"
            >
              <div v-html="editalIcon"></div>
            </button>
          </div>
          <div class="flex justify-center lg:justify-start items-baseline mb-4">
            <p
              v-if="job.qtd != 0"
              class="lg:text-[66px] text-[40px] font-extrabold text-primary"
            >
              {{ job.qtd }}
            </p>
            <span class="ml-2 text-lg font-medium">
              {{
                job.qtd == 0
                  ? "SEM NENHUMA VAGA DISPONÍVEL"
                  : job.qtd == 1
                  ? "VAGA"
                  : "VAGAS"
              }}
            </span>
          </div>
          <div
            class="flex lg:flex-row flex-col justify-start items-center gap-3"
          >
            <button
              v-if="job.candidacy.length != '0'"
              @click="redirectToShowCandidacy(job.idJob)"
              class="bg-primary text-white px-3 py-1 rounded-md text-[14px] hover:bg-blue-700"
            >
              Visualizar
              {{
                job.candidacy.length == 1
                  ? "1 candidatura"
                  : job.candidacy.length + " candidaturas"
              }}
            </button>
            <button
              v-if="showToSecretaryAndEnterprise"
              class="bg-gray px-3 py-1 font-medium text-[14px] rounded-md hover:bg-gray-400 dark:border-strokedark dark:text-black"
              @click="redirectToUpdate(job.idJob)"
            >
              Editar
            </button>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>
