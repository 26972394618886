<template>
  <div class="resume p-8 max-w-[906px] bg-white dark:bg-black rounded-lg shadow-md mt-10 mx-auto">
    <div class="flex lg:flex-row flex-col justify-between items-start lg:gap-12">
      <div class="lg:w-[55%] flex flex-col justify-center items-start">
        
        <div>
          <img :src="resume.photo" alt="Foto de {{ resume.name }}" 
          class="w-[15rem] h-[15rem] shadow-lg rounded-full mb-8" />
          <div class="lg:hidden mb-6">
            <h1 class="lg:text-[45px] text-[32px] font-bold text-primary leading-[1.2]">{{ resume.name }}</h1>
            <p class="text-sm font-medium mt-2">{{ resume.age }} anos</p>
          </div>
        </div>

        <div class="flex items-start flex-col gap-2">
          <p class="flex justify-center items-center gap-2">
            <svg width="18px" height="18px" viewBox="0 -4 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
              fill="#3c50e0">
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <title>mail</title>
                <desc>Created with Sketch Beta.</desc>
                <defs> </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                  <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-412.000000, -259.000000)"
                    fill="#3c50e0">
                    <path
                      d="M442,279 C442,279.203 441.961,279.395 441.905,279.578 L433,270 L442,263 L442,279 L442,279 Z M415.556,280.946 L424.58,271.33 L428,273.915 L431.272,271.314 L440.444,280.946 C440.301,280.979 415.699,280.979 415.556,280.946 L415.556,280.946 Z M414,279 L414,263 L423,270 L414.095,279.578 C414.039,279.395 414,279.203 414,279 L414,279 Z M441,261 L428,271 L415,261 L441,261 L441,261 Z M440,259 L416,259 C413.791,259 412,260.791 412,263 L412,279 C412,281.209 413.791,283 416,283 L440,283 C442.209,283 444,281.209 444,279 L444,263 C444,260.791 442.209,259 440,259 L440,259 Z"
                      id="mail" sketch:type="MSShapeGroup"> </path>
                  </g>
                </g>
              </g>
            </svg>
            <span class="text-sm font-medium">{{ resume.contact.email }}</span>
          </p>
          <p class="flex justify-center items-center gap-2">
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0" />
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
              <g id="SVGRepo_iconCarrier">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z"
                  fill="#3c50e0" />
              </g>
            </svg>
            <span class="text-sm font-medium">{{ resume.contact.cellphone }}</span>
          </p>
          <p class="flex justify-center items-center gap-2">
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z"
                stroke="#3c50e0" stroke-width="1.5" />
              <path d="M8 12H16" stroke="#3c50e0" stroke-width="1.5" stroke-linecap="round" />
              <path d="M8 8H16" stroke="#3c50e0" stroke-width="1.5" stroke-linecap="round" />
              <path d="M8 16H13" stroke="#3c50e0" stroke-width="1.5" stroke-linecap="round" />
            </svg>
            <span class="text-sm font-medium">{{ resume.document.cpf }}</span>
          </p>
        </div>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Redes
          Sociais</h2>
        <div>
          <ul>
            <li v-for="(link, platform) in resume.socialmedia" :key="platform" class="mb-4">
              <h3 class="font-medium text-sm capitalize">{{ platform }}</h3>
              <p class="text-sm link">{{ link }}</p>
            </li>            
            <li class="mb-4" v-if="resume.socialmedia && Object.keys(resume.socialmedia).length === 0">
              <p class="text-sm">Nenhuma rede social cadastrada.</p>
            </li>
          </ul>
          <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-2 hide-for-print"
            @click="showModal('socialmedia')">Adicionar Rede Social
          </button>
        </div>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Idiomas</h2>
        <ul>
          <li v-for="language in resume.languages" :key="language.id" class="mb-4">
            <div class="flex justify-start items-center gap-2">
              <svg width="18px" height="18px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path fill="#3c50e0"
                  d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z" />
              </svg>
              <h3 class="text-sm font-bold">{{ language.name }}</h3> |
              <p class="text-sm">{{ language.level }}</p>
            </div>
          </li>
          <li class="mb-4" v-if="resume.languages.length === 0">
            <p class="text-sm">Nenhum idioma cadastrado.</p>
          </li>
        </ul>
        <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-2 hide-for-print"
          @click="showModal('language')">Adicionar Idioma</button>
      </div>

      <div class="w-full flex flex-col justify-center items-start mt-[40px]">
        <div class="lg:block hidden">
          <h1 class="lg:text-[45px] text-[32px] font-bold text-primary leading-[1.0]">{{ resume.name }}</h1>
          <p class="text-sm font-medium mt-2">{{ resume.age }} anos</p>
        </div>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Experiência Profissional</h2>
          <div>
            <ul>
              <li v-for="experience in resume.experience" :key="experience.id" class="mb-4">
                <h3 class="text-lg font-bold">{{ experience.jobTitle }} - {{ experience.company }}</h3>
                <p class="text-sm font-medium">{{ experience.startDate }} - {{ experience.endDate }}</p>
                <p class="text-sm">{{ experience.description }}</p>
              </li>
              <li v-if="resume.experience.length === 0" class="mb-4">
                <p class="text-sm">Nenhuma experiência profissional cadastrada.</p>
              </li>
            </ul>
            <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-2 hide-for-print"
                    @click="showModal('experience')">Adicionar Experiência</button>
          </div>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Educação
        </h2>
        <div>
          <ul>
            <li v-for="education in resume.education" :key="education.id" class="mb-4">
              <h3 class="text-lg font-bold">{{ education.degree }} - {{ education.institution }}</h3>
              <p class="text-sm font-medium">{{ education.startDate }} - {{ education.endDate }}</p>
              <p>{{ education.description }}</p>
            </li>
            <li class="mb-4" v-if="resume.education && Object.keys(resume.socialmedia).length === 0">
              <p class="text-sm">Nenhuma formação cadastrada.</p>
            </li>
          </ul>
        </div>
        <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-2 hide-for-print"
          @click="showModal('education')">Adicionar Educação</button>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Disponibilidade para Viagem</h2>
        <div>
          <p class="text-sm">{{ resume.travelAvailability ? "Disponível para viagens" : "Não disponível para viagens" }}</p>
          <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-2 hide-for-print"
          @click="showModal('travelAvailability')">Editar Disponibilidade para Viagem</button>
        </div>

        <h2 class="text-lg font-bold text-primary w-full border-b-[2px] border-primary mt-8 mb-5 uppercase">Habilidades
        </h2>
        <div>
          <ul>
            <h3 class="mb-2 font-semibold">Interpessoais</h3>
            <li class="mb-2 ml-4" v-for="(skill, index) in resume.softSkills" :key="index">
              <p class="text-sm font-medium">• {{ skill }}</p>
            </li>
            <h3 class="mb-2 font-semibold">Técnicas</h3>
            <li class="mb-2" v-if="!resume.softSkills.length">
              <p class="text-sm">Nenhuma habilidade cadastrada (Soft Skills).</p>
            </li>
          </ul>
          <ul>
            <li class="mb-4 ml-4" v-for="(skill, index) in resume.hardSkills" :key="index">
              <p class="text-sm font-medium">• {{ skill }}</p>
            </li>
            <li class="mb-4" v-if="!resume.hardSkills.length">
              <p class="text-sm">Nenhuma habilidade cadastrada (Hard Skills).</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="modalVisible" class="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="modal-content bg-white dark:bg-boxdark p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
        <span class="close absolute top-2 right-2 text-graydark cursor-pointer" @click="closeModal">&times;</span>
        <h2 class="text-2xl font-semibold mb-4" v-if="modalType === 'experience'">Adicionar Experiência</h2>
        <h2 class="text-2xl font-semibold mb-4" v-if="modalType === 'education'">Adicionar Educação</h2>
        <h2 class="text-2xl font-semibold mb-4" v-if="modalType === 'language'">Adicionar Idioma</h2>
        <h2 class="text-2xl font-semibold mb-4" v-if="modalType === 'socialmedia'">Adicionar Rede Social</h2> 

        <div v-if="modalType === 'experience'">
          <label class="block mb-2" for="JobPosition">Cargo</label>
          <input v-model="newEntry.jobTitle" type="text" placeholder="Título do cargo" id="JobPosition"
            name="JobPosition"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
          <label class="block mb-2 mt-4" for="jobEnterprise">Empresa</label>
          <input v-model="newEntry.company" type="text" placeholder="Nome da empresa" id="jobEnterprise"
            name="jobEnterprise"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
          <label class="block mb-2 mt-4" for="jobInitDate">Data de Início</label>
          <input type="month" placeholder="Digite aqui" id="jobInitDate" name="jobInitDate"
            class="w-full lg:w-2/3 rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="newEntry.startDate" required />
          <label class="block mb-2 mt-4" for="jobEndDate">Data de Término</label>
          <input type="month" placeholder="Digite aqui" id="jobEndDate" name="jobEndDate"
            class="w-full lg:w-2/3 rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="newEntry.endDate" required />
          <label class="block mb-2 mt-4" for="jobDescription">Descrição</label>
          <textarea v-model="newEntry.description" placeholder="Funções e responsabilidades" id="jobDescription"
            name="jobDescription"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
          </textarea>
        </div>

        <div v-if="modalType === 'education'">
          <label class="block mb-2" for="educationDegree">Grau de Educação</label>
          <input v-model="newEntry.degree" type="text" placeholder="Grau de educação" id="educationDegree"
            name="educationDegree"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">

          <label class="block mb-2 mt-4" for="educationInstitution">Instituição</label>
          <input v-model="newEntry.institution" type="text" placeholder="Instituição" id="educationInstitution"
            name="educationInstitution"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">

          <label class="block mb-2 mt-4" for="jobInitDate">Data de Início</label>
          <input type="month" placeholder="Digite aqui" id="jobInitDate" name="jobInitDate"
            class="w-full lg:w-2/3 rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="newEntry.startDate" required />

          <label class="block mb-2 mt-4" for="jobEndDate">Data de Término</label>
          <input type="month" placeholder="Digite aqui" id="jobEndDate" name="jobEndDate"
            class="w-full lg:w-2/3 rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
            v-model="newEntry.endDate" required />

          <label class="block mb-2 mt-4" for="educationDescription">Descrição</label>
          <textarea v-model="newEntry.description" placeholder="Descrição do curso" id="educationDescription"
            name="educationDescription"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
        </textarea>
        </div>

        <div v-if="modalType === 'language'">
          <label class="block mb-2" for="languagueName">Nome do Idioma</label>
          <input v-model="newEntry.name" type="text" placeholder="Nome do idioma" id="languagueName"
            name="languagueName"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
          <label class="block mb-2 mt-4" for="languageLevel">Nível de Proficiência</label>
          <input v-model="newEntry.level" type="text" placeholder="Ex: Básico, Intermediário, Avançado, Fluente"
            id="languageLevel" name="languageLevel"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
        </div>
       
        <div v-if="modalType === 'socialmedia'">
          <label class="block mb-2" for="socialMediaPlatform">Plataforma</label>
          <input v-model="newEntry.platform" type="text" placeholder="Ex: LinkedIn, Instagram" id="socialMediaPlatform"
            name="socialMediaPlatform"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
          <label class="block mb-2 mt-4" for="socialMediaLink">Link</label>
          <input v-model="newEntry.link" type="text" placeholder="Link do perfil" id="socialMediaLink"
            name="socialMediaLink"
            class="w-full p-2 rounded-md focus:border-primary focus-visible:shadow-none border border-black dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white">
        </div>
 
        <div v-if="modalVisible && modalType === 'travelAvailability'" class="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="modal-content bg-white dark:bg-boxdark p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
        <span class="close absolute top-2 right-2 text-graydark cursor-pointer" @click="closeModal">&times;</span>
        <h2 class="text-2xl font-semibold mb-4">Editar Disponibilidade para Viagem</h2>
        <div>
          <label class="inline-flex items-center">
            <input type="checkbox" v-model="resume.travelAvailability" class="form-checkbox text-primary">
            <span class="ml-2 text-sm">Disponível para viagens</span>
          </label>
        </div>


        <div class="flex justify-end items-center gap-5 mt-4">
          <button class="underline" @click="closeModal">Cancelar</button>
          <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white"
            @click="saveTravelAvailability">Salvar</button>
        </div>
      </div>
    </div>

        <div class="flex justify-end items-center gap-5 mt-4">
          <button class="underline" @click="closeModal">Cancelar</button>
          <button class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white"
            @click="addEntry">Adicionar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center">
  <button @click="exportToPDF" class="py-2 px-4 text-sm bg-primary hover:bg-primary_hover rounded-lg text-white mt-4 hide-for-print">
    Exportar como PDF
  </button>
</div>

</template>

<script>
import { fetchCandidate } from "@/services/candidateService";
import { ref } from "vue";
const userType = localStorage.getItem("usertype");
import axios from "@/api/axios.js";
const candidateId = ref('');
import html2pdf from 'html2pdf.js';

export default {
  name: "Resume",
  data() {
    return {
      usertype: localStorage.getItem("usertype"),
      resume: {
        photo: "",
        name: "",
        contact: {
          email: "",
          cellphone: "",
        },
        document: {
          cpf: "",
        },
        age: "",
        experience: [],
        education: [],
        languages: [],
        socialmedia: {
          linkedin: "",
          instagram: "",
          facebook: "",
          twitter: "",
          tiktok: "",
        },
        softSkills: [],
        hardSkills: [],
        travelAvailability: false,
      },
      modalVisible: false,
      modalType: "",
      newEntry: {
        jobTitle: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
        degree: "",
        institution: "",
        name: "",
        level: "",
        platform: "",
        link: "",
      },
      userType: '',
    };
  },
  methods: {
    async fetchCandidateId() {
      const userId = this.getIdUrl();

      if (!userId) {
        console.error("Erro: userId é null ou undefined");
        return;
      }

      try {
        const candidateData = await fetchCandidate(userId);
        candidateId.value = candidateData.id;
        this.updateResumeData(candidateData);
      } catch (error) {
        console.error("Erro ao carregar Candidato:", error);
      }
    },
    getIdUrl() {
      let url = window.location.href;
      let segments = url.split("/");
      let id = segments[4];
      return id;
    },
    updateResumeData(candidateData) {
      this.resume.photo = candidateData.imagepath || "";
      this.resume.name = candidateData.fullname || "";
      this.resume.contact.email = candidateData.email || "";
      this.resume.contact.cellphone = candidateData.cellphone || "";
      this.resume.document.cpf = candidateData.cpf || "";
      this.resume.age = this.calculateAge(candidateData.dateofbirth || "");

      // Initialize arrays if not provided by backend
      this.resume.experience = JSON.parse(candidateData.experience || "[]");
      this.resume.education = JSON.parse(candidateData.education || "[]");
      this.resume.languages = JSON.parse(candidateData.languages || "[]");
          
      this.resume.socialmedia.linkedin = candidateData.linkedin || "";
      this.resume.socialmedia.instagram = candidateData.instagram || "";
      this.resume.socialmedia.facebook = candidateData.facebook || "";
      this.resume.socialmedia.twitter = candidateData.xtwitter || "";
      this.resume.socialmedia.tiktok = candidateData.tiktok || "";

      this.resume.softSkills = (candidateData.skills_soft || '').split(', ').map(skill => skill.replace(/^sf_/, ''));
      this.resume.hardSkills = (candidateData.skills_hard || '').split(', ').map(skill => skill.replace(/^hs_/, ''));
    },
    calculateAge(dateOfBirth) {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    showModal(type) {
      this.modalType = type;
      this.modalVisible = true;
      this.resetNewEntry();
    },
    closeModal() {
      this.modalVisible = false;
    },
    resetNewEntry() {
      this.newEntry = {
        jobTitle: "",
        company: "",
        startDate: "",
        endDate: "",
        description: "",
        degree: "",
        institution: "",
        name: "",
        level: "",
        platform: "",
        link: "",
      };
    },

    async exportToPDF() {
      const element = document.querySelector('.resume');
      const buttons = document.querySelectorAll('.hide-for-print');
      buttons.forEach(button => button.style.display = 'none');

      const opt = {
        margin: 1,
        filename: 'curriculo.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true
        },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      try {
        await html2pdf().from(element).set(opt).save();
      } catch (error) {
        console.error('Erro ao exportar para PDF:', error);
      } finally {
        buttons.forEach(button => button.style.display = 'inline-block');
      }
    },


    async addEntry() {
      if (this.modalType === "experience") {
        const newExperience = {
          id: Date.now(),
          jobTitle: this.newEntry.jobTitle,
          company: this.newEntry.company,
          startDate: this.newEntry.startDate,
          endDate: this.newEntry.endDate,
          description: this.newEntry.description,
        };
        this.resume.experience.push(newExperience);
        await this.saveData("experience", this.resume.experience);
      } else if (this.modalType === "education") {
        const newEducation = {
          id: Date.now(),
          degree: this.newEntry.degree,
          institution: this.newEntry.institution,
          startDate: this.newEntry.startDate,
          endDate: this.newEntry.endDate,
          description: this.newEntry.description,
        };
        this.resume.education.push(newEducation);
        await this.saveData("education", this.resume.education);
      } else if (this.modalType === "language") {
        const newLanguage = {
          id: Date.now(),
          name: this.newEntry.name,
          level: this.newEntry.level,
        };
        this.resume.languages.push(newLanguage);
        await this.saveData("languages", this.resume.languages);
      } else if (this.modalType === "socialmedia") {
        // Assuming newEntry has platform and link fields
        const platform = this.newEntry.platform.toLowerCase(); // Normalize platform name
        this.resume.socialmedia[platform] = this.newEntry.link;
        await this.saveData("socialmedia", this.resume.socialmedia);
      }
      this.closeModal();
    },
    async saveData(key, data) {
    const url = `/candidates/${candidateId.value}`;
    try {
      const response = await axios.patch(url, { [key]: data });
      return response.data;
    } catch (error) {
      console.error(`Erro ao salvar ${key}:`, error);
      throw error;
    }
},
    async loadData(key) {
    const url = `/candidates/${candidateId.value}`;
    try {
      const response = await axios.get(url);
      return response.data[key]; // Retorna somente a parte específica dos dados (experience, education, languages)
    } catch (error) {
      console.error(`Erro ao carregar ${key}:`, error);
      throw error;
    }
  }
  },
  mounted() {
    localStorage.setItem("page", "Currículo");
    this.fetchCandidateId();
  },
};
</script>

<style scoped>
.modal {
  z-index: 1000;
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
}

.close {
  cursor: pointer;
}


</style>