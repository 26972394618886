

<script setup>
import { ref, computed, onMounted } from "vue";
import axios from "@/api/axios.js";
import Swal from 'sweetalert2';
import Papa from 'papaparse';

import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

const pageTitle = ref("Lista De Candidatos");
const candidates = ref([]);
const error = ref(null);
const searchQuery = ref("");

const selectedCandidate = ref(null);
const showPersonalData = ref(false);

const fetchCandidates = async () => {
  try {
    const response = await axios.get('/candidates');
    candidates.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar candidatos:', error);
    error.value = error.message;
  }
};

onMounted(() => {
  fetchCandidates();
});

const filteredCandidates = computed(() => {
  const query = searchQuery.value ? searchQuery.value.toLowerCase() : '';
  return candidates.value.filter(candidate => {
    const fullname = candidate.fullname ? candidate.fullname.toLowerCase() : '';
    const email = candidate.email ? candidate.email.toLowerCase() : '';
    return fullname.includes(query) || email.includes(query);
  });
});


const deleteCandidate = (candidate) => {
  Swal.fire({
    title: 'Tem certeza?',
    text: 'Você não poderá reverter isso!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Sim, excluir!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      axios.delete(`/candidates/${candidate.id}`)
        .then(() => {
          const index = candidates.value.indexOf(candidate);
          candidates.value.splice(index, 1);
          Swal.fire(
            'Excluído!',
            'O candidato foi removido.',
            'success'
          );
        })
        .catch(error => {
          console.error('Erro ao excluir candidato:', error);
          Swal.fire(
            'Erro!',
            'Ocorreu um erro ao excluir o candidato.',
            'error'
          );
        });
    }
  });
};

const viewPersonalData = (candidate) => {
  selectedCandidate.value = candidate;
  showPersonalData.value = true;
};

const generateCSV = () => {
  const fields = ['fullname', 'email', 'cellphone'];
  const data = filteredCandidates.value.map(candidate => ({
    fullname: candidate.fullname,
    email: candidate.email,
    cellphone: candidate.cellphone
  }));

  const csv = Papa.unparse(data, {
    header: true,
    delimiter: ',',
    quotes: true,
    skipEmptyLines: true
  });

  const bom = "\uFEFF";
  const csvWithBom = bom + csv;

  const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', 'Lista_De_Candidatos(Admin).csv');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
</script>
<template>
  <DefaultLayout>
    <div>
      <BreadcrumbDefault :pageTitle="pageTitle" />

      <!-- Search input field -->
      <div class="mb-4">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Pesquisar por nome ou email..."
          class="w-full px-4 py-2 border rounded"
        />
      </div>

      <!-- Botão para gerar CSV -->
      <div class="text-right mb-4">
        <button @click="generateCSV" class="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded">
          Baixar CSV
        </button>
      </div>

      <!-- Se houver um erro, exibe uma mensagem -->
      <div v-if="error" class="text-red-600">
        Erro ao carregar candidatos: {{ error }}
      </div>

      <!-- Tabela de Candidatos -->
      <table v-if="filteredCandidates.length > 0" class="w-full border-collapse bg-white dark:bg-black mt-4">
        <thead>
          <tr class="bg-strokedark dark:bg-black border-b-[1px] border-[#edf2f7] dark:border-bodydark2 text-white text-[14px] leading-none text-center">
            <th class="py-3 px-4">Nome Completo</th>
            <th class="py-3 px-4">Email</th>
            <th class="py-3 px-4">Telefone</th>
            <th class="py-3 px-4">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="candidate in filteredCandidates" :key="candidate.id"
            class="border-[1px] border-[#edf2f7] text-[base] md:text-[lg] text-center leading-none odd:bg-white odd:dark:bg-strokedark even:bg-gray-2 even:dark:bg-black dark:border-strokedark">
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px] text-[base] md:text-[lg]">{{ candidate.fullname }}</td>
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px] text-[base] md:text-[lg]">{{ candidate.email }}</td>
            <td class="border-[1px] border-[#edf2f7] py-[8px] px-[15px] text-[base] md:text-[lg]">{{ candidate.cellphone }}</td>
            <td class="py-[8px] px-[15px] flex justify-center items-center gap-2">
              <button @click="viewPersonalData(candidate)" class="flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded-full">
                <i class="fas fa-user-circle text-xl mr-1"></i>
                <span class="hidden md:inline">Dados Pessoais</span>
              </button>
              <button @click="deleteCandidate(candidate)" class="text-red-light hover:opacity-75 ml-2"><i class="fa-solid fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Se não houver candidatos -->
      <div v-else class="mt-4 text-[base] md:text-[lg]">
        Não há candidatos para mostrar.
      </div>

      <!-- Seção/Modal de Dados Pessoais -->
      <div v-if="showPersonalData" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
        <div class="bg-white dark:bg-black p-6 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto h-[85svh] mt-[8vh]">
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl">Dados Pessoais de {{ selectedCandidate.fullname }}</h2>
            <button @click="showPersonalData = false" class="text-xl">✖️</button>
          </div>
          <div class="flex justify-center mb-4">
            <img class="w-32 h-32 rounded-full" :src="selectedCandidate.imagepath" alt="Imagem do Candidato" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p><strong>CPF:</strong> {{ selectedCandidate.cpf }}</p>
            <p><strong>Data de Nascimento:</strong> {{ selectedCandidate.dateofbirth }}</p>
            <p><strong>Gênero:</strong> {{ selectedCandidate.gender }}</p>
            <p><strong>Identidade de Gênero:</strong> {{ selectedCandidate.genderIdentity }}</p>
            <p><strong>Local de Nascimento:</strong> {{ selectedCandidate.birthplace }}</p>
            <p><strong>Estado Civil:</strong> {{ selectedCandidate.maritalstatus }}</p>
            <p><strong>Estado:</strong> {{ selectedCandidate.state }}</p>
            <p><strong>Cidade:</strong> {{ selectedCandidate.city }}</p>
            <p><strong>Nível Educacional:</strong> {{ selectedCandidate.educationallevel }}</p>
            <p><strong>Biografia:</strong> {{ selectedCandidate.biography }}</p>
            <p><strong>Sexo:</strong> {{ selectedCandidate.sex }}</p>
            <p><strong>PCD:</strong> {{ selectedCandidate.pcd }}</p>
            <p><strong>CID:</strong> {{ selectedCandidate.cid }}</p>
            <p><strong>CEP:</strong> {{ selectedCandidate.zipCode }}</p>
            <p><strong>Rua:</strong> {{ selectedCandidate.street }}</p>
            <p><strong>Bairro:</strong> {{ selectedCandidate.neighborhood }}</p>
            <p><strong>Programa Social:</strong> {{ selectedCandidate.socialProgram }}</p>
            <p><strong>Estágio:</strong> {{ selectedCandidate.internship }}</p>
            <p><strong>Nível de Renda:</strong> {{ selectedCandidate.incomeLevel }}</p>
            <p><strong>Número de Pessoas na Casa:</strong> {{ selectedCandidate.numberPeopleHome }}</p>
            <p><strong>Renda Total:</strong> {{ selectedCandidate.totalIncome }}</p>
            <p><strong>Vínculo Empregatício:</strong> {{ selectedCandidate.employmentLink }}</p>
            <p><strong>Instituição de Ensino:</strong> {{ selectedCandidate.educationInstitution }}</p>
            <p><strong>Tipo de Instituição:</strong> {{ selectedCandidate.institutionType }}</p>
            <p><strong>Cursos Concluídos:</strong> {{ selectedCandidate.completedCourses }}</p>
            <p><strong>Tipos de Trabalho Desejado:</strong> {{ selectedCandidate.desiredJobTypes }}</p>
            <p><strong>Salário Esperado:</strong> {{ selectedCandidate.expectedSalary }}</p>
            <p><strong>LinkedIn:</strong> 
              <span v-if="selectedCandidate.linkedin">
                <a class="text-blue-700 underline" :href="selectedCandidate.linkedin" target="_blank">{{ selectedCandidate.linkedin }}</a>
              </span>
              <span v-else>Não Informado</span>
            </p>
            <p><strong>Instagram:</strong> 
              <span v-if="selectedCandidate.instagram">
                <a class="text-blue-700 underline" :href="selectedCandidate.instagram" target="_blank">{{ selectedCandidate.instagram }}</a>
              </span>
              <span v-else>Não Informado</span>
            </p>
            <p><strong>Facebook:</strong> 
              <span v-if="selectedCandidate.facebook">
                <a class="text-blue-700 underline" :href="selectedCandidate.facebook" target="_blank">{{ selectedCandidate.facebook }}</a>
              </span>
              <span v-else>Não Informado</span>
            </p>
            <p><strong>Twitter:</strong> 
              <span v-if="selectedCandidate.xtwitter">
                <a class="text-blue-700 underline" :href="selectedCandidate.xtwitter" target="_blank">{{ selectedCandidate.xtwitter }}</a>
              </span>
              <span v-else>Não Informado</span>
            </p>
            <p><strong>TikTok:</strong> 
              <span v-if="selectedCandidate.tiktok">
                <a class="text-blue-700 underline" :href="selectedCandidate.tiktok" target="_blank">{{ selectedCandidate.tiktok }}</a>
              </span>
              <span v-else>Não Informado</span>
            </p>
            <p><strong>Idiomas:</strong> {{ selectedCandidate.languages }}</p>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>
