import axios from "axios";

interface JobDocument {
  id: string;
  title: string;
  departament: string | null;
  company: {
    name: string;
  };
  slug: string;
  job_quantity: number | null;
}

export const fetchVecanciesFabrica = async () => {
  try {
    const response = await axios.get(
      "https://data1.fgenios.com/collections/job/documents/search?q=*&query_by=title,location,education,requirements_and_skills,activities_description,benefits,departament&sort_by=updated_at:asc&filter_by=status:published&group_by=&facet_by=job_format,departament,education,company.name,experience_level,dedication&group_limit=1&per_page=250",
      {
        headers: {
          "X-TYPESENSE-API-KEY": import.meta.env.VITE_FABRICA_VECANCIES_API_KEY,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Erro ao buscar dados das vagas");
    } else {
      const jobData = response.data.hits;
      console.log("Dados recebidos da API Fabrica de Gênios:", jobData);

      const formattedJobs = jobData.map((job: { document: JobDocument }) =>
        formatjobData(job)
      );

      console.log(
        "Dados formatados Fabrica de Gênios:",
        formattedJobs
      );

      return formattedJobs;
    }
  } catch (error) {
    console.error("Erro ao buscar dados da API:", error);
    return [];
  }
};

const formatjobData = (job: { document: JobDocument }) => {
  return {
    id: job.document.id,
    position: job.document.title,
    area: job.document.departament
      ? job.document.departament
      : "Não informado",
    companyname: job.document.company.name,
    post_permalink: `https://app.jobsfg.com/vagas/${job.document.slug}`,
    jobs_number: job.document.job_quantity
      ? job.document.job_quantity
      : "Não informado",
  };
};

export default { fetchVecanciesFabrica };
