<script setup lang="ts">
import { ref } from "vue";
import Vue from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ProfileEnterpriseComponent from "@/components/ProfileEnterprise/ProfileEnterpriseComponent.vue";

const pageTitle = ref("Análise de Perfil");
</script>

<template >
  <DefaultLayout>
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <div class="bg-white dark:bg-black border border-stroke rounded-lg">
      <ProfileEnterpriseComponent />
    </div>
  </DefaultLayout>
</template>