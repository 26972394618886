<script setup>
import { ref } from "vue";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import TableOne from "@/components/Tables/TableOne.vue";
import TableTwo from "@/components/Tables/TableTwo.vue";
import TableThree from "@/components/Tables/TableThree.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";

const pageTitle = ref("Tables");
</script>

<template>
  <DefaultLayout>
    <!-- Breadcrumb Start -->
    <BreadcrumbDefault :pageTitle="pageTitle" />
    <!-- Breadcrumb End -->

    <div class="flex flex-col gap-10">
      <TableOne />
      <TableTwo />
      <TableThree />
    </div>
  </DefaultLayout>
</template>
