<template>
  <LoginLayout>
    <div
      class="bg-white dark:bg-black p-8 rounded-lg shadow-md w-full lg:w-[412px] mx-auto"
    >
      <h2 class="text-2xl font-bold mb-6 text-center text-primary">
        Criar Conta
      </h2>
      <form @submit.prevent="handleSubmit">
        <div class="mb-4">
          <label
            for="email"
            class="mb-2.5 block font-medium text-black dark:text-white"
            >Email</label
          >
          <div class="relative">
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Digite seu e-mail"
              class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              required
            />
            <div class="absolute top-[18px] right-[10px]">
              <svg
                class="fill-current"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                    fill=""
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label
            for="password"
            class="mb-2.5 block font-medium text-black dark:text-white"
            >Senha</label
          >
          <div class="relative">
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Digite sua senha"
              class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              required
            />
            <div class="absolute top-[18px] right-[10px]">
              <svg
                class="fill-current"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                    fill=""
                  />
                  <path
                    d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                    fill=""
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label
            for="confirmPassword"
            class="mb-2.5 block font-medium text-black dark:text-white"
            >Confirmar Senha</label
          >
          <div class="relative">
            <input
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              placeholder="Confirme sua senha"
              class="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary text-black dark:text-white"
              required
            />
            <div
              v-if="passwordError"
              class="flex justify-start items-center gap-1 mt-2"
            >
              <img src="@/assets/images/icon/alert.svg" width="18px" alt="" />
              <p class="text-red text-sm font-medium mt-1">
                {{ passwordError }}
              </p>
            </div>
            <div class="absolute top-[18px] right-[10px]">
              <svg
                class="fill-current"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                    fill=""
                  />
                  <path
                    d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                    fill=""
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div
          v-if="
            usertype == 'enterprise' ||
            usertype == 'secretary' ||
            usertype == 'userSecretary'
          "
          class="mb-4"
        >
          <label
            for="typeEnterprise"
            class="mb-2.5 block font-medium text-black dark:text-white"
            >Tipo de Organização</label
          >
          <div class="relative">
            <select
              required
              v-model="typeEnterprise"
              class="w-full rounded border border-stroke py-3 px-4.5 font-normal text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
              name="typeEnterprise"
              id="typeEnterprise"
            >
              <option disabled selected value="">Selecione</option>
              <option value="pub">Pública</option>
              <option value="priv">Privada</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          class="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 font-medium text-white transition hover:bg-opacity-90"
        >
          Criar Conta
        </button>
        <div class="mt-6 text-center">
          <p class="font-medium">
            Já possui uma conta?<br />
            <router-link to="/login" class="text-primary font-bold"
              >Entrar</router-link
            >
          </p>
        </div>
        <div class="flex justify-center items-center mt-5">
          <DarkModeSwitcher />
        </div>
      </form>
    </div>
  </LoginLayout>
</template>

<script setup>
import { ref } from "vue";
import axios from "@/api/axios.js";
import { useRouter } from "vue-router";
import LoginLayout from "@/layouts/LoginLayout.vue";
import DarkModeSwitcher from "@/components/Header/DarkModeSwitcher.vue";

const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const usertype = ref(localStorage.getItem("usertype") || "candidate");
const passwordError = ref("");
const router = useRouter();
const typeEnterprise = ref("");

const validatePasswords = () => {
  if (password.value !== confirmPassword.value) {
    passwordError.value = "As senhas não correspondem.";
    return false;
  } else {
    passwordError.value = "";
    return true;
  }
};

const validateUserType = () => {
  const validUserTypes = [
    "candidate",
    "enterprise",
    "secretary",
    "userSecretary",
    "admin",
  ];
  if (!validUserTypes.includes(usertype.value)) {
    alert(`Tipo de usuário inválido: ${usertype.value}`);
    return false;
  }
  return true;
};

const handleSubmit = async () => {
  console.log("usertype: " + usertype.value);
  if (!validatePasswords()) {
    return;
  }
  if (!validateUserType()) {
    return;
  }

  try {
    if (typeEnterprise.value == "pub") {
      usertype.value = "secretary";
    } else if (typeEnterprise.value == "priv") {
      usertype.value = "enterprise";
    }

    console.log("Dados enviados para registro:", {
      email: email.value,
      password: password.value,
      usertype: usertype.value,
    });

    const response = await axios.post("/auth/register", {
      email: email.value,
      password: password.value,
      usertype: usertype.value,
    });

    console.log("Cadastro bem-sucedido:", response.data);
    localStorage.setItem("token", response.data.access_token);
    router.push(`/login?usertype=${usertype.value}`);
  } catch (error) {
    console.log("usertype: " + usertype.value);
    console.error("Erro no cadastro:", error);
    const errorMessage = error.response?.data?.message || error.message;
    alert(`Erro no cadastro: ${errorMessage}`);
  }
};
</script>
