<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useSidebarStore } from "@/stores/sidebar";
import DarkModeSwitcher from "./DarkModeSwitcher.vue";
import DropdownUser from "./DropdownUser.vue";
import axios from 'axios';

const { isSidebarOpen, toggleSidebar } = useSidebarStore();
const router = useRouter();

const searchQuery = ref('');
const showSuggestions = ref(false);
const showFilterModal = ref(false);
let selectedSuggestionIndex = ref(-1);
const results = ref<string[]>([]);
const filterOptions = ref({
  jovemAprendiz: false,
  estagio: true
});

const filteredResults = computed(() => {
  if (!searchQuery.value) {
    return [];
  }
  return results.value.filter(result =>
    result.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const fetchResults = async (query: string) => {
  try {
    const response = await axios.get('https://themosvagas.com.br/wp-json/api-externa/v1/resultados', {
      headers: {
        Authorization:
        `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
      params: {
        tipo: 'estagio',
        regiao: 'sao-paulo',
        area: '',
        term: query,
        paged: 1,
        total: 15
      }
    });
    const data = await response.data;
    results.value = data.map((item: { post_title: string }) => item.post_title);
  } catch (error) {
    console.error('Error fetching results:', error);
  }
};

const selectResult = (result: string) => {
  searchQuery.value = result;
  showSuggestions.value = false;
};

const handleSubmit = async () => {
  try {
    router.push({
      path: '/vacanciesList',
      query: { q: searchQuery.value }
    });
  } catch (error) {
    console.error('Error updating results and redirecting:', error);
  }
};

const handleInputFocus = () => {
  if (searchQuery.value) {
    showSuggestions.value = true;
  }
};

const handleInputBlur = () => {
  showSuggestions.value = false;
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'ArrowDown') {
    if (selectedSuggestionIndex.value < filteredResults.value.length - 1) {
      selectedSuggestionIndex.value++;
    }
  } else if (event.key === 'ArrowUp') {
    if (selectedSuggestionIndex.value > 0) {
      selectedSuggestionIndex.value--;
    }
  } else if (event.key === 'Enter') {
    if (selectedSuggestionIndex.value !== -1) {
      selectResult(filteredResults.value[selectedSuggestionIndex.value]);
    }
  }
};

watch(searchQuery, (newValue) => {
  if (newValue) {
    showSuggestions.value = true;
    fetchResults(newValue);
  } else {
    showSuggestions.value = false;
  }
});

onMounted(() => {
  // Fetch initial data if needed
});

const applyFilters = () => {
  // Lógica para aplicar os filtros
  console.log(filterOptions.value);
  showFilterModal.value = false;
};

const isExcluirPagina = computed(() => {
  return router.currentRoute.value.path === '/excluir-pagina';
});
</script>

<template>
  <header v-if="!isExcluirPagina" class="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
    <div class="flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11">
      <div class="flex items-center gap-2 sm:gap-4 lg:hidden">
        <button class="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
                @click="toggleSidebar">
          <span class="relative block h-5.5 w-5.5 cursor-pointer">
            <span class="du-block absolute right-0 h-full w-full">
              <span class="relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white"
                    :class="{ '!w-full delay-300': !isSidebarOpen }"></span>
              <span class="relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white"
                    :class="{ '!w-full delay-400': !isSidebarOpen }"></span>
              <span class="relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white"
                    :class="{ '!w-full delay-500': !isSidebarOpen }"></span>
            </span>
            <span class="du-block absolute right-0 h-full w-full rotate-45">
              <span class="absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white"
                    :class="{ '!h-0 delay-[0]': !isSidebarOpen }"></span>
              <span class="delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white"
                    :class="{ '!h-0 dealy-200': !isSidebarOpen }"></span>
            </span>
          </span>
        </button>
        <!-- <router-link class="block flex-shrink-0 lg:hidden" to="/">
          <img src="@/assets/images/logo/logo-icon.svg" alt="Logo"/>
        </router-link> -->
      </div>
      <div class="hidden sm:block relative">
        <form @submit.prevent="handleSubmit">
          <div class="relative">
            <button class="absolute top-1/2 left-0 -translate-y-1/2">
              <svg class="fill-body hover:fill-primary dark:fill-bodydark dark:hover:fill-primary" width="20" height="20"
                   viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.16666 3.33332C5.945 3.33332 3.33332 5.945 3.33332 9.16666C3.33332 12.3883 5.945 15 9.16666 15C12.3883 15 15 12.3883 15 9.16666C15 5.945 12.3883 3.33332 9.16666 3.33332ZM1.66666 9.16666C1.66666 5.02452 5.02452 1.66666 9.16666 1.66666C13.3088 1.66666 16.6667 5.02452 16.6667 9.16666C16.6667 13.3088 13.3088 16.6667 9.16666 16.6667C5.02452 16.6667 1.66666 13.3088 1.66666 9.16666Z"
                      fill=""/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.2857 13.2857C13.6112 12.9603 14.1388 12.9603 14.4642 13.2857L18.0892 16.9107C18.4147 17.2362 18.4147 17.7638 18.0892 18.0892C17.7638 18.4147 17.2362 18.4147 16.9107 18.0892L13.2857 14.4642C12.9603 14.1388 12.9603 13.6112 13.2857 13.2857Z"
                      fill=""/>
              </svg>
            </button>
            <input type="text" placeholder="Encontre sua vaga"
                   class="w-full xl:!w-125 !pr-4 !pl-9 focus:outline-none !bg-transparent" v-model="searchQuery"
                   @focus="handleInputFocus" @blur="handleInputBlur" @keydown="handleKeyDown"/>
          </div>
        </form>
        <ul v-if="showSuggestions && filteredResults.length"
            class="absolute z-10 w-full bg-white border border-gray-200 shadow-lg">
          <li v-for="(result, index) in filteredResults" :key="result"
              :class="{ 'bg-gray-100': index === selectedSuggestionIndex }"
              class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              @click="selectResult(result)">
            {{ result }}
          </li>
        </ul>
      </div>
      <div class="flex items-center gap-3 2xsm:gap-7">
        <ul class="flex items-center gap-2 2xsm:gap-4">
          <li>
            <DarkModeSwitcher/>
          </li>
        </ul>
        <DropdownUser/>
      </div>
    </div>
  </header>

  <div v-if="showFilterModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-4 rounded-md">
      <h3 class="text-lg font-bold">Nível de Experiência</h3>
      <div class="mt-2">
        <label>
          <input type="checkbox" v-model="filterOptions.jovemAprendiz" /> Jovem Aprendiz
        </label>
      </div>
      <div class="mt-2">
        <label>
          <input type="checkbox" v-model="filterOptions.estagio" /> Estágio
        </label>
      </div>
      <div class="mt-4 flex justify-end">
        <button @click="applyFilters" class="mr-2 px-4 py-2 bg-blue-500 text-white rounded-md">Aplicar</button>
        <button @click="showFilterModal = false" class="px-4 py-2 bg-gray-300 rounded-md">Fechar</button>
      </div>
    </div>
  </div>
</template>